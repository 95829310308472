import {NgModule} from "@angular/core";
import {InfiniteScrollDirective} from "./infiniteScroll.directive";

@NgModule({
    declarations: [
        InfiniteScrollDirective
    ],
    exports: [
        InfiniteScrollDirective
    ]
})
export class InfiniteScrollModule {

}
