import {ElementRef, EmbeddedViewRef, TemplateRef, ViewContainerRef} from "@angular/core";

export abstract class TemplatePortal<T> {

    /** The embedded template that will be used to instantiate an embedded View in the host. */
    templateRef: TemplateRef<T>;

    /** Reference to the ViewContainer into which the template will be stamped out. */
    viewContainerRef: ViewContainerRef;

    context: T | undefined;

    private _attachedHost: TemplatePortalOutlet | null;

    protected constructor(template: TemplateRef<any>, viewContainerRef: ViewContainerRef, context?: T) {
        this.templateRef = template;
        this.viewContainerRef = viewContainerRef;
        if (context) {
            this.context = context;
        }
    }

    get origin(): ElementRef {
        return this.templateRef.elementRef;
    }

    /** Whether this portal is attached to a host. */
    get isAttached(): boolean {
        return this._attachedHost != null;
    }

    /**
     * Attach the the portal to the provided `PortalOutlet`.
     * When a context is provided it will override the `context` property of the `TemplatePortal`
     * instance.
     */
    attach(host: TemplatePortalOutlet, context: T | undefined = this.context): any {
        this.context = context;
        this._attachedHost = host;
        return host.attach(this);
    }

    detach(): void {
        this.context = undefined;
        let host = this._attachedHost;
        this._attachedHost = undefined;
        host.detach();
    }

    setAttachedHost(host: TemplatePortalOutlet | undefined) {
        this._attachedHost = host;
    }

}

export abstract class TemplatePortalOutlet {
    /** The portal currently attached to the host. */
    private _attachedPortal: TemplatePortal<any> | undefined;

    /** A function that will permanently dispose this host. */
    private _disposeFn: (() => void) | undefined;

    /** Whether this host has already been permanently disposed. */
    private _isDisposed: boolean = false;

    attach<T>(portal: TemplatePortal<T>, existView?: EmbeddedViewRef<T>): EmbeddedViewRef<T> {
        if (this.hasAttached()) {
            throw new Error("Already attached");
        }

        if (this._isDisposed) {
            throw new Error("Portal outlet disposed");
        }

        this._attachedPortal = portal;
        return this.attachTemplatePortal(portal, existView);
    }

    abstract attachTemplatePortal<C>(portal: TemplatePortal<C>, existView?: EmbeddedViewRef<C>): EmbeddedViewRef<C>;

    /** Detaches a previously attached portal. */
    detach(invokeDispose: boolean = true): void {
        if (this._attachedPortal) {
            this._attachedPortal.setAttachedHost(undefined);
            this._attachedPortal = undefined;
        }

        if (invokeDispose) {
            this._invokeDisposeFn();
        }
    }

    /** Permanently dispose of this portal host. */
    dispose(): void {
        if (this.hasAttached()) {
            this.detach();
        }

        this._invokeDisposeFn();
        this._isDisposed = true;
    }

    /** Whether this host has an attached portal. */
    hasAttached(): boolean {
        return !!this._attachedPortal;
    }

    /** @docs-private */
    setDisposeFn(fn: () => void) {
        this._disposeFn = fn;
    }

    private _invokeDisposeFn() {
        if (this._disposeFn) {
            this._disposeFn();
            this._disposeFn = undefined;
        }
    }
}
