import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2} from "@angular/core";
import {MemorySubject} from "../../../modules/store";
import {distinctUntilChanged, map, Observable, of} from "rxjs";
import {getEntityCssColor} from "../../models/EntityColor";
import {ImageSize} from "../../services/image.service";
import {IEntityWithAvatar} from "../../models/IEntityWithAvatar";

@Component({
    selector: "user-avatar,team-avatar",
    templateUrl: "./user-avatar.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./user-avatar.ng.css"
    ]
})
export class UserAvatarComponent implements OnInit {

    background$: Observable<string>;
    initials$: Observable<string>;
    navigateUrl$: Observable<string[]>;

    @Input() showTitle: boolean;
    @Input() size: ImageSize;
    @Input() link: boolean;
    title$: Observable<string>;

    private _entity$ = new MemorySubject<IEntityWithAvatar>();

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    get item(): IEntityWithAvatar {
        return this._entity$.value;
    }

    @Input() set item(val: IEntityWithAvatar) {
        this._entity$.next(val);
    }

    ngOnInit() {

        this.background$ = this._entity$
            .pipe(
                map(item => {
                    if (item.avatarPath) {
                        return "";
                    } else {
                        const color1 = getEntityCssColor(item.avatarColor1);
                        const color2 = getEntityCssColor(item.avatarColor2);
                        return `linear-gradient(to right, ${color1}, ${color2})`;
                    }
                }),
                distinctUntilChanged()
            );

        this.initials$ = this._entity$
            .pipe(
                map(item => {
                    return item.getInititals();
                }),
                distinctUntilChanged()
            );

        this.title$ = this._entity$
            .pipe(
                map(item => item.getPopupText()),
                distinctUntilChanged()
            );
        //
        // if (this.showTitle) {
        //     this._entity$.subscribe(item => {
        //         const popupText = item.getPopupText();
        //         if (popupText) {
        //             this.renderer.setAttribute(this.el.nativeElement, "title", popupText);
        //         } else {
        //             this.renderer.removeAttribute(this.el.nativeElement, "title");
        //         }
        //     });
        // }

        this.navigateUrl$ = this.link ? this._entity$
            .pipe(
                map(entity => entity.getNavigateUrl()),
                distinctUntilChanged()
            ) : of(undefined);

    }
}
