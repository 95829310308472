import {Component, NgZone, Renderer2, ViewEncapsulation} from "@angular/core";
import {AuthService} from "./core/services/auth.service";
import {NavigationEnd, Router} from "@angular/router";
import {ScrollService} from "./core/services/scroll.service";
import {CredentialService} from "./core/services/credential.service";
import {ModalInstance, ModalService} from "./modules/modal";
import {ModalWindowComponent} from "./modules/modal/components/modal-window/modal-window.component";
import {SupportService} from "./core/services/support.service";
import {applyScrollFixBody} from "./core/directives/scroll-fix-body.directive";

/*
 * App Component
 * Top Level Component
 */
@Component({
    selector: "renote-app",
    styleUrls: [
        "./app.print.bem.css",
        "./modal.bem.css",
        "../assets/css/lato/lato.bem.css",
        "../assets/css/normalize.bem.css",
        "../assets/css/base.bem.css"
    ],
    encapsulation: ViewEncapsulation.None,
    template: `
        <n-global-styles></n-global-styles>
        <app-layout close-menus-on-navigate></app-layout>`
})
export class AppComponent {

    constructor(
        auth: AuthService,
        router: Router,
        scrollSvc: ScrollService,
        modalSvc: ModalService,
        zone: NgZone,
        supportSvc: SupportService,
        renderer: Renderer2,
        creds: CredentialService) {


        auth.setCredentialsAdapter(creds);

        let contextualActivated = false;
        router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            if (!isContextual(evt.urlAfterRedirects)) {
                if (contextualActivated) {
                    contextualActivated = false;
                } else {
                    scrollSvc.moveTop();
                }
            } else {
                contextualActivated = true;
            }

        });

        modalSvc.openModalEvents.subscribe((evt: ModalInstance<any>) => {

            if (evt.window.instance instanceof ModalWindowComponent && evt.options.windowClass === "lightbox") {
                let sub: () => void;
                zone.runOutsideAngular(() => {
                    sub = applyScrollFixBody(evt.window.instance.windowWrapperElement, renderer, supportSvc);
                });
                evt.dismiss.subscribe(() => sub());
            }

        });
    }

}


function isContextual(url: string) {
    return url && url.startsWith("/doc"); // TODO kostil
}
