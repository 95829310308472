import {ChangeDetectionStrategy, Component, Input} from "@angular/core";

@Component({
    selector: "dr-logo",
    template: `<n-icon class="icon" [iconId]="'main-logo'"></n-icon>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./dr-logo.ng.css"
    ]
})
export class DrLogoComponent {

    // fields
    @Input() mod: string;

}
