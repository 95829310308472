export class DomainInfo {

    /**
     * Only external profiles are allowed. App should not display sign-in/sing-up page
     */
    readonly externalProfiles: boolean;
    /**
     * Domain name
     */
    readonly name: string;
    /**
     * Display title
     */
    readonly title: string;
    /**
     * Which emails are allowed
     */
    readonly emailRestrict: string;

    readonly signoutUrl: string;

    constructor(serverData: any) {
        this.externalProfiles = serverData.externalProfiles;
        this.name = serverData.name;
        this.title = serverData.title;
        this.emailRestrict = serverData.emailRestrict;
        this.signoutUrl = serverData.signoutUrl;
    }

    isEmailAllowed(email: string) {
        if (this.emailRestrict) {
            return email.toUpperCase().endsWith(this.emailRestrict.toUpperCase());
        }
        return true;
    }

}
