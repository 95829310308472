import {APP_BOOTSTRAP_LISTENER, Injector, ModuleWithProviders, NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ModalsModule} from "../modules/modal";
import {TranslateModule} from "../modules/translate";
import {SignInPageComponent} from "./pages/sign-in-page/sign-in-page.component";
import {SearchPickerModule} from "./components/search-picker/searchpicker.module";
import {UserPickerItemComponent} from "./components/user-picker/user-picker-item/user-picker-item.component";
import {CORE_DIRECTIVES} from "./directives";
import {InfiniteScrollModule} from "../modules/infinite-scroll";
import {startCoreServices} from "./StartBackgroundServices";
import {CORE_COMPONETNS} from "./components";
import {CORE_PAGES} from "./pages";
import {CORE_PIPES} from "./pipes";
import {ContextRoutingModule, registerContextualRoute} from "../modules/routing";
import {PortalsModule} from "../modules/portals";
import {SignUpPageComponent} from "./pages/sign-up-page/sign-up-page.component";
import {ResetPasswordPageComponent} from "./pages/reset-password-page/reset-password-page.component";
import {ConfirmPasswordResetPageComponent} from "./pages/confirm-password-reset/confirm-password-reset-page.component";
import {ExternalLoginPageComponent} from "./pages/external-login/external-login-page.component";
import {ErrorMessagesFactory, NFrameworkIconsFactory, NFrameworkModule} from "../modules/n-framework";
import {NToastsModule} from "../modules/n-toasts";
import {CommonModule} from "@angular/common";
import {AppIconsFactoryService} from "./services/n-framework-icons/app-icons-factory.service";
import {AppErrorMessagesService} from "./services/n-framework-icons/app-error-messages.service";
import {HttpClientModule} from "@angular/common/http";
import {EventManager} from "@angular/platform-browser";
import {OutZoneEventManager} from "./services/OutZoneEventManager";
import {DocumentEntryComponent} from "./pages/document-entry/document-entry.component";
import {NPopoversModule} from "../modules/n-popover";
import {SearchSuggestModalComponent} from "./components/search/search-suggest-modal/search-suggest-modal.component";
import {MentionUserPopoverComponent} from "./components/mention-user-popover/mention-user-popover.component";
import {TouchModule} from "../modules/touch";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MentionSuggestsComponent} from "./components/mention-suggests/mention-suggests.component";

const DEPENDENCY_MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SearchPickerModule,
    PortalsModule,

    ContextRoutingModule,
    HttpClientModule,
    NFrameworkModule,
    TouchModule,
    DragDropModule,

    NPopoversModule,
    TranslateModule,
    NToastsModule,
    ModalsModule,
    InfiniteScrollModule
];

@NgModule({
    declarations: [
        ...CORE_PIPES,
        ...CORE_COMPONETNS,
        ...CORE_DIRECTIVES,
        ...CORE_PAGES
    ],
    imports: [
        ...DEPENDENCY_MODULES
    ],
    exports: [
        ...CORE_DIRECTIVES,
        ...CORE_COMPONETNS,
        ...CORE_PIPES,
        ...DEPENDENCY_MODULES
    ],
    providers: [
        {
            provide: APP_BOOTSTRAP_LISTENER,
            useFactory: startCoreServices,
            deps: [Injector],
            multi: true
        }
    ]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                {provide: NFrameworkIconsFactory, useClass: AppIconsFactoryService},
                {provide: ErrorMessagesFactory, useClass: AppErrorMessagesService},
                {provide: EventManager, useClass: OutZoneEventManager}
            ]
        };
    }
}

registerContextualRoute(SignUpPageComponent);
registerContextualRoute(ResetPasswordPageComponent);
registerContextualRoute(ConfirmPasswordResetPageComponent);
registerContextualRoute(ExternalLoginPageComponent);
registerContextualRoute(DocumentEntryComponent, true);
