<div class="page">
    <div class="body">
        <form-header>
            <dr-logo></dr-logo>
        </form-header>

        <page-message *ngIf="errorKey$ | async" [messageKey]="errorKey$ | async"></page-message>

        <form-window *ngIf="!(errorKey$ | async)">
            <form-window-header>{{"pwdResetConfirm.header" | translate}}</form-window-header>

            <p *ngIf="success$ | async">
                {{ "pwdResetConfirm.successHint" | translate }}

                <a [routerLink]="['/']">
                    {{"signUp.signInAction" | translate}}
                </a>
            </p>

            <form (ngSubmit)="submit(form.value)" *ngIf="!(success$ | async)" [formGroup]="form" autocomplete="off">

                <dr-field label="signUp.pwd">
                    <n-input #pwdInput [focusOnDefault]="pwdInput" [formControlName]="'password'" [required]="true" [type]="'password'"
                             wide></n-input>
                </dr-field>
                <dr-field label="signUp.confirmPwd">
                    <n-input [formControlName]="'confirmPassword'" [required]="true" type="password" wide></n-input>
                </dr-field>

                <div class="submit">
                    <n-button [disabled]="confirming$ | async" [loading]="confirming$ | async" [text]="'pwdResetConfirm.submit'|translate"
                              [theme]="'prime'" [type]="'submit'"></n-button>
                </div>
            </form>
        </form-window>

        <form-window-extra [hideGoogle]="true" [showBoth]="true"></form-window-extra>
    </div>
    <div class="footer">
        <culture-switcher type="tabs"></culture-switcher>
    </div>
</div>
