import {UserRef} from "./UserRef";

export class UserSearchResult {
    readonly byEmail: boolean;
    readonly users: UserRef[];

    constructor(users: UserRef[], byEmail: boolean) {
        this.users = users;
        this.byEmail = byEmail;
    }

    static parse(data: any): UserSearchResult {
        return new UserSearchResult(UserRef.parseArray(data.users), !!data.byEmail);
    }

}
