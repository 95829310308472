import {ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, Renderer2} from "@angular/core";
import {ImageService, ImageSize} from "../../services/image.service";

@Component({
    selector: "avatar-img",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./avatar-img.ng.css"
    ]
})
export class AvatarImgComponent implements OnChanges {

    @Input()
    size: ImageSize = "medium";

    @Input()
    url: string;

    constructor(private element: ElementRef,
                private imgSvc: ImageService,
                private renderer: Renderer2) {

    }

    ngOnChanges() {
        if (!this.url) {
            this.renderer.setAttribute(this.element.nativeElement, "style", "");
        } else {
            let url = this.url;
            if (!isAbsUrl(url)) {
                url = this.imgSvc.getImageUrl(url, this.size);
            }
            this.renderer.setStyle(this.element.nativeElement, "background-image", "url('" + escapeStyleUrl(url) + "')");
        }
    }
}

function escapeStyleUrl(str: string): string {
    return str.split("(").join("%28").split(")").join("%29").split(";").join("%3B").split("'").join("%27");
}

function isAbsUrl(url: string): boolean {
    if (!url) {
        return false;
    }
    return (url.indexOf("https://") === 0 || url.indexOf("http://") === 0 || url.indexOf("//") === 0);
}
