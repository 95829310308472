import {Injectable} from "@angular/core";
import {distinctUntilChanged, filter, map, Observable, Subject} from "rxjs";
import {UserInfo} from "../models/UserInfo";
import {MemorySubject} from "../../modules/store";
import {AppService} from "./app.service";
import {FileService} from "./file.service";
import {SignupInfo, SignupResult} from "../models/SignupResult";
import {NotebookEditDocumentRestrict, NotebookInfo, notebookListComparer, NotebookType} from "../models/NotebookInfo";
import {CacheableDataContextService, isCachedData} from "./cacheable-data-context.service";
import {NamedStore} from "./NamedStore";
import {FailServerResponse} from "../models/FailServerResponse";
import {FetchError} from "./FetchError";
import {UserSearchResult} from "../models/UserSearchResult";
import {WsConnectionManager} from "./websockets/WsConnectionManager";
import {NOTEBOOK_EVENT_NAME, NotebookPushNotification} from "./pushnotifications/NotebookPushNotification";
import {AnalyticsService} from "./analytics.service";

@Injectable({providedIn: "root"})
export class UserService {

    private _userNotebooks = new UserNotebooksStore();
    private _userStore = new NamedStore<UserInfo>();

    constructor(private files: FileService,
                private cacheDataCtx: CacheableDataContextService,
                private appSvc: AppService,
                private analytics: AnalyticsService,
                ws: WsConnectionManager) {

        ws.subscribe(NOTEBOOK_EVENT_NAME).subscribe((e: NotebookPushNotification) => {
            this.handleNotebookEvent(e);
        });

    }

    private _errors$ = new Subject<FetchError>();

    get errors$(): Observable<FetchError> {
        return this._errors$.asObservable();
    }

    private _fetchingNotebooks$ = new MemorySubject<boolean>(false);

    get fetchingNotebooks$(): Observable<boolean> {
        return this._fetchingNotebooks$.pipe(
            distinctUntilChanged()
        );
    }

    changeAvatar(user: UserInfo, file: File): Promise<string> {
        return this.files.postFiles([file], `/api/users/${user.username}/avatar`)
            .result
            .then(res => {
                user.changeAvatar(res.avatarPath);
                this._userStore.update(user.username, user);
                return res.avatarPath;
            });
    }

    changeEmail(username: string, password: string, email: string): Promise<SignupResult | undefined> {
        return this.cacheDataCtx
            .post(`/api/users/${username}/email`, {newEmail: email, password})
            .then(data => data.token ? SignupResult.parse(data) : undefined);
    }

    changePassword(username: string, oldPassword: string, newPassword: string): Promise<SignupResult> {
        return this.cacheDataCtx
            .post(`/api/users/${username}/password`, {currentPassword: oldPassword, newPassword})
            .then((data) => {
                return SignupResult.parse(data);
            });
    }

    confirmEmail(username: string, token: string): Promise<SignupResult | undefined> {
        return this.cacheDataCtx
            .post(`/api/users/${username}/confirmemail`, {token})
            .then(data => data.token ? SignupResult.parse(data) : undefined);
    }

    confirmPasswordReset(username: string, token: string, newPassword: string): Promise<SignupResult> {
        return this.cacheDataCtx
            .post(`/api/users/${username}/confirmpasswordreset`, {token, newPassword}, {allowAnonymous: true})
            .then((data: any) => SignupResult.parse(data));
    }

    createUserNotebook(name: string, title: string, color: string): Promise<any> {
        this.analytics.sendEvent("users", "create_notebook", "count", 1);
        const username = this.appSvc.currentUserSnapshot.username;
        return this.cacheDataCtx
            .post(`api/users/${username}/notebooks`, {name, title, color})
            .then((payload) => {
                const notebook = NotebookInfo.parse(payload);
                this._userNotebooks.newNotebook(username, notebook);
                this.appSvc.notebookPinned(notebook);
            });
    }

    emailExists(email: string): Promise<boolean> {
        return this.cacheDataCtx
            .get(`/api/email/${email}/status`, {allowAnonymous: true}, true)
            .toPromise()
            .then((data: any) => !!data.exists);
    }

    exchangeSignInToken(token: string, defaultCulture: string): Promise<SignupResult> {
        return this.cacheDataCtx
            .post(`/api/signup/external`, {token, defaultCulture}, {allowAnonymous: true})
            .then((data: any) => SignupResult.parse(data));
    }

    findByUsername(username: string): Observable<UserInfo> {
        this.actualizeUser(username);
        return this._userStore.get(username);
    }

    getEmailByInviteToken(token: string): Promise<string | undefined> {
        return this.cacheDataCtx.get(`api/inviteemail/${token}`, {allowAnonymous: true}, true)
            .toPromise()
            .then((data) => data.email, () => undefined);
    }

    getUserNotebooks(username: string, actualize = true): Observable<NotebookInfo[]> {
        if (actualize || !this._userNotebooks.hasSnapshot(username)) {
            this.actualizeUserNotebooks(username);
        }
        return this._userNotebooks
            .getNotebooks(username)
            .pipe(
                distinctUntilChanged(notebookListComparer)
            );
    }

    movePinnedNotebook(notebook: NotebookInfo, newOrder: number): Promise<any> {
        const username = this.appSvc.currentUserSnapshot.username;
        this.appSvc.movePinnedNotebook(notebook, newOrder);

        return this.cacheDataCtx
            .post(`api/users/${username}/pinnednotebooks/${notebook.name}/order`, {order: newOrder})
            .catch(() => {
            });
    }

    pinNotebook(notebook: NotebookInfo): Promise<any> {
        const username = this.appSvc.currentUserSnapshot.username;
        notebook.pin();
        this.appSvc.notebookPinned(notebook);

        return this.cacheDataCtx
            .post(`api/users/${username}/notebooks/${notebook.name}/pin`, undefined)
            .catch(() => {
                notebook.unpin();
                this.appSvc.notebookUnpinned(notebook);
            });
    }

    profileNotExist(username: string): Observable<boolean> {
        return this.errors$
            .pipe(
                filter(e => e instanceof FindUserError && e.username === username),
                map(e => e.response.statusCode === 404)
            );
    }

    removeNotebook(username: string, notebook: NotebookInfo): Promise<any> {
        return this.cacheDataCtx
            .delete(`/api/users/${username}/notebooks/${notebook.name}`, undefined)
            .then(() => {
                this.appSvc.notebookUnpinned(notebook);
                this._userNotebooks.removeNotebook(username, notebook.name);
            });
    }

    resetPassword(nameOrEmail: string): Promise<any> {
        return this.cacheDataCtx
            .post(`/api/users/${nameOrEmail}/resetpassword`, undefined, {allowAnonymous: true});
    }

    searchUsers(query: string): Promise<UserSearchResult> {
        let params = {
            query
        };
        return this.cacheDataCtx
            .get(`/api/usersearch`, {
                params
            }, true)
            .toPromise()
            .then((data: any) => UserSearchResult.parse(data));
    }

    sendEmailConfirmation(username: string): Promise<any> {
        return this.cacheDataCtx
            .post(`/api/users/${username}/sendemailconfirmation`, undefined);
    }

    signup(info: SignupInfo): Promise<SignupResult> {
        return this.cacheDataCtx
            .post(`/api/signup`, info, {allowAnonymous: true})
            .then((data: any) => SignupResult.parse(data));
    }

    unpinNotebook(notebook: NotebookInfo): Promise<any> {
        const username = this.appSvc.currentUserSnapshot.username;
        notebook.unpin();
        this.appSvc.notebookUnpinned(notebook);
        return this.cacheDataCtx.delete(`api/users/${username}/notebooks/${notebook.name}/pin`)
            .catch(() => {
                notebook.pin();
                this.appSvc.notebookPinned(notebook);
            });
    }

    async updateProfile(username: string, user: UserInfo): Promise<SignupResult | undefined> {
        const result = await this.cacheDataCtx.put(`/api/users/${username}`, {
            firstname: user.firstname,
            lastname: user.lastname,
            aboutText: user.aboutText,
            username: user.username
        }).then(data => data.token ? SignupResult.parse(data) : undefined);

        if (username !== user.username) {
            this._userStore.rename(username, user.username);
            this._userNotebooks.rename(username, user.username);
        }
        this.actualizeUser(user.username);

        return result;
    }

    updateUserNotebook(username: string,
                       name: string,
                       newName: string,
                       title: string,
                       color: string,
                       type: NotebookType,
                       editRestrict: NotebookEditDocumentRestrict): Promise<any> {

        return this.cacheDataCtx
            .put(`api/users/${username}/notebooks/${name}`, {name: newName, title, color, type, editRestrict})
            .then((payload) => {
                const notebook = NotebookInfo.parse(payload);
                this._userNotebooks.updateNotebook(username, name, notebook);
                this.appSvc.notebookChanged(name, notebook);
            });
    }

    userNameExists(username: string): Promise<boolean> {
        return this.cacheDataCtx.get(`/api/users/${username}/exists`, {allowAnonymous: true}, true).toPromise().then(result => result.exist);
    }

    private actualizeUser(username: string) {
        this.cacheDataCtx.get(`api/users/${username}`).subscribe(p => {
            const user = UserInfo.parse(p);
            this._userStore.update(username, user, isCachedData(p));
        }, (err: FailServerResponse) => {
            this._errors$.next(new FindUserError(username, err));
        });
    }

    private actualizeUserNotebooks(username: string) {
        this._fetchingNotebooks$.next(true);
        this.cacheDataCtx.get(`api/users/${username}/notebooks`)
            .subscribe(res => {
                this._fetchingNotebooks$.next(false);
                this._userNotebooks.update(username, NotebookInfo.parseArray(res.notebooks), isCachedData(res));
            }, fail => {
                this._fetchingNotebooks$.next(false);
                this._errors$.next(new FindUserNotebooksError(username, fail));
            });
    }

    private handleNotebookEvent(e: NotebookPushNotification) {
        if (!e.userName) {
            return;
        }
        switch (e.type) {
            case "created":
            case "changed":
                this.actualizeUserNotebooks(e.userName);
                break;
            case "removed":
                this._userNotebooks.removeNotebook(e.userName, e.notebookName);
                break;
            default:
                console.warn(`Unknown event type ${e.type}`);
                break;
        }
    }
}

export class FindUserNotebooksError extends FetchError {

    readonly username: string;

    constructor(username: string, response: FailServerResponse) {
        super(response);
        this.username = username;
    }

}

export class FindUserError extends FetchError {

    readonly username: string;

    constructor(username: string, response: FailServerResponse) {
        super(response);
        this.username = username;
    }

}

class UserNotebooksStore extends NamedStore<NotebookInfo[]> {

    getNotebooks(username: string): Observable<NotebookInfo[]> {
        return this.ensure(username).asObservable();
    }

    newNotebook(username: string, notebook: NotebookInfo) {
        const pipe = this.ensure(username);
        if (pipe.value) {
            pipe.value.unshift(notebook);
            pipe.update();
        }
    }

    removeNotebook(username: string, notebookName: string) {
        const pipe = this.ensure(username);
        if (pipe.value) {
            const indx = pipe.value.findIndex(n => n.name.toUpperCase() === notebookName.toUpperCase());
            if (indx >= 0) {
                const newValue = pipe.value.slice(0);
                newValue.splice(indx, 1);
                pipe.next(newValue);
            }
        }
    }

    updateNotebook(username: string, oldName: string, notebook: NotebookInfo) {
        const pipe = this.ensure(username);
        if (pipe.value) {
            const indx = pipe.value.findIndex(n => n.name.toUpperCase() === oldName.toUpperCase());
            if (indx >= 0) {
                pipe.value.splice(indx, 1, notebook);
                pipe.update();
            }
        }
    }

}
