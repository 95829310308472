import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {diffInMonths, getPrevDate, getTimeText, isEqualDateWithoutTime} from "../../DateUtility";
import {TranslateService} from "../../../modules/translate";
import {combineLatest, map, Observable} from "rxjs";
import {MemorySubject} from "../../../modules/store";

@Component({
    selector: "format-date",
    template: `<span class="format-date__date">{{(dateToDisplay$ | async)?.date}}</span><span
        *ngIf="(dateToDisplay$|async)?.time" class="format-date__time">,&nbsp;{{(dateToDisplay$ | async).time}}</span>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormatDateComponent implements OnInit {

    dateToDisplay$: Observable<{ date: string, time: string }>;

    @Input() disableReduction: boolean;

    private _date$ = new MemorySubject<Date>();

    constructor(private translateSvc: TranslateService) {
    }

    @Input()
    set date(val: Date) {
        this._date$.next(val);
    }

    ngOnInit() {
        this.dateToDisplay$ = combineLatest([this._date$, this.translateSvc.currentLocale$])
            .pipe(
                map(([date]) => this.map(date))
            );
    }

    private map(date: Date): { date: string, time: string } {
        const now = new Date();

        if (!this.disableReduction) {
            if (isEqualDateWithoutTime(now, date)) {
                return {
                    date: this.translateSvc.getString("dates.today"),
                    time: getTimeText(date)
                };
            } else if (isEqualDateWithoutTime(getPrevDate(now), date)) {
                return {
                    date: this.translateSvc.getString("dates.yesterday"),
                    time: getTimeText(date)
                };
            }

        }

        const year = diffInMonths(now, date) > 11;
        return {
            date: this.translateSvc.formatDate(date, year, false),
            time: year ? undefined : getTimeText(date)
        };

    }
}
