import {Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {buildErrorMessage} from "../utils";
// noinspection TypeScriptPreferShortImport
import {DROPDOWN_MENU_PROVIDER, IDropdownMenu, IDropdownMenuItem, NDropdownMenuComponent} from "../dropdown/n-dropdown-menu.component";
// noinspection TypeScriptPreferShortImport
import {ErrorMessagesFactory} from "../../services/error-messages-factory.service";
import {BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable} from "rxjs";
import {KeyCodes} from "../KeyCodes";
import {IControlWithErrors, NControlsValidationErrors, NInputErrorMessages} from "../index";
import {NDropdownDirective} from "../dropdown/nDropdown.directive";

const FILTER_DEBOUNCE = 500;

@Component({
    selector: "n-select",
    templateUrl: "./n-select.html",
    styleUrls: [
        "./n-select.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NSelectComponent),
            multi: true
        },
        {
            provide: DROPDOWN_MENU_PROVIDER,
            useExisting: forwardRef(() => NSelectComponent)
        }
    ]
})

export class NSelectComponent
    implements ControlValueAccessor,
        OnInit,
        IDropdownMenu,
        IControlWithErrors,
        OnDestroy {

    @Input() placeholder: string;
    @Input() isDisabled: boolean;

    @ViewChild("menu", {static: true}) menu: NDropdownMenuComponent;
    @ViewChild("selectButton", {static: true}) selectButton: ElementRef;
    @ViewChild(NDropdownDirective, {static: true}) dropdown: NDropdownDirective;

    @Output() opened = new EventEmitter<any>();
    selectedOptionId$ = new BehaviorSubject<any>(undefined);
    selectedOption$: Observable<IDropdownMenuItem>;
    /**
     * Custom error message for input
     */
    @Input() errorMessages: NInputErrorMessages;

    @Input() required: boolean;

    errorMessage: string;
    items$: Observable<IDropdownMenuItem[]>;
    empty$: Observable<boolean>;
    isActive$ = new BehaviorSubject<boolean>(false);
    switcher: boolean;

    private filtering$ = new BehaviorSubject<string>("");
    private propagateChange: (_) => void;
    private touchedFn: () => void;
    private isDestroyed = false;

    constructor(private el: ElementRef,
                private messageFactory: ErrorMessagesFactory,
                private cd: ChangeDetectorRef,
                @Attribute("wide") wide: string | null,
                @Attribute("switcher") switcher: string | null,
                @Attribute("fitWidth") fitWidth: string | null) {
        if (wide !== null) {
            this.el.nativeElement.classList.add("_wide");
        } else if (fitWidth !== null) {
            this.el.nativeElement.classList.add("_fit-width");
        }

        this.switcher = switcher !== null;

        if (this.switcher) {
            this.el.nativeElement.classList.add("_switcher");
        }

    }

    get options(): IDropdownMenuItem[] {
        return this.menu.getItemsSnapshot();
    }

    addItem(menuItem: IDropdownMenuItem) {
        if (this.isDestroyed) {
            return;
        }
        Promise.resolve().then(() => {
            // Promise to avoid expression was changed after checked
            this.menu.addItem(menuItem);
        });
    }

    ngOnDestroy() {
        this.isDestroyed = true;
    }

    ngOnInit() {

        const native = this.selectButton.nativeElement;
        native.addEventListener("mousedown", () => {
            if (this.isDisabled) return;
        });

        native.addEventListener("click", (e) => {
            e.preventDefault();
            return false;
        });

        this.items$ = this.menu.getItems();

        this.selectedOption$ = combineLatest([this.items$, this.selectedOptionId$.pipe(distinctUntilChanged())])
            .pipe(
                map(([items, id]) => items.find(x => x.id === id))
            );

        this.empty$ = this.selectedOption$
            .pipe(
                map(x => !x)
            );

        this.processErrors(undefined);

        this.selectedOption$.subscribe(option => {
            if (option) {
                this.menu.setFocusedItemIndex(this.options.findIndex(i => i.id === option.id));
            } else {
                this.menu.setFocusedItemIndex(-1);
            }
        });

        this.filtering$
            .subscribe(word => {
                if (word.length === 0) {
                    return;
                }
                this.focusOrSelect(word);
            });

        this.filtering$
            .pipe(
                debounceTime(FILTER_DEBOUNCE)
            )
            .subscribe(word => {
                if (!word) {
                    return;
                }
                // clear filter after some debounce
                this.filtering$.next("");
            });
    }

    onBlur() {
        this.touchedFn && this.touchedFn();
    }

    onKeyDown(e: KeyboardEvent) {
        const key = e.key;
        if (key && key.length === 1) {
            // user typing, filter results
            let filter = this.filtering$.value;
            if (filter !== key) {
                // continue typing
                filter = filter + key;
            }
            this.filtering$.next(filter);
        } else if (!this.dropdown.opened) {

            const prevent = () => {
                event.preventDefault();
                event.stopPropagation();
            };

            const keyCode = e.which || e.keyCode;
            switch (keyCode) {
                case KeyCodes.ARROW_UP: {
                    this.selectPrevOption();
                    prevent();
                    break;
                }
                case KeyCodes.ARROW_DOWN: {
                    this.selectNextOption();
                    prevent();
                    break;
                }
                case KeyCodes.END: {
                    this.selectLastOption();
                    prevent();
                    break;
                }
                case KeyCodes.HOME: {
                    this.selectFirstOption();
                    prevent();
                    break;
                }
            }

        }
    }

    onMenuClosed() {
        this.isActive$.next(false);
    }

    onMenuOpened() {
        this.isActive$.next(true);
        Promise.resolve().then(() => {
            // wait until popover will shown to scroll to focused item
            this.focusToCurrent();
        });
    }

    // noinspection JSMethodCanBeStatic
    optionIdentity(index: number, item: IDropdownMenuItem) {
        return item.id;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.touchedFn = fn;
    }

    removeItem(menuItem: IDropdownMenuItem) {
        if (this.isDestroyed) {
            return;
        }
        this.menu.removeItem(menuItem);

        if (this.selectedOptionId$.value === menuItem.id) {
            this.setSelectedOption(this.options.length > 0 ? this.options[0] : undefined, true);
        }
    }

    selectOption(option: IDropdownMenuItem, pristine?: boolean, focus?: boolean) {
        if (this.isDestroyed) {
            return;
        }
        this.setSelectedOption(option, pristine);

        if (focus) {
            this.selectButton.nativeElement.focus();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
        this.cd.markForCheck();
    }

    setErrors(errors: NControlsValidationErrors) {
        this.processErrors(errors);
    }

    writeValue(val: any): void {
        this.selectedOptionId$.next(val);
    }

    private focusOrSelect(key: string) {

        key = key.toUpperCase();

        const indexes: number[] = [];
        for (let i = 0; i < this.options.length; i++) {
            const option = this.options[i];
            if (option.headingSnapshot().toUpperCase().startsWith(key)) {
                indexes.push(i);
            }
        }

        if (indexes.length === 0) {
            // not found
            return;
        }
        const currentIndex = this.dropdown.nDropdown.focusedIndexSnapshot;
        let indexToFocus = indexes.find(indx => indx > currentIndex);
        if (indexToFocus === undefined) {
            indexToFocus = indexes[0];
        }

        if (indexToFocus !== currentIndex) {
            this.dropdown.nDropdown.setFocusedItemIndex(indexToFocus);
            if (!this.dropdown.opened) {
                this.selectFocused(); // select result if not opened dropdown
            }
        }
    }

    private focusToCurrent() {
        if (!this.selectedOptionId$.value) {
            this.dropdown.nDropdown.setFocusedItemIndex(-1);
        }
        const indx = this.options.findIndex(o => o.id === this.selectedOptionId$.value);
        this.dropdown.nDropdown.setFocusedItemIndex(indx);
    }

    private processErrors(errors: NControlsValidationErrors) {
        if (!errors) {
            if (this.required) {
                this.errorMessage = buildErrorMessage({required: true}, this.messageFactory, this.errorMessages);
            } else {
                this.errorMessage = "";
            }
            return;
        }

        this.errorMessage = buildErrorMessage(errors, this.messageFactory, this.errorMessages);
    }

    private selectFirstOption() {
        this.dropdown.nDropdown.focusFirst();
        this.selectFocused();
    }

    private selectFocused() {
        this.selectOption(this.options[this.dropdown.nDropdown.focusedIndexSnapshot]);
    }

    private selectLastOption() {
        this.dropdown.nDropdown.focusLast();
        this.selectFocused();
    }

    private selectNextOption() {
        this.dropdown.nDropdown.focusNext();
        this.selectFocused();
    }

    private selectPrevOption() {
        this.dropdown.nDropdown.focusPrev();
        this.selectFocused();
    }

    private setSelectedOption(option: IDropdownMenuItem | undefined, pristine?: boolean) {
        this.selectedOptionId$.next(option ? option.id : undefined);
        if (this.propagateChange && !pristine) {
            let val;
            if (option) {
                val = option.id;
            }
            this.propagateChange(val);
        }
    }
}
