import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: "manage-panel",
    templateUrl: "./manage-panel.html",
    styleUrls: [
        "./manage-panel.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagePanelComponent {

    @Input()
    selectedCount: number;

    @Input()
    placeholderText: string;
    @Input()
    selectedText: string;

    @Output()
    cancel = new EventEmitter();

    tryCancel() {
        this.cancel.emit();
    }
}
