import {AfterContentInit, Component, ElementRef, HostListener, NgZone, Renderer2, ViewChild, ViewContainerRef, ViewEncapsulation} from "@angular/core";
import {ModalCompileContext} from "../../ModalCompileContext";

@Component({
    selector: "modal-window",
    templateUrl: "./modal-window.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./modal-window.bem.css"
    ]
})
export class ModalWindowComponent implements AfterContentInit {

    @ViewChild("modalContent", {read: ViewContainerRef, static: true})
    contentVc: ViewContainerRef;

    @ViewChild("windowWrapper", {read: ElementRef, static: true})
    windowWrapperElement: ElementRef;

    constructor(private compileCtx: ModalCompileContext,
                public hostElement: ElementRef,
                private renderer: Renderer2,
                private zone: NgZone) {
    }

    focus() {
        this.zone.runOutsideAngular(() => {
            this.hostElement.nativeElement.focus();
        });
    }

    ngAfterContentInit(): any {
        let wndClass = this.compileCtx.modalCtx.options.windowClass;
        if (wndClass) {
            const classNames = wndClass.split(" ");
            for (let clsName of classNames) {
                this.renderer.addClass(this.hostElement.nativeElement, clsName);
            }
        }

        // create inner component instance
        this.compileCtx.compile(this.contentVc);

        if (!this.compileCtx.modalCtx.options.manualZIndex) {
            this.renderer.setStyle(this.hostElement.nativeElement, "zIndex", (1050 + (this.compileCtx.layerNumber * 10)).toString());
        }
        this.renderer.setProperty(this.hostElement.nativeElement, "tabIndex", "-1");

        // this.focus();
    }

    @HostListener("click", ["$event"])
    onClick(evt) {
        const options = this.compileCtx.modalCtx.options;

        if (!options || !options.backdrop) {
            return;
        }
        if (evt.target === evt.currentTarget || evt.target === this.windowWrapperElement.nativeElement) {
            evt.preventDefault();
            evt.stopPropagation();

            this.compileCtx.modalCtx.cancel();
        }
    }

}
