import {ChangeDetectorRef, Pipe} from "@angular/core";
import {TranslateService} from "../../modules/translate";
import {AsyncPipe} from "@angular/common";
import {distinctUntilChanged, interval, map, Observable, startWith} from "rxjs";

@Pipe({
    name: "timeElapsed",
    pure: false
})
export class TimeElapsedPipe extends AsyncPipe {

    private obs = {};

    constructor(private translateSvc: TranslateService, ref: ChangeDetectorRef) {
        super(ref);
    }

    transform<T>(date: any): T {
        if (date instanceof Date) {
            const key = date.getTime();
            if (!this.obs[key]) {
                this.obs[key] = this.getObservable(date);
            }
            return super.transform<T>(this.obs[key] as any);
        }
        return super.transform<T>(date);
    }

    private getObservable(date: Date): Observable<string> {
        return interval(60000)
            .pipe(
                startWith(0),
                map(() => this.translateSvc.elapsedTime(date)),
                distinctUntilChanged()
            );
    }

}
