import {ErrorMessagesFactory} from "../../../modules/n-framework";
import {TranslateService} from "../../../modules/translate";
import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class AppErrorMessagesService extends ErrorMessagesFactory {

    constructor(private translateSvc: TranslateService) {
        super();
    }

    getMessage(errorKey: string, errorValue: any): string {
        const value = this.translateSvc.getValue("forms.errors");
        // console.log(errorKey, errorValue);
        if (value[errorKey]) {
            let result = value[errorKey];
            for (const key of Object.keys(errorValue)) {
                result = result.replace(`$${key}`, errorValue[key]);
            }
            return result;
        }
        return `Error: ${errorKey}`;

    }

}
