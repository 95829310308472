import {Injectable} from "@angular/core";

export interface IStorageService {
    clearAll();

    getItem<T>(key: string): T;

    remove(key: string);

    save(key: string, model: any, mode?: StorageMode, expired?);
}

@Injectable({providedIn: "root"})
export class StorageService implements IStorageService {

    private storageIsCorrupted: boolean;

    clearAll() {
        this.getStorage(StorageMode.PERSISTENT).clear();
        this.getStorage(StorageMode.SESSION).clear();
    }

    getItem<T>(key: string): T {
        return this.getItemFrom<T>(key, StorageMode.SESSION) ||
            this.getItemFrom<T>(key, StorageMode.PERSISTENT);
    }

    remove(key: string) {
        let session = this.getStorage(StorageMode.SESSION);
        if (session.getItem(key))
            session.removeItem(key);

        let persist = this.getStorage(StorageMode.PERSISTENT);
        if (persist.getItem(key))
            persist.removeItem(key);
    }

    save(key: string, model: any, mode: StorageMode = StorageMode.SESSION, expired: number = 0) {
        let storage = this.getStorage(mode);
        let item = StorageItem.create(model, expired);
        storage.setItem(key, JSON.stringify(item));
    }

    private getItemFrom<T>(key: string, mode: StorageMode) {
        let targetStorage = this.getStorage(mode);
        if (this.storageIsCorrupted) {
            return undefined;
        }

        try {
            targetStorage.getItem(key);
        } catch (e) {
            if (e.name === "NS_ERROR_FILE_CORRUPTED") {
                this.storageIsCorrupted = true;
                alert("Sorry, it looks like your browser storage has been corrupted. Please clear your storage by going to Tools -> Clear Recent History -> Cookies and set time range to 'Everything'. This will remove the corrupted browser storage across all sites.");
                return undefined;
            }
        }

        let data = targetStorage.getItem(key);
        if (!data) {
            return undefined;
        }
        let item = StorageItem.parse(data);

        if (item.isExpired()) {
            // item expired
            targetStorage.removeItem(key); // remove from storage
            return undefined;
        }
        return <T>item.data;
    }

    private getStorage(mode: StorageMode): IStorage {
        switch (mode) {
            case StorageMode.SESSION:
                return window.sessionStorage;
            case StorageMode.PERSISTENT:
                return window.localStorage;
            default:
                throw new Error("Storage type is not supported");
        }
    }
}

// localStorage || sessionStorage
interface IStorage {
    clear();

    getItem(key: string): string;

    removeItem(key: string);

    setItem(key: string, value: string);

}

export enum StorageMode {
    SESSION = 1,
    PERSISTENT = 2
}

class StorageItem {
    data: any;
    expired: Date;

    static create(data: any, expiredInSec): StorageItem {
        let item = new StorageItem();
        item.data = data;
        if (expiredInSec > 0) {
            let now = new Date();
            now.setSeconds(now.getSeconds() + expiredInSec);
            item.expired = now;
        }
        return item;
    }

    static parse(raw: string): StorageItem {
        let item = new StorageItem();
        let fromJson = JSON.parse(raw);
        item.data = fromJson.data;
        item.expired = new Date(fromJson.expired);
        return item;
    }

    isExpired(): boolean {
        return this.expired && this.expired < new Date();
    }
}
