import {Directive, ElementRef, NgZone, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {SupportService} from "../services/support.service";

@Directive({
    selector: "[scroll-fix-body]"
})
export class ScrollFixBodyDirective implements OnInit, OnDestroy {
    private sub: () => void;

    constructor(private supportService: SupportService,
                private el: ElementRef,
                private renderer: Renderer2,
                private zone: NgZone) {

    }

    ngOnDestroy(): void {
        this.sub();
    }

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            this.sub = applyScrollFixBody(this.el, this.renderer, this.supportService);
        });
    }
}

export function applyScrollFixBody(el: ElementRef, renderer: Renderer2, supportSvc: SupportService): () => void {
    if (supportSvc.getScrollWidth() === 0)
        return () => {
        };

    function adjust() {
        if (window.innerWidth >= 768) {
            renderer.setStyle(el.nativeElement, "width", `${window.innerWidth - supportSvc.getScrollWidth()}px`);
        } else {
            renderer.setStyle(el.nativeElement, "width", null);
        }
    }

    window.addEventListener("resize", adjust);
    adjust();
    return () => window.removeEventListener("resize", adjust);
}
