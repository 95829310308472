import {Compiler, Component, ComponentRef, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from "@angular/core";
import {resolveGlobalComponent} from "../../../modules/split";
import {RenderService} from "../../services/render.service";

@Component({
    selector: "dynamic-component",
    // changeDetection: ChangeDetectionStrategy.OnPush,
    template: "<ng-container #viewRef></ng-container>"
})
export class DynamicComponentComponent implements OnInit, OnDestroy {

    @Input()
    type: string | Function;

    @Input()
    module: string;

    @Input()
    data: any;

    @Input()
    providers: any;

    @Output()
    instance = new EventEmitter<ComponentRef<any>>();

    @Output()
    error = new EventEmitter<any>();

    isDestroyed: boolean;

    @ViewChild("viewRef", {read: ViewContainerRef, static: true})
    viewRef: ViewContainerRef;

    private cmpRef: ComponentRef<any>;

    constructor(private renderSvc: RenderService,
                private el: ElementRef,
                private injector: Injector,
                private compiler: Compiler) {

    }

    ngOnDestroy(): void {
        if (this.cmpRef) {
            this.cmpRef.destroy();
        }
        this.isDestroyed = true;
    }

    ngOnInit() {
        const moduleName = this.module;

        const attach = (cmpType, cmpFactory?, injector?) => {
            this.cmpRef = this.renderSvc.render(cmpType, this.data, this.providers, cmpFactory, injector);
            this.instance.emit(this.cmpRef);
            this.viewRef.insert(this.cmpRef.hostView);
            this.cmpRef.changeDetectorRef.markForCheck();
        };
        if (moduleName) {
            let modulePath: string;
            let moduleType: Promise<any>;
            switch (moduleName) {
                case "document":
                    moduleType = import("../../../+document/document.module").then(m => m.DocumentModule);
                    break;
            }
            moduleType.then(moduleType => this.compiler.compileModuleAsync(moduleType))
                .then(factory => {
                    const module = factory.create(this.injector);
                    const cmpType = resolveGlobalComponent(this.type as string);
                    attach(cmpType, module.componentFactoryResolver, module.injector);
                })
                .catch(err => {
                    console.error(err);
                    this.error.emit(err);
                });
        } else {
            attach(this.type);
        }
    }

}
