import {Directive, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {HotKeyService} from "../services/hot-key.service";

@Directive({selector: "[hotKey]"})
export class HotKeyDirective implements OnInit, OnDestroy {

    @Output()
    hotKeyEvent = new EventEmitter<string>();

    @Input()
    hotKey: string;

    private subs: Array<() => void> = [];

    constructor(private hotKeys: HotKeyService) {
    }

    ngOnDestroy(): void {
        for (let sub of this.subs) {
            sub();
        }
        this.subs = undefined;
    }

    ngOnInit(): void {
        const pairs = this.hotKey.split(",");
        for (let hotKey of pairs) {
            const sub = this.hotKeys.addHandler(hotKey, (e) => {
                this.hotKeyEvent.emit(e);
            });
            this.subs.push(sub);
        }
    }


}
