import {NotebookInfo} from "../NotebookInfo";
import {Observable} from "rxjs";
import {MemorySubject} from "../../../modules/store";
import {DocumentListInfo} from "./DocumentListInfo";
import {DocumentNotebookGroup} from "./DocumentNotebookGroup";
import {removeFromArray} from "../../../shared/Utility";

export class DocumentWithNotebooksList {
    constructor(notebooks: DocumentNotebookGroup[]) {
        this._notebooks$ = new MemorySubject(notebooks);
    }

    get notebooksSnapshot(): DocumentNotebookGroup[] {
        return this._notebooks$.value;
    }

    private _notebooks$: MemorySubject<DocumentNotebookGroup[]>;

    get notebooks$(): Observable<DocumentNotebookGroup[]> {
        return this._notebooks$.asObservable();
    }

    static parse(data: any): DocumentWithNotebooksList {
        return new DocumentWithNotebooksList(DocumentNotebookGroup.parseArray(data.notebooks));
    }

    findDocument(documentId: string): DocumentListInfo | undefined {
        for (let n of this._notebooks$.value) {
            const doc = n.findDocument(documentId);
            if (doc) return doc;
        }
        return undefined;
    }

    markAsRecent(notebook: NotebookInfo, documentId: string) {
        const groups = this._notebooks$.value;
        const targetNotebookIndex = groups.findIndex(g => g.notebookSnapshot.name === notebook.name);
        if (targetNotebookIndex >= 0) {
            const [removed] = groups.splice(targetNotebookIndex, 1);
            groups.unshift(removed);

            removed.moveDocumentUp(documentId);

            this._notebooks$.next(groups);
        }
    }

    prependDocument(doc: DocumentListInfo, notebook: NotebookInfo) {
        const groups = this._notebooks$.value;
        const targetNotebook = groups.find(g => g.notebookSnapshot.name === notebook.name);
        if (targetNotebook) {
            targetNotebook.prependDocument(doc);
        } else {
            const newGroup = new DocumentNotebookGroup(notebook, [doc]);
            groups.unshift(newGroup);
        }
        this._notebooks$.next(groups);
    }

    removeDocument(documentId: string) {
        for (let notebook of this._notebooks$.value) {
            for (let doc of notebook.documentsSnapshot) {
                if (doc.id === documentId) {
                    notebook.removeDocument(doc);
                    if (notebook.isEmpty()) {
                        removeFromArray(this._notebooks$.value, notebook);
                        this._notebooks$.update();
                    }
                    return;
                }
            }
        }
    }
}
