import {Directive} from "@angular/core";
import {Event, NavigationEnd, Router} from "@angular/router";
import {PopoverCollection} from "../../modules/n-popover";
import {MenuService} from "../services/menu.service";

@Directive({
    selector: "[close-menus-on-navigate]"
})
export class CloseMenusOnNavigateDirective {

    constructor(private popovers: PopoverCollection,
                private menuSvc: MenuService,
                private router: Router) {

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.popovers.closeAll();
                this.menuSvc.closeMenu();
            }
        });

    }

}
