import {Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from "@angular/core";
import {ITouchEvent, TouchService} from "../services/touch.service";

const MAX_TOUCH_OFFSET = 10;

@Directive({
    selector: "[longTap]"
})
export class LongTapDirective implements OnInit, OnDestroy {

    @Output()
    longTap = new EventEmitter();

    @Input()
    touchDuration = 600;

    @Input()
    longTapDisabled: boolean;

    private timer: any;
    private wasTap: boolean;
    private touchSub: { destroy: () => void };

    constructor(private el: ElementRef,
                private zone: NgZone,
                private touchSvc: TouchService) {


    }

    ngOnDestroy(): void {
        this.touchSub.destroy();
        this.el.nativeElement.removeEventListener("click", this.clickHandler, true);
    }

    ngOnInit(): void {
        this.touchSub = this.touchSvc.bind(this.el.nativeElement, {
            start: () => this.touchStart(),
            end: () => this.touchEnd(),
            cancel: () => this.onTouchEnd(),
            move: e => this.touchMove(e)
        }, {mouseEvents: true});

        this.zone.runOutsideAngular(() => {
            this.el.nativeElement.addEventListener("click", this.clickHandler, true);
            if (isTouchDevice()) {
                this.el.nativeElement.addEventListener("contextmenu", (e) => {
                    if (this.longTapDisabled) {
                        return;
                    }
                    e.preventDefault();
                    e.stopPropagation();
                });
            }
        });
    }

    private clickHandler = (e) => this.onClick(e);

    private emitEvent() {
        this.wasTap = true;
        this.zone.runGuarded(() => {
            this.longTap.emit();
        });
    }

    private onClick(e) {
        if (this.longTapDisabled) {
            return;
        }
        this.onTouchEnd();
        if (this.wasTap) {
            // prevent click event if long tap fired
            this.wasTap = false;
            e.preventDefault();
            e.stopPropagation();
        }
    }

    private onTouchEnd() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }
    }

    private onTouchStart() {
        if (this.timer) {
            return;
        }
        this.timer = setTimeout(() => this.emitEvent(), this.touchDuration);
    }

    private touchEnd = () => this.onTouchEnd();

    private touchMove(e: ITouchEvent) {
        // noinspection JSSuspiciousNameCombination
        if (Math.abs(e.deltaX) > MAX_TOUCH_OFFSET || Math.abs(e.deltaY) > MAX_TOUCH_OFFSET) {
            this.touchEnd();
        }
    }

    private touchStart = () => this.onTouchStart();
}

function isTouchDevice(): boolean {
    return "ontouchstart" in window        // works on most browsers
        || !!navigator.maxTouchPoints;
}
