import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {TranslateService} from "../../../modules/translate";
import {AppContextualRoutesAdapter} from "../../services/app-contextual-routes-adapter.service";
import {Router} from "@angular/router";
import {distinctUntilChanged, map, Observable, switchMap} from "rxjs";
import {MemorySubject} from "../../../modules/store";
import {DocumentService} from "../../services/document.service";
import {DocumentListInfo} from "../../models/documents/DocumentListInfo";
import {makeRippleEffect} from "../../services/ripple/ripple-effect";

@Component({
    selector: "document-list-item",
    templateUrl: "document-list-item.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./document-list-item.bem.css"
    ],
    encapsulation: ViewEncapsulation.None
})
export class DocumentListItemComponent implements OnInit {

    @Input() actionIconId: string;
    @Input() selected: boolean;
    @Input() canSelect: boolean;
    @Input() selectMode: boolean;
    @Input() showGroup: boolean;
    @Output() selectChange = new EventEmitter<boolean>();
    @Output() onAction = new EventEmitter();
    // fields
    emptyTitle: string;
    title$: Observable<string>;
    snippet$: Observable<string>;
    previewImageUrl$: Observable<string>;

    document$ = new MemorySubject<DocumentListInfo>();

    constructor(private translate: TranslateService,
                private adapter: AppContextualRoutesAdapter,
                private documents: DocumentService,
                private el: ElementRef,
                private router: Router) {

    }

    @Input() set document(val: DocumentListInfo) {
        this.document$.next(val);
    }

    actionButtonClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.onAction.emit();
    }

    ngOnInit() {
        this.emptyTitle = this.translate.getString("documents.emptyTitle");

        this.title$ = this.document$.pipe(
            switchMap(x => x.title$),
            map(v => v || this.emptyTitle),
            distinctUntilChanged()
        );

        this.snippet$ = this.document$.pipe(
            switchMap(x => x.snippet$),
            distinctUntilChanged()
        );

        this.previewImageUrl$ = this.document$.pipe(
            switchMap(x => x.previewImageUrl$),
            distinctUntilChanged()
        );
    }

    @HostListener("mousedown.out-zone", ["$event"])
    @HostListener("touchstart.out-zone", ["$event"])
    onMouseDown(e: TouchEvent | MouseEvent) {
        makeRippleEffect(this.el.nativeElement, e);
    }

    onNavigate(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.selectMode) {
            this.selectChange.emit(!this.selected);
        } else {
            this.navigateToDocument();
        }

        return false;
    }

    onTap() {
        if (this.canSelect && !this.selected) {
            this.selectChange.emit(true);
        }
    }

    // toggleStarred() {
    //     // this.document.starred = !this.document.starred;
    //     // TODO server call
    // }

    private async navigateToDocument() {
        const {id, title} = this.document$.value;
        this.adapter.setContextRouteData({
            title
        });
        await this.router.navigate(["/doc", id]);
    }
}
