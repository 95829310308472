import {SignUpPageComponent} from "./pages/sign-up-page/sign-up-page.component";
import {ResetPasswordPageComponent} from "./pages/reset-password-page/reset-password-page.component";
import {ConfirmPasswordResetPageComponent} from "./pages/confirm-password-reset/confirm-password-reset-page.component";
import {ExternalLoginPageComponent} from "./pages/external-login/external-login-page.component";
import {ConfirmEmailPageComponent} from "./pages/confirm-email/confirm-email-page.component";
import {SignOutPageComponent} from "./pages/sign-out-page/sign-out-page.component";
import {RecentPageComponent} from "./pages/recent-page";
import {DocumentEntryComponent} from "./pages/document-entry/document-entry.component";
import {SharedPageComponent} from "./pages/shared-page/shared-page.component";
import {StarredPageComponent} from "./pages/starred-page";

export const CORE_ROUTES = [
    {path: "", component: RecentPageComponent},
    {path: "sign-up", component: SignUpPageComponent},
    {path: "sign-out", component: SignOutPageComponent},
    {path: "reset-password", component: ResetPasswordPageComponent},
    {path: "confirm-password-reset", component: ConfirmPasswordResetPageComponent},
    {path: "external-login", component: ExternalLoginPageComponent},
    {path: "confirm-email", component: ConfirmEmailPageComponent},
    {path: "shared", component: SharedPageComponent},
    {path: "starred", component: StarredPageComponent},
    {
        path: "doc/:documentId",
        component: DocumentEntryComponent
    }
];
