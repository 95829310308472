import {Directive, ElementRef, Input} from "@angular/core";
import {HoverPopoverInstance} from "../services/hover-popover.service";
import {MentionService} from "../services/mentions/mention.service";
import {CURRENT_DOMAIN} from "../../shared/Utility";

const regexp1 = /^\/?profile\/[A-Za-z0-9_.\-]+/i;
const regexp2 = new RegExp(`^${CURRENT_DOMAIN}\\/profile\\/[A-Za-z0-9_.\\-]+`);
const savedRegexp = /profile\/([A-Za-z0-9_.\-]+)/i;

@Directive({selector: "[bindMentions]"})
export class BindMentionsDirective {

    constructor(private el: ElementRef,
                private mentions: MentionService) {
    }

    @Input()
    set bindMentions(val: any) {
        // signal to update
        // wait to render
        Promise.resolve().then(() => {
            this.update();
        });
    }

    update() {
        const linksList = this.el.nativeElement.getElementsByTagName("a");
        for (let i = 0; i < linksList.length; i++) {
            const href = linksList[i].getAttribute("href");
            if (href && (href.match(regexp1) || href.match(regexp2))) {
                const login = href.match(savedRegexp)[1];
                this.bind(linksList[i], login);
            }
        }
    }

    private bind(element: HTMLElement, login: string)
        : HoverPopoverInstance | undefined {
        if (!login) {
            return undefined;
        }
        this.mentions.bindPopover(new ElementRef(element), login);
    }
}
