import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector} from "@angular/core";
import {NToastContainerComponent, NToastTextComponent} from "./components/public_components";
import {IMessageToastAction, IMessageToastParams} from "./IMessageToastParams";
import {MessageToastType} from "./MessageToastType";
import {ToastInstance} from "./ToastInstance";
import {IToastOptions} from "./IToastOptions";


@Injectable({providedIn: "root"})
export class ToastService {

    constructor(private injector: Injector,
                private resolver: ComponentFactoryResolver,
                private appRef: ApplicationRef) {
    }

    private _container: ComponentRef<NToastContainerComponent>;

    private get container(): ComponentRef<NToastContainerComponent> {
        if (this._container) {
            return this._container;
        }

        const factory = this.resolver.resolveComponentFactory(NToastContainerComponent);
        this._container = factory.create(this.injector);

        const rootElm = this.appRef.components[0].location;
        rootElm.nativeElement.appendChild(this.container.location.nativeElement);

        return this._container;
    }

    getOpenedToasts(type?: MessageToastType): ToastInstance[] {
        return this.container.instance.toasts
            .filter(i => type && i.data ? i.data.type === type : true)
            .map(t => t.instance);
    }

    showActions(text: string, uniqueId: string,
                actions?: IMessageToastAction[],
                onAction?: (action: IMessageToastAction) => void,
                timeout?: number): ToastInstance {

        const toast = this.showMessage(text, MessageToastType.WARNING, timeout || 0, uniqueId, actions, (a) => {
            if (onAction) {
                onAction(a);
            }
            toast.close();
        });

        return toast;
    }

    /**
     * Show cancelable message (yellow with cancel button)
     * @param text                  text of message
     * @param onCancel              invokes when user press cancel button
     * @param cancelTitle           text of cancel button
     * @param timeout               timeout of message
     */
    showCancel(text: string,
               onCancel: () => void,
               cancelTitle: string,
               timeout: number = 5000): ToastInstance {
        const toast = this.showMessage(
            text,
            MessageToastType.WARNING,
            timeout,
            undefined, [
                {
                    title: cancelTitle,
                    id: "cancel"
                }
            ], () => {
                if (onCancel) {
                    onCancel();
                }
                toast.close();
            }, "right");
        return toast;
    }

    showError(text: string, uniqueId?: string): ToastInstance {
        return this.showMessage(text, MessageToastType.ERROR, 0, uniqueId, undefined, undefined, undefined, true);
    }

    showMessage(text: string,
                type: MessageToastType,
                timeout: number = 0,
                uniqueId?: string,
                actions?: IMessageToastAction[],
                onAction?: (action: IMessageToastAction) => void,
                actionsAlign: "bottom" | "right" = "bottom",
                canClose?: boolean) {

        const p: IMessageToastParams = {
            type: type,
            text: text,
            actions: actions || [],
            actionsAlign,
            onAction: onAction || function () {
            }
        };
        return this.showToast(NToastTextComponent, {data: p, timeout, uniqueId, canClose});
    }

    showProcess(text: string): ToastInstance {
        return this.showMessage(text, MessageToastType.WARNING, 0, undefined);
    }

    /**
     * Show success message (green)
     * @param text         text of message
     * @param timeout      timeout of message
     */
    showSuccess(text: string, timeout: number = 3000): ToastInstance {
        return this.showMessage(text, MessageToastType.SUCCESS, timeout);
    }

    showToast(componentType: Function, options: IToastOptions): ToastInstance {
        return this.container.instance.showToast(componentType, options);
    }

}
