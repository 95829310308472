import {ContextualRouterOutlet} from "./directives/contextual-router-outlet.directive";
import {ActivatedRoute, ChildrenOutletContexts, Router} from "@angular/router";
import {ComponentFactory, ComponentFactoryResolver, Injectable, Injector, Type} from "@angular/core";
import {ContextualRoutesAdapter} from "./ContextualRoutesAdapter";
import {ContextualRouteInfo} from "./ContextualRouteInfo";
import {isContextual} from "./ContextualRouteMap";
import {merge} from "rxjs";

@Injectable({providedIn: "root"})
export class ContextualRouteService {

    private contextualOutlet: ContextualRouterOutlet;
    private lastId = 0;

    private contextualRoutes: ContextualRouteInfo[] = [];
    private contextPath: string;
    private activeRoutePath: string;

    constructor(private adapter: ContextualRoutesAdapter,
                private parentContexts: ChildrenOutletContexts,
                private router: Router) {

        adapter.restoreContext = () => {
            this.navigateToContext();
        };

    }

    getRouteTitle() {
        return this.contextualOutlet.routeTitle;
    }

    hasContext() {
        return typeof (this.contextPath) !== "undefined";
    }

    navigate(factory: ComponentFactory<any>, inj: Injector, resolver: ComponentFactoryResolver, route: ActivatedRoute) {

        if (isContextual(route.component as Type<any>)) {

            if (this.contextualRoutes.length === 0) {
                this.contextPath = this.activeRoutePath;
            }

            this.lastId++;
            let info = new ContextualRouteInfo(this.lastId.toString(), route, this.contextualOutlet.routeTitle);
            this.adapter.activate(factory, inj, resolver, info);

            this.contextualRoutes.push(info);

        } else {
            this.resetContext();

            this.contextualOutlet.render(factory, inj, route);
            this.activeRoutePath = this.router.url;
            //noinspection TypeScriptUnresolvedFunction
            merge(route.params, route.queryParams).subscribe(() => {
                this.activeRoutePath = this.router.url;
            });

        }

    }

    navigateToContext(defaultUrl?: any[]) {
        if (!this.hasContext()) {
            this.router.navigate(defaultUrl || ["/"]);
        } else {
            this.router.navigateByUrl(this.contextPath);
        }
    }

    registerOutlet(name: string, outlet: ContextualRouterOutlet) {
        this.contextualOutlet = outlet;
        this.parentContexts.onChildOutletCreated(name, <any>outlet);
    }

    removeOutlet(name: any) {
        this.parentContexts.onChildOutletDestroyed(name);
    }

    setupContext(path: string) {
        this.contextPath = path;
    }

    private resetContext() {
        this.contextPath = undefined;
        for (let route of this.contextualRoutes) {
            this.adapter.deactivate(route);
        }
        this.contextualRoutes = [];
    }
}
