<page-message *ngIf="emptyList$|async" [iconId]="'documents-not-found'"
              [messageKey]="'shared.emptyList'"></page-message>

<div (infiniteScroll)="scrollToEnd()" *ngIf="(documents$ | async)?.length" class="page-wrapper">
    <div class="title">{{"shared.title"|translate}}</div>
    <div class="description">{{"shared.description"|translate}}</div>

    <document-list-item *ngFor="let doc of documents$ | async; trackBy:docIdentity"
                        [canSelect]="false"
                        [document]="doc"
                        [selectMode]="false"
                        [selected]="false"
                        [showGroup]="true"></document-list-item>
</div>
