import {ChangeDetectionStrategy, Component, ElementRef, Renderer2} from "@angular/core";

@Component({
    selector: "modal-backdrop",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ``,
    styleUrls: [
        "./modal-backdrop.ng.css"
    ]
})
export class ModalBackdropComponent {

    constructor(private el: ElementRef, private renderer: Renderer2) {

    }

    setLayer(layer: number) {
        this.renderer.setStyle(this.el.nativeElement, "zIndex", (1040 + (layer * 10)).toString());
    }
}
