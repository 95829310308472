import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";

@Component({
    selector: "side-menu-item",
    templateUrl: "side-menu-item.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./side-menu-item.ng.css"
    ]
})
export class SideMenuItemComponent implements OnInit {
    @Input() text: string;
    @Input() invited: boolean;
    @Input() route: any[] | string;
    @Input() hotKeyLabel: string;
    // fields
    typeClass: string;

    constructor() {
    }

    @Input()
    set type(val: SideMenuItemType) {
        val = val || "item";
        this.typeClass = "_" + val;
    }

    ngOnInit() {

    }
}

export type SideMenuItemType = "common" | "avatar" | "item";
