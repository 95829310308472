import {TeamRef} from "./TeamRef";
import {TeamMember} from "./TeamMember";
import {UserRef} from "./UserRef";

export class TeamInfo extends TeamRef {

    /**
     * Description (max length 1024)
     */
    description: string;

    /**
     * Group membership type
     */
    type: TeamType;

    /**
     * Restrict for document creation in this group
     */
    inviteRestrict: TeamInviteRestrict;

    /**
     * Default document expiration (in days)
     */
    documentDefaultExpiration: number;

    /**
     * Total team members count
     */
    membersCount: number;

    /**
     * First members of team
     */
    members: TeamMember[];

    currentUserRole: TeamMemberRole;

    currentUserInviting: boolean;

    removed: boolean;

    removedDate: Date;

    lastActivity: Date;

    static parse(data: any): TeamInfo {
        let info = new TeamInfo(data.name, data.title, data.avatarPath, data.avatarColors);

        info.description = data.description;
        info.documentDefaultExpiration = data.documentDefaultExpiration || 0;
        info.type = data.type;
        info.inviteRestrict = data.inviteRestrict;
        info.lastActivity = new Date(data.lastActivity);
        info.membersCount = data.membersCount || 0;
        info.currentUserRole = data.currentUserRole;
        info.currentUserInviting = data.currentUserInviting;
        info.removed = data.removed;
        info.removedDate = data.removedDate ? new Date(data.removedDate) : undefined;
        info.members = data.members ? TeamMember.parseArray(data.members) : [];
        return info;
    }

    static parseArray(data: any[]): TeamInfo[] {
        return data.map(d => TeamInfo.parse(d));
    }

    clone(name?: string, title?: string): TeamInfo {
        const team = new TeamInfo(name || this.name, title || this.title, this.avatarPath, this.getAvatarColors());
        team.description = this.description;
        team.documentDefaultExpiration = this.documentDefaultExpiration || 0;
        team.type = this.type;
        team.inviteRestrict = this.inviteRestrict;
        team.lastActivity = this.lastActivity;
        team.membersCount = this.membersCount || 0;
        team.currentUserRole = this.currentUserRole;
        team.removed = this.removed;
        team.removedDate = this.removedDate;
        team.members = this.members;
        return team;
    }

    getNavigateUrl(): string[] {
        return ["/", this.name];
    }

    isAdmin(): boolean {
        return this.currentUserRole === "administrator";
    }

    isMember(): boolean {
        return this.currentUserRole === "administrator" || this.currentUserRole === "member";
    }

    joinToTeam(user: UserRef, acceptInvite?: boolean) {

        //this.members = this.members.slice(-1, 1);
        this.currentUserRole = "member";
        this.currentUserInviting = false;
        if (!acceptInvite) {
            this.members.unshift(new TeamMember(user, "member", false));
            this.membersCount++;
        }
    }

    leaveTeam(user: UserRef) {
        const memberIndx = this.members.findIndex(m => m.user.username === user.username);
        if (memberIndx >= 0) {
            this.members.splice(memberIndx, 1);
        }
        this.currentUserRole = undefined;
        this.currentUserInviting = false;
        this.membersCount--;
    }

    private getAvatarColors() {
        return `${this.avatarColor1};${this.avatarColor2}`;
    }
}

export function teamEquals(team1: TeamInfo, team2: TeamInfo): boolean {
    return team1.name === team2.name &&
        team1.type === team2.type &&
        team1.title === team2.title &&
        team1.description === team2.description &&
        team1.removed === team2.removed &&
        team1.inviteRestrict === team2.inviteRestrict;
}

export type TeamMemberRole = "member" | "administrator";
export type TeamType = "open" | "closed" | "limited"; // публич.открытая | приватная | публич. закрытая
export type TeamInviteRestrict = "administrators" | "members";
