<div #container [class._clearable]="clearable"
     [class._required]="required"
     [class._wide]="wide"
     class="n-textarea">
  <textarea #area
            (blur)="onBlur()"
            (focus)="focus.emit($event)"
            (input)="onChange($event)"
            [attr.readonly]="readonly ? '' : null"
            [placeholder]="placeholder"
            class="textarea"></textarea>
    <span (mouseleave)="onMouseLeave()"
          (mouseover)="onMouseOver()"
          class="n-textarea__system-icon"></span>
    <span class="n-textarea__error">{{errorMessage}}</span>

    <span (click)="clear()"
          (mouseleave)="onMouseLeave()"
          (mouseover)="onMouseOver()"
          *ngIf="clearable"
          [class._show]="showClear"
          class="n-textarea__clear-button"></span>
</div>
