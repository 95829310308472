import {ComponentRef} from "@angular/core";
import {IModalOptions} from "./IModalOptions";

/**
 * Context of modal rendering
 */
export class ModalContext<T = any, TResult = any> {

    modalData: T;
    onDone: (result: TResult) => void;
    onCancel: (reason?: any) => void;

    private instanceAwaiters: { resolve: any, reject: any }[] = [];

    constructor(modalData: T, public options: IModalOptions) {
        this.modalData = modalData;
    }

    private _instance: ComponentRef<any>;

    get instance(): ComponentRef<any> {
        return this._instance;
    }

    private _destroyed = false;

    get destroyed(): boolean {
        return this._destroyed;
    }

    cancel(reason?: any) {
        this.onCancel(reason);
        this.destroy();
    }

    destroy() {
        this._destroyed = true;
        this.destroyInstance();
    }

    done(result?: TResult) {
        this.onDone(result);
        this.destroy();
    }

    ensureInstance(): Promise<ComponentRef<any>> {
        if (this._instance) {
            return Promise.resolve(this._instance);
        }
        return new Promise((resolve, reject) => {
            this.instanceAwaiters.push({resolve, reject});
        });
    }

    setComponentInstance(instance: ComponentRef<any>) {
        this._instance = instance;

        if (this._destroyed) {
            // modal was destroyed while component was instantiated
            this.destroyInstance();
            return;
        }

        if (this.instanceAwaiters.length) {
            for (let awaiter of this.instanceAwaiters) {
                awaiter.resolve(instance);
            }
            this.instanceAwaiters = [];
        }
    }

    private destroyInstance() {
        if (this.instance) {
            this.instance.destroy();
            this._instance = undefined;

            if (this.instanceAwaiters.length) {
                for (let awaiter of this.instanceAwaiters) {
                    awaiter.reject();
                }
            }
        }
    }

}
