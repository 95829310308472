<n-dropdown-menu-item #menuItem (select)="select()" *ngIf="!_divider; else d"
                      [description]="description" [heading]="heading"
                      [iconColor]="iconColor"
                      [iconId]="iconId"
                      [id]="value"
                      [tid]="tid">
</n-dropdown-menu-item>

<ng-template #d>
    <n-dropdown-menu-divider #menuItem></n-dropdown-menu-divider>
</ng-template>
