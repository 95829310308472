import {NgModule} from "@angular/core";
// BUG: do not fix path, see https://github.com/dherges/ng-packagr/issues/734
import {NotificationToastComponent} from "./components/n-notification-toast/n-notification-toast.component";
import {NToastContainerComponent} from "./components/n-toast-container/n-toast-container.component";
import {NToastTextComponent} from "./components/n-toast-text/n-toast-text.component";
import {CommonModule} from "@angular/common";

const TOASTS_DIRECTIVES = [
    NotificationToastComponent,
    NToastContainerComponent,
    NToastTextComponent
];

@NgModule({
    declarations: [
        ...TOASTS_DIRECTIVES
    ],
    imports: [
        CommonModule
    ],
    providers: []
})
export class NToastsModule {
}
