import {UrlParser} from "./UrlParser";

const emailRegEx = /([A-Z0-9][-_.A-Z0-9+]*@[A-Z0-9][-.A-Z0-9]*\.[A-Z]{2,})/gmi;
const urlPattern = /[a-zA-Zа-яА-ЯёЁ\d:._\-\\\/?=#!+$*%@&{},()~]+/g;
const linkPattern = /((?:ht|f)tps?:\/\/url)/g;
// markupdown links:
const pattern = /(?:\[([^\[\]]+)]\((url)\))|(?:link)/g;
const mentionRegex = /(^|\s|>|,|\(|\))(?:@)([A-Za-z0-9_.\-]+)(?:\[(.+?)])?/g;

function isExternalLink(url: string): boolean {
    const parsed = UrlParser.parseUrl(encodeURI(url));
    /* у IE может не задан, если ссылка относительная */
    return parsed.hostname ? parsed.hostname !== location.hostname : false;
}

/**
 * Cut long raw urls in text and wrap input <a> tag
 */
export function shrinkUrlsInText(text: string): string {

    const result = pattern.source
        .replace(/link/g, linkPattern.source.replace(/url/g, urlPattern.source))
        .replace(/url/g, urlPattern.source);

    const urlRegex = new RegExp(result, "g");

    const deniedEnders = [".", ",", "!", "#", ":", "?"];
    return text.replace(urlRegex, (allMatch: string, match1: string, match2: string, match3: string) => {

        const args = [match1, match2, match3];
        const matches = [];
        for (let i = 0; i < args.length; i++) {
            if (args[i] && args[i].length) {
                matches.push(args[i]);
            }
        }

        let url: string, title: string;

        if (matches.length === 1) { // Указан только URL
            url = matches[0];
        } else {
            title = matches[0];
            url = matches[1];
        }

        let endSymbol = "";
        /* Отброшенный символ из ссылки */
        // Определяем заканчивается ли ссылка на запрещенный символ
        const endUrlSymbol = url.slice(-1);
        if (deniedEnders.indexOf(endUrlSymbol) >= 0) {
            url = url.substring(0, url.length - 1);
            endSymbol = endUrlSymbol;
        }

        const openedBracketMatch = url.match(/\(/g);
        const closedBracketMatch = url.match(/\)/g);
        let endOfString = "";
        if (closedBracketMatch) {
            const closedBracketPosition = url.match(/\)/).index;
            if (openedBracketMatch) {
                if (closedBracketMatch.length > 1) {
                    endOfString = url.substring(closedBracketPosition + 1);
                    url = url.substring(0, closedBracketPosition + 1);
                }
            } else {
                endOfString = url.substring(closedBracketPosition);
                url = url.substring(0, closedBracketPosition);
            }
        }

        const parsed = UrlParser.parseUrl(url);

        /* у IE может не задан, если ссылка относительная */
        const openLinkInNewWindow = isExternalLink(url);

        if (!title) {
            // Если заголовок ссылки не указан, то правильно усекаем ее

            title = parsed.protocol + "//" + parsed.hostname;
            if (parsed.port.length > 0 && parsed.port !== "80" && parsed.port !== "443" && parsed.port !== "0") {
                title += ":" + parsed.port;
            }
            let ends = false;
            const pathname = parsed.pathname[0] === "/" ? parsed.pathname : "/" + parsed.pathname;
            if (pathname.length > 9) {
                ends = true;
                title += pathname.substring(0, 5) + "...";
            } else {
                title += pathname;
            }
            if (!ends && title.length < 20) {
                const allSearch = parsed.search + parsed.hash;
                title += allSearch.length > 9 ? allSearch.substr(0, 5) + "..." : allSearch;
            }
        }
        const newWindowLinkParams = ` target="_blank" rel="noopener nofollow noreferrer" `;

        return "<a" + (openLinkInNewWindow ? newWindowLinkParams : " ") + "href=\"" + url + "\">" + title + "</a>" + endOfString + endSymbol;
    });
}

export function replaceEmailAddresses(text: string): string {
    if (text.indexOf("@") > -1) {
        return text.replace(emailRegEx, "<a href=\"mailto:$1\">$1</a>");
    } else {
        return text;
    }
}

export function replaceMentionsInText(text: string): string {
    return text.replace(mentionRegex, (all: string, ...args: any[]) => {
        const space: string = args[0];
        const login: string = args[1];
        let name: string = args[2];
        if (!name) {
            name = "@" + login;
        }
        return space + `<a class="mention-link" href="/profile/${login}">${name}</a>`;
    });
}
