import {Injectable} from "@angular/core";
import {
    ACTION_DOCUMENT_ID_INDEX,
    DatabaseService,
    DatabaseStore,
    DOCUMENTS_DIFF_OPERATIONS_NAME,
    DOCUMENTS_PENDING_ACTIONS_NAME,
    DOCUMENTS_PENDING_OPERATIONS_NAME,
    DOCUMENTS_SNAPSHOT_STORE_NAME,
    OP_DOCUMENT_ID_INDEX
} from "./database.service";
import {IDocumentTransferAction, IOperationTransferObject} from "../../+document/services/operations/TransferModel";
import {generateUniqueId} from "../../shared/Utility";
import {DocumentBody, DocumentBodyWithOperations} from "../models/documents/DocumentBody";
import {diffInDays} from "../../modules/translate/DateUtility";

const DAYS_TO_EXPIRE_CACHE = 5; // days after document state in CACHE will be expired

export interface IDocumentStorageInfo {
    /**
     * Date of creation
     */
    created: Date;

    id: string;

    /**
     * Serialized DocumentBody
     */
    rawBody: any;
}

@Injectable({providedIn: "root"})
export class DocumentStorage {

    constructor(private db: DatabaseService) {

    }

    /**
     * Append new action for specified document id
     */
    async addPendingAction(documentId: string, action: IDocumentTransferAction): Promise<any> {
        const store = await this.getActionStore("readwrite");
        await store.save({id: action.id, ops: action.ops, documentId, baseRev: action.baseRev, created: new Date()});
    }

    async dropDiffOperations(documentId: string) {
        const store = await this.getDiffOperationsStore("readwrite");
        await store.removeRange(OP_DOCUMENT_ID_INDEX.name, IDBKeyRange.only(documentId));
    }

    /**
     * Return actual document state (last snapshot + diff operations)
     */
    async getActualDocumentSnapshot(documentId: string): Promise<DocumentBodyWithOperations | undefined> {
        const bodyStore = await this.getDocumentStore("readonly");
        const body = await bodyStore.read(documentId);
        if (!body) {
            // ensure that client has no diff operations
            await this.dropDiffOperations(documentId);
            return;
        }
        if (diffInDays(new Date(), body.created) > DAYS_TO_EXPIRE_CACHE) {
            // document has expired
            // TODO diff operations, what to do? Server possible to reject this operations, because it's too old
            return;
        }
        const opsStore = await this.getDiffOperations(documentId);

        return {body: DocumentBody.parse(documentId, body.rawBody), ops: opsStore};
    }

    /**
     * Returns pending actions for specified document
     */
    async getPendingActions(documentId: string): Promise<IDocumentTransferAction[]> {
        const store = await this.getActionStore("readonly");
        const actions = await store.find(ACTION_DOCUMENT_ID_INDEX.name, IDBKeyRange.only(documentId));
        return actions
            // sort by date, asc
            .sort((a, b) => a.created.getTime() - b.created.getTime())
            .map(a => {
                return {
                    id: a.id,
                    ops: a.ops,
                    baseRev: a.baseRev
                };
            });
    }

    /**
     * Returns all pending operations for specified document
     */
    async getPendingOperations(documentId: string): Promise<StoredOperations[]> {
        const store = await this.getOperationsStore("readonly");
        const storedOps = await store.find(OP_DOCUMENT_ID_INDEX.name, IDBKeyRange.only(documentId));
        return storedOps
            // sort by date, asc
            .sort((a, b) => a.created.getTime() - b.created.getTime());
    }

    async registerDiffOperations(documentId: string, ops: IOperationTransferObject[]) {
        const store = await this.getDiffOperationsStore("readwrite");
        await store.save({id: generateUniqueId(10), created: new Date(), documentId, ops});
    }

    async registerPendingOperations(documentId: string, ops: IOperationTransferObject[]): Promise<any> {
        const store = await this.getOperationsStore("readwrite");
        await store.save({id: generateUniqueId(10), created: new Date(), documentId, ops});
    }

    async removeActions(actions: IDocumentTransferAction[]) {
        const store = await this.getActionStore("readwrite");
        for (let action of actions) {
            await store.remove(action.id);
        }
    }

    async removeDocument(id: string): Promise<any> {
        const store = await this.getDocumentStore("readwrite");
        return store.remove(id);
    }

    async removePendingOperations(pendingOps: StoredOperations[]) {
        const store = await this.getOperationsStore("readwrite");
        for (let op of pendingOps) {
            await store.remove(op.id);
        }
    }

    async saveDocument(info: IDocumentStorageInfo): Promise<any> {
        const store = await this.getDocumentStore("readwrite");
        return store.save(info);
    }

    private async getActionStore(mode: IDBTransactionMode): Promise<DatabaseStore<StoredAction>> {
        return this.db.getStore<StoredAction>(DOCUMENTS_PENDING_ACTIONS_NAME, mode);
    }

    private async getDiffOperations(documentId: string) {
        const opsStore = await this.getDiffOperationsStore("readonly");
        const ops = await opsStore.find(OP_DOCUMENT_ID_INDEX.name, IDBKeyRange.only(documentId));

        return ops
            // sort by date, asc
            .sort((a, b) => a.created.getTime() - b.created.getTime())
            .reduce((agg, curr) => agg.concat(curr.ops), []);
    }

    private getDiffOperationsStore(mode: IDBTransactionMode): Promise<DatabaseStore<StoredOperations>> {
        return this.db.getStore<StoredAction>(DOCUMENTS_DIFF_OPERATIONS_NAME, mode);
    }

    private getDocumentStore(mode: IDBTransactionMode): Promise<DatabaseStore<IDocumentStorageInfo>> {
        return this.db.getStore<IDocumentStorageInfo>(DOCUMENTS_SNAPSHOT_STORE_NAME, mode);
    }

    private getOperationsStore(mode: IDBTransactionMode): Promise<DatabaseStore<StoredOperations>> {
        return this.db.getStore<StoredAction>(DOCUMENTS_PENDING_OPERATIONS_NAME, mode);
    }
}

interface StoredAction {
    id: string;
    ops: IOperationTransferObject[];
    documentId: string;
    baseRev: number;
    created: Date;
}

export interface StoredOperations {
    id: string;
    ops: IOperationTransferObject[];
    documentId: string;
    created: Date;
}
