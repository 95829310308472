import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from "@angular/core";
import {ToastInstance} from "../../ToastInstance";
import {IToastOptions} from "../../IToastOptions";
import {animate, group, keyframes, query, style, transition, trigger} from "@angular/animations";

// noinspection TsLint
@Component({
    selector: "n-toast-container",
    styles: [
        `
      :host {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        right: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 380px;
        transform: translateZ(0);
      }

      .container {
        will-change: height;
      }

      @media only screen and (min-width: 380px) {
        :host {
          margin-top: 2px;
          max-width: 340px;
        }
      }

      @media only screen and (min-width: 768px) {
        :host {
          margin-top: 28px;
        }
      }
    `
    ],
    animations: [
        trigger("toast", [

            transition("void => in", [
                query("n-notification-toast", [
                    animate("300ms", keyframes([
                        style({opacity: 0, transform: "translate(0, -25%)"}),
                        style({opacity: 1, transform: "translate(0, -12.5%)"}),
                        style({opacity: 1, transform: "translate(0, 0)"})
                    ]))
                ])
            ]),

            transition("in => void", [
                group([
                    query("n-notification-toast", [
                        animate("300ms", keyframes([
                            style({opacity: 1, transform: "translate(0, 0)"}),
                            style({opacity: 0, transform: "translate(0, -50%)"}),
                            style({opacity: 0, transform: "translate(0, -100%)"}),
                        ]))
                    ]),
                    animate("300ms ease", style({height: "0px"}))
                ])
            ])

        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div *ngFor="let t of toasts" class="container" (click)="onToastClick(t)" [@toast]="'in'">
      <n-notification-toast
        [instance]="t.instance"
        [componentType]="t.componentType"
        [data]="t.data"></n-notification-toast>
    </div>`
})
export class NToastContainerComponent {

    toasts: ToastInfo[] = [];

    constructor(private cd: ChangeDetectorRef) {
    }

    onToastClick(t: ToastInfo) {
        if (t.canClose) {
            t.instance.close();
        }
    }

    showToast(componentType: any, options: IToastOptions): ToastInstance {

        if (options.uniqueId) {
            // find opened toast
            const toast = this.toasts.find(t => t.uniqueId === options.uniqueId);
            if (toast) {
                // toast already opened
                return toast.instance;
            }
        }

        let tmrId: number;

        const cancelTimer = () => {
            if (tmrId) {
                window.clearTimeout(tmrId);
                tmrId = undefined;
            }
        };

        let toastInstance: ToastInstance, toastInfo: ToastInfo;

        const onDestroy = () => {
            cancelTimer();
            const indx = this.toasts.indexOf(toastInfo);
            if (indx >= 0) {
                this.toasts.splice(indx, 1);
                this.cd.detectChanges();
            }
        };

        toastInstance = new ToastInstance(onDestroy, options.data);
        toastInfo = {
            uniqueId: options.uniqueId,
            instance: toastInstance,
            componentType: componentType,
            data: options.data,
            canClose: options.canClose
        };

        tmrId = options.timeout > 0 ? window.setTimeout(() => toastInstance.close(), options.timeout) : null;

        this.toasts.push(toastInfo);

        this.cd.detectChanges();

        return toastInstance;

    }

}


export interface ToastInfo {
    instance: ToastInstance;
    uniqueId?: string;

    data: any;
    componentType: any;
    canClose: boolean;
}
