import {Component, ElementRef, TemplateRef, ViewChild, ViewEncapsulation} from "@angular/core";
import {MenuService} from "../../services/menu.service";
import {AppService, getAppVersion} from "../../services/app.service";
import {distinctUntilChanged, map, Observable} from "rxjs";
import {UserInfo} from "../../models/UserInfo";
import {NotebookInfo} from "../../models/NotebookInfo";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {PinnedTeamInfo} from "../../models/PinnedTeamInfo";
import {SearchSuggestsService} from "../../services/search/search-suggests.service";
import {getOsCommandIdentifier} from "../../../shared/Utility";
import {PopoverInstance, PopoverService} from "../../../modules/n-popover";
import {UserService} from "../../services/user.service";
import {TeamService} from "../../services/team.service";
import {SupportService} from "../../services/support.service";
import {CdkDragDrop} from "@angular/cdk/drag-drop";

@Component({
    selector: "side-menu",
    templateUrl: "./side-menu.html",
    providers: [MenuService],
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./side-menu.bem.css"
    ]
})
export class SideMenuComponent {

    // fields

    currentLang$: Observable<string>;
    currentLangSnapshot = undefined;

    currentUser$: Observable<UserInfo>;
    // pinned teams:
    teams$: Observable<PinnedTeamInfo[]>;
    // pinned notebooks
    notebooks$: Observable<NotebookInfo[]>;

    appVersion: string;
    searchHotKey: string = getOsCommandIdentifier("⌘P", "Сtrl+P");

    @ViewChild("helpButton", {static: true, read: ElementRef})
    helpButton: ElementRef;

    notebookSnapshot: NotebookInfo[];

    dragDelay: number;

    private helpMenu: PopoverInstance;
    private teamsSnapshot: PinnedTeamInfo[];

    constructor(private appSvc: AppService,
                private authSvc: AuthService,
                private menuSvc: MenuService,
                private suggests: SearchSuggestsService,
                private router: Router,
                private popovers: PopoverService,
                private userSvc: UserService,
                private teamSvc: TeamService,
                support: SupportService) {
        this.dragDelay = support.isTouch() ? 600 : 0;
    }

    createTeam() {
        this.router.navigateByUrl("/create-team");
    }

    drop(event: CdkDragDrop<string[]>) {
        this.moveUserPinnedNotebook(event.previousIndex, event.currentIndex);
    }

    dropTeam(event: CdkDragDrop<string[]>) {
        this.movePinnedTeam(event.previousIndex, event.currentIndex);
    }

    dropTeamNotebook(team: PinnedTeamInfo, event: CdkDragDrop<string[]>) {
        this.moveTeamPinnedNotebook(team, event.previousIndex, event.currentIndex);
    }

    ngOnInit() {

        this.currentUser$ = this.appSvc.context$
            .pipe(
                map(ctx => ctx ? ctx.user : undefined)
            );

        this.currentLang$ = this.appSvc.context$
            .pipe(
                map(ctx => {
                    if (!ctx || !ctx.user) {
                        return;
                    }
                    switch (ctx.user.culture) {
                        case "ru":
                            return "en";
                        case "en":
                            return "ru";
                        default:
                            return "en";
                    }

                }),
                distinctUntilChanged()
            );

        this.currentLang$.subscribe(v => this.currentLangSnapshot = v);

        this.teams$ = this.appSvc.context$
            .pipe(
                map(ctx => {
                    if (!ctx) {
                        return [];
                    }
                    return ctx.pinnedTeams;
                })
            );

        this.notebooks$ = this.appSvc.context$
            .pipe(
                map(ctx => {
                    if (!ctx) {
                        return [];
                    }
                    return ctx.pinnedNotebooks;
                })
            );

        this.appVersion = getAppVersion();

        this.notebooks$.subscribe(n => this.notebookSnapshot = n.slice(0));

        this.teams$.subscribe(teams => this.teamsSnapshot = teams);
    }

    // noinspection JSMethodCanBeStatic
    noteBookIdentity(indx: number, notebook: NotebookInfo) {
        return notebook.name;
    }

    openHelp(template: TemplateRef<any>) {
        if (this.helpMenu) {
            this.helpMenu.close();
            this.helpMenu = undefined;
        } else {
            this.helpMenu = this.popovers.open({
                templateRef: template,
                popoverData: {},
                mobileView: true,
                targetElementRef: this.helpButton,
                position: "top"
            });
            this.helpMenu.result.then(() => this.helpMenu = undefined);
        }
    }

    openSearch(e) {
        this.menuSvc.closeMenu();
        this.suggests.openSuggests();

        e.preventDefault();
    }

    signout() {
        this.authSvc.signOut();
    }

    teamInfoIdentity(indx: number, info: PinnedTeamInfo) {
        return info.team.name;
    }

    private async movePinnedTeam(oldIndex: number, newIndex: number) {
        const teamToMove = this.teamsSnapshot[oldIndex];
        await this.teamSvc.movePinnedTeam(teamToMove.team.name, newIndex);
    }

    private async moveTeamPinnedNotebook(team: PinnedTeamInfo, oldIndex: number, newIndex: number) {
        const notebookToMove = team.pinnedNotebooks[oldIndex];
        await this.teamSvc.movePinnedNotebook(team.team.name, notebookToMove, newIndex);
        // await this.appSvc.actualizeContext(true, true);
    }

    private async moveUserPinnedNotebook(oldIndex: number, newIndex: number) {
        const notebookToMove = this.notebookSnapshot[oldIndex];
        await this.userSvc.movePinnedNotebook(notebookToMove, newIndex);
        // await this.appSvc.actualizeContext(true, true);
    }
}
