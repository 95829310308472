export const KeyCodes = {
    ENTER: 13,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    END: 35,
    HOME: 36,
    SPACE: 32,
    ESCAPE: 27,
    TAB: 9
};
