import {ChangeDetectionStrategy, Component} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {ModalContext} from "../../../modules/modal";
import {TranslateService} from "../../../modules/translate";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: "sign-in-page",
    templateUrl: "./sign-in-page.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./sign-in-page.ng.css"
    ]
})
export class SignInPageComponent {

    errorKey$ = new BehaviorSubject<string>("");
    submitting$ = new BehaviorSubject<boolean>(false);

    form: FormGroup;

    constructor(private modalCtx: ModalContext<any, string>,
                private authSerivce: AuthService,
                private translateSvc: TranslateService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        const {culture} = this.route.snapshot.queryParams;

        if (culture) {
            this.translateSvc.setLocale(culture);
        }

        this.form = new FormGroup({
            username: new FormControl("", [Validators.required, Validators.maxLength(32)]),
            password: new FormControl("", [Validators.required, Validators.maxLength(64)]),
            dontRememberMe: new FormControl(false)
        });
    }

    resetPassword(e) {
        e.preventDefault();
        const val = this.form.controls["username"].value;
        this.router.navigateByUrl("/reset-password" + (val ? `?from=${val}` : ""));
    }

    async submit(form: { username: string, password: string, dontRememberMe: boolean }) {

        if (!form.username || !form.password) {
            return;
        }

        if (this.submitting$.value) {
            return;
        }

        try {
            this.submitting$.next(true);
            await this.authSerivce.authentificate(form.username, form.password, !form.dontRememberMe);

            this.errorKey$.next("");
            this.modalCtx.done();
        } catch (fail) {
            if (fail.statusCode === 400) {
                this.errorKey$.next("signIn.invalidcredentials");
            } else {
                this.errorKey$.next("signIn.unabletoauth");
                console.error(fail);
            }
        } finally {
            this.submitting$.next(false);
        }

    }

}
