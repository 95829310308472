import {UserRef} from "./UserRef";
import {CultureCode} from "./CultureCode";
import {TeamRef} from "./TeamRef";

export type ExternalLogin = {
    providerName: string;
}

export class UserInfo extends UserRef {

    culture: CultureCode;
    aboutText: string;
    email: string; // exist only in current profile context
    emailConfirmed: boolean; // exist only in current profile context
    passwordNotSet: boolean; // exist only in current profile context
    unconfirmedEmail: string; // exist only in current profile context
    externalPageUrl: string;
    /**
     * External logins of current user (google, facebook etc)
     */
    logins: ExternalLogin[];  // exist only in current profile context

    participantIn: TeamRef[] = [];
    administratorIn: TeamRef[] = [];

    get isExternal(): boolean {
        return !!this.externalPageUrl;
    }

    static parse(data: any): UserInfo {
        let user = UserRef.parse(data, new UserInfo()) as UserInfo;
        user.culture = data.culture;
        user.aboutText = data.aboutText;
        user.email = data.email;
        user.emailConfirmed = data.emailConfirmed;
        user.unconfirmedEmail = data.unconfirmedEmail;
        user.passwordNotSet = data.passwordNotSet;
        user.externalPageUrl = data.externalPageUrl;
        if (data.externalLogins) {
            user.logins = data.logins;
        }
        if (data.administratorIn) {
            user.administratorIn = TeamRef.parseArray(data.administratorIn);
        }
        if (data.participantIn) {
            user.participantIn = TeamRef.parseArray(data.participantIn);
        }
        return user;
    }

    static parseArray(data: any[]): UserInfo[] {
        return data.map(d => UserInfo.parse(d));
    }

    getNavigateUrl(): string[] {
        return ["/profile", this.username];
    }


}
