<div (click)="select.emit()" *ngIf="hiddenCount; else suggestTmpl" [class._focused]="focused" class="expander">
    {{ "search.more" | translate: {count: hiddenCount} }}
</div>

<ng-template #suggestTmpl>
    <div (click)="select.emit()" [class._focused]="focused" class="line">
        <div class="avatar">
            <ng-container *ngIf="suggest.$type === 'suggest'">
                <ng-container [ngSwitch]="suggest.type">
                    <n-icon *ngSwitchCase="'doc'" [fillColor]="'rgba(0,0,0,.6)'" [iconId]="'doc'"
                            class="doc-icon"></n-icon>
                    <user-avatar *ngSwitchCase="'team'" [item]="suggest"></user-avatar>
                    <user-avatar *ngSwitchCase="'user'" [item]="suggest"></user-avatar>
                </ng-container>
            </ng-container>

            <n-icon *ngIf="suggest.$type === 'notebook'" [fillColor]="suggest.cssColor" [iconId]="'notebook-icon'"
                    class="notebook-icon"></n-icon>
        </div>
        <ng-container [ngSwitch]="suggest.$type">
            <div *ngSwitchCase="'block'" [class.block-header]="suggest.type === 'header'"
                 [innerHTML]="suggest.text"
                 class="text _block-text">
            </div>
            <div *ngSwitchCase="'notebook'" class="text">
                {{suggest.title}}
            </div>
            <div *ngSwitchDefault class="title">
                {{suggest.title}}
                <div *ngIf="suggest.type === 'doc'" class="details">
                    <span class="notebook">{{suggest.notebookTitle}}</span>
                    <span class="author">{{suggest.author}}</span>
                    <span class="created">
                    <format-date [date]="suggest.created"></format-date>
                </span>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
