import {TeamInfo} from "./TeamInfo";
import {NotebookInfo} from "./NotebookInfo";

export class PinnedTeamInfo {
    readonly team: TeamInfo;
    readonly pinnedNotebooks: NotebookInfo[];
    readonly inviting: boolean;

    constructor(team: TeamInfo, pinnedNotebooks: NotebookInfo[], inviting: boolean) {
        this.team = team;
        this.pinnedNotebooks = pinnedNotebooks;
        this.inviting = inviting;
    }

    static parse(data: any): PinnedTeamInfo {
        return new PinnedTeamInfo(TeamInfo.parse(data.team), NotebookInfo.parseArray(data.pinnedNotebooks), data.inviting);
    }

    static parseArray(data: any[]): PinnedTeamInfo[] {
        return data.map(d => PinnedTeamInfo.parse(d));
    }

}
