export type EntityColor = "brown" | "blue" | "green" | "red" | "violet" | "yellow" | "marine";

const colorMap = {
    "brown": "#8c6c5c",
    "blue": "#3f95dd",
    "green": "#6aa458",
    "red": "#c25959",
    "violet": "#9e73af",
    "yellow": "#eebc45",
    "marine": "#539e9f",
};

export function getEntityColors(): EntityColor[] {
    return Object.keys(colorMap) as EntityColor[];
}

export function getEntityCssColor(color: EntityColor): string {
    return colorMap[color] || "#000";
}


export function getRandomEntityColor(): EntityColor {
    const colors = getEntityColors();
    const index = Math.floor(Math.random() * (colors.length - 1));

    return colors[index];
}
