<div class="page">
    <div class="body">
        <form-header>
            <dr-logo></dr-logo>
        </form-header>

        <form-window>

            <form-window-header>{{"pwdReset.header" | translate}}</form-window-header>

            <p *ngIf="success$ | async" class="text">
                {{ "pwdReset.successHint" | translate }}
            </p>

            <form (ngSubmit)="submit(form.value)" *ngIf="!(success$ | async)" [formGroup]="form" autocomplete="off"
                  class="form" focusInvalidFieldOnSubmit>

                <p class="text">
                    {{ "pwdReset.hint" | translate }}
                </p>

                <dr-field [label]="'signIn.loginLabel'" style="margin-top: 20px;">
                    <n-input #nameInput [focusOnDefault]="nameInput"
                             [formControlName]="'nameOrEmail'"
                             [required]="true"
                             wide></n-input>
                </dr-field>

                <div class="submit">
                    <n-button [disabled]="submitting$ | async"
                              [loading]="submitting$ | async"
                              [text]="'pwdReset.submit'|translate"
                              [theme]="'prime'"
                              [type]="'submit'"></n-button>
                </div>

            </form>

        </form-window>

        <form-window-extra [hideGoogle]="true" [showBoth]="true"></form-window-extra>
    </div>
    <div class="footer">
        <culture-switcher type="tabs"></culture-switcher>
    </div>
</div>



