import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from "@angular/core";
import {BehaviorSubject, combineLatest, map, Observable} from "rxjs";

@Component({
    selector: "n-button,n-action-button",

    template: `
    <button [ngClass]="joinClasses|async"
            [type]="type"
            [title]="title"
            [disabled]="disabled"
            (focus)="focus.emit($event)"
            (blur)="blur.emit($event)">
      <span *ngIf="icon" class="icon">
          <n-icon [iconId]="icon" [iconSize]="iconSize" [optionalSize]="true"></n-icon>
      </span>
      <span *ngIf="text$|async" class="text">{{text$ | async}}</span>
      <span *ngIf="count$|async" class="count">{{count$ | async}}</span>
    </button>
  `,
    styleUrls: ["./n-button.ng.css"],
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class NButtonComponent implements OnInit {

    @Input() type: NButtonType = "button";
    @Input() key: string;
    @Input() icon: string;
    @Input() title = "";
    /* EVENTS */
    @Output() focus = new EventEmitter<any>();
    @Output() blur = new EventEmitter<any>();
    joinClasses: Observable<string>;
    icon$ = new BehaviorSubject<string>("");
    text$ = new BehaviorSubject<string>("");
    count$ = new BehaviorSubject<string>("");
    theme$ = new BehaviorSubject<NButtonTheme>(null);
    isLoading$ = new BehaviorSubject<boolean>(false);
    isWide$ = new BehaviorSubject<boolean>(false);
    mobileOnlyIcon$ = new BehaviorSubject<boolean>(false);
    isLowercase$ = new BehaviorSubject<boolean>(false);
    readonly iconSize: string;
    readonly rootClass: string;

    constructor(private el: ElementRef, private renderer: Renderer2) {

        if (el.nativeElement.tagName.toLowerCase() === "n-action-button") {
            this.rootClass = "action-button";
            this.iconSize = "18";
        } else {
            this.rootClass = "button";
            this.iconSize = "14";
        }

    }

    private _disabled: boolean;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input() set disabled(val: boolean) {
        if (val) {
            this.renderer.addClass(this.el.nativeElement, "_disabled");
        } else {
            this.renderer.removeClass(this.el.nativeElement, "_disabled");
        }
        this._disabled = val;
    }

    @Input()
    set loading(val: boolean) {
        this.isLoading$.next(val);
    }

    @Input()
    set wide(val: boolean) {
        this.isWide$.next(val);
    }

    @Input()
    set mobileOnlyIcon(val: boolean) {
        this.mobileOnlyIcon$.next(val);
    }

    @Input()
    set theme(val: NButtonTheme) {
        this.theme$.next(val);
    }

    @Input()
    set count(val: string) {
        this.count$.next(val);
    }

    @Input()
    set text(val: string) {
        this.text$.next(val);
    }

    @Input()
    set lowercase(val: boolean) {
        this.isLowercase$.next(val);
    }

    ngOnInit() {

        this.joinClasses =
            combineLatest(
                this.text$,
                this.icon$,
                this.count$,
                this.isLoading$,
                this.isWide$,
                this.theme$,
                this.mobileOnlyIcon$,
                this.isLowercase$)
                .pipe(
                    map(([text, icon, count, isLoading, isWide, theme, mobileOnlyIcon, lowercase]) => {

                        const classes = [];

                        if (!text && !count) {
                            classes.push("_only-icon");
                        }
                        if (isLoading) {
                            classes.push("_loading");
                        }
                        if (isWide) {
                            classes.push("_wide");
                        }
                        if (mobileOnlyIcon) {
                            classes.push("_mobile-only-icon");
                        }
                        if (lowercase) {
                            classes.push("_lowercase");
                        }
                        if (theme) {
                            classes.push("_" + theme);
                        }

                        classes.push("_size-" + this.iconSize);
                        classes.push(this.rootClass);
                        return classes.join(" ");
                    })
                );

    }

}

export type NButtonType = "button" | "submit";
export type NButtonTheme = "prime" | "negative" | "white";
