import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: "new-document-action",
    templateUrl: "new-document-action.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./new-document-action.ng.css"
    ]
})
export class NewDocumentActionComponent {

    @Input() text: string;
    @Output() create = new EventEmitter();

    createDocument() {
        this.create.emit();
    }

}
