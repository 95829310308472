import {Injectable} from "@angular/core";
import {TranslateService} from "../../../modules/translate";

@Injectable({providedIn: "root"})
export class SearchPickerNoResultsRenderer {

    constructor(private translateSvc: TranslateService) {

    }

    createRenderer(): (query: string) => Text {
        const text = this.translateSvc.getString("searchPicker.noResultLabel");
        return (query: string) => {
            const textToDisplay = text.replace("$query", query);
            return document.createTextNode(textToDisplay);
        };
    }

}
