import {Injectable, NgZone} from "@angular/core";
import {Observable, Subject} from "rxjs";


@Injectable({providedIn: "root"})
export class ScrollService {

    private overridedScrollElement: Element;


    constructor(private zone: NgZone) {
        zone.runOutsideAngular(() => {
            const handler = e => {
                if (this._scrollEvents.observers.length) {
                    this._scrollEvents.next(e);
                }
            };
            this.getScrollElement().addEventListener("scroll", handler, {passive: true});
        });
    }

    private _scrollEvents = new Subject<Event>();

    get scrollEvents(): Observable<Event> {
        return this._scrollEvents;
    }

    clearScrollElement() {
        this.overridedScrollElement = undefined;
    }

    getScrollElement(): Element {
        return this.overridedScrollElement || <any>window;
    }

    getScrollTop(): number {
        if (this.overridedScrollElement) {
            return this.overridedScrollElement.scrollTop;
        }
        return window.pageYOffset || document.documentElement.scrollTop;
    }

    moveTop() {
        this.scrollTo(0);
    }

    scrollTo(yPos: number) {
        if (this.overridedScrollElement) {
            this.overridedScrollElement.scrollTop = yPos;
        } else {
            window.scrollTo(0, yPos);
        }
    }

    setScrollElement(element: Element) {
        this.overridedScrollElement = element;
    }
}
