import {Injector} from "@angular/core";
import {AppService} from "./core/services/app.service";
import {AuthService} from "./core/services/auth.service";
import {AnalyticsService} from "./core/services/analytics.service";
import {TranslateService} from "./modules/translate";
import {EnglishTranslation} from "./translations/english/EnglishTranslation";
import {RussianTranslation} from "./translations/russian/RussianTranslation";

const allowAnonymousPathForExternalProfiles = [
    "/doc/",
    "/external-login"
];

export function initializeApp(injector: Injector) {
    const appSvc = injector.get<AppService>(AppService);
    const authSvc = injector.get<AuthService>(AuthService);
    const translateSvc = injector.get<TranslateService>(TranslateService);

    return () => new Promise(async (resolve, reject) => {

        try {
            const ctx = await appSvc.ensureContext();
            const analytics = injector.get<AnalyticsService>(AnalyticsService);

            analytics.startCollect();

            if (ctx.domain.externalProfiles && !isAllowAnonyous()) {
                const needToRequest = ctx.anonymous || !authSvc.isAuthenticated(); // token may expires
                if (needToRequest) {
                    authSvc.externalSignin("DomainOAuth");
                    return; // never resolve, because redirect
                }
            }
            // load current culture from server before app starts
            await translateSvc.addLocales([new EnglishTranslation(), new RussianTranslation()]);
        } catch (err) {
            reject(err);
            return;
        }
        resolve(undefined);
    });

}

function isAllowAnonyous() {
    return allowAnonymousPathForExternalProfiles.some(a => location.pathname.startsWith(a));
}
