import {AuthService, ICredentialsAdapter} from "./auth.service";
import {AppService} from "./app.service";
import {ModalInstance, ModalService} from "../../modules/modal";
import {SignInPageComponent} from "../pages/sign-in-page/sign-in-page.component";
import {Injectable} from "@angular/core";
import {StorageService} from "./storage.service";
import {CacheStorageProvider} from "./CacheStorageProvider";
import {NamedStore} from "./NamedStore";
import {DatabaseService} from "./database.service";
import {Router} from "@angular/router";

const SIGNOUT_URL = "/sign-out";

@Injectable({providedIn: "root"})
export class CredentialService implements ICredentialsAdapter {

    signingOut: boolean;
    private authModal: ModalInstance<any>;

    constructor(private authSvc: AuthService,
                private appSvc: AppService,
                private modal: ModalService,
                cacheStorage: CacheStorageProvider,
                router: Router,
                database: DatabaseService,
                storage: StorageService,
                cache: CacheStorageProvider) {

        authSvc.setCredentialsAdapter(this);
        let currentDomain;
        this.appSvc.context$.subscribe(context => {
            if (context) {
                currentDomain = context.domain;
            }
        });

        let lastSigninUrl = undefined;

        authSvc.onSignIn.subscribe(() => {
            if (lastSigninUrl) {
                router.navigateByUrl(lastSigninUrl, {replaceUrl: true});
                lastSigninUrl = undefined;
            } else if (router.url === SIGNOUT_URL) {
                router.navigateByUrl("/", {replaceUrl: true});
            }
        });

        authSvc.onSignOut.subscribe(async () => {
            lastSigninUrl = router.url;
            // clear all store data
            cache.clear();
            storage.clearAll();
            NamedStore.clearAll();
            database.clearAllStorage(); // dont wait
            cacheStorage.clear();
            if (currentDomain.externalProfiles) {
                window.location.href = currentDomain.signoutUrl;
            } else {
                this.signingOut = true;
                await router.navigate([SIGNOUT_URL]); // clear state of current opened page
                await this.authSvc.ensureToken();
            }
        });

    }

    cancelRequest() {
        if (this.authModal) {
            this.authModal.done(undefined);
            this.authModal = undefined;
        }
    }

    async requestCredentials(): Promise<any> {
        const context = await this.appSvc.ensureContext();
        if (context.domain.externalProfiles) {
            this.authSvc.externalSignin("DomainOAuth");
            await new Promise(() => {
            }); // redirect, never resolve
        }
        // open sign-in form if domain has no external profiles
        this.authModal = this.modal.open(SignInPageComponent);
        try {
            await this.authModal.result;
        } finally {
            this.authModal = undefined;
        }
    }

}
