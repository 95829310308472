import {Injectable} from "@angular/core";
import {TemplatePortalOutlet} from "./model";

@Injectable({providedIn: "root"})
export class PortalOutletMap {

    private outlers: { [name: string]: TemplatePortalOutlet } = {};
    private outletAwaiters: { [outletName: string]: Array<(outlet: TemplatePortalOutlet) => void> } = {};

    register(name: string, outlet: TemplatePortalOutlet) {
        if (this.outlers[name]) {
            throw new Error(`Portal outlet with name ${name} already registered`);
        }
        this.outlers[name] = outlet;

        if (this.outletAwaiters[name]) {
            for (const awaiter of this.outletAwaiters[name]) {
                awaiter(outlet);
            }
            delete this.outletAwaiters[name];
        }
    }

    unregister(name: string) {
        if (this.outlers[name]) {
            delete this.outlers[name];
        }
    }

    wait(outletName: string): Promise<TemplatePortalOutlet> {
        const outlet = this.resolve(outletName);
        if (outlet) {
            return Promise.resolve(outlet);
        }
        if (!this.outletAwaiters[outletName]) {
            this.outletAwaiters[outletName] = [];
        }
        return new Promise(resolve => {
            this.outletAwaiters[outletName].push(resolve);
        });
    }

    private resolve(name: string): TemplatePortalOutlet | undefined {
        if (!this.outlers[name]) {
            return undefined;
        }
        return this.outlers[name];
    }
}
