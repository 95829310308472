import {ChangeDetectionStrategy, Component} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import {ErrorService} from "../../services/error.service";
import {TranslateService} from "../../../modules/translate";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: "sign-in-page",
    templateUrl: "./external-login-page.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./external-login-page.ng.css"
    ]
})
export class ExternalLoginPageComponent {

    processing$ = new BehaviorSubject(true);

    constructor(private userSvc: UserService,
                private authService: AuthService,
                private route: ActivatedRoute,
                private errors: ErrorService,
                private translate: TranslateService,
                private router: Router) {
    }

    async ngOnInit() {
        if (this.authService.isAuthenticated()) {
            await this.router.navigateByUrl("/");
            return;
        }
        const {token, redirect_url} = this.route.snapshot.queryParams;

        if (!token) {
            await this.router.navigateByUrl("/");
            return;
        }

        try {

            this.authService.startTokenObtaining(async () => {
                const result = await this.userSvc.exchangeSignInToken(token, this.translate.getLocale());
                this.authService.authentificateByToken(result.token, result.tokenExpiresIn, true);

                if (redirect_url && redirect_url.indexOf("external-login") < 0) {
                    await this.router.navigateByUrl(redirect_url, {replaceUrl: true});
                } else {
                    await this.router.navigateByUrl("/", {replaceUrl: true});
                }
            });

        } catch (fail) {
            this.errors.showFail(fail);
            this.processing$.next(false);
        }

    }

}
