import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {SearchSuggestItem} from "../../../models/search/SearchSuggestItem";

@Component({
    selector: "mention-suggests-item",
    templateUrl: "./mention-suggests-item.html",
    styleUrls: [
        "./mention-suggests-item.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MentionSuggestsItemComponent implements OnInit {

    @Input()
    suggest: SearchSuggestItem;

    constructor() {
    }

    ngOnInit() {
    }
}
