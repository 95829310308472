<ng-template #actions>
    <n-action-button (click)="openSettings()"
                     *ngIf="canManage"
                     [icon]="'settings-menu'"
                     class="settings-button"></n-action-button>
    <ng-container *ngIf="(notebook$|async).isTeamNotebook || canManage">
        <n-action-button [icon]="'kebab-menu'" [nDropdown]="menu"></n-action-button>

        <n-dropdown-menu #menu>
            <n-dropdown-menu-item (select)="select.emit()" *ngIf="canSelect"
                                  [heading]="'notebooks.menu.select' | translate"></n-dropdown-menu-item>
            <n-dropdown-menu-item (select)="pin.emit()" *ngIf="canPin"
                                  [heading]="pinKey$ | async | translate"></n-dropdown-menu-item>
            <n-dropdown-menu-item (select)="toBin()"
                                  [heading]="'notebooks.menu.recycle' | translate"></n-dropdown-menu-item>
            <n-dropdown-menu-item (select)="remove.emit()" *ngIf="canManage"
                                  [heading]="'notebooks.menu.remove' | translate"></n-dropdown-menu-item>
        </n-dropdown-menu>
    </ng-container>
</ng-template>
<ng-template portal="header-buttons">
    <ng-container *ngTemplateOutlet="actions"></ng-container>
</ng-template>

<div class="header">
    <div class="avatar">
        <notebook-icon
            [color]="(notebook$|async).cssColor"
            [shared]="(notebook$|async).type === 'open'"
            class="icon">
        </notebook-icon>
    </div>
    <div class="info">
        <div class="owner"><a [routerLink]="ownerLink">{{ownerTitle}}</a></div>
        <div class="title">{{(notebook$|async).title}}</div>
    </div>
    <div class="actions">
        <ng-container *ngTemplateOutlet="actions"></ng-container>
    </div>
</div>
