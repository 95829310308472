import {ChangeDetectionStrategy, Component, ElementRef, OnInit} from "@angular/core";
import {combineLatest, distinctUntilChanged, map, Observable, of, switchMap} from "rxjs";
import {MenuService} from "../../services/menu.service";
import {TitleService} from "../../services/title.service";
import {ScrollService} from "../../services/scroll.service";

@Component({
    selector: "header-bar",
    templateUrl: "./header-bar.html",
    styleUrls: [
        "./header-bar.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderBarComponent implements OnInit {

    pageTitle$: Observable<string>;

    showContentTitle$: Observable<boolean>;
    pageContentTitle$: Observable<string>;

    constructor(private menuSvc: MenuService,
                private titleSvc: TitleService,
                private scrollSvc: ScrollService,
                private el: ElementRef) {

    }

    ngOnInit() {
        this.pageTitle$ = this.titleSvc.pageTitle$;

        this.showContentTitle$ =
            combineLatest([this.titleSvc.pageTitle$, this.titleSvc.pageContentTitle$])
                .pipe(
                    switchMap(([pageTitle, pageContentTitle]) => {
                        if (!pageContentTitle) {
                            return of(false);
                        }
                        const offset = pageContentTitle.scrollOffset;
                        return this.scrollSvc
                            .scrollEvents
                            // .notOften(50)
                            .pipe(
                                map(() => this.scrollSvc.getScrollTop() > offset)
                            );
                    }),
                    distinctUntilChanged()
                );

        this.pageContentTitle$ = this.titleSvc
            .pageContentTitle$
            .pipe(
                map(title => title ? title.content : ""),
                distinctUntilChanged()
            );

        this.showContentTitle$.subscribe(show => {

            if (show) {
                this.el.nativeElement.classList.add("_content-title");
            } else {
                this.el.nativeElement.classList.remove("_content-title");
            }

        });
    }

    openMenu() {
        this.menuSvc.openMenu();
    }
}
