import {ChangeDetectionStrategy, Component, ElementRef, Input, NgZone, OnChanges, SimpleChanges} from "@angular/core";
// noinspection TypeScriptPreferShortImport
import {INIconMetadata, INIconSet, NFrameworkIconsFactory} from "../../services/nframework-icons-factory.service";


const awaiters: { [iconSet: string]: Array<(set: INIconSet) => void> } = {};
const fetchingSets: { [iconSet: string]: boolean } = {};
const loadedSets: { [iconSet: string]: INIconSet } = {};

export const BUILTIN_ICON_SET = "builtin";

@Component({
    selector: "n-icon",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ``
})
export class NIconComponent implements OnChanges {

    @Input() iconId: string;
    @Input() iconSize: any;
    @Input() optionalSize: boolean;
    @Input() iconSet: string;
    @Input() autoScale: boolean;

    @Input() fillColor: string;
    @Input() borderColor: string;

    private renderedIcon: SVGElement;

    constructor(private iconsFactory: NFrameworkIconsFactory,
                private el: ElementRef,
                private zone: NgZone) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const {iconSet, iconId} = changes;
        if (iconId || iconSet) {
            this.zone.runOutsideAngular(() => {
                this.refresh();
            });
        }
    }

    private ensureIconSet(iconSet?: string): Promise<INIconSet> {

        if (this.iconSet === BUILTIN_ICON_SET) {
            return Promise.resolve(builtInIcons);
        }

        if (loadedSets[iconSet]) {
            return Promise.resolve(loadedSets[iconSet]);
        }

        if (fetchingSets[iconSet]) {
            if (!awaiters[iconSet]) {
                awaiters[iconSet] = [];
            }
            return new Promise(resolve => awaiters[iconSet].push(resolve));
        }

        fetchingSets[iconSet] = true;

        return this.iconsFactory.getIconSet(this.iconSet).then(set => {

            fetchingSets[iconSet] = false;
            if (awaiters[iconSet]) {
                for (const awaiter of awaiters[iconSet]) {
                    awaiter(set);
                }
            }
            awaiters[iconSet] = [];
            return loadedSets[iconSet] = set;
        });
    }

    private async refresh() {
        const iconSet = await this.ensureIconSet(this.iconSet);

        const icon = iconSet[this.iconId];
        if (!icon) {
            throw new Error(`Icon with id="${this.iconId}" not found in icon set "${this.iconSet}"`);
        }

        let specificIcon = this.iconSize ? icon.find(i => i.size === this.iconSize.toString()) : icon[0];

        if (!specificIcon) {
            if (this.optionalSize) {
                specificIcon = icon[0];
            } else {
                throw new Error(`Icon '${this.iconId}' does not contain size '${this.iconSize}'`);
            }
        }

        this.renderedIcon = buildSvg(specificIcon, this.fillColor, this.borderColor);
        const viewBoxSize = specificIcon.size || this.iconSize;
        if (viewBoxSize) {
            this.renderedIcon.setAttribute("viewBox", `0 0 ${viewBoxSize} ${viewBoxSize}`);
        }
        const useIconSize = this.iconSize && !this.autoScale;
        this.renderedIcon.style.width = useIconSize ? this.iconSize + "px" : "100%";
        this.renderedIcon.style.height = useIconSize ? this.iconSize + "px" : "100%";
        this.renderedIcon.style.verticalAlign = "top";
        this.renderedIcon.style.overflow = "hidden";

        const container = this.el.nativeElement.shadowRoot || this.el.nativeElement;
        // clear
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }
        if (this.renderedIcon) {
            container.appendChild(this.renderedIcon);
        }

    }

}

function buildSvg(icon: INIconMetadata, fillColor?: string, borderColor?: string): SVGElement {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

    if (icon.border) {
        const borderPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
        borderPath.setAttribute("d", icon.border);
        borderPath.setAttribute("fill-rule", "evenodd");
        borderPath.setAttribute("class", "n-icon__border");
        if (borderColor) {
            borderPath.setAttribute("style", "fill: " + borderColor);
        }
        svg.appendChild(borderPath);
    }

    const fillPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
    fillPath.setAttribute("d", icon.fill);
    fillPath.setAttribute("class", "n-icon__fill");
    fillPath.setAttribute("fill-rule", "evenodd");
    if (fillColor) {
        fillPath.setAttribute("style", "fill: " + fillColor);
    }
    svg.appendChild(fillPath);

    return svg;
}


const builtInIcons: INIconSet = {
    "calendar": [
        {
            size: "14",
            fill: "M1 1h3V.5a.5.5 0 0 1 1 0V1h4V.5a.5.5 0 0 1 1 0V1h3v11a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V1zm1 1v2h10V2h-2v.5a.5.5 0 0 1-1 0V2H5v.5a.5.5 0 0 1-1 0V2H2zm1 11h8a1 1 0 0 0 1-1V5H2v7a1 1 0 0 0 1 1zm6.5-7h1a.5.5 0 1 1 0 1h-1a.5.5 0 0 1 0-1zm-3 2h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1 0-1zm-3 0h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1 0-1zm3-2h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1 0-1zm3 2h1a.5.5 0 1 1 0 1h-1a.5.5 0 0 1 0-1zm-6 2h1a.5.5 0 1 1 0 1h-1a.5.5 0 1 1 0-1zm3 0h1a.5.5 0 1 1 0 1h-1a.5.5 0 1 1 0-1z"
        }
    ],
    "back": [
        {
            size: "14",
            fill: "M4.207,7l5.596,5.596l-0.707,0.707l-6.303,-6.303l6.303,-6.303l0.707,0.707l-5.596,5.596Z"
        }
    ],
    "next": [
        {
            size: "14",
            fill: "M9.792,7l-5.596,5.596l0.707,0.707l6.303,-6.303l-6.303,-6.303l-0.707,0.707l5.596,5.596Z"
        }
    ]
};
