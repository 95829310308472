import {MemorySubject} from "../../modules/store";
import {Observable} from "rxjs";

const _allStores: NamedStore<any>[] = [];

export class NamedStore<T> {

    protected _pipes: { [key: string]: MemorySubject<T> } = {};

    constructor() {
        _allStores.push(this);
    }

    static clearAll() {
        for (let store of _allStores) {
            store.clear();
        }
    }

    allKeys(): string[] {
        return Object.keys(this._pipes);
    }

    clear() {
        for (let key of this.allKeys()) {
            this._pipes[key].reset();
        }
    }

    clearByKey(key: string) {
        key = key.toUpperCase();
        if (this._pipes[key]) {
            delete this._pipes[key];
        }
    }

    error(key: string, err: any) {
        const pipe = this.ensure(key);
        pipe.error(err);
    }

    get(key: string): Observable<T> {
        return this.ensure(key).asObservable();
    }

    getSnapshot(key: string): T {
        return this.ensure(key).value;
    }

    hasKey(key: string): boolean {
        return this._pipes.hasOwnProperty(key.toUpperCase());
    }

    hasSnapshot(key: string): boolean {
        return this.ensure(key).hasValue();
    }

    rename(oldKey: string, newKey: string) {
        newKey = newKey.toUpperCase();
        oldKey = oldKey.toUpperCase();

        if (oldKey === newKey) {
            return;
        }

        if (this._pipes[oldKey]) {
            this._pipes[newKey] = this._pipes[oldKey];
            delete this._pipes[oldKey];
        }
    }

    transform(fn: TransformFunction<T>) {
        const change = (key, newVal: T) => {
            this.update(key, newVal, false);
        };
        for (let key of this.allKeys()) {
            if (this.hasSnapshot(key)) {
                fn(key, this.getSnapshot(key), change);
            }
        }
    }

    update(key: string, state: T, fromCache?: boolean) {

        if (fromCache) {
            const snapshot = this.getSnapshot(key);
            // prefer local state instead of cache
            if (snapshot)
                return;
        }

        const pipe = this.ensure(key);
        pipe.next(state);
    }

    protected ensure(key: string): MemorySubject<T> {
        key = key.toUpperCase();
        if (!this._pipes[key]) {
            this._pipes[key] = new MemorySubject<T>();
        }
        return this._pipes[key];
    }
}

export type TransformFunction<T> = (key: string, value: T, change: (key: string, newValue: T) => void) => void;
