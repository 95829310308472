import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl} from "@angular/forms";
import {SearchService} from "../../../services/search/search.service";
import {BehaviorSubject, combineLatest, debounceTime, delay, distinctUntilChanged, map, merge, Observable, of, startWith, Subject, switchMap, takeUntil, tap} from "rxjs";
import {ModalContext} from "../../../../modules/modal";
import {KeyCodes} from "../../../../shared/KeyCodes";
import {SearchSuggestBlockInfo, SearchSuggestItem, SearchSuggestNodebookInfo} from "../../../models/search/SearchSuggestItem";
import {SearchSuggestListItem, SearchSuggestListState} from "./SearchSuggestListState";
import {ISearchSuggestModalData} from "../../../services/search/search-suggests.service";
import {DocumentService} from "../../../services/document.service";
import {DocumentWithNotebooksList} from "../../../models/documents/DocumentWithNotebooksList";
import {MemorySubject} from "../../../../modules/store";
import {AnalyticsService} from "../../../services/analytics.service";
import {shareLastValue} from "../../../../modules/rxjs-ex/shareLastValue";


const MAX_RECENTS_COUNT = 10;

let lastQuery: string = "";
let lastFocusedId: string;
let expanded: string[];

@Component({
    selector: "search-suggest-modal",
    templateUrl: "./search-suggest-modal.html",
    styleUrls: [
        "./search-suggest-modal.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchSuggestModalComponent implements OnInit, OnDestroy {

    filterControl = new FormControl(lastQuery);

    suggests$: Observable<SearchSuggestItem[]>;
    focused$ = new BehaviorSubject<boolean>(false);

    fetching$: Observable<boolean>;
    error$: Observable<boolean>;

    message$: Observable<string>;
    showCancel$: Observable<boolean>;

    @ViewChild("input", {static: true})
    inputElm: ElementRef;

    @ViewChild("wrapper", {static: true})
    wrapper: ElementRef;

    listState = new SearchSuggestListState();
    recents$ = new MemorySubject<boolean>();

    private componentExist$ = new Subject();


    constructor(private search: SearchService,
                private modalCtx: ModalContext<ISearchSuggestModalData>,
                private docSvc: DocumentService,
                private cd: ChangeDetectorRef,
                private analytics: AnalyticsService) {
    }

    clear() {
        this.filterControl.setValue("");
        this.inputElm.nativeElement.focus();
    }

    close() {
        this.modalCtx.done();
    }

    handleKeyDown(e) {
        const code = e.keyCode;
        switch (code) {
            case KeyCodes.ESCAPE:
                this.close();
                break;
            case KeyCodes.ARROW_DOWN:
                this.listState.next();
                e.preventDefault();
                break;
            case KeyCodes.ARROW_UP:
                this.listState.prev();
                e.preventDefault();
                break;
            case KeyCodes.ENTER:
                const focusedItem = this.listState.getFocused();
                if (!focusedItem) {
                    return;
                }
                this.navigateToResult(focusedItem);
                break;
            default:
                return;
        }
    }

    // noinspection JSMethodCanBeStatic
    listIdentity(indx: number, suggest: SearchSuggestListItem) {
        return suggest.id;
    }

    navigateToResult(item: SearchSuggestListItem) {
        if (item.expander) {
            this.listState.expand(item);
            return;
        }
        const urlTree = getUrlFor(item);
        this.analytics.sendEvent("search", "search_navigate", "count", 1);
        if (urlTree) {
            // first close search modal and change location
            // to avoid document modal return to search modal
            let documentId: string, pathToHighlight: number[];
            if (item.item instanceof SearchSuggestBlockInfo) {
                documentId = item.item.documentId;
                pathToHighlight = item.item.path;
            }
            this.modalCtx.modalData.navigateEvents.emit({url: urlTree, documentId, pathToHighlight});
        }
    }

    ngOnDestroy(): void {
        this.componentExist$.next(1);
        this.componentExist$.complete();
        lastFocusedId = this.listState.focusedId;
        expanded = this.listState.expanded;
    }

    ngOnInit() {

        let clearStateAfterSearch = false;

        const queryText$ = this.filterControl.valueChanges
            .pipe(
                startWith(this.filterControl.value),
                distinctUntilChanged(),
                shareLastValue()
            );

        this.suggests$ = merge(
            of(this.filterControl.value), // do not delay for first change
            queryText$.pipe(debounceTime(50))
        )
            .pipe(
                distinctUntilChanged(),
                switchMap((query: string) => {
                    if (!query) {
                        return this.docSvc.getRecent(false)
                            .pipe(
                                map(recentToSuggest),
                                map(suggests => {
                                    return {
                                        recent: true,
                                        suggests
                                    };
                                })
                            );
                    }
                    return this.search.searchSuggests(query)
                        .pipe(
                            map(suggests => {
                                return {
                                    recent: false,
                                    suggests
                                };
                            })
                        );
                }),
                tap(list => {
                    this.recents$.next(list.recent);
                }),
                map(list => list.suggests),
                takeUntil(this.componentExist$),
                shareLastValue()
            );

        this.error$ = queryText$
            .pipe(
                switchMap(query => this.search.hasError(query)),
                distinctUntilChanged()
            );

        this.fetching$ = queryText$
            .pipe(
                switchMap(query => this.search.isFetching(query)
                    .pipe(
                        // if fetching, delay 300ms to show loader
                        switchMap(fetching => of(fetching).pipe(delay(300))),
                        distinctUntilChanged()
                    )),
                takeUntil(this.componentExist$),
                shareLastValue()
            );

        this.message$ = combineLatest(
            [
                this.error$.pipe(startWith(undefined)),
                queryText$,
                this.suggests$,
                this.fetching$.pipe(startWith(false))
            ]
        )
            .pipe(
                map(([error, query, suggests, fetching]) => {
                    if (query && !suggests.length) {
                        return fetching ? "search.searching" : "search.empty";
                    }
                    if (!error && !query && !suggests.length) {
                        return "search.hint";
                    }
                    return undefined;
                }),
                distinctUntilChanged()
            );

        this.showCancel$ = queryText$
            .pipe(
                map(query => query.length > 0),
                distinctUntilChanged()
            );

        queryText$.subscribe((query) => {
            clearStateAfterSearch = true;
            lastQuery = query;
        });

        this.suggests$.subscribe(suggests => {
            this.listState.update(suggests, clearStateAfterSearch, lastFocusedId);
            lastFocusedId = undefined;
            clearStateAfterSearch = false;
        });

        if (this.filterControl.value) {
            Promise.resolve().then(() => {
                this.inputElm.nativeElement.setSelectionRange(0, this.filterControl.value.length);
            });
        }

        if (expanded) {
            this.listState.setExpanded(expanded);
        }
    }

    onBlur() {
        this.focused$.next(false);
    }

    onFocus() {
        this.focused$.next(true);
        // focus on default
        this.cd.detectChanges();
    }


}

function getUrlFor(item: SearchSuggestListItem): any[] {
    if (item.item instanceof SearchSuggestItem) {
        switch (item.item.type) {
            case "doc":
                return ["doc", item.item.name];
            case "team":
                return [item.item.name];
            case "user":
                return ["profile", item.item.name];
            default:
                console.log(`Unknown result type`, item.item.type);
                return undefined;
        }
    } else if (item.item instanceof SearchSuggestNodebookInfo) {
        if (item.item.ownerType === "user") {
            return ["profile", item.item.ownerName, "nb", item.item.name];
        }
        return [item.item.ownerName, "nb", item.item.name];
    } else if (item.item instanceof SearchSuggestBlockInfo) {
        return ["doc", item.item.documentId];
    }

}

function recentToSuggest(list: DocumentWithNotebooksList): SearchSuggestItem[] {
    const result: SearchSuggestItem[] = [];
    for (let group of list.notebooksSnapshot) {
        for (let doc of group.documentsSnapshot) {
            result.push(new SearchSuggestItem("doc",
                doc.title,
                "", "", "",
                doc.id, `doc_${doc.id}`, [], [], doc.createdBy.toFullname(),
                group.notebookSnapshot.title, doc.createdDate));

            if (result.length >= MAX_RECENTS_COUNT) {
                return result;
            }
        }
    }
    return result;
}
