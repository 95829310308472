import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "dr-field",
    templateUrl: "./dr-field.html",
    styleUrls: [
        "./dr-field.bem.css"
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrFieldComponent {

    // fields
    @Input() label: string;
    @Input() labelExtra: string;
    @Input() description: string;
    @Input() mod: string;

}
