import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {IDropdownMenuItem, NDropdownMenuComponent} from "../dropdown/n-dropdown-menu.component";

@Component({
    selector: "n-tabset",
    templateUrl: "./n-tabset.html",
    styleUrls: [
        "./n-tabset.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NTabsetComponent),
            multi: true
        }
    ]
})
export class NTabsetComponent implements ControlValueAccessor {

    /**
     * сворачивание в селект на мобиле
     */
    @Input() selectable: boolean;
    /**
     * другой вид табов
     */
    @Input() switchable: boolean;
    /* todo другой вид табов */
    @Input() mod: "float" | "onLine" | "switcher" | "buttonSwitcher" = "float";
    selectorTitle$ = new BehaviorSubject("");
    selectorCount$ = new BehaviorSubject(null);
    noSelectedTabs = false;

    @ViewChild(NDropdownMenuComponent, {static: true})
    dropdownMenu: NDropdownMenuComponent;

    private propagateChange: (_) => void;
    private panes: INTabPane[] = [];
    private currentModelValue: any;
    private countSub: Subscription;
    private selectedPane: INTabPane;

    constructor() {
    }

    addPane(pane: INTabPane) {
        this.panes.push(pane);
        if (this.currentModelValue && this.currentModelValue === pane.id) {
            this.markPaneAsSelected(pane);
        }
        const menuItem: IDropdownMenuItem = {
            disabled: pane.disabled,
            heading: pane.heading,
            iconColor: pane.iconColor,
            divider: false,
            iconId: pane.iconId,
            description: of(""),
            id: pane.id,
            disabledSnapshot: pane.disabledSnapshot,
            headingSnapshot: pane.headingSnapshot,
            select: new EventEmitter(),
            tid: pane.tid
        };
        menuItem.select.subscribe(() => {
            this.selectPane(pane);
        });
        this.dropdownMenu.addItem(menuItem);
    }

    markFocusedMenu() {
        this.dropdownMenu.setFocusedItemIndex(this.panes.indexOf(this.selectedPane));
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    removePane(pane: INTabPane) {
        const indx = this.panes.indexOf(pane);
        if (indx >= 0) {
            this.panes.splice(indx, 1);
        }
        const addedItem = this.dropdownMenu.getItemsSnapshot().find(i => i.id === pane.id);
        if (addedItem) {
            this.dropdownMenu.removeItem(addedItem);
        }
    }

    selectPane(paneToSelect: INTabPane) {
        this.markPaneAsSelected(paneToSelect);
        this.setSelectedPane(paneToSelect);
    }

    writeValue(val: any): void {
        this.currentModelValue = val;

        for (let i = 0; i < this.panes.length; i++) {
            const pane = this.panes[i];
            if (pane.id && pane.id === val || pane === val) {
                this.markPaneAsSelected(pane);
                return;
            }
        }
        this.markPaneAsSelected(null);
    }

    private markPaneAsSelected(paneToSelect: INTabPane) {
        this.selectedPane = paneToSelect;
        for (let i = 0; i < this.panes.length; i++) {
            const pane = this.panes[i];
            if (pane !== paneToSelect) {
                pane.setSelected(false);
            }
        }

        if (this.countSub) {
            this.countSub.unsubscribe();
        }

        if (paneToSelect) {
            paneToSelect.setSelected(true);
            this.selectorTitle$.next(paneToSelect.headingSnapshot());
            this.countSub = paneToSelect.count$.subscribe(val => this.selectorCount$.next(val));
            this.noSelectedTabs = false;
        } else {
            this.selectorTitle$.next("Не выбрано"); // todo по-русски )))
            this.selectorCount$.next(undefined);
            this.noSelectedTabs = true;
            this.dropdownMenu.setFocusedItemIndex(-1);
        }
        this.markFocusedMenu();
    }

    private setSelectedPane(pane: INTabPane) {
        if (this.propagateChange) {
            this.propagateChange(pane ? pane.id || pane : undefined);
        }
    }

}


export interface INTabPane {

    readonly heading: Observable<string>;
    readonly disabled: Observable<boolean>;
    readonly iconId?: Observable<string>;
    readonly iconColor?: Observable<string>;
    readonly id: any;
    readonly select: EventEmitter<any>;
    readonly tid?: string;

    readonly count$: Observable<number>;

    disabledSnapshot(): boolean;

    headingSnapshot(): string;

    setSelected(set: boolean);
}
