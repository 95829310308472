import {Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from "@angular/core";

@Directive({
    selector: "[infiniteScroll]"
})
export class InfiniteScrollDirective implements OnInit, OnDestroy {

    @Output()
    infiniteScroll = new EventEmitter<any>(true);

    @Input()
    infiniteScrollDistance: number = 150;

    @Input()
    infiniteScrollDisabled: number;
    @Input()
    infiniteScrollElem: Element;
    private scrollElm: any;

    constructor(private el: ElementRef, private zone: NgZone) {
    }

    @Input()
    set infiniteScrollCheck(val: boolean) {
        if (val) {
            this.handler();
        }
    }

    ngOnDestroy() {
        this.scrollElm.removeEventListener("scroll", this.handler, {passive: true});
    }

    ngOnInit() {
        this.scrollElm = this.getScrollElement();
        this.zone.runOutsideAngular(() => {
            this.scrollElm.addEventListener("scroll", this.handler, {passive: true});
        });
    }

    private getScrollElement(): Element | Window {
        if (this.infiniteScrollElem) {
            return this.infiniteScrollElem;
        } else {
            return window;
        }
    }

    private handler = () => {
        if (this.infiniteScrollDisabled) {
            return;
        }
        const rect = this.el.nativeElement.getBoundingClientRect();
        if ((rect.bottom - this.infiniteScrollDistance) < window.innerHeight) {
            this.zone.runGuarded(() => {
                this.infiniteScroll.emit();
            });
        }
    };
}
