import {Injectable} from "@angular/core";
import {TeamInfo, TeamMemberRole} from "../models/TeamInfo";
import {TeamMember} from "../models/TeamMember";
import {distinctUntilChanged, filter, map, Observable, Subject} from "rxjs";
import {NotebookEditDocumentRestrict, NotebookInfo, notebookListComparer, NotebookType} from "../models/NotebookInfo";
import {CacheableDataContextService} from "./cacheable-data-context.service";
import {DataContextService} from "./data-context.service";
import {FailServerResponse} from "../models/FailServerResponse";
import {AppService} from "./app.service";
import {NamedStore} from "./NamedStore";
import {FetchError} from "./FetchError";
import {MemorySubject} from "../../modules/store";
import {FileService} from "./file.service";
import {EntityColor} from "../models/EntityColor";
import {TeamEmailInvite} from "../models/TeamEmailInvite";
import {UserRef} from "../models/UserRef";
import {NOTEBOOK_EVENT_NAME, NotebookPushNotification} from "./pushnotifications/NotebookPushNotification";
import {WsConnectionManager} from "./websockets/WsConnectionManager";
import {AnalyticsService} from "./analytics.service";

type TeamUpdateInfo = {
    type: "updated" | "removed" | "created",
    teamName: string;
    team?: TeamInfo;
};

@Injectable({providedIn: "root"})
export class TeamService {

    private teamUpdates$ = new Subject<TeamUpdateInfo>();
    private teamStore = new NamedStore<TeamInfo>();
    private teamEmailInvitesStore = new NamedStore<TeamEmailInvite[]>();
    private teamNotebooks = new TeamNotebooksStore();

    constructor(private ctx: DataContextService,
                private files: FileService,
                private cacheDataCtx: CacheableDataContextService,
                private analytics: AnalyticsService,
                private appSvc: AppService,
                ws: WsConnectionManager) {


        this.teamUpdates$.subscribe((info) => {
            switch (info.type) {
                case "updated":
                    this.onTeamUpdated(info.teamName, info.team);
                    break;
                case "removed":
                    this.onTeamRemoved(info.teamName);
                    break;
                case "created":
                    this.onTeamCreated(info.team);
                    break;
                default:
                    throw new Error("unknown type event: " + info.type);
            }
        });

        ws.subscribe<NotebookPushNotification>(NOTEBOOK_EVENT_NAME)
            .subscribe(e => this.handleNotebookEvent(e));

    }

    private _errors$ = new Subject<FetchError>();

    get errors$(): Observable<FetchError> {
        return this._errors$.asObservable();
    }

    private _fetchingNotebooks$ = new MemorySubject<boolean>(false);

    get fetchingNotebooks$(): Observable<boolean> {
        return this._fetchingNotebooks$
            .pipe(
                distinctUntilChanged()
            );
    }

    changeAvatar(team: TeamInfo, file: File): Promise<any> {
        return this.files.postFiles([file], `/api/teams/${team.name}/avatar`)
            .result
            .then(res => {
                team.changeAvatar(res.avatarPath);
                this.teamStore.update(team.name, team);
                this.appSvc.teamUpdated(team.name, team);
                return res.avatarPath;
            });
    }

    createNotebook(teamName: string, name: string, title: string, color: string): Promise<NotebookInfo> {
        this.analytics.sendEvent("teams", "create_notebook", "count", 1);

        return this.cacheDataCtx.post(`api/teams/${teamName}/notebooks`, {name, title, color})
            .then((payload) => {
                const notebook = NotebookInfo.parse(payload);
                this.teamNotebooks.addNotebook(teamName, notebook);
                return notebook;
            });
    }

    createTeam(name: string, title: string): Promise<any> {
        this.analytics.sendEvent("teams", "create_team", "count", 1);

        return this.cacheDataCtx.post(`api/teams`, {name: name, title: title})
            .then((team) => {
                this.teamUpdates$.next({
                    type: "created",
                    team: TeamInfo.parse(team),
                    teamName: team.name
                });
            });
    }

    deleteTeam(team: TeamInfo): Promise<any> {
        const url = `api/teams/${team.name}`;
        return this.cacheDataCtx.delete(url)
            .then(() => {
                this.teamUpdates$.next({type: "removed", team: team, teamName: team.name});
                return this.cacheDataCtx.clearCache(url);
            });
    }

    getTeam(name: string, actualize = true): Observable<TeamInfo> {
        if (actualize || !this.teamStore.hasSnapshot(name)) {
            this.actualizeTeam(name);
        }
        return this.teamStore.get(name);
    }

    getTeamInvites(teamName: string): Observable<TeamEmailInvite[]> {
        this.cacheDataCtx.get(`api/teams/${teamName}/invites`)
            .pipe(
                map(x => TeamEmailInvite.parseArray(x.emailInvites))
            ).subscribe(v => this.teamEmailInvitesStore.update(teamName, v), err => console.error(err));

        return this.teamEmailInvitesStore.get(teamName);
    }

    getTeamMembers(name: string, role?: TeamMemberRole, skip?: number, top?: number): Observable<TeamMember[]> {
        let params: any = {};
        if (top) {
            params.top = top.toString();
        }
        if (skip) {
            params.skip = skip.toString();
        }
        if (role) {
            params.role = role;
        }
        return this.cacheDataCtx.get(`api/teams/${name}/members`, {params})
            .pipe(
                map(x => TeamMember.parseArray(x.members))
            );
    }

    getTeamNotebooks(teamName: string, actualize = true): Observable<NotebookInfo[]> {
        if (actualize || !this.teamNotebooks.hasSnapshot(teamName)) {
            this.actualizeTeamNotebooks(teamName);
        }
        return this.teamNotebooks
            .get(teamName)
            .pipe(
                distinctUntilChanged(notebookListComparer)
            );
    }

    inviteByEmail(teamName: string, email: string): Promise<any> {
        this.analytics.sendEvent("teams", "invite_by_email", "count", 1);
        return this.ctx.post(`api/teams/${teamName}/invites`, {email})
            .then(() => {
                const invites = this.teamEmailInvitesStore.getSnapshot(teamName);
                if (invites) {
                    invites.push(new TeamEmailInvite(email));
                    this.teamEmailInvitesStore.update(teamName, invites);
                }
            });
    }

    inviteUser(teamName: string, user: UserRef): Promise<any> {
        this.analytics.sendEvent("teams", "invite_user", "count", 1);
        return this.ctx.post(`api/teams/${teamName}/invites`, {userName: user.username});
    }

    joinToTeam(team: TeamInfo, acceptInvite?: boolean): Promise<any> {
        const user = this.appSvc.currentUserSnapshot;
        return this.ctx.post(`api/teams/${team.name}/join`)
            .then(() => {
                team.joinToTeam(user, acceptInvite);
                this.teamStore.update(team.name, team.clone());
                this.appSvc.addTeam(team);
            });
    }

    leaveTeam(team: TeamInfo): Promise<any> {
        const user = this.appSvc.currentUserSnapshot;
        return this.ctx.post(`api/teams/${team.name}/leave`)
            .then(() => {
                team.leaveTeam(user);
                this.teamStore.update(team.name, team.clone());
                this.appSvc.removeTeam(team.name);
            });
    }

    movePinnedNotebook(teamName: string, notebook: NotebookInfo, newOrder: number): Promise<any> {
        this.analytics.sendEvent("teams", "move_pinned_notebook", "count", 1);
        this.appSvc.movePinnedTeamNotebook(teamName, notebook, newOrder);
        return this.cacheDataCtx
            .post(`api/teams/${teamName}/pinnednotebooks/${notebook.name}/order`, {order: newOrder})
            .catch(() => {
            });
    }

    movePinnedTeam(teamName: string, newOrder: number): Promise<any> {
        this.analytics.sendEvent("teams", "move_pinned_team", "count", 1);
        this.appSvc.movePinnedTeam(teamName, newOrder);
        return this.cacheDataCtx
            .post(`api/pinnedteams/${teamName}/order`, {order: newOrder})
            .catch(() => {
            });
    }

    pinNotebook(teamName: string, notebook: NotebookInfo): Promise<any> {
        notebook.pin();
        this.appSvc.notebookPinned(notebook, teamName);

        this.analytics.sendEvent("teams", "pin_notebook", "count", 1);

        return this.cacheDataCtx
            .post(`api/teams/${teamName}/notebooks/${notebook.name}/pin`, undefined)
            .catch((err) => {
                notebook.unpin();
                this.appSvc.notebookUnpinned(notebook, teamName);

                throw err;
            });
    }

    removeMember(teamName: string, member: TeamMember): Promise<any> {
        return this.cacheDataCtx.delete(`api/teams/${teamName}/members/${member.user.username}`);
    }

    removeNotebook(teamName: string, notebook: NotebookInfo): Promise<any> {
        return this.cacheDataCtx.delete(`api/teams/${teamName}/notebooks/${notebook.name}`)
            .then(() => {
                this.teamNotebooks.removeNotebook(teamName, notebook.name);
                this.appSvc.notebookUnpinned(notebook, teamName);
            });
    }

    setMemberRole(teamName: string, member: TeamMember, role: TeamMemberRole): Promise<any> {
        return this.cacheDataCtx.post(`api/teams/${teamName}/setrole`, {
            users: [{
                username: member.user.username
            }],
            role
        });
    }

    teamExists(name: string): Promise<boolean> {
        return this.ctx.get(`api/teams/${name}/status`)
            .then((data: any) => {
                return !!data.exists;
            });
    }

    teamForbidden(teamName: string): Observable<boolean> {
        return this.errors$
            .pipe(
                filter(e => e instanceof FetchTeamError && e.teamName === teamName),
                map(e => e.response.statusCode === 403)
            );
    }

    teamNotExist(teamName: string): Observable<boolean> {
        return this.errors$
            .pipe(
                filter(e => e instanceof FetchTeamError && e.teamName === teamName),
                map(e => e.response.statusCode === 404)
            );
    }

    unpinNotebook(teamName: string, notebook: NotebookInfo): Promise<any> {
        notebook.unpin();
        this.appSvc.notebookUnpinned(notebook, teamName);

        this.analytics.sendEvent("teams", "unpin_notebook", "count", 1);

        return this.cacheDataCtx.delete(`api/teams/${teamName}/notebooks/${notebook.name}/pin`)
            .catch(() => {
                notebook.pin();
                this.appSvc.notebookPinned(notebook, teamName);
            });
    }

    /**
     * Update existing organization group on the server
     */
    updateTeam(name: string, newTeam: TeamInfo): Promise<any> {

        return this.ctx.put(`api/teams/${name}`, {
            title: newTeam.title,
            name: newTeam.name,
            description: newTeam.description,
            inviteRestrict: newTeam.inviteRestrict,
            type: newTeam.type
        })
            .then(() => {
                this.teamStore.update(name, newTeam);
                this.teamStore.rename(name, newTeam.name);
                this.appSvc.teamUpdated(name, newTeam);
            });
    }

    updateTeamNotebook(teamName: string, name: string, newName: string,
                       title: string, color: EntityColor, type: NotebookType,
                       editRestrict: NotebookEditDocumentRestrict) {
        return this.cacheDataCtx
            .put(`api/teams/${teamName}/notebooks/${name}`, {
                name: newName,
                title,
                color,
                type,
                editRestrict
            })
            .then((payload) => {
                const notebook = NotebookInfo.parse(payload);
                this.teamNotebooks.updateNotebook(teamName, name, notebook);
                this.appSvc.notebookChanged(name, notebook, teamName);
            });
    }

    withdrawInvite(teamName: string, invite: TeamEmailInvite): Promise<any> {
        return this.ctx.delete(`api/teams/${teamName}/invites/${invite.email}`)
            .then(() => {
                const invites = this.teamEmailInvitesStore.getSnapshot(teamName);
                if (invites) {
                    const indx = invites.findIndex(i => i.email === invite.email);
                    if (indx >= 0) {
                        invites.splice(indx, 1);
                    }
                    this.teamEmailInvitesStore.update(teamName, invites);
                }
            });
    }

    private actualizeTeam(name: string) {
        this.cacheDataCtx.get(`api/teams/${name}`,
            undefined,
            this.teamStore.hasSnapshot(name)).subscribe(data => {

            const team = TeamInfo.parse(data);
            this.teamStore.update(name, team);
        }, (err: FailServerResponse) => {
            this._errors$.next(new FetchTeamError(name, err));
        });
    }

    private actualizeTeamNotebooks(teamName: string) {
        this._fetchingNotebooks$.next(true);
        this.cacheDataCtx.get(`api/teams/${teamName}/notebooks`,
            undefined,
            this.teamNotebooks.hasSnapshot(teamName))
            .subscribe(res => {
                this._fetchingNotebooks$.next(false);
                this.teamNotebooks.update(teamName, NotebookInfo.parseArray(res.notebooks));
            }, fail => {
                this._fetchingNotebooks$.next(false);
                this._errors$.next(new FindTeamNotebooksError(teamName, fail));
            });
    }

    private handleNotebookEvent(e: NotebookPushNotification) {
        if (!e.teamName) {
            return;
        }
        switch (e.type) {
            case "created":
            case "changed":
                this.actualizeTeamNotebooks(e.teamName);
                break;
            case "removed":
                this.teamNotebooks.removeNotebook(e.teamName, e.notebookName);
                break;
            default:
                console.warn(`Unknown event type ${e.type}`);
                break;
        }
    }

    private onTeamCreated(team: TeamInfo) {
        this.appSvc.addTeam(team);
    }

    private onTeamRemoved(teamName: string) {
        this.appSvc.removeTeam(teamName);
        this.teamStore.clearByKey(teamName);
    }

    private onTeamUpdated(teamName: string, team: TeamInfo) {
        this.appSvc.teamUpdated(teamName, team);
    }
}

export class FetchTeamError extends FetchError {

    constructor(public teamName: string, err: FailServerResponse) {
        super(err);
    }

}

export class FindTeamNotebooksError extends FetchError {
    constructor(public teamName: string, err: FailServerResponse) {
        super(err);
    }
}

class TeamNotebooksStore extends NamedStore<NotebookInfo[]> {

    addNotebook(teamName: string, notebook: NotebookInfo) {
        const snapshot = this.getSnapshot(teamName);
        if (!snapshot) {
            return;
        }
        const notebooks = snapshot.slice(0); // do not mutate array
        if (notebooks) {
            notebooks.unshift(notebook);
            this.update(teamName, notebooks);
        }
    }

    removeNotebook(teamName: string, notebookName: string) {
        const snapshot = this.getSnapshot(teamName);
        if (!snapshot) {
            return;
        }
        const notebooks = snapshot.slice(0); // do not mutate array
        if (notebooks) {
            const indx = notebooks.findIndex(n => n.name === notebookName);
            if (indx >= 0) {
                notebooks.splice(indx, 1);
                this.update(teamName, notebooks);
            }
        }
    }

    updateNotebook(teamName: string, notebookName: string, notebook: NotebookInfo) {
        let notebooks = this.getSnapshot(teamName).slice(0);
        if (notebooks) {
            const indx = notebooks.findIndex(x => x.name === notebookName);
            if (indx >= 0) {
                notebooks.splice(indx, 1, notebook);
            }
            this.update(teamName, notebooks);
        }
    }

}
