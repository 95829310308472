import {Injectable} from "@angular/core";
import {replaceEmailAddresses, replaceMentionsInText, shrinkUrlsInText} from "../shared/userInputUtility";

@Injectable({providedIn: "root"})
export class HtmlProcessorService {

    processText(text: string, options: { links: boolean, emails: boolean, mentions: boolean }): string {

        let processed = text.replace(/</g, "\u2264").replace(/>/g, "\u2265");
        if (options.mentions) {
            processed = replaceMentionsInText(processed);
        }
        if (options.links) {
            processed = shrinkUrlsInText(processed);
        }
        if (options.emails) {
            processed = replaceEmailAddresses(processed);
        }
        return processed.replace(/\n/g, "<br/>").replace(/\u2264/g, "&lt;").replace(/\u2265/g, "&gt;");
    }

}
