import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {CacheableDataContextService, isCachedData} from "../cacheable-data-context.service";
import {SearchSuggestItem} from "../../models/search/SearchSuggestItem";
import {ExpireDataStream} from "../../../modules/store/ExpireDataStream";

const SECONDS_TO_EXPIRE_CACHE = 15;

@Injectable({providedIn: "root"})
export class SearchService {

    private suggestStreams: { [query: string]: ExpireDataStream<SearchSuggestItem[]> } = {};
    private _fetching$: { [query: string]: BehaviorSubject<boolean> } = {};
    private _errors$: { [query: string]: BehaviorSubject<boolean> } = {};

    constructor(private dataCtx: CacheableDataContextService) {

    }

    hasError(query: string): Observable<boolean> {
        return this.ensureErrorStream(query);
    }

    isFetching(query: string): Observable<boolean> {
        return this.ensureFetchingStream(query);
    }

    searchSuggests(query: string): Observable<SearchSuggestItem[]> {
        let stream = this.suggestStreams[query];
        if (stream) {
            if (stream.isExpired()) {
                this.actualizeSuggests(query);
            }
            return stream.data;
        }
        this.suggestStreams[query] = new ExpireDataStream(SECONDS_TO_EXPIRE_CACHE);
        this.actualizeSuggests(query);
        return this.suggestStreams[query].data;
    }

    private actualizeSuggests(query: string) {

        const fetching = this.ensureFetchingStream(query);
        const error$ = this.ensureErrorStream(query);

        fetching.next(true);
        this.dataCtx.get(`api/search/suggest`,
            {
                params: {q: query, top: "20"}
            })
            .subscribe(data => {
                const suggests = data.suggests.map(SearchSuggestItem.parse);
                const streamToUpdate = this.suggestStreams[query];
                if (streamToUpdate) {
                    this.suggestStreams[query].update(suggests);
                }
                if (!isCachedData(data)) {
                    fetching.next(false);
                    error$.next(false);
                }
            }, () => {
                fetching.next(false);
                error$.next(true);
            });
    }

    private ensureErrorStream(query: string): BehaviorSubject<boolean> {
        if (!this._errors$[query]) {
            this._errors$[query] = new BehaviorSubject<boolean>(false);
        }
        return this._errors$[query];
    }

    private ensureFetchingStream(query: string): BehaviorSubject<boolean> {
        if (!this._fetching$[query]) {
            this._fetching$[query] = new BehaviorSubject<boolean>(false);
        }
        return this._fetching$[query];
    }
}
