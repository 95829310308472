import {DocumentInfo} from "../models/documents/DocumentInfo";
import {Injectable} from "@angular/core";
import {BehaviorSubject, distinctUntilChanged, filter, Observable, Subject} from "rxjs";
import {documentListComparer, DocumentListInfo} from "../models/documents/DocumentListInfo";
import {MemorySubject} from "../../modules/store";
import {DataContextService} from "./data-context.service";
import {NamedStore} from "./NamedStore";
import {CacheableDataContextService, isCachedData} from "./cacheable-data-context.service";
import {DocumentWithNotebooksList} from "../models/documents/DocumentWithNotebooksList";
import {NotebookInfo} from "../models/NotebookInfo";
import {DocumentStorage} from "./document.storage";
import {removeFromArray} from "../../shared/Utility";
import {DocumentBody, DocumentBodyWithOperations} from "../models/documents/DocumentBody";
import {AppService} from "./app.service";
import {FailServerResponse} from "../models/FailServerResponse";
import {FetchError} from "./FetchError";
import {WsConnectionManager} from "./websockets/WsConnectionManager";
import {DocumentDiscussion} from "../models/documents/discussions/DocumentDiscussion";
import {UserRef} from "../models/UserRef";
import {DocumentAccess, DocumentAccessLevel} from "../models/documents/DocumentAccess";
import {AnalyticsService} from "./analytics.service";

const RECENTS_STORE_KEY = "recent";
const STARRED_STORE_KEY = "starred";
const RECYCLE_STORE_KEY_FACTORY = (username: string) => `recycle_${username}`;
const TEAM_RECYCLE_STORE_KEY_FACTORY = (username: string) => `team-recycle_${username}`;

const DOCUMENT_EVENTS = "doc_event";
type DocumentPushNotificationEventType = "created" | "removed" | "restored" | "metadataChanged";
type DocumentPushNotification = {
    readonly documentId: string;
    readonly notebookName?: string;
    readonly teamName?: string;
    readonly userName?: string;
    readonly type: DocumentPushNotificationEventType;
};

class NotebookDocumentsStore extends NamedStore<DocumentListInfo[]> {

    removeDocuments(documentIds: string[]) {
        const docMap = documentIds.reduce(function (acc, cur) {
            acc[cur] = true;
            return acc;
        }, {});

        this.transform((key, value, change) => {

            let changed = false;
            let result = value.slice(0);
            for (let i = result.length - 1; i >= 0; i--) {
                const doc = value[i];
                if (docMap[doc.id]) {
                    removeFromArray(result, doc);
                    changed = true;
                }
            }

            if (changed) {
                change(key, result);
            }

        });
    }

    restoreDocuments(storeKey: string, documents: DocumentListInfo[]) {
        let val = this.getSnapshot(storeKey);
        if (val) {
            val = val.concat(documents);
            this.update(storeKey, val);
        }
    }
}

class GroupedStore extends NamedStore<DocumentWithNotebooksList> {

    getTeamRecycle(teamName: string): Observable<DocumentWithNotebooksList> {
        return this.get(TEAM_RECYCLE_STORE_KEY_FACTORY(teamName));
    }

    getUserRecycle(username: string): Observable<DocumentWithNotebooksList> {
        return this.get(RECYCLE_STORE_KEY_FACTORY(username));
    }

    removeFromTeamRecycle(teamName: string, documentIds: string[]) {
        const list = this.getSnapshot(TEAM_RECYCLE_STORE_KEY_FACTORY(teamName));
        if (list) {
            for (let docId of documentIds) {
                list.removeDocument(docId);
            }
        }
    }

    removeFromUserRecycle(username: string, documentIds: string[]) {
        const list = this.getSnapshot(RECYCLE_STORE_KEY_FACTORY(username));
        if (list) {
            for (let docId of documentIds) {
                list.removeDocument(docId);
            }
        }
    }
}

export type HighlightBlockParams = { blockId?: string, path?: number[], documentId: string };

@Injectable({providedIn: "root"})
export class DocumentService {

    private _groupedStore = new GroupedStore();
    private _documentsStore = new NotebookDocumentsStore();
    private _documentInfoStore = new NamedStore<DocumentInfo>();

    private _errors = new Subject<FetchError>();
    private actualizing: { [id: string]: boolean } = {};

    private _highlightBlock = new MemorySubject<HighlightBlockParams>();
    private _moveToDiscussion = new MemorySubject<{ discussionId: string, documentId: string }>();

    private _sharedDocuments$ = new MemorySubject<DocumentListInfo[]>();

    constructor(private dataCtx: DataContextService,
                private cachedDataCtx: CacheableDataContextService,
                private docStorage: DocumentStorage,
                private analytics: AnalyticsService,
                ws: WsConnectionManager,
                private appSvc: AppService) {

        ws.subscribe<DocumentPushNotification>(DOCUMENT_EVENTS)
            .subscribe(e => this.handleDocumentEvent(e));

    }

    get blockToHighlight$(): Observable<HighlightBlockParams> {
        return this._highlightBlock;
    }

    get moveToDiscussion$(): Observable<{ discussionId: string, documentId: string }> {
        return this._moveToDiscussion;
    }

    get errors$(): Observable<FetchError> {
        return this._errors;
    }

    private _fetchingRecent$ = new BehaviorSubject(false);

    get fetchingRecent$(): Observable<boolean> {
        return this._fetchingRecent$.pipe(
            distinctUntilChanged()
        );
    }

    private _fetchingStarred$ = new BehaviorSubject(false);
    get fetchingStarred$(): Observable<boolean> {
        return this._fetchingStarred$.pipe(
            distinctUntilChanged()
        );
    }

    private _fetchingShared$ = new BehaviorSubject<boolean>(false);

    get fetchingShared$(): Observable<boolean> {
        return this._fetchingShared$.pipe(
            distinctUntilChanged()
        );
    }

    private _fetchingRecycle$ = new MemorySubject<boolean>(false);

    get fetchingRecycle$(): Observable<boolean> {
        return this._fetchingRecycle$
            .pipe(
                distinctUntilChanged()
            );
    }

    actualizeDocumentInfo(documentId: string): Promise<any> {
        return new Promise((resolve) => {
            const url = `/api/documents/${documentId}`;
            this.cachedDataCtx.get(url, {allowAnonymous: true})
                .subscribe(data => {
                    this._documentInfoStore.update(documentId, DocumentInfo.parse(data), isCachedData(data));
                    resolve(undefined);
                }, err => {
                    this.cachedDataCtx.clearCache(url);
                    this._documentInfoStore.clearByKey(documentId);
                    this._errors.next(new DocumentFetchError(documentId, err));
                });
        });
    }

    async changeLevel(document: DocumentInfo, access: DocumentAccess, level: DocumentAccessLevel) {
        this.analytics.sendEvent("documents", "change_document_access", "count", 1);
        const beforeLevel = access.level;
        access.changeLevel(level);

        const request = {
            users: [access.user.serialize()],
            level
        };

        return this.dataCtx.post(`/api/documents/${document.id}/access`, request)
            .catch((fail) => {
                access.changeLevel(beforeLevel);
                throw fail;
            });
    }

    cleanTeamRecycle(teamName: string, documentIds: string[]): Promise<any> {
        return this.dataCtx.post(`/api/teams/${teamName}/recycle/clean`, {ids: documentIds})
            .then(() => {
                this._documentsStore.removeDocuments(documentIds);
                this._groupedStore.removeFromTeamRecycle(teamName, documentIds);
            });
    }

    //
    // addTagsToDocuments(groupName: string, documentIds: string[], tags: DocumentTag[]): Promise<any> {
    //     return this.dataCtx.post(`api/teams/${groupName}/tags`, {documentIds, tagNamesToAdd: tags.map(t => t.name)})
    //         .then(() => {
    //             // TODO update state
    //         });
    // }

    cleanUserRecycle(username: string, documentIds: string[]): Promise<any> {
        return this.dataCtx.post(`/api/users/${username}/recycle/clean`, {ids: documentIds})
            .then(() => {
                this._documentsStore.removeDocuments(documentIds);
                this._groupedStore.removeFromUserRecycle(username, documentIds);
            });
    }

    clearHighlightedBlock() {
        this._highlightBlock.next(undefined);
    }

    clearMoveDiscussion() {
        this._moveToDiscussion.next(undefined);
    }

    copyDocument(document: DocumentInfo, notebook: NotebookInfo, withComments: boolean): Promise<DocumentInfo> {

        let params: { notebookName: string, teamName?: string, withComments?: boolean } = {
            withComments,
            notebookName: notebook.name
        };
        if (notebook.ownerTeam) {
            params.teamName = notebook.ownerTeam.name;
        }
        return this.dataCtx.post(`api/documents/${document.id}/copy`, params).then(data => DocumentInfo.parse(data));
    }

    createDocument(options: IDocumentCreationInfo): Promise<DocumentInfo> {
        const storeKey = options.username ? userNotebookStoreKey(options.username, options.notebookName) : teamNotebookStoreKey(options.teamName, options.notebookName);

        this.analytics.sendEvent("documents", "create_document", "count", 1);

        return this.dataCtx.post(`/api/documents`, options)
            .then(data => {

                const doc = DocumentInfo.parse(data);

                // update group documents list
                let list = this._documentsStore.getSnapshot(storeKey);
                if (list) {
                    list = list.slice(0); // do not mutate array
                    list.unshift(doc.toListInfo());
                    this._documentsStore.update(storeKey, list);
                }

                return doc;
            });
    }

    documentFetchError(documentId: string): Observable<FetchError> {
        return this._errors.pipe(
            filter(x => x instanceof DocumentFetchError && x.documentId === documentId)
        );
    }

    /**
     * Fetch document body from the server or from the local db
     * @param {string} documentId           id of document
     * Server pass this revision to list items.
     * @param actualize fetch last version from server
     */
    async getDocumentBody(documentId: string, actualize?: boolean): Promise<DocumentBodyWithOperations> {
        if (!actualize) {
            const shapshot = await this.docStorage.getActualDocumentSnapshot(documentId);
            if (shapshot) {
                return shapshot;
            }
        }

        // also, fetch actual document state from the server
        let data;

        try {
            data = await this.dataCtx.get(`/api/documents/${documentId}/body`, {allowAnonymous: true});
        } catch (err) {
            this._errors.next(new DocumentFetchError(documentId, err));
            return undefined;
        }
        const body = DocumentBody.parse(documentId, data);
        await this.docStorage.saveDocument({
            created: new Date(),
            id: documentId,
            rawBody: data
        });
        return {body, ops: []};

    }

    getDocumentInfo(documentId: string, actualize = true): Observable<DocumentInfo> {
        if (!this._documentInfoStore.hasKey(documentId) || actualize) {
            this.actualizeDocumentInfo(documentId);
        }
        return this._documentInfoStore.get(documentId);
    }

    getRecent(actualize: boolean = true): Observable<DocumentWithNotebooksList> {
        if (!this._groupedStore.hasSnapshot(RECENTS_STORE_KEY) || actualize) {
            this.actualizeRecent();
        }
        return this._groupedStore.get(RECENTS_STORE_KEY);
    }

    getShared(actualize: boolean = true): Observable<DocumentListInfo[]> {
        if (!this._sharedDocuments$.hasValue() || actualize) {
            this.actualizeShared();
        }
        return this._sharedDocuments$;
    }

    getStarred(actualize: boolean = true): Observable<DocumentWithNotebooksList> {
        if (!this._groupedStore.hasSnapshot(STARRED_STORE_KEY) || actualize) {
            this.actualizeStarred();
        }
        return this._groupedStore.get(STARRED_STORE_KEY);
    }

    getTeamNotebookDocuments(teamName: string, notebookName: string, actualize = true): Observable<DocumentListInfo[]> {
        const key = teamNotebookStoreKey(teamName, notebookName);
        if (actualize || !this._documentsStore.hasKey(key)) {
            this.actualizeTeamNotebookDocuments(teamName, notebookName);
        }
        return this._documentsStore
            .get(key)
            .pipe(
                distinctUntilChanged(documentListComparer)
            );
    }

    getTeamRemovedDocuments(teamName: string): Observable<DocumentWithNotebooksList> {
        this.actualizeTeamRecycle(teamName);
        return this._groupedStore.getTeamRecycle(teamName);
    }

    getUserNotebookDocuments(username: string, notebookName: string, actualize = true): Observable<DocumentListInfo[]> {
        const key = userNotebookStoreKey(username, notebookName);
        if (actualize || !this._documentsStore.hasKey(key)) {
            this.actualizeUserNotebookDocuments(username, notebookName);
        }
        return this._documentsStore
            .get(key)
            .pipe(
                distinctUntilChanged(documentListComparer)
            );
    }

    getUserRemovedDocuments(username: string): Observable<DocumentWithNotebooksList> {
        this.actualizeUserRecycle(username);
        return this._groupedStore.getUserRecycle(username);
    }

    grantAccess(document: DocumentInfo, users: UserRef[], level: DocumentAccessLevel, notifyByEmail: boolean): Promise<any> {
        const editors = users.map(u => new DocumentAccess(u, level));
        document.addAccess(editors);

        this.analytics.sendEvent("documents", "add_document_access", "count", editors.length);
        const request = {
            users: editors.map(editor => editor.user.serialize()),
            level,
            notifyByEmail
        };

        return this.dataCtx.post(`/api/documents/${document.id}/access`, request)
            .catch((fail) => {
                document.removeAccess(editors);
                throw fail;
            });
    }

    highlightBlock(documentId: string, pathOrBlockId: number[] | string) {
        this._highlightBlock.next({
            documentId,
            path: Array.isArray(pathOrBlockId) ? pathOrBlockId : undefined,
            blockId: Array.isArray(pathOrBlockId) ? undefined : pathOrBlockId
        });
    }

    markAsRecent(document: DocumentInfo): Promise<any> {
        if (!this.appSvc.isAuthenticated() || !document.notebook || document.notebook.isTemplatesNotebook) {
            return Promise.resolve();
        }
        const model = this._groupedStore.getSnapshot(RECENTS_STORE_KEY);
        if (model) {
            model.markAsRecent(document.notebook, document.id);
        }
        return this.dataCtx.post(`/api/recentdocuments`, {documentId: document.id}).catch(() => {
        });
    }

    moveDocument(document: DocumentInfo, notebook: NotebookInfo): Promise<any> {
        this.analytics.sendEvent("documents", "change_document_notebook", "count", 1);
        const sourceNotebook = document.notebook;
        document.moveTo(notebook);
        return this.dataCtx
            .post(`api/documents/${document.id}/move`, {
                notebook: notebook.name
            })
            .catch(fail => {
                document.moveTo(sourceNotebook);
                throw fail;
            });
    }

    moveDocuments(documents: DocumentListInfo[], notebook: NotebookInfo) {
        this.analytics.sendEvent("documents", "bulk_change_documents_notebook", "count", 1);
        return this.dataCtx.post(`api/documents/move`, {
            documents: documents.map(d => d.id),
            notebook: notebook.name
        });
    }

    //
    // removeTagsFromDocuments(groupName: string, documentIds: string[], tags: DocumentTag[]): Promise<any> {
    //     return this.dataCtx.post(`api/teams/${groupName}/tags`, {documentIds, tagNamesToRemove: tags.map(t => t.name)})
    //         .then(() => {
    //             // TODO update state
    //         });
    // }

    moveToDiscussion(discussion: DocumentDiscussion) {
        this._moveToDiscussion.next({documentId: discussion.documentId, discussionId: discussion.id});
    }

    removeDocumentAccess(document: DocumentInfo, access: DocumentAccess): Promise<any> {
        this.analytics.sendEvent("documents", "remove_document_access", "count", 1);
        document.removeAccess([access]);

        return this.dataCtx.delete(`/api/documents/${document.id}/editors/${access.user.username}`)
            .catch((fail) => {
                document.addAccess([access]);
                throw fail;
            });
    }

    removeDocuments(notebook: NotebookInfo, documents: DocumentListInfo[]): Promise<any> {
        if (notebook.ownerTeam) {
            return this.removeTeamDocuments(notebook.ownerTeam.name, notebook.name, documents);
        } else {
            return this.removeUserDocuments(notebook.owner.username, notebook.name, documents);
        }
    }

    removeTeamDocuments(teamName: string, notebookName: string, documents: DocumentListInfo[]): Promise<any> {
        const ids = documents.map(d => d.id);
        return this.dataCtx.delete(`/api/teams/${teamName}/notebooks/${notebookName}/documents`, {ids})
            .then(() => {
                this._documentsStore.removeDocuments(ids);
            });
    }

    removeUserDocuments(username: string, notebookName: string, documents: DocumentListInfo[]): Promise<any> {
        const ids = documents.map(d => d.id);
        return this.dataCtx.delete(`/api/users/${username}/notebooks/${notebookName}/documents`, {ids})
            .then(() => {
                this._documentsStore.removeDocuments(ids);
            });
    }

    renameDocument(documentId: string, title: string): Promise<any> {
        let oldTitle: string;

        this.updateDocumentState(documentId, doc => {
            oldTitle = doc.title;
            doc.updateTitle(title);
        });

        return this.dataCtx.put(`/api/documents/${documentId}/title`, {newTitle: title})
            .catch((err) => {
                this.updateDocumentState(documentId, doc => doc.updateTitle(oldTitle));
                throw err;
            });
    }

    /**
     * Document body has changed
     */
    async replaceDocumentBody(body: DocumentBody): Promise<any> {
        const snapshot = await this.docStorage.getActualDocumentSnapshot(body.id);
        if (snapshot && snapshot.body.rev >= body.rev) {
            console.warn(`Document body not updated because same or new revision was saved before. ${snapshot.body.rev}>=${body.rev}`);
            // do not update to same revision
            return;
        }
        await this.docStorage.saveDocument({
            id: body.id,
            created: new Date(),
            rawBody: {
                rev: body.rev,
                body: body.body,
                lastModified: body.lastModified
            }
        });
        await this.docStorage.dropDiffOperations(body.id);
    }

    restoreTeamDocuments(teamName: string, documents: DocumentListInfo[]) {
        for (let doc of documents) {
            this._documentsStore.restoreDocuments(teamNotebookStoreKey(teamName, doc.notebookName), [doc]);
        }
        this._groupedStore.removeFromTeamRecycle(teamName, documents.map(d => d.id));
        return this.dataCtx.post(`/api/teams/${teamName}/recycle/restore`, {ids: documents.map(d => d.id)});
    }

    restoreUserDocuments(username: string, documents: DocumentListInfo[]) {
        for (let doc of documents) {
            this._documentsStore.restoreDocuments(userNotebookStoreKey(username, doc.notebookName), [doc]);
        }
        this._groupedStore.removeFromUserRecycle(username, documents.map(d => d.id));
        return this.dataCtx.post(`/api/users/${username}/recycle/restore`, {ids: documents.map(d => d.id)});
    }

    async setDocumentPublished(doc: DocumentInfo, published: boolean, accessLevel: DocumentAccessLevel): Promise<any> {
        const url = `api/documents/${doc.id}/publish`;
        try {
            await (published ? this.dataCtx.post(url, {accessLevel: accessLevel}) : this.dataCtx.delete(url));
            published ? doc.publish(accessLevel) : doc.unpublish();

            if (published) {
                this.analytics.sendEvent("documents", "share_by_link", "count", 1);
            }
        } catch (err) {
            throw err;
        }
    }

    setDocumentWatch(document: DocumentInfo, watch: any): Promise<any> {
        document.setWatched(watch);
        if (watch) {
            return this.dataCtx.post(`/api/documents/${document.id}/watch`);
        } else {
            return this.dataCtx.post(`/api/documents/${document.id}/unwatch`);
        }
    }

    starDocument(doc: DocumentListInfo, notebook?: NotebookInfo): Promise<any> {
        if (notebook && this._groupedStore.hasSnapshot(STARRED_STORE_KEY)) {
            const list = this._groupedStore.getSnapshot(STARRED_STORE_KEY);
            list.prependDocument(doc, notebook);
        }
        return this.dataCtx.post(`api/starreddocuments`, {documentId: doc.id});
    }

    unstarDocument(documentId: string): Promise<any> {
        if (this._groupedStore.hasSnapshot(STARRED_STORE_KEY)) {
            const list = this._groupedStore.getSnapshot(STARRED_STORE_KEY);
            list.removeDocument(documentId);
        }
        return this.dataCtx.delete(`api/starreddocuments/${documentId}`);
    }

    updateDocumentSnippet(documentId: string, text: string, previewImageUrl: string) {
        this.updateDocumentState(documentId, d => d.updateInfo(text, previewImageUrl));
    }

    private actualizeRecent() {
        this._fetchingRecent$.next(true);
        this.cachedDataCtx.get(`/api/recentdocuments`).subscribe((data: any) => {
            this._fetchingRecent$.next(false);
            this._groupedStore.update(RECENTS_STORE_KEY, DocumentWithNotebooksList.parse(data), isCachedData(data));
        });
    }

    private actualizeShared() {
        this._fetchingShared$.next(true);
        this.cachedDataCtx.get(`/api/shareddocuments`, {params: {top: "500"}}).subscribe((data: any) => {
            this._fetchingShared$.next(false);
            this._sharedDocuments$.next(DocumentListInfo.parseArray(data.documents));
        });
    }

    private actualizeStarred() {
        this._fetchingStarred$.next(true);
        this.cachedDataCtx.get(`/api/starreddocuments`).subscribe((data: any) => {
            this._fetchingStarred$.next(false);
            this._groupedStore.update(STARRED_STORE_KEY, DocumentWithNotebooksList.parse(data), isCachedData(data));
        });
    }

    private actualizeTeamNotebookDocuments(teamName: string, notebookName: string) {
        const key = `t_${teamName}-${notebookName}`;
        if (this.actualizing[key]) {
            return;
        }
        this.actualizing[key] = true;
        this.cachedDataCtx.get(`api/teams/${teamName}/notebooks/${notebookName}/documents`)
            .subscribe(list => {
                const fromCache = isCachedData(list);
                if (!fromCache) {
                    this.actualizing[key] = false;
                }
                this._documentsStore.update(teamNotebookStoreKey(teamName, notebookName), DocumentListInfo.parseArray(list.documents), fromCache);
            }, () => {
                // TODO emit error
                this.actualizing[key] = false;
            });
    }

    private actualizeTeamRecycle(teamName: string) {
        this._fetchingRecycle$.next(true);
        this.cachedDataCtx.get(`/api/teams/${teamName}/recycle`).subscribe((data: any) => {
            this._fetchingRecycle$.next(false);
            this._groupedStore.update(TEAM_RECYCLE_STORE_KEY_FACTORY(teamName), DocumentWithNotebooksList.parse(data), isCachedData(data));
        });
    }

    private actualizeUserNotebookDocuments(username: string, notebookName: string) {
        const key = `u_${username}-${notebookName}`;
        if (this.actualizing[key]) {
            return;
        }
        this.actualizing[key] = true;
        this.cachedDataCtx.get(`api/users/${username}/notebooks/${notebookName}/documents`)
            .subscribe(list => {
                const fromCache = isCachedData(list);
                this.actualizing[key] = false;
                this._documentsStore.update(userNotebookStoreKey(username, notebookName), DocumentListInfo.parseArray(list.documents), fromCache);
            }, () => {
                // TODO emit error
                this.actualizing[key] = false;
            });
    }

    private async actualizeUserRecycle(username: string) {
        this._fetchingRecycle$.next(true);
        this.cachedDataCtx.get(`/api/users/${username}/recycle`).subscribe((data: any) => {
            this._fetchingRecycle$.next(false);
            this._groupedStore.update(RECYCLE_STORE_KEY_FACTORY(username), DocumentWithNotebooksList.parse(data), isCachedData(data));
        });
    }

    private handleDocumentEvent(e: DocumentPushNotification) {
        switch (e.type) {
            case "created":
                e.teamName ?
                    this.actualizeTeamNotebookDocuments(e.teamName, e.notebookName) :
                    this.actualizeUserNotebookDocuments(e.userName, e.notebookName);
                break;
            case "removed":
                this._documentsStore.removeDocuments([e.documentId]);
                break;
            case "restored":
                e.teamName ?
                    this.actualizeTeamNotebookDocuments(e.teamName, e.notebookName) :
                    this.actualizeUserNotebookDocuments(e.userName, e.notebookName);
                break;
            case "metadataChanged":
                this.actualizeDocumentInfo(e.documentId);
                break;
            default:
                console.warn(`Unknown push type: ${e.type}`);
                break;
        }
    }

    private updateDocumentState(documentId: string, invoke: (doc: DocumentListInfo) => void) {
        let keys = this._documentsStore.allKeys();
        for (let key of keys) {
            const documents = this._documentsStore.getSnapshot(key);
            if (documents) {
                const targetDoc = documents.find(d => d.id === documentId);
                if (targetDoc) {
                    invoke(targetDoc);
                    this._documentsStore.update(key, documents);
                }
            }
        }

        keys = this._groupedStore.allKeys();
        for (let key of keys) {
            const list = this._groupedStore.getSnapshot(key);
            if (list) {
                let changed = false;
                for (let notebook of list.notebooksSnapshot) {
                    const targetDoc = notebook.documentsSnapshot.find(d => d.id === documentId);
                    if (targetDoc) {
                        invoke(targetDoc);
                        changed = true;
                    }
                }
                if (changed) {
                    this._groupedStore.update(key, list);
                }
            }
        }
    }
}

export interface IDocumentCreationInfo {
    teamName?: string;
    username?: string;
    notebookName: string;
    title?: string;
    tags?: string[];
}

function userNotebookStoreKey(username: string, notebookName: string): string {
    return `user_${username}_${notebookName}`;
}

function teamNotebookStoreKey(teamname: string, notebookName: string): string {
    if (!notebookName) {
        throw new Error(`Notebookname not defined`);
    }
    return `team_${teamname}_${notebookName}`;
}


class DocumentFetchError extends FetchError {
    constructor(public readonly documentId: string, err: FailServerResponse) {
        super(err);
    }
}
