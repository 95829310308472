<div [class._error]="error$|async"
     [class._focused]="focused$|async"
     [loading-line]="fetching$|async"
     class="search-field">

    <n-icon [fillColor]="'rgba(0,0,0,.6)'"
            [iconId]="'search'"
            class="search-icon">
    </n-icon>
    <form class="search-form">
        <input #input
               (blur)="onBlur()"
               (focus)="onFocus()"
               (keydown)="handleKeyDown($event)"
               [attr.placeholder]="'search.placeholder' | translate"
               [focusOnDefaultForceTouch]="true"
               [focusOnDefault]="input"
               [formControl]="filterControl"
               class="input"
               type="search"/>
    </form>

    <button (click)="clear()" *ngIf="showCancel$|async" class="search-cancel" tabindex="-1">
        <n-icon [fillColor]="'rgba(0,0,0,.4)'" [iconId]="'close'"></n-icon>
    </button>
</div>

<page-message *ngIf="message$|async; else results" [messageKey]="message$|async"></page-message>

<ng-template #results>
    <div class="search-results">

        <h3 *ngIf="(recents$|async) && (listState.list | async)?.length" class="recent-label">
            {{"search.recent" | translate}}
        </h3>

        <search-suggest-item (mousedown)="listState.focusTo(listItem)"
                             (select)="navigateToResult(listItem)"
                             *ngFor="let listItem of listState.list | async; trackBy: listIdentity"
                             [focused]="listState.isFocused(listItem) | async"
                             [hiddenCount]="listItem.expander && listItem.hiddenItemsCount"
                             [suggest]="$any(listItem.item)">
        </search-suggest-item>
    </div>
</ng-template>
