import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";
import {UserPickerItem} from "../UserPickerItem";
import {UserRef} from "../../../models/UserRef";

@Component({
    selector: "user-picker-item",
    templateUrl: "user-picker-item.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./user-picker-item.bem.css"
    ]
})
export class UserPickerItemComponent {

    user: UserRef;

    title: string;
    subtitle: string;

    constructor(pickerItem: UserPickerItem) {
        this.user = pickerItem.toRef();
        this.title = pickerItem.title;
        this.subtitle = pickerItem.post || `@${pickerItem.id}`;
    }


}
