import {Injectable} from "@angular/core";
import {FailServerResponse} from "../models/FailServerResponse";
import {AuthService} from "./auth.service";
import {buildFullUrl} from "./UrlHelper";
import {HttpClient, HttpResponse} from "@angular/common/http";

export interface IDataContextService {

    delete(url: string, options?: RequestOptions): Promise<any>;

    get(url: string, options?: RequestOptions): Promise<any>;

    patch(url: string, body: any, options?: RequestOptions): Promise<any>;

    post(url: string, body?: any, options?: RequestOptions): Promise<any>;

    put(url: string, body: any, options?: RequestOptions): Promise<any>;
}

@Injectable({providedIn: "root"})
export class DataContextService implements IDataContextService {

    constructor(private http: HttpClient,
                private authService: AuthService) {
    }

    delete(url: string, body?: any, options?: RequestOptions): Promise<any> {
        return this.makeHttpCall(() => {
            return this.getRequestOptionArgs(options)
                .then((httpOptions) => {
                    return this.http.request("delete", buildFullUrl(url), {...httpOptions, body} as any).toPromise();
                });
        });
    }

    get(url: string, options?: RequestOptions): Promise<any> {
        return this.makeHttpCall(() => {
            if (!options) {
                options = {};
            }
            return this.getRequestOptionArgs(options)
                .then((httpOptions) => {
                    return this.http.get(buildFullUrl(url), httpOptions).toPromise();
                });
        });

    }

    getResponse<T>(url: string, options?: RequestOptions): Promise<HttpResponse<T>> {
        return this.makeHttpCall(() => {
            return this.getRequestOptionArgs(options)
                .then((httpOptions) => {
                    return this.http.get<T>(
                        buildFullUrl(url),
                        {
                            ...httpOptions,
                            observe: "response"
                        } as any
                    ).toPromise() as Promise<HttpResponse<T>>;
                });
        });
    }

    patch(url: string, body: any, options?: RequestOptions): Promise<any> {
        return this.makeHttpCall(() => {
            return this.getRequestOptionArgs(options)
                .then((httpOptions) => {
                    return this.http.patch(buildFullUrl(url), body, httpOptions).toPromise();
                });
        });
    }

    post<T>(url: string, body?: any, options?: RequestOptions): Promise<any> {
        return this.makeHttpCall(() => {
            return this.getRequestOptionArgs(options)
                .then((httpOptions) => {
                    return this.http.post(buildFullUrl(url), body, httpOptions).toPromise();
                });
        });
    }

    put(url: string, body: any, options?: RequestOptions): Promise<any> {
        return this.makeHttpCall(() => {
            return this.getRequestOptionArgs(options)
                .then((httpOptions) => {
                    return this.http.put(buildFullUrl(url), body, httpOptions).toPromise();
                });
        });
    }

    private getRequestOptionArgs(options?: RequestOptions): Promise<RequestOptions> {
        if (!options) {
            options = {};
        }
        // patch headers:
        const headers: any = options.headers || {};
        headers["Content-Type"] = "application/json";

        const tokenObtain = options.allowAnonymous ?
            Promise.resolve(this.authService.readToken()) :
            this.authService.ensureToken();

        return tokenObtain.then(token => {
            if (token) {
                headers.Authorization = `Bearer ${token}`;
                options.headers = headers as any;
            }
            return options;
        });
    }

    private makeHttpCall<T>(request: () => Promise<T>): Promise<T> {
        return request()
            .catch(data => {
                if (data && data.status && data.status === 401) {
                    return this.authService.tokenExpired().then(request);
                }
                throw new FailServerResponse(data);
            })
            .catch(data => {
                if (data instanceof FailServerResponse) {
                    throw data;
                }
                throw new FailServerResponse(data);
            });
    }

}

export interface RequestOptions {
    headers?: {
        [header: string]: string | string[];
    };
    params?: {
        [param: string]: string | string[];
    };
    responseType?: any;
    allowAnonymous?: boolean;
}
