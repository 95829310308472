import {diffInDays, diffInHours, diffInMinutes, diffInMonths, truncateTime} from "../../modules/translate/DateUtility";
import {translateEngQuantity} from "./Helpers";

function getTimeText(d: Date): string {
    let pad = n => n < 10 ? "0" + n : n;
    return pad(d.getHours()) + ":" + pad(d.getMinutes());
}

export function getMonthNameEnglish(month: number): string {
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    return months[month];
}

function getMinutesText(n: number) {
    if (n <= 0)
        return "just now";
    if (n === 1)
        return "a minute ago";
    let minutes = ["minute", "minutes"];
    return n.toString() + " " + translateEngQuantity(n, minutes) + " ago";
}

function getHoursText(n: number) {
    if (n === 1)
        return "an hour ago";
    let hours = ["hour", "hours"];
    return n.toString() + " " + translateEngQuantity(n, hours) + " ago";
}

function getDaysText(n: number) {
    if (n <= 0)
        return "today";
    if (n === 1)
        return "yesterday";

    let days = ["day", "days"];
    return n.toString() + " " + translateEngQuantity(n, days) + " ago";
}

function getMonthText(n: number) {
    if (n === 1)
        return "a month ago";
    let months = ["month", "months"];
    return n.toString() + " " + translateEngQuantity(n, months) + " ago";
}

export function formatEnglishDate(d: Date, includeYear: boolean, includeTime: boolean): string {
    let result = `${getMonthNameEnglish(d.getMonth())} ${d.getDate()}`;
    if (includeYear)
        result += ", " + d.getFullYear();
    if (includeTime)
        result += " " + getTimeText(d);
    return result;
}

export function elapsedEnglishTime(d: Date): string {
    let result: string;
    let currentDate = new Date();

    let inMinutes = diffInMinutes(d, currentDate);

    if (inMinutes < 60) { // разница составляет минуты
        result = getMinutesText(inMinutes);
    } else { // разница составляет часы
        let inHours = diffInHours(d, currentDate);
        if (inHours < 24) {
            result = getHoursText(inHours);
        } else { // разница составляет дни
            currentDate = truncateTime(new Date());
            let dateToCompare = truncateTime(d);

            let inDays = diffInDays(dateToCompare, currentDate);
            if (inDays <= 31) {
                result = getDaysText(inDays);
            } else { // разница составляет месяца
                let inMonth = diffInMonths(dateToCompare, currentDate);
                if (inMonth < 12) {
                    result = getMonthText(inMonth);
                } else { // больше года - показываем дату
                    result = formatEnglishDate(d, true, false);
                }
            }
        }
    }
    return result;
}
