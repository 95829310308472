import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {BehaviorSubject, combineLatest, concat, map, Observable, of, switchMap} from "rxjs";
import {DocumentService} from "../../services/document.service";
import {DocumentNotebookGroup} from "../../models/documents/DocumentNotebookGroup";
import {DocumentListInfo} from "../../models/documents/DocumentListInfo";
import {IApplicationPage, PageTitleContent, TitleContent} from "../../../pages";
import {MemorySubject} from "../../../modules/store";
import {shareLastValue} from "../../../modules/rxjs-ex/shareLastValue";

const PAGE_SIZE = 20;

@Component({
    selector: "recent-page",
    templateUrl: "recent-page.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./recent-page.ng.css"
    ]
})
export class RecentPageComponent implements IApplicationPage, OnInit {

    recentGroups$: Observable<DocumentNotebookGroup[]>;
    emptyList$: Observable<boolean>;

    readonly documentTitle$ = new MemorySubject<TitleContent>({title: "sidemenu.recent", translate: true});
    readonly pageTitle$ = new MemorySubject<PageTitleContent>({title: "sidemenu.recent", translate: true});

    private limit$ = new BehaviorSubject<number>(PAGE_SIZE);
    private hasMorePages = true;

    constructor(private documents: DocumentService) {

    }

    docIdentity(indx: number, doc: DocumentListInfo) {
        return doc.id;
    }

    getRouteTitle(): string {
        return "$sidemenu.recent";
    }

    groupIdentity(indx: number, group: DocumentNotebookGroup) {
        if (group.notebookSnapshot.owner) {
            return group.notebookSnapshot.owner.username + "_" + group.notebookSnapshot.name;
        } else {
            return group.notebookSnapshot.ownerTeam.name + "_" + group.notebookSnapshot.name;
        }
    }

    ngOnInit() {

        const allGroups$ =
            concat(of([]), this.documents.getRecent()
                .pipe(
                    switchMap(r => r.notebooks$),
                    shareLastValue()
                ));

        this.recentGroups$ = combineLatest(allGroups$, this.limit$)
            .pipe(
                map(([groups, limit]) => this.filterPage(groups, limit)),
                shareLastValue()
            );

        this.emptyList$ = combineLatest([this.documents.fetchingRecent$, allGroups$])
            .pipe(
                map(([fetching, groups]) => !fetching && groups.length === 0)
            );

    }

    scrollToEnd() {
        if (this.hasMorePages) {
            this.limit$.next(this.limit$.getValue() + PAGE_SIZE);
        }
    }

    private filterPage(groups: DocumentNotebookGroup[], limit: number): DocumentNotebookGroup[] {
        this.hasMorePages = groups.length > limit;
        if (!this.hasMorePages) {
            return groups;
        }
        return groups.slice(0, limit);
    }

}
