export function translateEngQuantity(num: number, values: string[]): string {
    if (values.length < 2) {
        throw new Error(`Cannot translate quantity: ${num}, ${values}`);
    }

    if (num === 1) {
        return values[0];
    }
    return values[1];

}
