import {ModalRenderer} from "../ModalRenderer";
import {ModalInstance} from "../ModalInstance";
import {ApplicationRef, ComponentFactoryResolver, ComponentRef, ElementRef, Injectable, Injector, TemplateRef, Type} from "@angular/core";
import {ComponentModalCompileContext, ModalCompileContext, TemplateModalCompileContext} from "../ModalCompileContext";
import {ModalWindowComponent} from "../components/modal-window/modal-window.component";
import {ModalContext} from "../ModalContext";
import {IModalOptions} from "../IModalOptions";
import {ModalBackdropComponent} from "../components/modal-backdrop/modal-backdrop.component";

@Injectable({providedIn: "root"})
export class DOMModalRenderer extends ModalRenderer {

    private rootElm: ElementRef;

    constructor(private injector: Injector, private appRef: ApplicationRef) {
        super();
    }

    render<T>(type: Type<unknown>,
              modalData: any,
              options: IModalOptions = {},
              layerNumber: number): ModalInstance<T> {

        const injector = options.injector || this.injector;

        const modalContext = new ModalContext<any, any>(modalData, options);
        const resolver = options.resolver || injector.get(ComponentFactoryResolver);

        const compileContext = new ComponentModalCompileContext(modalContext, resolver, type, layerNumber);

        const bindings = (options.bindings || []).concat([
            {provide: ModalRenderer, useValue: this},
            {provide: ModalCompileContext, useValue: compileContext},
            {provide: ModalContext, useValue: modalContext}
        ]);

        compileContext.bindings = bindings;

        const childInjector = Injector.create({
            providers: bindings,
            parent: injector
        });

        const cmpFactory = resolver.resolveComponentFactory(options.disableWrapper ? type : ModalWindowComponent);
        const cmpRef = cmpFactory.create(childInjector);
        this.appRef.attachView(cmpRef.hostView);

        const rootElement = this.getRootElement();

        rootElement.nativeElement.appendChild(cmpRef.location.nativeElement);

        return new ModalInstance<T>(cmpRef, modalContext, options && options.stashed);
    }

    renderBackdrop(inj?: Injector): ComponentRef<ModalBackdropComponent> {

        const injector = inj || this.injector;

        const resolver = this.injector.get(ComponentFactoryResolver);

        const cmpFactory = resolver.resolveComponentFactory(ModalBackdropComponent);

        const cmpRef = cmpFactory.create(injector);

        const modal = document.body.querySelector("modal-window");
        injector.get(ApplicationRef).attachView(cmpRef.hostView);

        const rootElement = this.getRootElement();

        if (modal) {
            rootElement.nativeElement.insertBefore(cmpRef.location.nativeElement, modal);
        } else {
            rootElement.nativeElement.appendChild(cmpRef.location.nativeElement);
        }

        return cmpRef;
    }

    renderTemplate<T>(template: TemplateRef<any>,
                      modalData: any,
                      options: IModalOptions,
                      layerNumber: number): ModalInstance<T> {
        const injector = options.injector || this.injector;

        const modalContext = new ModalContext<any, any>(modalData, options);
        const resolver = options.resolver || injector.get(ComponentFactoryResolver);

        const compileContext = new TemplateModalCompileContext(modalContext, template, layerNumber);

        const bindings = (options.bindings || []).concat([
            {provide: ModalRenderer, useValue: this},
            {provide: ModalCompileContext, useValue: compileContext},
            {provide: ModalContext, useValue: modalContext}
        ]);

        const childInjector = Injector.create({
            providers: bindings,
            parent: injector
        });

        const cmpFactory = resolver.resolveComponentFactory(ModalWindowComponent);
        const cmpRef = cmpFactory.create(childInjector);
        this.appRef.attachView(cmpRef.hostView);

        const rootElement = this.getRootElement();

        rootElement.nativeElement.appendChild(cmpRef.location.nativeElement);

        return new ModalInstance<T>(cmpRef, modalContext, options && options.stashed);
    }

    private getRootElement(): ElementRef {
        if (this.rootElm) {
            return this.rootElm;
        }
        return this.rootElm = this.appRef.components[0].location;
    }


}
