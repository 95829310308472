import {AfterContentInit, Component, ElementRef, HostListener, Renderer2, ViewChild, ViewContainerRef} from "@angular/core";
import {PopoverCompileContext} from "../../PopoverCompileContext";
import {IPopoverOptions} from "../../IPopoverOptions";
import {PopoverOptions} from "../../PopoverOptions";
import {POPOVER_COMPONENT_SELECTOR} from "../../PopoverInstance";
import {PopoverConfig} from "../../PopoverConfig";
import {applyArrowStyles, calculatePosition, IArrowPosition, IBox, IRect} from "../../position";

@Component({
    selector: POPOVER_COMPONENT_SELECTOR,
    styleUrls: [
        "./n-popover-window.ng.css"
    ],
    templateUrl: "./n-popover-window.html"
})
export class PopoverWindowComponent implements AfterContentInit {
    @ViewChild("popover", {static: true})
    popoverEl: ElementRef;

    @ViewChild("popoverContent", {read: ViewContainerRef, static: true})
    contentVc: ViewContainerRef;

    @ViewChild("arrow", {read: ElementRef, static: true})
    arrow: ElementRef;

    private options: IPopoverOptions;
    private mobileViewActive: boolean;

    constructor(options: PopoverOptions,
                private config: PopoverConfig,
                private compileCtx: PopoverCompileContext,
                private el: ElementRef,
                private renderer: Renderer2) {
        this.options = options.options;
    }

    hide() {
        this.renderer.removeClass(this.el.nativeElement, "__show");
        this.renderer.addClass(this.el.nativeElement, "__hide");
    }

    ngAfterContentInit(): any {

        this.renderer.addClass(this.popoverEl.nativeElement, "__" + this.options.position || "bottom");

        if (this.options.animate) {
            this.renderer.addClass(this.popoverEl.nativeElement, "__animate");
        }

        if (this.options.popoverClass) {
            this.renderer.addClass(this.popoverEl.nativeElement, this.options.popoverClass);
        }

        /*add backdrop to host class*/
        if (this.options.mobileView) {
            this.renderer.addClass(this.el.nativeElement, "__backdrop");
        }

        this.compileCtx.compile(this.contentVc);

        this.mobileViewActive = this.getIsMobileView();

        this.positionPopover(true);

        Promise.resolve().then(() => {
            this.renderer.addClass(this.el.nativeElement, "__show");
        });
    }

    @HostListener("window:resize")
    onResize() {
        this.mobileViewActive = this.getIsMobileView();
        this.positionPopover();
    }

    positionPopover(hide?: boolean) {
        const element = this.popoverEl.nativeElement;
        // открыли первый раз - нужно показать её, чтобы можно было высчитать размер, но показать слева за пределами экрана
        if (hide) {
            this.renderer.setStyle(element, "left", "-9999px");
            this.renderer.setStyle(element, "position", this.options.fixed ? "fixed" : "absolute");
        }

        if (this.mobileViewActive) {
            this.renderer.addClass(this.el.nativeElement, "__mobile-view");
        } else {
            this.renderer.removeClass(this.el.nativeElement, "__mobile-view");
        }

        Promise.resolve().then(() => {
            let targetRect: IRect;
            if (this.options.targetElementRef) {
                const nativeElement = this.options.targetElementRef.nativeElement;
                if (!nativeElement.parentElement) {
                    // target element may be removed from the DOM
                    return;
                }
                targetRect = nativeElement.getBoundingClientRect();
                if (this.options.parentContainer) {
                    const parentRect = this.options.parentContainer.getBoundingClientRect();
                    targetRect = {
                        left: targetRect.left,
                        right: targetRect.right,
                        top: targetRect.top - parentRect.top,
                        bottom: targetRect.bottom - parentRect.top
                    };
                }
                const offset = this.options.targetElementOffset;
                if (offset) {
                    const pos = this.options.position;
                    const vertical = pos === "bottom" || pos === "top";
                    targetRect = {
                        left: targetRect.left - (vertical ? 0 : offset),
                        top: targetRect.top - (vertical ? offset : 0),
                        right: targetRect.right + (vertical ? 0 : offset),
                        bottom: targetRect.bottom + (vertical ? offset : 0)
                    };
                }
            } else {
                targetRect = this.options.targetRect;
            }

            let arrowSize: IBox = {width: 2, height: 2};
            if (!this.options.hideArrow) {
                switch (this.options.position) {
                    case "left":
                    case "right":
                        arrowSize = {width: 11, height: 22};
                        break;
                    case "top":
                    case "bottom":
                        arrowSize = {width: 22, height: 11};
                        break;
                }
            } else {
                this.arrow.nativeElement.style.display = "none";
            }

            const container: IBox = this.options.parentContainer ? {
                width: this.options.parentContainer.clientWidth,
                height: this.options.parentContainer.clientHeight
            } : {
                width: (document.body.offsetWidth || document.body.clientWidth),
                height: window.innerHeight
            };

            const position = calculatePosition(this.options.position,
                targetRect,
                {height: element.clientHeight, width: element.clientWidth},
                container, this.config.paddings, arrowSize,
                this.options.horizontalAlign
            );

            let popoverY;

            if (!this.mobileViewActive) {
                // calc scrolltop before positioning
                const scrollTop = (this.options.fixed ? 0 : this.options.parentContainer ? this.options.parentContainer.scrollTop : (window.pageYOffset || document.documentElement.scrollTop));

                this.renderer.setStyle(element, "left", `${Math.round(position.left)}px`);
                this.renderer.setStyle(element, "right", `auto`);
                popoverY = position.top + scrollTop;
                this.renderer.setStyle(element, "top", `${Math.round(popoverY)}px`);
            }

            if (this.options.setupMinWidth) {
                const minWidth = targetRect.right - targetRect.left;
                if (minWidth > 0) {
                    this.renderer.setStyle(element, "minWidth", `${minWidth}px`);
                }
            }

            if (!this.options.hideArrow) {
                this.positionArrow(position.arrowPos);
            }
        });
    }

    private getIsMobileView(): boolean {
        if (this.options.mobileView) {
            const wWidth = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;
            return wWidth < 768;
        } else {
            return false;
        }
    }

    private positionArrow(arrowPos: IArrowPosition) {
        this.renderer.addClass(this.arrow.nativeElement, "__" + arrowPos.arrowPlacement);
        applyArrowStyles(this.renderer, this.arrow, arrowPos);

    }

}
