import {ModuleWithProviders, NgModule} from "@angular/core";
import {PopoverRenderer} from "./PopoverRenderer";
import {DOMPopoverRenderer} from "./dom/DOMPopoverRenderer";
import {PopoverCollection} from "./PopoverCollection";
import {DOMPopoverCollection} from "./dom/DOMPopoverCollection";
// BUG: do not fix path, see https://github.com/dherges/ng-packagr/issues/734
import {PopoverWindowComponent} from "./directives/popover-window/n-popover-window.component";
import {NPopoverDirective} from "./directives/n-popover/n-popover.directive";

const POPOVER_DIRECTIVES = [
    PopoverWindowComponent,
    NPopoverDirective
];

@NgModule({
    declarations: [
        ...POPOVER_DIRECTIVES
    ],
    imports: [],
    exports: [
        NPopoverDirective
    ],
    providers: []
})
export class NPopoversModule {
    static forRoot(): ModuleWithProviders<NPopoversModule> {
        return {
            ngModule: NPopoversModule,
            providers: [
                {provide: PopoverCollection, useClass: DOMPopoverCollection},
                {provide: PopoverRenderer, useClass: DOMPopoverRenderer}
            ]
        };
    }
}
