import {ChangeDetectionStrategy, Component} from "@angular/core";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: "google-signin-button",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <form class="google-signin-button" (ngSubmit)="submit()">
            <button type="submit" class="button"><span class="logo"></span> {{ "google.signIn" | translate}}</button>
        </form>`,
    styleUrls: [
        "./google-signin-button.ng.css"
    ]
})
export class GoogleSignInButtonComponent {

    constructor(private auth: AuthService) {

    }

    submit() {
        this.auth.externalSignin("Google");
    }

}
