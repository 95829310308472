export class SignupResult {

    readonly token: string;
    readonly username: string;
    readonly tokenExpiresIn: number;

    constructor(username: string, token: string, tokenExpiresIn: number) {
        this.username = username;
        this.token = token;
        this.tokenExpiresIn = tokenExpiresIn;
    }

    static parse(data: any): SignupResult {
        return new SignupResult(data.username, data.token, data.tokenExpiresIn);
    }

}


export interface SignupInfo {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    culture: string;
    token?: string;
}

