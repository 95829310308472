import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {AppService} from "../../services/app.service";
import {FormControl} from "@angular/forms";
import {BehaviorSubject, distinctUntilChanged, Subscription} from "rxjs";
import {CultureCode} from "../../models/CultureCode";

@Component({
    selector: "culture-switcher",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./culture-switcher.ng.css"
    ],
    template: `

        <div [ngSwitch]="type">
            <div *ngSwitchCase="'tabs'">
                <button class="button" (click)="changeTo('en')"
                        [class._active]="(cultureLocal$|async) === 'en'" type="button">English
                </button>
                <button class="button" (click)="changeTo('ru')"
                        [class._active]="(cultureLocal$|async) === 'ru'" type="button">Русский
                </button>
            </div>
            <div *ngSwitchCase="'select'">
                <n-select [formControl]="select" wide>
                    <n-option [value]="'ru'" [heading]="'Русский'"></n-option>
                    <n-option [value]="'en'" [heading]="'English'"></n-option>
                </n-select>
            </div>
        </div>
    `
})
export class CultureSwitcherComponent implements OnInit, OnDestroy {

    select = new FormControl("");
    cultureLocal$ = new BehaviorSubject<CultureCode>("en");
    @Input() type: CultureSwitcherType;

    private subscription: Subscription;

    constructor(private appSvc: AppService) {

    }

    changeTo(culture: CultureCode) {
        this.appSvc.changeCulture(culture);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscription = undefined;
    }

    ngOnInit() {
        this.type = this.type || "select";
        this.subscription = this.appSvc.currentCulture$.subscribe(code => {
            this.select.setValue(code);
            this.cultureLocal$.next(code);
        });
        this.select.valueChanges
            .pipe(
                distinctUntilChanged()
            )
            .subscribe(code => {
                this.appSvc.changeCulture(code);
            });
    }
}

export type CultureSwitcherType = "tabs" | "select";
