import {Observable} from "rxjs";
import {MemorySubject} from "../../modules/store";
import {EntityColor} from "./EntityColor";
import {IEntityWithAvatar} from "./IEntityWithAvatar";

export class UserRef implements IEntityWithAvatar {

    username: string;
    firstname: string;
    lastname: string;

    post: string;

    avatarColor1: EntityColor;
    avatarColor2: EntityColor;

    get avatarPath(): string {
        return this._avatarPath$.getValue();
    }

    private _avatarPath$ = new MemorySubject<string>();

    get avatarPath$(): Observable<string> {
        return this._avatarPath$.asObservable();
    }

    static parse(data: any, destination?: UserRef): UserRef {
        let user = destination || new UserRef();
        user.username = data.username;
        user.firstname = data.firstname || "";
        user.lastname = data.lastname || "";
        user.post = data.post;
        user._avatarPath$.next(data.avatarPath);

        const colors = data.avatarColors.split(";");
        user.avatarColor1 = colors[0];
        user.avatarColor2 = colors[1];

        return user;
    }

    static parseArray(data: any[]): UserRef[] {
        return data.map(d => UserRef.parse(d));
    }

    changeAvatar(avatarPath: string) {
        this._avatarPath$.next(avatarPath);
    }

    getInititals(): string {
        return this.firstname[0] + this.lastname[0];
    }

    getNavigateUrl(): string[] {
        return ["/profile", this.username];
    }

    getPopupText(): string {
        return this.toFullname();
    }

    search(query: string): boolean {
        const normalized = query.toUpperCase();
        return this.lastname.toUpperCase().indexOf(normalized) >= 0 ||
            this.firstname.toUpperCase().indexOf(normalized) >= 0 ||
            this.username.toUpperCase().indexOf(normalized) >= 0;
    }

    serialize() {
        return {
            username: this.username
        };
    }

    toFullname() {
        return `${this.firstname} ${this.lastname}`;
    }
}
