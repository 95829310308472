import {diffInDays, diffInHours, diffInMinutes, diffInMonths, truncateTime} from "../../modules/translate/DateUtility";
import {translateRussianQuantity} from "./RussianMorphology";

function getTimeText(d: Date): string {
    const pad = n => n < 10 ? "0" + n : n;
    return pad(d.getHours()) + ":" + pad(d.getMinutes());
}

export function getRussionMonthNameParent(month: number): string {
    const months = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"];
    return months[month];
}

function getMonthName(month: number): string {
    const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
    return months[month];
}

function getWeekDayName(day: number): string {
    const days = ["Воскресение", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
    return days[day];
}

function getMinutesText(n: number) {
    if (n <= 0)
        return "только что";
    if (n === 1)
        return "минуту назад";
    const minutes = ["минуту", "минуты", "минут"];
    return n.toString() + " " + translateRussianQuantity(n, minutes) + " назад";
}

function getHoursText(n: number) {
    if (n === 1)
        return "час назад";
    const hours = ["час", "часа", "часов"];
    return n.toString() + " " + translateRussianQuantity(n, hours) + " назад";
}

function getDaysText(n: number) {
    if (n <= 0)
        return "сегодня";
    if (n === 1)
        return "вчера";

    const days = ["день", "дня", "дней"];
    return n.toString() + " " + translateRussianQuantity(n, days) + " назад";
}

function getMonthText(n: number) {
    if (n === 1)
        return "месяц назад";
    const months = ["месяц", "месяца", "месяцев"];
    return n.toString() + " " + translateRussianQuantity(n, months) + " назад";
}

export function formatRussianDate(d: Date, includeYear: boolean, includeTime: boolean): string {
    let result = d.getDate() + ' ' + getRussionMonthNameParent(d.getMonth()).toLowerCase();
    if (includeYear)
        result += ' ' + d.getFullYear();
    if (includeTime)
        result += ' ' + getTimeText(d);
    return result;
}

export function elapsedRussianTime(d: Date): string {
    let result: string;
    const currentDate = new Date();

    const inMinutes = diffInMinutes(d, currentDate);

    if (inMinutes < 60) { // разница составляет минуты
        result = getMinutesText(inMinutes);
    } else { // разница составляет часы
        const inHours = diffInHours(d, currentDate);
        if (inHours < 24) {
            result = getHoursText(inHours);
        } else { // разница составляет дни
            const currentDate = truncateTime(new Date());
            const dateToCompare = truncateTime(d);

            const inDays = diffInDays(dateToCompare, currentDate);
            if (inDays <= 31) {
                result = getDaysText(inDays);
            } else { // разница составляет месяца
                const inMonth = diffInMonths(dateToCompare, currentDate);
                if (inMonth < 12) {
                    result = getMonthText(inMonth);
                } else { // больше года - показываем дату
                    result = formatRussianDate(d, true, false);
                }
            }
        }
    }
    return result;
}
