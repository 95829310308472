import {ChangeDetectionStrategy, Component, Input} from "@angular/core";

@Component({
    selector: "notebook-icon",
    templateUrl: "./notebook-icon.html",
    styleUrls: [
        "./notebook-icon.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotebookIconComponent {

    @Input() color: string;
    @Input() shared: boolean;

    constructor() {
    }

}
