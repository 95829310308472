import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {INTabPane, NTabsetComponent} from "../n-tabset.component";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: "n-tab",
    styleUrls: [
        "./n-tab.ng.css"
    ],
    templateUrl: "./n-tab.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NTabComponent implements OnInit, OnDestroy {
    @Output() select = new EventEmitter<any>();
    showCount: boolean;
    switchable: boolean;
    mod: "float" | "onLine" | "switcher" | "buttonSwitcher";
    pane: NTabPane;

    constructor(private tabset: NTabsetComponent) {
        this.pane = new NTabPane();
        this.pane.select = this.select;
    }

    @Input() set count(val: number) {
        this.pane.count$.next(val);
        this.showCount = typeof (val) !== "undefined";
    }

    @Input() set value(val: any) {
        this.pane.id = val;
    }

    @Input() set heading(val: string) {
        this.pane.heading.next(val);
    }

    @Input() set disabled(val: boolean) {
        this.pane.disabled.next(val);
    }

    get selected(): boolean {
        return this.pane.selected.value;
    }

    ngOnDestroy() {
        this.tabset.removePane(this.pane);
    }

    ngOnInit() {
        this.tabset.addPane(this.pane);
        this.mod = this.tabset.mod;
        this.switchable = this.tabset.switchable;
    }

    selectTab() {
        if (!this.pane.selected.value) {
            this.pane.selected.next(true);
            this.tabset.selectPane(this.pane);
            this.select.emit();
        }
    }
}

class NTabPane implements INTabPane {
    readonly disabled = new BehaviorSubject<boolean>(false);
    readonly selected = new BehaviorSubject<boolean>(false);
    readonly heading = new BehaviorSubject<string>("");
    readonly iconColor = new BehaviorSubject<string>(null);
    readonly iconId = new BehaviorSubject<string>(null);
    id: any;
    select: EventEmitter<any>;
    readonly tid: string;
    readonly count$ = new BehaviorSubject<number>(null);

    disabledSnapshot(): boolean {
        return this.disabled.value;
    }

    headingSnapshot(): string {
        return this.heading.value;
    }

    setSelected(set: boolean) {
        this.selected.next(set);
    }

}
