<h3>
    {{ 'notebooks.move.title' | translate}}
</h3>

<div class="list slim-scroll">
    <notebook-select-item (click)="select(notebook)"
                          *ngFor="let notebook of notebooks$|async;trackBy:notebookIdentity"
                          [notebook]="notebook"
                          [selected]="notebook.name === (selectedNotebookName$|async)">
    </notebook-select-item>
</div>

<div *ngIf="hasHiddenNotebooks$ | async" class="hide-hint">
    {{"documents.aside.move.accessHint" | translate}}
</div>

<div class="actions">
    <n-action-button (click)="moveSignal.emit()"
                     [disabled]="notebook.name === (selectedNotebookName$|async)"
                     [loading]="loading$ | async"
                     [text]="'notebooks.panel.move' | translate"
                     [theme]="'prime'"></n-action-button>
</div>
