import {NgModule} from "@angular/core";
import {PortalDirective, PortalOutletDirective} from "./portals.directives";


@NgModule({
    declarations: [
        PortalDirective,
        PortalOutletDirective
    ],
    exports: [
        PortalDirective,
        PortalOutletDirective
    ],
    providers: []
})
export class PortalsModule {

}

