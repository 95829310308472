import {SearchPickerComponent} from "./search-picker.component";
import {NgModule} from "@angular/core";

@NgModule({
    declarations: [
        SearchPickerComponent
    ],
    imports: [
    // dependencies
    ],
    exports: [
        SearchPickerComponent
    ],
    providers: []
})
export class SearchPickerModule {

}
