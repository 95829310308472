import {ElementRef, Injectable} from "@angular/core";
import {SearchSuggestItem} from "../../models/search/SearchSuggestItem";
import {Observable} from "rxjs";
import {CacheableDataContextService} from "../cacheable-data-context.service";
import {ExpireDataStream} from "../../../modules/store/ExpireDataStream";
import {MentionUserPopoverAdapter} from "./MentionUserPopoverAdapter";
import {UserService} from "../user.service";
import {HoverPopoverService} from "../hover-popover.service";

const SECONDS_TO_EXPIRE_CACHE = 10;

@Injectable({providedIn: "root"})
export class MentionService {
    private mentionStreams: { [query: string]: ExpireDataStream<SearchSuggestItem[]> } = {};

    constructor(private dataCtx: CacheableDataContextService,
                private hoverPopovers: HoverPopoverService,
                private userSvc: UserService) {

    }

    // noinspection JSMethodCanBeStatic

    bindPopover(targetElement: ElementRef<HTMLElement>, username: string) {
        this.hoverPopovers.bind(targetElement, new MentionUserPopoverAdapter(username, this.userSvc));
    }

    findMentions(query: string, users = false, teamName?: string): Observable<SearchSuggestItem[]> {
        const key = buildKey(query, users, teamName);
        let stream = this.mentionStreams[key];
        if (stream) {
            if (stream.isExpired()) {
                this.actualize(query, users, teamName);
            }
            return stream.data;
        }
        this.mentionStreams[key] = new ExpireDataStream(SECONDS_TO_EXPIRE_CACHE);
        this.actualize(query, users, teamName);
        return this.mentionStreams[key].data;
    }

    private actualize(query: string, users: boolean, teamName?: string) {
        const key = buildKey(query, users, teamName);
        const params = {
            q: query,
            top: "10"
        };
        if (users) {
            params["suggestType"] = "users";
        }
        if (teamName) {
            params["scope"] = teamName;
        }
        this.dataCtx.get(`api/search/mentions`, {params})
            .subscribe(data => {
                const suggests = data.suggests.map(SearchSuggestItem.parse);
                const streamToUpdate = this.mentionStreams[key];
                if (streamToUpdate) {
                    this.mentionStreams[key].update(suggests);
                }
            }, (e) => {
                console.error(e);
            });
    }
}

function buildKey(query: string, users: boolean, teamName: string) {
    return `${query}-${!!users}-${teamName || ''}`;
}
