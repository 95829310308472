<div class="page">
    <div class="body">
        <form-header>
            <dr-logo></dr-logo>
        </form-header>

        <form-window>

            <form-window-header>{{"signIn.enter" | translate}}</form-window-header>

            <form (ngSubmit)="submit(form.value)" [formGroup]="form" focusInvalidFieldOnSubmit>
                <dr-field [label]="'signIn.loginLabel'">
                    <n-input #nameInput [focusOnDefault]="nameInput"
                             [formControlName]="'username'"
                             [required]="true"
                             wide></n-input>
                </dr-field>

                <dr-field [label]="'signIn.password'">
                    <div class="reset">
                        <a (click)="resetPassword($event)" [routerLink]="['/', 'reset-password']" tabindex="-1">
                            {{ "signIn.resetPwd" | translate }}
                        </a>
                    </div>
                    <n-input [formControlName]="'password'"
                             [required]="true"
                             [type]="'password'"
                             wide></n-input>
                </dr-field>

                <div *ngIf="errorKey$ | async" class="error-message">
                    {{ errorKey$ | async | translate}}
                </div>

                <div class="remember">
                    <n-checkbox [formControlName]="'dontRememberMe'" [text]="'signIn.forget'|translate"
                                name="h1"></n-checkbox>
                </div>

                <div class="submit-button">
                    <n-button [disabled]="submitting$ | async" [loading]="submitting$ | async" [text]="'signIn.enterButton'|translate"
                              [theme]="'prime'"
                              [type]="'submit'"></n-button>
                </div>
            </form>

        </form-window>

        <form-window-extra [showSignUp]="true"></form-window-extra>
    </div>
    <div class="footer">
        <culture-switcher type="tabs"></culture-switcher>
    </div>
</div>
