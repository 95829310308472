<div [ngClass]="{'_selectable': selectable, '_switchable': switchable, '_on-line': mod==='onLine', '_button-switcher':mod==='buttonSwitcher'}"
     class="n-tabset">
    <div (dropdownToggleOpened)="markFocusedMenu()" [nDropdown]="menu"
         [ngClass]="{'_not-active': noSelectedTabs}"
         class="n-tabset__selector"
         tabindex="1">
        <span class="title">{{selectorTitle$|async}}</span> <span *ngIf="selectorCount$|async"
                                                                  class="counter">{{selectorCount$|async}}</span>
        <span class="underline"></span>
    </div>

    <div class="n-tabset__content">
        <ng-content></ng-content>
    </div>
</div>

<n-dropdown-menu #menu></n-dropdown-menu>
