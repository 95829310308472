import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output} from "@angular/core";
import {DROPDOWN_MENU_PROVIDER, IDropdownMenu, IDropdownMenuItem} from "./n-dropdown-menu.component";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: "n-dropdown-menu-item",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ``
})
export class NDropdownMenuItemComponent implements OnInit, OnDestroy {

    @Output() select = new EventEmitter<any>();
    @Input() id: any;
    @Input() tid: string;

    private _heading$ = new BehaviorSubject(null);
    private _description$ = new BehaviorSubject(null);
    private _iconId$ = new BehaviorSubject(null);
    private _iconColor$ = new BehaviorSubject<string>("rgba(0,0,0,.7)");
    private _disabled$ = new BehaviorSubject(false);
    private menuItem: IDropdownMenuItem;

    constructor(@Inject(DROPDOWN_MENU_PROVIDER) @Optional() private menu: IDropdownMenu) {
    }

    @Input() set heading(val: string) {
        this._heading$.next(val);
    }

    @Input() set description(val: string) {
        this._description$.next(val);
    }

    @Input() set disabled(val: boolean) {
        this._disabled$.next(val);
    }

    @Input() set iconId(val: string) {
        this._iconId$.next(val);
    }

    @Input() set iconColor(val: string) {
        if (val) {
            this._iconColor$.next(val);
        }
    }

    getDropdownMenuItem(): IDropdownMenuItem {
        return this.menuItem;
    }

    ngOnDestroy(): void {
        if (this.menu) {
            this.menu.removeItem(this.menuItem);
        }
    }

    ngOnInit() {
        this.menuItem = {
            id: this.getId(),
            divider: false,
            heading: this._heading$,
            description: this._description$,
            disabled: this._disabled$,
            iconId: this._iconId$,
            iconColor: this._iconColor$,
            select: this.select,
            tid: this.tid,
            disabledSnapshot: () => this._disabled$.value,
            headingSnapshot: () => this._heading$.value
        };
        if (this.menu) {
            this.menu.addItem(this.menuItem);
        }
    }

    private getId(): any {
        return this.id === undefined ? this : this.id;
    }

}
