import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {NotebookInfo} from "../../models/NotebookInfo";
import {combineLatest, map, Observable} from "rxjs";
import {UserInfo} from "../../models/UserInfo";
import {TeamInfo} from "../../models/TeamInfo";
import {MemorySubject} from "../../../modules/store";
import {Router} from "@angular/router";

@Component({
    selector: "notebook-list-item",
    templateUrl: "notebook-list-item.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./notebook-list-item.bem.css"
    ]
})
export class NotebookListItemComponent implements OnInit {

    @Input()
    selected: boolean;

    @Input()
    selectMode: boolean;

    @Input()
    canSelect: boolean;

    @Output()
    togglePin = new EventEmitter<any>();

    @Output()
    toggleSelect = new EventEmitter<any>();

    link$: Observable<string>;

    notebook$ = new MemorySubject<NotebookInfo>();
    shared$ = new Observable<boolean>();

    @Input() canPin: boolean;

    private link: string;
    private owner$ = new MemorySubject<UserInfo | TeamInfo>();

    constructor(private router: Router) {
    }

    @Input()
    set notebook(val: NotebookInfo) {
        this.notebook$.next(val);
    }

    @Input()
    set owner(val: UserInfo | TeamInfo) {
        this.owner$.next(val);
    }

    ngOnInit() {

        this.link$ = combineLatest([this.notebook$, this.owner$])
            .pipe(
                map(([notebook, owner]) => {
                    if (owner instanceof UserInfo) {
                        return `/profile/${owner.username}/nb/${notebook.name}`;
                    } else {
                        // блокнот команды
                        return `/${owner.name}/nb/${notebook.name}`;
                    }
                })
            );

        this.link$.subscribe(l => this.link = l);

        this.shared$ = this.notebook$
            .pipe(
                map(notebook => {
                    return notebook.type === "open";
                })
            );
    }

    onNavigate(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.selectMode) {
            this.toggleSelect.emit(!this.selected);
        } else {
            this.router.navigateByUrl(this.link);
        }
        return false;
    }

    onTap() {
        if (this.canSelect && !this.selected) {
            this.toggleSelect.emit(true);
        }
    }

}
