import {Injectable} from "@angular/core";
import {UserService} from "../../services/user.service";
import {TranslateService} from "../../../modules/translate";
import {UserPickerItem} from "./UserPickerItem";
import {IPickerItem, ISearcher, ISearchPickerOptions} from "searchpicker";

@Injectable({providedIn: "root"})
export class UserSearcher implements ISearcher {

    private tmrId: any;

    constructor(private userSvc: UserService, private translate: TranslateService) {

    }

    search(query: string,
           options: ISearchPickerOptions,
           onresults: (items: IPickerItem[]) => void,
           onerror?: (message: string) => void): any {

        if (this.tmrId)
            clearTimeout(this.tmrId);

        this.tmrId = setTimeout(() => {
            this.userSvc.searchUsers(query)
                .then(result => {
                    onresults(result.users.map(u => new UserPickerItem(u)));
                })
                .catch((e) => {
                    console.error(e);
                    onerror(this.translate.getString("searchPicker.error"));
                });
        }, 100);

    }

}
