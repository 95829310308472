import {Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {TemplatePortal, TemplatePortalOutlet} from "./model";
import {PortalOutletMap} from "./PortalOutletMap";
import {BehaviorSubject, distinctUntilChanged, filter, Subject, switchMap, takeUntil} from "rxjs";

@Directive({
    selector: "[portal]",
    exportAs: "portal"
})
export class PortalDirective extends TemplatePortal<any> implements OnDestroy, OnInit {

    private attachedOutlet: TemplatePortalOutlet;
    private attachedView: EmbeddedViewRef<any>;

    private portalName$ = new BehaviorSubject<string>(undefined);
    private directiveExist$ = new Subject();

    constructor(templateRef: TemplateRef<any>,
                viewContainerRef: ViewContainerRef,
                private ourlets: PortalOutletMap) {
        super(templateRef, viewContainerRef);
    }

    @Input("portal")
    set portalName(val: string) {
        this.portalName$.next(val);
    }

    ngOnDestroy(): void {
        this.directiveExist$.next(1);
        this.directiveExist$.complete();
        if (this.attachedOutlet) {
            this.attachedOutlet.detach();
            this.attachedOutlet = undefined;
        }
    }

    ngOnInit(): void {
        this.portalName$
            .pipe(
                filter(name => !!name),
                distinctUntilChanged(),
                switchMap(name => this.ourlets.wait(name)),
                takeUntil(this.directiveExist$)
            )
            .subscribe(outlet => {
                this.moveTo(outlet);
            });
    }

    private moveTo(outlet: TemplatePortalOutlet) {
        if (!this.attachedOutlet) {
            this.attachedOutlet = outlet;
            this.attachedView = this.attachedOutlet.attach(this);
        } else {
            this.attachedOutlet.detach(false);
            outlet.attach(this, this.attachedView);
            this.attachedOutlet = outlet;
        }
    }
}

@Directive({
    selector: "[portalOutlet]",
    exportAs: "portalOutlet"
})
export class PortalOutletDirective extends TemplatePortalOutlet implements OnDestroy {
    constructor(private _viewContainerRef: ViewContainerRef,
                private outlets: PortalOutletMap) {
        super();
    }

    private _portalName: string;

    @Input("portalOutlet")
    set portalName(portalName: string) {
        if (this.hasAttached()) {
            super.detach();
        }
        this._portalName = portalName;
        this.outlets.register(this._portalName, this);
    }

    attachTemplatePortal<C>(portal: TemplatePortal<C>, existView?: EmbeddedViewRef<C>): EmbeddedViewRef<C> {
        portal.setAttachedHost(this);
        let viewRef: EmbeddedViewRef<C>;
        const viewContainer = this._viewContainerRef;
        if (!existView) {
            viewRef = viewContainer.createEmbeddedView(portal.templateRef, portal.context);
            viewRef.markForCheck();
            super.setDisposeFn(() => viewContainer.clear());
        } else {
            viewRef = existView;
            viewRef.rootNodes.forEach(n => viewContainer.element.nativeElement.appendChild(n));
            super.setDisposeFn(() => {
                let index = viewContainer.indexOf(viewRef);
                if (index !== -1) {
                    viewContainer.remove(index);
                }
            });
        }

        return viewRef;
    }

    ngOnDestroy() {
        super.dispose();
        this.outlets.unregister(this._portalName);
    }
}
