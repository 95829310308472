import {FormGroup} from "@angular/forms";

export function validatePasswordsEquals(passwordFieldName: string, confirmPasswordFieldName) {
    return (group: FormGroup) => {
        const pwd = group.controls[passwordFieldName].value;
        const confirm = group.controls[confirmPasswordFieldName].value;
        if (!pwd || !confirm) {
            return null;
        }

        if (pwd !== confirm) {
            group.controls[confirmPasswordFieldName].setErrors({
                ...group.controls[confirmPasswordFieldName].errors,
                passwordNotEquals: true
            });
        } else {
            const newErrors = {
                ...group.controls[confirmPasswordFieldName].errors
            };
            delete newErrors.passwordNotEquals;
            group.controls[confirmPasswordFieldName].setErrors(Object.keys(newErrors).length === 0 ? null : newErrors);
        }

        return undefined;
    };
}
