import {ChangeDetectionStrategy, Component, ComponentFactoryResolver, ElementRef, Injector, Input, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ToastContext} from "../../ToastContext";
import {ToastInstance} from "../../ToastInstance";

@Component({
    selector: "n-notification-toast",
    styleUrls: ["./n-notification-toast.ng.css"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <ng-container #content></ng-container>`
})
export class NotificationToastComponent implements OnInit {
    /**
     * Component toast type
     */
    @Input()
    componentType: any;
    /**
     * Data for inner component
     */
    @Input()
    data: any;
    @Input()
    instance: ToastInstance;
    @ViewChild("content", {read: ViewContainerRef, static: true})
    contentVc: ViewContainerRef;

    constructor(private el: ElementRef,
                private cr: ComponentFactoryResolver) {

    }

    ngOnInit() {

        const cmpFactory = this.cr.resolveComponentFactory(this.componentType);

        const vcr = this.contentVc,
            ctxInjector = vcr.injector;

        const childInjector = Injector.create({
            providers: [
                {provide: ToastContext, useValue: new ToastContext(this.data)}
            ],
            parent: ctxInjector
        });

        vcr.createComponent(cmpFactory, 0, childInjector);

    }

}
