import {ChangeDetectionStrategy, Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../services/user.service";
import {ErrorService} from "../../services/error.service";
import {AppService} from "../../services/app.service";
import {MessageToastType, ToastService} from "../../../modules/n-toasts";
import {TranslateService} from "../../../modules/translate";
import {AuthService} from "../../services/auth.service";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: "confirm-email-page",
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmEmailPageComponent {

    confirming$ = new BehaviorSubject(false);

    constructor(private appSvc: AppService,
                private router: Router,
                private route: ActivatedRoute,
                private userSvc: UserService,
                private errors: ErrorService,
                private authSvc: AuthService,
                private translateSvc: TranslateService,
                private notifications: ToastService) {
    }

    async ngOnInit() {

        const token = this.route.snapshot.queryParams["token"];

        if (!token) {
            this.navigateToRoot();
            return;
        }

        const user = (await this.appSvc.ensureAuthorizedContext()).user;

        try {

            const result = await this.userSvc.confirmEmail(user.username, token);
            if (result) {
                this.authSvc.authentificateByToken(result.token, result.tokenExpiresIn);
            }
            await this.appSvc.actualizeContext(true);

        } catch (fail) {
            this.errors.showFail(fail);
            this.navigateToRoot();
            return;
        } finally {
            this.confirming$.next(false);
        }

        this.notifications.showMessage(this.translateSvc.getString("emailConfirm.success"), MessageToastType.INFO, 5000);
        this.navigateToRoot();
    }


    private navigateToRoot() {
        this.router.navigateByUrl("/", {replaceUrl: true});
    }
}
