import {AfterViewInit, Directive, ElementRef, Input, NgZone} from "@angular/core";
import {SupportService} from "../services/support.service";

@Directive({
    selector: "[focusOnDefault]"
})
export class FocusOnDefaultDirective implements AfterViewInit {
    @Input("focusOnDefault") focusOnDefault: any;
    @Input("focusOnDefaultForceTouch") focusOnDefaultForceTouch: boolean;

    constructor(private zone: NgZone,
                private supportSvc: SupportService,
                private el: ElementRef) {
    }

    ngAfterViewInit() {
        if (this.supportSvc.isTouch() && !this.focusOnDefaultForceTouch) {
            return; // do not drop keyboard on touch devices
        }
        this.focusOnDefault ? focusTo(this.focusOnDefault) : focusTo(this.el.nativeElement);
    }

}

function focusTo(el: any) {
    if (el.setFocus) {
        el.setFocus();
    } else if (el.focus) {
        el.focus();
    }
}
