import {Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {buildErrorMessage} from "../utils";
import {IControlWithErrors, NControlsValidationErrors, NInputErrorMessages} from "../";
// noinspection TypeScriptPreferShortImport
import {ErrorMessagesFactory} from "../../services/error-messages-factory.service";

@Component({
    selector: "n-input",
    styleUrls: [
        "./n-input.ng.css"
    ],
    templateUrl: "./n-input.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NInputComponent),
            multi: true
        }
    ]
})
export class NInputComponent implements ControlValueAccessor, OnInit, IControlWithErrors {
    /**
     * Input placeholder with field if empty
     */
    @Input() placeholder = "";
    /**
     * User can clear input text
     */
    @Input() clearable: boolean;
    /**
     * No trim value
     */
    @Input() noTrim: boolean;

    @Input() pattern: string;

    /**
     * Input type
     */
    @Input() type: "text" | "email" | "number" | "date" | "password" = "text";
    /**
     * Marks fields as read only
     */
    @Input() readonly: boolean;
    /**
     * Custom error message for input
     */
    @Input() errorMessages: NInputErrorMessages;
    /**
     * Mark field as required (only styles)
     */
    @Input() required: boolean;
    /**
     * autocomplete property for input (true by default)
     */
    @Input() autocomplete = true;

    /**
     * Icon of the input
     */
    @Input()
    icon: string;

    @Input()
    iconSet: string;


    @Output() focus = new EventEmitter<any>();
    @Output() blur = new EventEmitter<any>();

    @ViewChild("input", {read: ElementRef, static: true})
    domInput: ElementRef;

    errorMessage: string;
    showClear: boolean;
    readonly wide: boolean;

    @ViewChild("container", {read: ElementRef, static: true})
    private container: ElementRef;

    private propagateChange: (_) => void;
    private propagateTouch: () => void;
    private readonly compositionMode: boolean;
    private composing: boolean;
    private propagationDisabled: boolean;

    constructor(private renderer: Renderer2,
                private host: ElementRef,
                private cd: ChangeDetectorRef,
                private messageFactory: ErrorMessagesFactory,
                @Attribute("wide") wide: string) {

        this.wide = wide !== null;

        this.compositionMode = !_isAndroid();

    }

    private _disabled: boolean;

    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    set disabled(val: boolean) {
        this.setDisabledState(val);
    }

    clear() {
        this.renderer.setProperty(this.domInput.nativeElement, "value", "");
        this.onChange("");
        this.setFocus();
    }

    compositionEnd(evt: any) {
        const value = evt.target.value;
        this.composing = false;
        if (this.compositionMode) {
            this.onChange(value);
        }
    }

    compositionStart() {
        this.composing = true;
    }

    disablePropagation() {
        this.propagationDisabled = true;
    }

    enablePropagaton() {
        this.propagationDisabled = false;
    }

    /* public api */
    getInputElement(): HTMLInputElement | HTMLTextAreaElement {
        return this.domInput.nativeElement as HTMLInputElement;
    }

    ngOnInit() {
        this.invalidateStyles();
        this.processErrors(undefined);
    }

    onBlur() {
        if (this.propagateTouch) {
            this.propagateTouch();
        }
        this.blur.emit();
    }

    onChange(evt: any) {
        const val = evt.target.value;
        if (this.propagationDisabled) {
            return;
        }
        if (this.propagateChange) {
            this.propagateChange(this.noTrim ? val.toString() : val.toString().trim());
        }
        this.invalidateStyles();

        if (this.clearable) {
            this.showClear = !!val;
        }
    }

    onMouseLeave() {
        this.renderer.removeClass(this.container.nativeElement, "n-input_hover");
    }

    onMouseOver() {
        this.renderer.addClass(this.container.nativeElement, "n-input_hover");
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }

    setDisabledState(disabled: boolean) {
        this._disabled = disabled;
        this.renderer.setProperty(this.domInput.nativeElement, "disabled", disabled);
        this.cd.markForCheck();
    }

    setErrors(errors: NControlsValidationErrors) {
        this.processErrors(errors);
    }

    /* public api */
    setFocus() {
        this.domInput.nativeElement.focus();
    }

    writeValue(obj: any): void {
        obj = obj || "";
        const normalizedValue = this.noTrim ? obj.toString() : obj.toString().trim();
        this.renderer.setProperty(this.domInput.nativeElement, "value", normalizedValue);
        this.invalidateStyles();

        if (this.clearable) {
            this.showClear = !!normalizedValue;
            this.cd.markForCheck();
        }
    }

    private invalidateStyles() {
        if (!this.domInput.nativeElement.value) {
            this.renderer.addClass(this.container.nativeElement, "n-input_empty");
        } else {
            this.renderer.removeClass(this.container.nativeElement, "n-input_empty");
        }
    }

    private processErrors(errors: NControlsValidationErrors) {
        if (!errors) {
            if (this.required) {
                this.errorMessage = buildErrorMessage({required: true}, this.messageFactory, this.errorMessages);
            } else {
                this.errorMessage = "";
            }
            return;
        }

        this.errorMessage = buildErrorMessage(errors, this.messageFactory, this.errorMessages);
    }
}

function _isAndroid(): boolean {
    return /android (\d+)/.test(navigator.userAgent.toLowerCase());
}
