import {ComponentRef, EventEmitter} from "@angular/core";
import {ModalContext} from "./ModalContext";
import {IModalOptions} from "./IModalOptions";
import {ModalWindowComponent} from "./components/modal-window/modal-window.component";

export class ModalInstance<T = any> {

    static topModalCssClass = "last-modal-window";
    static stashCssClass = "_stashed";

    readonly result: Promise<T>;
    readonly dismiss = new EventEmitter<any>();
    onUnstash = new EventEmitter<any>();
    private resolve: Function;
    private reject: Function;
    private isClosed: boolean;

    constructor(private component: ComponentRef<unknown>,
                private modalContext: ModalContext<any, any>,
                stashed: boolean) {
        this.result = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });

        modalContext.onDone = result => this.done(result);
        modalContext.onCancel = (reason?: any) => this.close(reason);
        if (stashed) {
            this.stash();
        }
    }

    get componentRef(): ComponentRef<any> {
        return this.modalContext.instance;
    }

    get options(): IModalOptions {
        return this.modalContext.options;
    }

    get window(): ComponentRef<ModalWindowComponent> {
        return this.component as ComponentRef<ModalWindowComponent>;
    }

    private _stashed: boolean;

    get stashed(): boolean {
        return this._stashed;
    }

    close(reason?: any) {
        if (this.isClosed) {
            return;
        }
        return this.closeModal().then(() => {
            return this.reject(reason);
        });
    }

    done(result: T) {
        return this.closeModal().then(() => {
            return this.resolve(result);
        });
    }

    ensureComponent(): Promise<ComponentRef<any>> {
        return this.modalContext.ensureInstance();
    }

    focus() {
        // TODO force reflow this.component.location.nativeElement.focus();
    }

    markAsLast(last: boolean) {
        if (last) {
            this.component.location.nativeElement.classList.add(ModalInstance.topModalCssClass);
        } else {
            this.component.location.nativeElement.classList.remove(ModalInstance.topModalCssClass);
        }
    }

    stash() {
        if (this.stashed || this.modalContext.destroyed) {
            return;
        }
        this._stashed = true;
        this.component.location.nativeElement.classList.add(ModalInstance.stashCssClass);
    }

    /**
     * Move from stash (make visible)
     */
    unstash() {
        if (!this.stashed || this.modalContext.destroyed) {
            return;
        }
        this._stashed = false;
        this.component.location.nativeElement.classList.remove(ModalInstance.stashCssClass);
        this.onUnstash.emit();
    }

    private closeModal(): Promise<any> {
        let instance = this.modalContext.instance;
        let idle = Promise.resolve();
        if (instance && instance["canDestroyModal"]) {
            idle = instance["canDestroyModal"]();
        }
        return idle.then(() => {
            this.isClosed = true;
            this.component.destroy();
            this.modalContext.destroy();

            this.dismiss.emit();
        });
    }

}
