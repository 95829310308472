import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Injector, Input, NgZone, OnDestroy, OnInit, StaticProvider} from "@angular/core";
import {NHintComponent, NHintOptions, NHintPlacement} from "../components/n-hint/n-hint.component";

let activeHint: HintControl;

let showImmediately = false;
let delayResetTimerId: any;
let showTimerId: any;


@Directive({
    selector: "[nHint]"
})
export class NHintDirective implements OnDestroy, OnInit {

    @Input("nHint") hintText: string;
    @Input("nHintSubtitle") subtitle = "";

    @Input() placement: NHintPlacement = "top";
    @Input() delay = 500;

    private control: HintControl;
    private _disabled: boolean;

    constructor(private elementRef: ElementRef,
                private cr: ComponentFactoryResolver,
                private injector: Injector,
                private zone: NgZone) {
    }

    @Input()
    set nHintDisabled(val: boolean) {
        this._disabled = val;
        if (!this.control) {
            return;
        }
        if (val) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    ngOnDestroy() {
        if (this.control) {
            this.control.destroy();
            this.control = undefined;
        }
    }

    ngOnInit() {
        if (this.hintText) {
            this.zone.runOutsideAngular(() => {
                this.control = new HintControl(this.elementRef,
                    this.cr, this.injector,
                    this.hintText,
                    this.subtitle,
                    this.placement,
                    this.delay,
                    this.zone);

                if (this._disabled) {
                    this.control.disable();
                } else {
                    this.control.enable();
                }
            });
        }
    }

}

class HintControl {

    private _disabled: boolean;
    private targetElement: ElementRef;
    private hintCmp: ComponentRef<NHintComponent>;

    constructor(hintElement: ElementRef,
                private cr: ComponentFactoryResolver,
                private injector: Injector,
                private title: string,
                private subtitle: string,
                private placement: NHintPlacement,
                private delay: number,
                private zone: NgZone) {

        this.targetElement = hintElement;

        const {nativeElement} = this.targetElement;
        nativeElement.addEventListener("mouseenter", this.onMouseEnter);
        nativeElement.addEventListener("mouseleave", this.onMouseLeave);
        nativeElement.addEventListener("click", this.onMouseClick);
    }

    destroy() {
        if (activeHint && activeHint === this) {
            destroyActiveHint();
        }

        this.targetElement.nativeElement.removeEventListener("mouseenter", this.onMouseEnter);
        this.targetElement.nativeElement.removeEventListener("mouseleave", this.onMouseLeave);
        this.targetElement.nativeElement.removeEventListener("click", this.onMouseClick);
        // cancel all timer and hide hint
        this.hide();
    }

    disable() {
        this._disabled = true;
        if (showImmediately) {
            clearTimeout(showTimerId);
            showTimerId = undefined;
        }
    }

    enable() {
        this._disabled = false;
    }

    hide() {
        if (this.hintCmp) {
            this.zone.runGuarded(() => this.hintCmp.destroy());
            this.hintCmp = undefined;
        }

        if (showTimerId) {
            clearTimeout(showTimerId);
            showTimerId = undefined;
        }
    }

    showHint() {

        if (activeHint === this) {
            return;
        }

        destroyActiveHint();

        if (delayResetTimerId) {
            clearTimeout(delayResetTimerId);
            delayResetTimerId = undefined;
        }

        const childInjector = Injector.create({
            providers: [
                {provide: NHintOptions, useValue: new NHintOptions(this.title, this.subtitle, this.placement, this.targetElement)}
            ] as StaticProvider[],
            parent: this.injector
        });

        const cmpFactory = this.cr.resolveComponentFactory(NHintComponent);
        this.hintCmp = cmpFactory.create(childInjector);

        const appRef = this.injector.get(ApplicationRef);
        appRef.attachView(this.hintCmp.hostView);
        document.body.appendChild(this.hintCmp.location.nativeElement);
        this.hintCmp.changeDetectorRef.detectChanges();

        activeHint = this;
        showImmediately = true;
    }

    private onMouseClick = () => {
        destroyActiveHint();
    };

    private onMouseEnter = () => {
        // Delay появления
        if (this._disabled || !this.title) {
            return;
        }

        if (showTimerId) {
            clearTimeout(showTimerId);
            showTimerId = undefined;
        }

        if (showImmediately || (activeHint && activeHint !== this)) {
            this.showHint();
            return;
        }

        showTimerId = setTimeout(() => {
            if (this.title && !activeHint) {
                this.showHint();
            }
        }, this.delay);

    };

    private onMouseLeave = () => {
        destroyActiveHint();
    };
}

function destroyActiveHint() {

    if (showTimerId) {
        clearTimeout(showTimerId);
        showTimerId = undefined;
    }

    if (activeHint) {
        activeHint.hide();
        activeHint = undefined;

        delayResetTimerId = setTimeout(() => {
            showImmediately = false;
        }, 2000);
    }

}
