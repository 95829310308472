import {ComponentRef, Injector, TemplateRef} from "@angular/core";
import {ModalInstance} from "./ModalInstance";
import {IModalOptions} from "./IModalOptions";
import {ModalBackdropComponent} from "./components/modal-backdrop/modal-backdrop.component";

export abstract class ModalRenderer {

    abstract render<T>(componentType: Function,
                       modalData: any,
                       options: IModalOptions,
                       layerNumber: number): ModalInstance<T>;

    abstract renderBackdrop(inj?: Injector): ComponentRef<ModalBackdropComponent>;

    abstract renderTemplate<T>(template: TemplateRef<any>,
                               modalData: any,
                               options: IModalOptions,
                               layerNumber: number): ModalInstance<T>;

}
