import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {distinctUntilChanged, map, Observable} from "rxjs";
import {MemorySubject} from "../../../modules/store";
import {TranslateService} from "../../../modules/translate";

@Component({
    selector: "password-checker",
    templateUrl: "./password-checker.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./password-checker.ng.css"
    ]
})
export class PasswordCheckerComponent implements OnInit {

    level$: Observable<PasswordSecurityLevel>;
    title$: Observable<string>;
    tip$: Observable<string>;

    private password$ = new MemorySubject<string>();

    constructor(private translateSvc: TranslateService) {
    }

    @Input()
    set password(val: string) {
        this.password$.next(val);
    }

    ngOnInit() {
        const result$ = this.password$
            .pipe(
                map(pwd => checkPassword(pwd))
            );

        this.level$ = result$
            .pipe(
                map(r => toLevel(r)),
                distinctUntilChanged()
            );

        this.title$ = this.level$
            .pipe(
                map(l => this.translateSvc.getString(`passwordChecker.${l}.title`)),
                distinctUntilChanged()
            );

        this.tip$ = result$
            .pipe(
                map(result => this.getSuggestions(result)),
                map(suggestions => {
                    if (suggestions.length === 0) return "";
                    return this.translateSvc.getString(`passwordChecker.use`) + " " + suggestions.join(", ") + ".";
                }),
                distinctUntilChanged()
            );
    }

    private getSuggestions(result: PasswordCheckResult): string[] {
        if (!result) {
            return [];
        }

        const suggestions = [];

        if (!result.words && !result.upper) {
            suggestions.push(this.translateSvc.getString(`passwordChecker.anywords`));
        } else if (!result.words) {
            suggestions.push(this.translateSvc.getString(`passwordChecker.words`));
        } else if (!result.upper) {
            suggestions.push(this.translateSvc.getString(`passwordChecker.upper`));
        }
        if (!result.digits) {
            suggestions.push(this.translateSvc.getString(`passwordChecker.digits`));
        }
        if (!result.nonWords) {
            suggestions.push(this.translateSvc.getString(`passwordChecker.nonWords`));
        }

        return suggestions;
    }
}

type PasswordCheckResult = { upper: boolean, digits: boolean, words: boolean, nonWords: boolean };
export type PasswordSecurityLevel = "none" | "veryweak" | "weak" | "middle" | "strong";

function toLevel(result: PasswordCheckResult): PasswordSecurityLevel {
    if (!result) {
        return "none";
    }

    let score = 0;
    if (result.digits) {
        score++;
    }
    if (result.nonWords) {
        score++;
    }
    if (result.words && result.upper) {
        score++;
    }

    if (score === 0) {
        return "veryweak";
    } else if (score === 1) {
        return "weak";
    } else if (score === 2) {
        return "middle";
    } else {
        return "strong";
    }
}

function checkPassword(password: string): PasswordCheckResult {
    if (password.length < 8) return undefined;
    let digits = false;
    let words = false;
    let nonWords = false;
    let upper = false;

    for (let c of password) {
        if (!digits) {
            digits = /\d/.test(c);
        }
        if (!words) {
            words = /[a-zа-яё]/.test(c);
        }
        if (!upper) {
            upper = /[A-ZА-ЯЁ]/.test(c);
        }
        if (!nonWords) {
            nonWords = /\W/.test(c);
        }
    }
    //
    // if (!words && !upper) {
    //     upper = true;
    // }

    return {digits, words, upper, nonWords};

}
