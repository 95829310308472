import {ChangeDetectionStrategy, Component, Input} from "@angular/core";

@Component({
    selector: "modal-header",
    templateUrl: "./modal-header.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./modal-header.ng.css"
    ]
})
export class ModalHeaderComponent {

    @Input()
    contextual: boolean;

    @Input()
    url: any[];

    @Input()
    text: string;

    @Input()
    defaultText: string;

    constructor() {
    }

}
