import {MonoTypeOperatorFunction, ReplaySubject, share} from "rxjs";

export function shareLastValue<T>(): MonoTypeOperatorFunction<T> {
    return share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
    });
}
