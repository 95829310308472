import {Injectable} from "@angular/core";

export abstract class ErrorMessagesFactory {

    abstract getMessage(errorKey: string, errorValue: any): string;

}

@Injectable({
    providedIn: "root"
})
export class DefaultErrorMessagesFactory extends ErrorMessagesFactory {

    getMessage(errorKey: string, errorValue: any): string {

        switch (errorKey) {
            case "required":
                return `This field is required`;
            case "maxlength":
                return `Max length of this field is ${errorValue}`;
            case "minlength":
                return `Min length of this field is ${errorValue.requiredLength}`;
            default:
                if (errorValue["message"]) {
                    return errorValue["message"];
                } else {
                    return `Error ${errorKey}`;
                }
        }
    }

}
