import {ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, OnInit, Optional} from "@angular/core";
import {DROPDOWN_MENU_PROVIDER, IDropdownMenu, IDropdownMenuItem} from "./n-dropdown-menu.component";
import {of} from "rxjs";


@Component({
    selector: "n-dropdown-menu-divider",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ``
})
export class NDropdownMenuDividerComponent implements OnInit, OnDestroy {
    private menuItem: IDropdownMenuItem;

    constructor(@Inject(DROPDOWN_MENU_PROVIDER) @Optional() private menu: IDropdownMenu) {
    }

    getDropdownMenuItem(): IDropdownMenuItem {
        return this.menuItem;
    }

    ngOnDestroy(): void {
        this.menu.removeItem(this.menuItem);
    }

    ngOnInit(): void {
        this.menuItem = {
            id: this,
            divider: true,
            heading: of(""),
            disabled: of(true),
            iconId: of(""),
            iconColor: of(""),
            select: new EventEmitter<any>(false),
            disabledSnapshot: () => true,
            headingSnapshot: () => ""
        };
        this.menu.addItem(this.menuItem);
    }
}
