import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {NotebookInfo} from "../../models/NotebookInfo";

@Component({
    selector: "notebook-section",
    templateUrl: "notebook-section.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./notebook-section.ng.css"
    ]
})

export class NotebookSectionComponent {

    @Input()
    notebook: NotebookInfo;

    get ownerLink(): string[] {
        if (this.notebook.ownerTeam) {
            return ["/", this.notebook.ownerTeam.name, "nb", this.notebook.name];
        }
        return ["/", "profile", this.notebook.owner.username, "nb", this.notebook.name];
    }

}
