<form (submit)="save.next(form.value)" [formGroup]="form" class="form">

    <dr-form-group>
        <div class="cols">
            <div class="inputs">
                <dr-field label="notebooks.form.title">
                    <n-input #titleField [focusOnDefault]="titleField" [formControlName]="'title'" wide></n-input>
                </dr-field>
                <dr-field label="notebooks.form.name">
                    <n-input [formControlName]="'name'" wide></n-input>
                </dr-field>
            </div>
            <div class="color">
                <dr-field label="notebooks.form.color">
                    <notebook-color-picker [formControlName]="'color'"></notebook-color-picker>
                </dr-field>
            </div>
        </div>
    </dr-form-group>

    <dr-form-group *ngIf="isTeamNotebook" description="notebooks.settings.whoTeamCreateDesc"
                   header="notebooks.settings.whoCreateTitle">
        <div class="radio">
            <n-radio [formControlName]="'editRestrict'" [name]="'editRestrict'" [text]="'notebooks.settings.onlyAdmin'|translate"
                     value="administrators"></n-radio>
        </div>
        <div class="radio">
            <n-radio [formControlName]="'editRestrict'" [name]="'editRestrict'" [text]="'notebooks.settings.allTeamMembers'|translate"
                     value="allMembers"></n-radio>
        </div>
    </dr-form-group>

    <dr-form-group [description]="typeDescKey"
                   header="notebooks.settings.whoReadTitle">

        <ng-container *ngIf="!teamType || teamType === 'limited'">
            <div class="radio">
                <n-radio [formControlName]="'type'"
                         [text]="typeCloseKey | translate"
                         name="type"
                         value="closed"></n-radio>
            </div>
            <div class="radio">
                <n-radio [formControlName]="'type'"
                         [text]="typeOpenKey|translate"
                         name="type"
                         value="open"></n-radio>
            </div>
        </ng-container>

        <div *ngIf="teamType === 'closed'">
            <div class="field-header">{{'notebooks.settings.onlyMembers'|translate}}</div>
            <div class="field-description">{{'notebooks.settings.onlyMembersDesc'|translate}}
            </div>
        </div>

        <div *ngIf="teamType === 'open'">
            <div class="field-header">{{'notebooks.settings.allUsers'|translate}}</div>
            <div class="field-description">
                {{'notebooks.settings.allPublicTeamUsersDesc'|translate}}
            </div>
        </div>
    </dr-form-group>


    <div class="buttons">
        <n-button [disabled]="form.invalid || !form.dirty || saving" [loading]="saving" [text]="'forms.save'|translate"
                  [theme]="'prime'" [type]="'submit'"></n-button>
        <n-button (click)="cancel.emit()" [disabled]="saving" [text]="'forms.cancel'|translate"></n-button>
    </div>
</form>
