export function diffInMinutes(d1: Date, d2: Date): number {
    const t2 = d2.getTime();
    const t1 = d1.getTime();
    return Math.floor((t2 - t1) / (60 * 1000));
}

export function diffInHours(d1: Date, d2: Date): number {
    const t2 = d2.getTime();
    const t1 = d1.getTime();
    return Math.floor((t2 - t1) / (3600 * 1000));
}

export function diffInDays(d1: Date, d2: Date): number {
    const t2 = d2.getTime();
    const t1 = d1.getTime();

    return Math.floor((t2 - t1) / (24 * 3600 * 1000));
}

export function diffInSeconds(d1: Date, d2: Date): number {
    const t2 = d2.getTime();
    const t1 = d1.getTime();

    return Math.floor((t2 - t1) / 1000);
}

export function diffInMonths(d1: Date, d2: Date): number {
    const d1Y = d1.getFullYear();
    const d2Y = d2.getFullYear();
    const d1M = d1.getMonth();
    const d2M = d2.getMonth();

    return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
}

export function truncateTime(d: Date): Date {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}
