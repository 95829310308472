import {UserRef} from "../UserRef";
import {BehaviorSubject, distinctUntilChanged, map, Observable} from "rxjs";

export class DocumentAccess {

    readonly user: UserRef;
    private readonly _level$: BehaviorSubject<DocumentAccessLevel>;
    private readonly _levelTitle$: Observable<string>;

    constructor(user: UserRef, level: DocumentAccessLevel) {
        this.user = user;
        this._level$ = new BehaviorSubject(level);

        this._levelTitle$ = this._level$.pipe(
            map(x => {
                switch (x) {
                    case DocumentAccessLevel.ReadOnly:
                        return "documents.access.readonly";
                    case DocumentAccessLevel.Comment:
                        return "documents.access.comment";
                    case DocumentAccessLevel.Edit:
                        return "documents.access.edit";
                    default:
                        return "documents.access.unknown";
                }
            }),
            distinctUntilChanged()
        );
    }

    get levelTitle$(): Observable<string> {
        return this._levelTitle$;
    }

    get level$(): Observable<DocumentAccessLevel> {
        return this._level$;
    }

    get level(): DocumentAccessLevel {
        return this._level$.value;
    }

    static parse(data: any): DocumentAccess {
        return new DocumentAccess(UserRef.parse(data.user), data.level || "edit");
    }

    static parseArray(data: any[]): DocumentAccess[] {
        return data.map(d => DocumentAccess.parse(d));
    }

    changeLevel(level: DocumentAccessLevel) {
        this._level$.next(level);
    }

}

export enum DocumentAccessLevel {
    ReadOnly = "readOnly",
    Comment = "comment",
    Edit = "edit"
}
