import {ElementRef, Renderer2} from "@angular/core";

export type Placement = "left" | "top" | "right" | "bottom";
export type HorizontalAlign = "center" | "left" | "right";
/**
 * Padding from window frame
 */
const PADDING = 20;

export interface IRect {
    left: number;
    top: number;
    right: number;
    bottom: number;
}

export interface IPosition {
    left: number;
    top: number;

    arrowPos: IArrowPosition;
}

export interface IBox {
    width: number;
    height: number;
}

export interface IArrowPosition {
    marginLeft?: string;
    marginTop?: string;
    left?: string;
    top?: string;
    right?: string;
    bottom?: string;

    arrowPlacement?: Placement;
}

interface ICalcResult {
    arrowPos: IArrowPosition;
    pos: number;
}

/**
 * Calculate position of the @box relative to @target inside @container includes @paddings
 * Also calculate arrow position and styles
 */
export function calculatePosition(placement: Placement,
                                  target: IRect,
                                  box: IBox,
                                  container: IBox,
                                  paddings: IRect = {top: PADDING, bottom: PADDING, right: PADDING, left: PADDING},
                                  arrowSize: IBox = {width: 10, height: 10},
                                  horizontalAlign: HorizontalAlign = "center"): IPosition {

    let pointX: number, placementX: number;
    let pointY: number, placementY: number;
    let arrowPos: IArrowPosition;
    const targetWidth = target.right - target.left;
    // const rectHeight = near.bottom - near.top;
    //
    switch (placement) {
        case "left":
            placementX = target.left;
            placementY = (target.bottom - target.top) / 2 + target.top;

            const leftResult = calcLeft(placementX, box.width, target, paddings.left, arrowSize.width);

            pointX = leftResult.pos;
            arrowPos = leftResult.arrowPos;

            pointY = calcVerticalCenter(placementY, box.height, container.height, paddings.bottom, paddings.top, arrowSize.height, arrowPos);

            break;
        case "top":
            placementY = target.top;
            placementX = (target.right - target.left) / 2 + target.left;

            const topResult = calcTop(placementY, box.height, target, arrowSize.height, paddings.top);

            pointY = topResult.pos;
            arrowPos = topResult.arrowPos;

            pointX = calcHorizontalCenter(placementX,
                targetWidth,
                box.width, paddings.left, paddings.right, container.width, arrowSize.width, arrowPos, horizontalAlign);
            break;
        case "right":
            placementX = target.right;
            placementY = (target.bottom - target.top) / 2 + target.top;

            const rightResult = calcRight(placementX, box.width, target, container.width, arrowSize.width, paddings.right);

            pointX = rightResult.pos;
            arrowPos = rightResult.arrowPos;

            pointY = calcVerticalCenter(placementY, box.height, container.height, paddings.bottom, paddings.top, arrowSize.height, arrowPos);
            break;
        case "bottom":
            placementY = target.bottom;
            placementX = (target.right - target.left) / 2 + target.left;

            const bottomResult = calcBottom(placementY, box.height, target, container.height, arrowSize.height, paddings.bottom);

            pointY = bottomResult.pos;
            arrowPos = bottomResult.arrowPos;

            pointX = calcHorizontalCenter(placementX,
                targetWidth,
                box.width, paddings.left, paddings.right, container.width, arrowSize.width, arrowPos, horizontalAlign);
            break;

    }

    return {
        left: pointX < paddings.left ? paddings.left : pointX,
        top: pointY,
        arrowPos
    };

}

export function applyArrowStyles(renderer: Renderer2, arrow: ElementRef, arrowPos: IArrowPosition) {

    if (arrowPos.left) {
        renderer.setStyle(arrow.nativeElement, "left", arrowPos.left);
    }

    if (arrowPos.right) {
        renderer.setStyle(arrow.nativeElement, "right", arrowPos.right);
    }

    if (arrowPos.top) {
        renderer.setStyle(arrow.nativeElement, "top", arrowPos.top);
    }

    if (arrowPos.bottom) {
        renderer.setStyle(arrow.nativeElement, "bottom", arrowPos.bottom);
    }

    if (arrowPos.marginTop) {
        renderer.setStyle(arrow.nativeElement, "margin-top", arrowPos.marginTop);
    }

    if (arrowPos.marginLeft) {
        renderer.setStyle(arrow.nativeElement, "margin-left", arrowPos.marginLeft);
    }

}

function calcLeft(placementX: number,
                  boxWidth: number,
                  target: IRect,
                  paddingLeft: number,
                  arrowWidth: number): ICalcResult {

    const arrowPos: IArrowPosition = {};

    if (placementX - boxWidth >= paddingLeft) {
        // open at the left side
        arrowPos.right = -arrowWidth + "px";
        arrowPos.arrowPlacement = "right";
        return {arrowPos, pos: (placementX - boxWidth - arrowWidth)};
    } else {
        // open at the right side
        arrowPos.left = -arrowWidth + "px";
        arrowPos.arrowPlacement = "left";
        return {arrowPos, pos: target.right + arrowWidth};
    }

}

function calcRight(placementX: number,
                   boxWidth: number,
                   target: IRect,
                   containerWidth: number,
                   arrowWidth: number,
                   paddingRight: number): ICalcResult {

    const arrowPos: IArrowPosition = {};

    if (placementX + boxWidth + paddingRight <= containerWidth) {
        // open at the right side
        arrowPos.left = -arrowWidth + "px";
        arrowPos.arrowPlacement = "left";
        return {pos: placementX + arrowWidth, arrowPos};
    } else {
        // open at the left side
        arrowPos.right = -arrowWidth + "px";
        arrowPos.arrowPlacement = "right";
        return {pos: target.left - boxWidth - arrowWidth, arrowPos};
    }

}

function calcBottom(placementY: number,
                    boxHeight: number,
                    target: IRect,
                    containerHeight: number,
                    arrowHeight: number,
                    paddingBottom: number): ICalcResult {

    const arrowPos: IArrowPosition = {};

    if (placementY + boxHeight + paddingBottom <= containerHeight) {
        // open at the bottom
        arrowPos.top = -arrowHeight + "px";
        arrowPos.arrowPlacement = "top";
        return {
            pos: placementY + arrowHeight,
            arrowPos
        };
    } else {
        // open at the top
        arrowPos.bottom = -arrowHeight + "px";
        arrowPos.arrowPlacement = "bottom";
        return {
            pos: target.top - boxHeight - arrowHeight,
            arrowPos
        };
    }
}

function calcTop(placementY: number,
                 boxHeight: number,
                 target: IRect,
                 arrowHeight: number,
                 paddingTop: number): ICalcResult {

    const arrowPos: IArrowPosition = {};

    if (placementY - boxHeight >= paddingTop) {
        // open at the top:
        arrowPos.bottom = -arrowHeight + "px";
        arrowPos.arrowPlacement = "bottom";
        return {
            arrowPos,
            pos: placementY - boxHeight - arrowHeight
        };
    } else {
        // open at the bottom
        arrowPos.top = -arrowHeight + "px";
        arrowPos.arrowPlacement = "top";
        return {
            pos: target.bottom + arrowHeight,
            arrowPos
        };
    }
}

function calcHorizontalCenter(placementX: number,
                              targetWidth: number,
                              boxWidth: number,
                              paddingLeft: number,
                              paddingRight: number,
                              containerWidth: number,
                              arrowWidth: number,
                              arrowPos: IArrowPosition,
                              horizontalAlign: HorizontalAlign): number {
    // TODO arrow pos for other alignments
    switch (horizontalAlign) {
        case "left":
            placementX = placementX + (boxWidth / 2) - (targetWidth / 2);
            break;
        case "right":
            placementX = placementX + (targetWidth / 2) - (boxWidth / 2);
            break;
    }
    // const documentWidth = (document.body.offsetWidth || document.body.clientWidth);
    if (placementX - boxWidth / 2 >= paddingLeft &&
        placementX + boxWidth / 2 + paddingRight <= containerWidth) {

        // at the center of the box
        arrowPos.left = "50%";
        arrowPos.marginLeft = -(arrowWidth / 2) + "px";

        return (placementX - boxWidth / 2);

    } else {
        let leftPos = placementX - boxWidth / 2;
        if (leftPos < paddingLeft) {
            // on the left side:
            arrowPos.left = "50%";
            arrowPos.marginLeft = -((paddingLeft - leftPos) + (arrowWidth / 2)) + "px";
            return paddingLeft;
        } else {
            // on the right side
            const rightOffset = containerWidth - paddingRight - placementX;
            leftPos = containerWidth - boxWidth - paddingRight;
            arrowPos.left = boxWidth - rightOffset - (arrowWidth / 2) + "px";
            return leftPos;
        }
    }
}

function calcVerticalCenter(placementY: number,
                            boxHeight: number,
                            containerHeight: number,
                            paddingBottom: number,
                            paddingTop: number,
                            arrowHeight: number,
                            arrowPos: IArrowPosition): number {

    if (placementY - boxHeight / 2 >= paddingTop && placementY + boxHeight / 2 + paddingBottom <= containerHeight) {
        arrowPos.top = "50%";
        arrowPos.marginTop = -(arrowHeight / 2) + "px";
        return (placementY - boxHeight / 2);

    } else if (placementY - boxHeight / 2 < paddingTop) {
        // stick to top side
        const topStyle = placementY - paddingTop;
        arrowPos.top = (topStyle > 0 ? topStyle : 4) + "px";
        return paddingTop;
    } else {
        // stick to bottom
        const top = containerHeight - boxHeight - (paddingBottom / 2);
        const topStyle = (placementY - top);
        arrowPos.top = (topStyle > 0 ? topStyle : 4) + "px";
        return top;
    }
}


