import {UserRef} from "../../models/UserRef";
import {IPickerItem} from "searchpicker";

export class UserPickerItem implements IPickerItem {

    readonly id: any;
    readonly title: string;

    readonly firstname: string;
    readonly lastname: string;

    readonly post: string;
    readonly avatarPath: string;

    constructor(private userRef: UserRef) {
        this.firstname = userRef.firstname;
        this.lastname = userRef.lastname;

        this.post = userRef.post;
        this.avatarPath = userRef.avatarPath;
        this.id = userRef.username;

        this.title = userRef.toFullname();
    }

    toRef(): UserRef {
        return this.userRef;
    }
}
