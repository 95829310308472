import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: "root"})
export class MenuService {

    readonly menuOpened$ = new BehaviorSubject<boolean>(false);

    constructor() {
    }

    // noinspection JSMethodCanBeStatic
    closeMenu() {
        this.menuOpened$.next(false);
    }

    // noinspection JSMethodCanBeStatic
    openMenu() {
        this.menuOpened$.next(true);
    }

}
