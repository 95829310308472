<div (longTap)="onTap()"
     [class._checked]="selected"
     [class._pinned]="(notebook$|async).pinned$|async"
     [class._selectMode]="selectMode"
     class="notebook-list-item">

    <div class="notebook-list-item__cover">
        <div [style.backgroundColor]="(notebook$|async).cssColor" class="notebook-list-item__cover-front"></div>
        <div [style.borderColor]="(notebook$|async).cssColor" class="notebook-list-item__cover-back"></div>
        <n-icon *ngIf="shared$|async" [fillColor]="'rgba(0,0,0,.3)'" [iconId]="'public-notebook'"
                class="notebook-list-item__public-icon"></n-icon>
    </div>

    <a (click)="onNavigate($event)" [href]="link$|async" class="notebook-list-item__link">
        <notebook-icon [color]="(notebook$|async).cssColor" [shared]="shared$|async"
                       class="notebook-list-item__icon"></notebook-icon>
        <div class="notebook-list-item__info">
            <div class="notebook-list-item__title">{{(notebook$|async).title}}</div>
            <div class="notebook-list-item__date">
                <format-date [date]="(notebook$|async).lastActivityDate"></format-date>
            </div>
        </div>
        <span class="notebook-list-item__check-marker"></span>
    </a>
    <div (click)="togglePin.emit()" *ngIf="canPin" class="notebook-list-item__pin-button">
        <n-icon [class._pinned]="(notebook$|async).pinned$|async"
                [iconId]="'pinned'"
                [iconSize]="'18'"
                class="notebook-list-item__pin-icon"></n-icon>
    </div>
</div>
