<label [ngClass]="modifierText"
       class="checkbox">
    <input #input
           (change)="onChange($event)"
           [name]="name"
           class="input"
           type="checkbox">
    <span class="text">
        <span>{{text}}</span>
        <span class="desc">{{description}}</span>
    </span>
    <ng-content></ng-content>
</label>
