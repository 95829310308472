<div [ngClass]="{'_on-line': mod === 'onLine', '_switchable': switchable,'_button-switcher':mod === 'buttonSwitcher'}"
     class="n-tab">
    <button (click)="selectTab()" (mousedown)="selectTab()" [disabled]="pane.disabled|async"
            [ngClass]="{_active: (pane.selected|async)}"
            class="link"
            type="button">
    <span class="tab-content">
      <span class="title">{{pane.heading|async}}</span>
      <span *ngIf="showCount" class="counter">{{pane.count$|async}}</span>
    </span>
        <span class="underline"></span>
    </button>
</div>
