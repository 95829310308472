<!-- todo _starred -->
<div (longTap)="onTap()"
     [class._checked]="selected"
     [class._selectMode]="selectMode"
     [longTapDisabled]="!canSelect"
     class="document-list-item">

    <a (click)="onNavigate($event)" [href]="'/doc/' + (document$|async).id" class="document-list-item__link"
       draggable="false">
        <div [class._preview]="previewImageUrl$|async" class="document-list-item__wrapper">
            <div class="document-list-item__info">
                <div class="document-list-item__title">{{title$ | async}}</div>
                <div *ngIf="snippet$|async" class="document-list-item__snippet">{{snippet$|async}}</div>
            </div>
            <div *ngIf="previewImageUrl$|async" class="document-list-item__preview">
                <avatar-img [url]="previewImageUrl$|async"></avatar-img>
            </div>
        </div>
        <div class="document-list-item__meta">
            <div class="document-list-item__date">
                <format-date [date]="(document$|async).createdDate"></format-date>
            </div>
            <div class="document-list-item__author">
                <user-name [item]="(document$|async).createdBy"></user-name>
            </div>
        </div>
        <span class="document-list-item__check-marker"></span>
        <span (click)="actionButtonClick($event)" *ngIf="actionIconId" class="document-list-item__action">
            <n-icon [fillColor]="'rgb(84, 140, 54)'" [iconId]="actionIconId"
                    class="document-list-item__action-icon"></n-icon>
        </span>
    </a>
</div>
