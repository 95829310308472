<div (hotKeyEvent)="tryCancel()"
     [hotKey]="'escape'"
     class="close-button">
    <n-action-button (click)="cancel.emit()" [icon]="'close'"></n-action-button>
</div>

<div class="title">
    <span *ngIf="selectedCount === 0">{{placeholderText}}</span>
    <span *ngIf="selectedCount > 0">{{selectedText}} {{selectedCount}}</span>
</div>

<div class="actions">
    <ng-content></ng-content>
</div>
