import {ContextualRouteInfo} from "./ContextualRouteInfo";
import {ComponentFactory, ComponentFactoryResolver, Injector} from "@angular/core";

/**
 * Implementation to connect application with contextual routes
 */
export abstract class ContextualRoutesAdapter {

    restoreContext: () => void;

    abstract activate(cr: ComponentFactory<any>, inj: Injector, resolver: ComponentFactoryResolver, contextualRoute: ContextualRouteInfo);

    abstract deactivate(contextualRoute: ContextualRouteInfo);

}
