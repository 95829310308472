import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "form-window",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-content></ng-content>`,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./form-window.bem.css"
    ]
})
export class FormWindowComponent {

}

@Component({
    selector: "form-header",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<ng-content></ng-content>`
})
export class FormHeaderComponent {

}

@Component({
    selector: "form-window-header",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<div class="form-window__header"><ng-content></ng-content></div>`
})
export class FormWindowHeaderComponent {

}


@Component({
    selector: "form-window-extra",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./form-window-extra.html"
})
export class FormWindowExtraComponent {
    @Input()
    showSignUp: boolean;

    @Input()
    showSignIn: boolean;

    @Input()
    hideGoogle: boolean;

    @Input()
    showBoth: boolean;
}
