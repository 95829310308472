import {Injectable} from "@angular/core";

export interface ICacheProvider {

    addAll(keys: string[]): Promise<any>;

    get(key: Request | string): Promise<Response>;

    getAllNamespaces(): Promise<string[]>;

    remove(key: Request | string): Promise<any>;

    removeNamespace(namespace: string): Promise<any>;

    set(key: Request | string, value: Response): Promise<void>;

}

/**
 *
 * Сервис-обертка над CACHE API
 */
@Injectable({providedIn: "root"})
export class CacheStorageProvider implements ICacheProvider {

    readonly defaultNamespace: string;

    constructor() {
        this.defaultNamespace = "renote-runtime-cache-v1";
    }

    addAll(keys: string[]): Promise<any> {
        return caches.open(this.defaultNamespace).then(cache => cache.addAll(keys), () => {
        });
    }

    async clear() {
        for (let namespace of (await this.getAllNamespaces())) {
            await this.removeNamespace(namespace);
        }
    }

    get(key: Request | string): Promise<Response> {
        if (!isSupport()) {
            return Promise.resolve(undefined);
        }
        return caches.open(this.defaultNamespace).then(cache => cache.match(key), () => undefined);
    }

    getAllNamespaces(): Promise<string[]> {
        if (!isSupport()) {
            return Promise.resolve([]);
        }
        return caches.keys().catch(() => []);
    }

    keys(): Promise<ReadonlyArray<Request>> {
        if (!isSupport()) {
            return Promise.resolve([]);
        }
        return caches.open(this.defaultNamespace)
            .then(cache => cache.keys())
            .catch(() => []);
    }

    remove(key: Request | string): Promise<any> {
        if (!isSupport()) {
            return Promise.resolve();
        }
        return caches.open(this.defaultNamespace).then(cache => cache.delete(key), () => {
        });
    }

    removeNamespace(namespace: string): Promise<any> {
        if (!isSupport()) {
            return Promise.resolve();
        }
        return caches.delete(namespace).catch(() => {
        });
    }

    set(key: Request | string, value: Response): Promise<void> {
        if (!isSupport()) {
            return Promise.resolve();
        }
        return caches.open(this.defaultNamespace).then(cache => cache.put(key, value), () => {
        });
    }

}

function isSupport(): boolean {
    if (window) {
        return "caches" in window;
    }

}
