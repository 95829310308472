import {distinctUntilChanged, Observable} from "rxjs";
import {MemorySubject} from "../../modules/store";
import {UserRef} from "./UserRef";
import {TeamRef} from "./TeamRef";
import {EntityColor, getEntityCssColor} from "./EntityColor";

export type NotebookType = "open" | "closed";
export type NotebookEditDocumentRestrict = "allMembers" | "administrators";

export const TEMPLATES_NOTEBOOK_NAME = "templates";

export class NotebookInfo {

    readonly name: string;
    readonly title: string;
    readonly color: EntityColor;
    readonly cssColor: string;
    readonly type: NotebookType;

    readonly owner: UserRef;

    readonly ownerTeam: TeamRef;
    readonly editRestrict: NotebookEditDocumentRestrict;
    private _lastActivity$: MemorySubject<Date>;

    constructor(name: string, title: string, color: EntityColor,
                pinned: boolean, type: NotebookType,
                editRestrict: NotebookEditDocumentRestrict,
                lastActivityDate?: Date,
                owner?: UserRef, ownerTeam?: TeamRef) {
        this.name = name;
        this.title = title;
        this.color = color;
        this.type = type;
        this._pinned$ = new MemorySubject(pinned);
        this.cssColor = getEntityCssColor(this.color);
        this._lastActivity$ = new MemorySubject<Date>(lastActivityDate || new Date());
        this.owner = owner;
        this.ownerTeam = ownerTeam;
        this.editRestrict = editRestrict;
    }

    get isTemplatesNotebook(): boolean {
        return this.name === TEMPLATES_NOTEBOOK_NAME;
    }

    get isTeamNotebook(): boolean {
        return !!this.ownerTeam;
    }

    get lastActivityDate(): Date {
        return this._lastActivity$.value;
    }

    private _pinned$: MemorySubject<boolean>;

    get pinned$(): Observable<boolean> {
        return this._pinned$
            .pipe(
                distinctUntilChanged()
            );
    }

    get pinned(): boolean {
        return this._pinned$.value;
    }


    static parse(data: any): NotebookInfo {
        return new NotebookInfo(data.name, data.title, data.color, !!data.pinned, data.type, data.editRestrict, new Date(data.lastActivityDate),
            data.owner ? UserRef.parse(data.owner) : undefined, data.ownerTeam ? TeamRef.parse(data.ownerTeam) : undefined);
    }

    static parseArray(data: any[]): NotebookInfo[] {
        return data.map(d => NotebookInfo.parse(d));
    }

    pin() {
        this._pinned$.next(true);
    }

    unpin() {
        this._pinned$.next(false);
    }

    updateActivity() {
        this._lastActivity$.next(new Date());
    }
}


export function notebookListComparer(list1: NotebookInfo[], list2: NotebookInfo[]): boolean {
    if (list1.length !== list2.length) {
        return false;
    }

    for (let i = 0; i < list1.length; i++) {
        const list1Item = list1[i];
        const list2Item = list2[i];
        if (list1Item.title !== list2Item.title ||
            list1Item.name !== list2Item.name ||
            list1Item.color !== list2Item.color ||
            list1Item.type !== list2Item.type ||
            list1Item.editRestrict !== list2Item.editRestrict ||
            list1Item.color !== list2Item.color ||
            list1Item.pinned !== list2Item.pinned) {
            return false;
        }
    }

    return true;
}
