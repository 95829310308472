import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {UserRef} from "../../models/UserRef";
import {combineLatest, distinctUntilChanged, map, Observable} from "rxjs";
import {MemorySubject} from "../../../modules/store";

@Component({
    selector: "user-avatar-list",
    templateUrl: "./user-avatar-list.html",
    styleUrls: [
        "./user-avatar-list.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarListComponent implements OnInit {

    showCounter$: Observable<boolean>;
    usersCount$: Observable<number>;
    usersToDisplay$: Observable<UserRef[]>;

    private _users$ = new MemorySubject<UserRef[]>();
    private _cutCount$ = new MemorySubject<number>();

    constructor() {
    }

    @Input()
    set cutCount(val: number) {
        this._cutCount$.next(val);
    }

    @Input()
    set users(val: UserRef[]) {
        this._users$.next(val);
    }

    expand() {
        this._cutCount$.next(-1);
    }

    ngOnInit() {

        this.usersToDisplay$ = combineLatest([this._users$, this._cutCount$])
            .pipe(
                map(([users, count]) => {
                    if (count <= 0) {
                        return users;
                    }
                    return users.slice(0, count);
                })
            );

        this.showCounter$ = combineLatest([this.usersToDisplay$, this._users$])
            .pipe(
                map(([displaying, total]) => displaying.length < total.length),
                distinctUntilChanged()
            );

        this.usersCount$ = this._users$
            .pipe(
                map(users => users.length),
                distinctUntilChanged()
            );
    }

    userIdentity = (i: number, user: UserRef) => user.username;
}
