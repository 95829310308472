const RIPPLE_DATA_ATTR = "data-ripple";
const RIPPLE_CLASS = "_ripple";
const ANIMATION_TIME = 210;
const START_WIDTH = 20;
const HIDE_CLASS = "_ripple-hide";
const endEvents = [
    "touchcancel",
    "touchend",
    "mouseup",
    "mouseleave",
    // "mousemove"
    "touchmove",

];

export function makeRippleEffect(el: HTMLElement, e: MouseEvent | TouchEvent) {

    if (!e.currentTarget["getBoundingClientRect"]) {
        return;
    }

    const rect = e.currentTarget["getBoundingClientRect"]();
    const touchEvt = e as TouchEvent;

    const x = (touchEvt.touches && touchEvt.touches.length ?
        touchEvt.touches[0].clientX : (e as MouseEvent).clientX || 0) - rect.left; // x position within the element.
    const y = (touchEvt.touches && touchEvt.touches.length ?
        touchEvt.touches[0].clientY : (e as MouseEvent).clientY || 0) - rect.top;

    if (el.getAttribute(RIPPLE_DATA_ATTR)) {
        return;
    }

    el.setAttribute(RIPPLE_DATA_ATTR, "1");
    el.classList.add(RIPPLE_CLASS);
    const rippleDiv = document.createElement("div");
    rippleDiv.classList.add("ripple");
    rippleDiv.setAttribute("style", "top:" + y + "px; left:" + x + "px;");

    let finished = false;
    let canceled = false;

    const removeRipple = () => {
        if (!el.getAttribute(RIPPLE_DATA_ATTR)) {
            return;
        }
        el.classList.remove(RIPPLE_CLASS);
        rippleDiv.classList.add(HIDE_CLASS);
        setTimeout(() => {
            rippleDiv.parentElement.removeChild(rippleDiv);
            el.removeAttribute(RIPPLE_DATA_ATTR);
        }, ANIMATION_TIME);
    };

    const onFinished = () => {
        finished = true;
        if (!canceled) {
            return;
        }
        removeRipple();
    };

    const onLeave = () => {
        if (canceled) {
            return;
        }
        unbindEvents(el, endEvents, onLeave);
        canceled = true;
        if (!finished) {
            return;
        }
        removeRipple();
    };

    bindEvents(el, endEvents, onLeave);

    el.appendChild(rippleDiv);
    const maxWidth = (el.clientWidth - x) > x ? el.clientWidth - x : x;
    const maxHeight = (el.clientHeight - y) > y ? el.clientHeight - y : y;
    // Расчитываем максимальный радиус окружности - это гипотенуза со сторонами maxHeight и maxWidth
    const maxLength = Math.sqrt(Math.pow(maxWidth, 2) + Math.pow(maxHeight, 2));
    const maxScale = Math.round(maxLength / (START_WIDTH / 2)) + 2 /* из за кривизны, окружность может не перекрыть всюповерхность */;
    setTimeout(() => {
        rippleDiv.classList.add("_ripple-show");
        rippleDiv.style.transform = `translateZ(0) scale(${maxScale})`;
    }, 0);
    setTimeout(onFinished, ANIMATION_TIME);
}

function bindEvents(el: HTMLElement, events: string[], func: (e) => void) {
    for (const e of events) {
        el.addEventListener(e, func);
    }
}

function unbindEvents(el: HTMLElement, events: string[], func: (e) => void) {
    for (const e of events) {
        el.removeEventListener(e, func);
    }
}
