import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "dr-form-group",
    templateUrl: "./dr-form-group.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./dr-form-group.bem.css"
    ]
})
export class DrFormGroupComponent {

    // fields
    @Input() header: string;
    @Input() description: string;

    constructor() {
    }

    ngOnInit() {
        // initialize your component here
    }


}
