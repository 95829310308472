import {Injectable} from "@angular/core";
import {StorageMode, StorageService} from "./storage.service";

const SCROLL_WIDTH_KEY = "_scroll";

@Injectable({providedIn: "root"})
export class SupportService {

    private _isTouch: boolean;
    private _scrollWidth: number;

    constructor(private storage: StorageService) {

    }

    getScrollWidth(): number {
        if (this.isTouch()) {
            return 0;
        }
        if (this._scrollWidth === undefined) {
            this._scrollWidth = this.getScrollWidthInternal();
        }
        return this._scrollWidth;
    }

    // noinspection JSMethodCanBeStatic
    isIE(): boolean {
        return !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);
    }

    isTouch(): boolean {
        if (typeof (this._isTouch) === "undefined") {
            this._isTouch = "ontouchstart" in window        // works on most browsers
                || !!navigator.maxTouchPoints; // works on IE10/11 and Surface
        }
        return this._isTouch;
    }

    private getScrollWidthInternal(): number {

        const value = this.storage.getItem(SCROLL_WIDTH_KEY) as string;
        if (value) {
            return parseInt(value, 10) || 0;
        }

        const outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style["msOverflowStyle"] = "scrollbar"; // needed for WinJS apps

        document.body.appendChild(outer);

        const widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";

        // add innerdiv
        const inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);

        const widthWithScroll = inner.offsetWidth;

        // remove divs
        outer.parentNode.removeChild(outer);

        const totalWidth = widthNoScroll - widthWithScroll;
        this.storage.save(SCROLL_WIDTH_KEY, totalWidth, StorageMode.PERSISTENT);
        return totalWidth;
    }
}
