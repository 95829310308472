<page-message *ngIf="emptyList$|async" [iconId]="'documents-not-found'"
              [messageKey]="'starred.emptyList'"></page-message>

<div (infiniteScroll)="scrollToEnd()" *ngIf="(groups$ | async).length" class="page">

    <div class="title">{{"starred.title"|translate}}</div>

    <notebook-section *ngFor="let group of groups$ | async;trackBy:groupIdentity" [notebook]="group.notebook$|async">
        <document-list-item (onAction)="unstar(doc)"
                            *ngFor="let doc of group.documents$ | async;trackBy:docIdentity"
                            [actionIconId]="'star-fill'"
                            [canSelect]="false"
                            [document]="doc"
                            [selectMode]="false"
                            [selected]="false"
                            [showGroup]="true"></document-list-item>
    </notebook-section>
</div>
