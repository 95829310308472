import {ChangeDetectionStrategy, Component} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {validateEmail} from "../../validation/validateEmail";
import {UserService} from "../../services/user.service";
import {ErrorService} from "../../services/error.service";
import {validateUsername} from "../../validation/validateUsername";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: "reset-password-page",
    templateUrl: "./reset-password-page.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./reset-password-page.ng.css"
    ]
})
export class ResetPasswordPageComponent {

    submitting$ = new BehaviorSubject<boolean>(false);
    success$ = new BehaviorSubject<boolean>(false);
    form: FormGroup;

    constructor(private authService: AuthService,
                private router: Router,
                private userSvc: UserService,
                private errors: ErrorService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            nameOrEmail: new FormControl(
                this.route.snapshot.queryParams["from"] || "",
                [Validators.required, Validators.maxLength(64)],
                [nameOrEmailExists(this.userSvc)]
            )
        });

        if (this.authService.isAuthenticated()) {
            this.router.navigateByUrl("/", {replaceUrl: true});
            return;
        }
    }

    async submit(form: { nameOrEmail: string }) {
        if (this.form.invalid) {
            return;
        }

        this.submitting$.next(true);

        try {
            await this.userSvc.resetPassword(form.nameOrEmail);
        } catch (fail) {
            this.errors.showFail(fail);
            return;
        } finally {
            this.submitting$.next(false);
        }

        this.success$.next(true);

    }

}

function nameOrEmailExists(userSvc: UserService) {

    const emailValidator = validateEmail(userSvc, true);
    const nameValidator = validateUsername(userSvc, "", {
        shouldExist: true,
        serverCheck: true
    });

    return async (c: FormControl): Promise<any> => {

        const [email, name] = await Promise.all([emailValidator(c), nameValidator(c)]);
        if (email && name) {
            return {nameOrEmailNotExists: true};
        }

        return undefined;
    };

}
