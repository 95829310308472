import {Directive, ElementRef, OnInit} from "@angular/core";
import {isMobileDevice} from "../../shared/Utility";

@Directive({
    selector: "[disableMobileContextMenu]"
})
export class DisableMobileContextMenuDirective implements OnInit {

    constructor(private el: ElementRef) {


    }

    ngOnInit(): void {

        if (!isMobileDevice()) {
            return;
        }

        this.el.nativeElement.oncontextmenu = function (event) {
            event.preventDefault();
            event.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available?
            event.stopImmediatePropagation();
            return false;
        };

    }

}


