import {Directive, ElementRef, HostListener} from "@angular/core";
import {ControlContainer, FormGroupDirective} from "@angular/forms";

@Directive({
    selector: "[focusInvalidFieldOnSubmit]"
})
export class FocusInvalidFieldOnSubmitDirective {

    constructor(
        private container: ControlContainer,
        private el: ElementRef) {
    }

    @HostListener("submit", ["$event"])
    onSubmit(e) {
        if (this.container.invalid) {
            e.preventDefault();
            e.stopPropagation();

            if (this.container instanceof FormGroupDirective) {
                const domContainer: HTMLInputElement = this.el.nativeElement.querySelector(".ng-invalid");
                if (domContainer) {
                    const input = domContainer.querySelector("input");
                    if (input) {
                        input.focus();
                    }
                }
            }
        }
    }

}
