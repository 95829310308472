import {ITranslation} from "../../modules/translate";
import {translateEngQuantity} from "./Helpers";
import {elapsedEnglishTime, formatEnglishDate, getMonthNameEnglish} from "./EnglishDate";

export class EnglishTranslation implements ITranslation {

    readonly lang = "en";
    readonly replaceQuotes = false;
    private _values: any;

    constructor() {

    }

    elapsedTime(d: Date): string {
        return elapsedEnglishTime(d);
    }

    formatDate(d: Date, includeYear: boolean, includeTime: boolean): string {
        return formatEnglishDate(d, includeYear, includeTime);
    }

    getMonthName(d: Date): string {
        return getMonthNameEnglish(d.getMonth());
    }

    async getValues(): Promise<any> {
        if (this._values) {
            return this._values;
        }

        return await new Promise((resolve, reject) => {
            require.ensure([], () => {
                this._values = require("./en.json");
                resolve(this._values);
            }, reject, "en-lang");
        });
    }

    translateQuantity(num: number, values: string[]): string {
        return translateEngQuantity(num, values);
    }

}
