import {IHoverPopoverAdapter} from "../hover-popover.service";
import {Type} from "@angular/core";
import {MentionUserPopoverComponent} from "../../components/mention-user-popover/mention-user-popover.component";
import {UserService} from "../user.service";
import {UserInfo} from "../../models/UserInfo";
import {take} from "rxjs";

export class MentionUserPopoverAdapter implements IHoverPopoverAdapter {
    // noinspection JSUnusedGlobalSymbols
    bindToElement = true;
    // noinspection JSUnusedGlobalSymbols
    popoverComponentType: Type<any> = MentionUserPopoverComponent;
    position: "right" | "left" | "top" | "bottom" = "top";

    constructor(private userName: string,
                private userSvc: UserService) {
    }

    getPopoverData(): Promise<UserInfo> {
        return new Promise<UserInfo>((resolve) => {
            this.userSvc.findByUsername(this.userName)
                .pipe(
                    take(1)
                )
                .subscribe(user => {
                    resolve(user);
                });
        });
    }

}
