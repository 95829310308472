import {NFrameworkIconsFactory} from "../../../modules/n-framework";
import {Injectable} from "@angular/core";
import {INIconSet} from "../../../modules/n-framework/services/nframework-icons-factory.service";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: "root"})
export class AppIconsFactoryService extends NFrameworkIconsFactory {

    constructor(private http: HttpClient) {
        super();
    }

    getIconSet(iconSet?: string): Promise<INIconSet | undefined> {

        return new Promise((resolve, reject) => {
            require.ensure([], () => {
                resolve(require("./icons/svg-icons.json"));
            }, reject, "icons");
        });
    }

}
