import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, InjectionToken, Input} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

export const DROPDOWN_MENU_PROVIDER = new InjectionToken("dropdown-menu");

export interface IDropdownMenu {
    addItem(menuItem: IDropdownMenuItem);

    removeItem(menuItem: IDropdownMenuItem);
}

@Component({
    selector: "n-dropdown-menu",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `:host {
      display: none
    }`
    ],
    template: ``,
    providers: [
        {
            provide: DROPDOWN_MENU_PROVIDER,
            useExisting: forwardRef(() => NDropdownMenuComponent),
        }
    ]
})
export class NDropdownMenuComponent implements IDropdownMenu {

    @Input()
    wide: boolean;
    @Input()
    mobileView: boolean;

    private focusedItemIndex$ = new BehaviorSubject<number>(-1);
    private menuItems$ = new BehaviorSubject<IDropdownMenuItem[]>([]);

    get focusedIndex$(): Observable<number> {
        return this.focusedItemIndex$;
    }

    get focusedIndexSnapshot(): number {
        return this.focusedItemIndex$.value;
    }

    addItem(menuItem: IDropdownMenuItem) {
        const items = this.menuItems$.value;
        items.push(menuItem);
        this.menuItems$.next(items);
    }

    focusFirst() {
        const items = this.menuItems$.value;
        this.setFocusedItemIndex(this.getNextItem(items, -1));
    }

    focusLast() {
        const items = this.menuItems$.value;
        this.setFocusedItemIndex(this.getPrevItem(items, items.length));
    }

    focusNext() {
        const focusedItemIndex = this.focusedItemIndex$.value,
            menuItems = this.menuItems$.value;
        this.setFocusedItemIndex(this.getNextItem(menuItems, focusedItemIndex));
    }

    focusPrev() {
        const focusedItemIndex = this.focusedItemIndex$.value;
        if (focusedItemIndex === 0) {
            return;
        }
        const menuItems = this.menuItems$.value;
        this.setFocusedItemIndex(this.getPrevItem(menuItems, focusedItemIndex));
    }

    getItems(): Observable<IDropdownMenuItem[]> {
        return this.menuItems$.asObservable();
    }

    getItemsSnapshot(): IDropdownMenuItem[] {
        return this.menuItems$.value;
    }

    removeItem(menuItem: IDropdownMenuItem) {
        const items = this.menuItems$.value;
        const indx = items.indexOf(menuItem);
        if (indx < 0) {
            throw new Error(`NDropdownMenu: can't remove item, because it's not found`);
        }
        items.splice(indx, 1);
        this.menuItems$.next(items);
    }

    // noinspection JSMethodCanBeStatic
    select(item: IDropdownMenuItem) {
        if (item) {
            item.select.emit();
        }

    }

    selectFocused() {
        const focusedItemIndex = this.focusedItemIndex$.value;
        if (focusedItemIndex >= 0) {
            const item = this.menuItems$.value[focusedItemIndex];
            if (item) {
                this.select(item);
            }
        }
    }

    setFocusedItemIndex(index: number) {
        this.focusedItemIndex$.next(index);
    }

    private getNextItem(menuItems: IDropdownMenuItem[], focusedItemIndex: number): number {
        if (focusedItemIndex === menuItems.length - 1) {
            return focusedItemIndex;
        }
        if (menuItems[focusedItemIndex + 1].divider || menuItems[focusedItemIndex + 1].disabledSnapshot()) {
            return this.getNextItem(menuItems, focusedItemIndex + 1);
        } else {
            return focusedItemIndex + 1;
        }
    }

    private getPrevItem(menuItems: IDropdownMenuItem[], focusedItemIndex: number): number {
        if (focusedItemIndex === 0) {
            return focusedItemIndex;
        }
        if (focusedItemIndex === -1) {
            return this.getPrevItem(menuItems, menuItems.length - 1);
        }
        if (menuItems[focusedItemIndex - 1].divider || menuItems[focusedItemIndex - 1].disabledSnapshot()) {
            return this.getPrevItem(menuItems, focusedItemIndex - 1);
        } else {
            return focusedItemIndex - 1;
        }
    }
}

export interface IDropdownMenuItem {
    divider?: boolean;
    heading: Observable<string>;
    description?: Observable<string>;
    disabled: Observable<boolean>;
    iconId?: Observable<string>;
    iconColor?: Observable<string>;
    id: any;
    select: EventEmitter<any>;
    tid?: string;

    disabledSnapshot(): boolean;

    headingSnapshot(): string;
}
