import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ContextualRouteService} from "../../../modules/routing";
import {TranslateService} from "../../../modules/translate";
import {ModalContext} from "../../../modules/modal";
import {Router} from "@angular/router";

@Component({
    selector: "modal-back-button",
    template: `
        <n-action-button [text]="buttonText" [icon]="'back'" [lowercase]="true" (click)="close()"></n-action-button>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalBackButtonComponent implements OnChanges {

    @Input()
    defaultText: string = "";

    @Input()
    defaultUrl: any[];

    @Input()
    contextual: boolean;

    @Input()
    url: any[];

    @Input()
    text: string;

    buttonText: string;

    constructor(private routeService: ContextualRouteService,
                private translateSvc: TranslateService,
                private router: Router,
                private modalCtx: ModalContext<any, any>) {

    }

    close() {
        if (this.url) {
            this.router.navigate(this.url);
        } else if (this.contextual) {
            this.modalCtx.cancel();
        } else {
            this.routeService.navigateToContext(this.defaultUrl);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        let {defaultText, text} = changes;

        if (text && text.currentValue) {
            this.buttonText = text.currentValue;
        } else if (defaultText && defaultText.currentValue && !this.routeService.getRouteTitle()) {
            this.buttonText = defaultText.currentValue;
        } else if (!this.text) {
            this.buttonText = this.routeService.getRouteTitle();
            if (this.buttonText) {
                if (this.buttonText.startsWith("$")) {
                    this.buttonText = this.translateSvc.getString(this.buttonText.substring(1));
                }
            } else {
                this.buttonText = this.defaultText;
            }
        }
    }

}
