import {Injectable} from "@angular/core";
import {TranslateService} from "../../../modules/translate/translate.service";

@Injectable({providedIn: "root"})
export class UserPickerItemNoResultFactory {

    constructor(private translateSvc: TranslateService) {

    }

    createRenderer(): (query: string) => Text {
        const text = this.translateSvc.getString("userPicker.noResultLabel");
        return (query: string) => {
            const textToDisplay = text.replace("$query", query);
            return document.createTextNode(textToDisplay);
        };
    }

}
