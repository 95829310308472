import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {buildModifierText} from "../utils";

@Component({
    selector: "n-radio",
    templateUrl: "./n-radio.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./n-radio.ng.css"
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NRadioComponent),
            multi: true
        }
    ]
})
export class NRadioComponent implements ControlValueAccessor, OnInit {

    @Input() value: any;
    @Input() text: string;
    @Input() description: string;
    @Input() name: string;
    @Input() mod: string;

    // fields:
    modifierText: string;
    inputControl = new FormControl();

    private propagateChange: (_) => void;

    ngOnInit() {
        this.modifierText = buildModifierText("n-radio_", this.mod);
        this.inputControl.valueChanges.subscribe((val) => {
            this.propagateChange(val);
        });
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(val: any): void {
        // this.checked = val === this.value;
        this.inputControl.setValue(val, {emitEvent: false});
    }

}
