import {APP_INITIALIZER, Injector, NgModule} from "@angular/core";
import {AppComponent} from "./app.component";
import {RouterModule} from "@angular/router";
import {CoreModule} from "./core/core.module";
import {ContextRoutingModule} from "./modules/routing";
import {routes} from "./app.routes";
import {AppContextualRoutesAdapter} from "./core/services/app-contextual-routes-adapter.service";
import {NPopoversModule} from "./modules/n-popover";
import {ModalsModule} from "./modules/modal";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {PortalsModule} from "./modules/portals";
import {BrowserModule} from "@angular/platform-browser";
import {TranslateModule} from "./modules/translate";
import {PreloadModuleStrategy} from "./PreloadModuleStrategy";
import {initializeApp} from "./initializeApp";
import {TouchModule} from "./modules/touch";
import {ErrorLoggingModule, ExceptionLogger} from "./modules/error-logging";
import {AppExceptionLogger} from "./app-exception-logger.service";

// const Workbox = require("workbox-window/build/workbox-window.prod.mjs").Workbox;


@NgModule({
    declarations: [
        AppComponent
    ], // directives, components, and pipes owned by this NgModule
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes,
            {
                useHash: false,
                preloadingStrategy: PreloadModuleStrategy
            }),
        NPopoversModule.forRoot(),
        ModalsModule,
        PortalsModule,
        TranslateModule,
        ContextRoutingModule.decorateRouter(AppContextualRoutesAdapter),
        BrowserAnimationsModule,
        TouchModule,

        CoreModule.forRoot(),
        ErrorLoggingModule.forRoot()
    ],
    providers: [
        PreloadModuleStrategy,
        {provide: ExceptionLogger, useClass: AppExceptionLogger},
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [Injector],
            multi: true
        }
    ], // additional providers
    bootstrap: [AppComponent]
})
export class AppModule {
}
