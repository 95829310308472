import {Injectable} from "@angular/core";
import {StorageMode, StorageService} from "./storage.service";
import {Observable, Subject} from "rxjs";
import {buildFullUrl} from "./UrlHelper";
import {FailServerResponse} from "../models/FailServerResponse";
import {HttpClient, HttpHeaders} from "@angular/common/http";

const STORAGE_TOKENKEY = "__accessToken";
const STORAGE_EXPIRESKEY = "__tokenExpires";


export interface IAuthService {
    onSignOut: Observable<any>;
    onSignIn: Observable<any>;

    authentificate(username: string, password: string, persistent: boolean): Promise<any>;

    ensureToken(): Promise<string>;

    isAuthenticated(): boolean;

    signOut();

}

@Injectable({providedIn: "root"})
export class AuthService implements IAuthService {

    onSignOut: Subject<any> = new Subject<any>();
    onSignIn: Subject<any> = new Subject<any>();

    private creds: ICredentialsAdapter;
    private tokenAwaiters: DeferOperation[] = [];
    private requestingToken: boolean;
    private signedIn: boolean;
    private persistent: boolean;
    private tokenObtaining: Promise<any>;

    constructor(private storage: StorageService,
                private http: HttpClient) {
        this.signedIn = this.isAuthenticated();
    }

    authentificate(username: string, password: string, persistent: boolean): Promise<any> {
        // let creds = "username=" + username + "&password=" + password;

        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        const headers = new HttpHeaders({"enctype": "multipart/form-data"});

        return this.http.post(buildFullUrl("api/token"), formData, {headers})
            .toPromise()
            .catch(res => {
                console.error(res);
                throw new FailServerResponse(res);
            })
            .then((data) => {
                this.persistent = persistent;
                this.authentificateByToken(data["access_token"], data["expires_in"], persistent);
            });
    }

    authentificateByToken(token: string, expiresIn: number, persistent?: boolean) {

        if (typeof (persistent) === "undefined") persistent = this.persistent;

        this.saveToken(token, expiresIn, persistent);

        this.tokenAwaiters.forEach(a => a.resolve());
        this.tokenAwaiters = [];
        this.requestingToken = false;

        this.signedIn = true;
        this.onSignIn.next(1);
        this.closeAuthModal();
    }

    ensureAuthenticated(): Promise<any> {
        return this.ensureToken();
    }

    /**
     *  Retreive actual auth token
     */
    async ensureToken(): Promise<string> {
        let token = this.readToken();

        if (!token) {
            if (this.tokenObtaining) {
                // token may obtaining due external sign-in
                try {
                    await this.tokenObtaining;
                    token = this.readToken();
                    if (token) {
                        return token;
                    }
                } catch (e) {

                }
            }
            // todo make renewval request
            await this.requestCredentials();
            token = this.readToken();
            if (!token) {
                // unable to get token
                throw "Unable to get token.";
            }
            return token;
        } else {
            return token;
        }
    }

    externalSignin(providerName: string) {
        const returnUrl = encodeURIComponent(location.pathname + location.search);
        const form = document.createElement("form");
        form.method = "post";
        form.action = `/api/external/${providerName}/authorize?returnUrl=${returnUrl}`;
        document.body.appendChild(form);
        form.submit();
    }

    hasAnyToken(): boolean {
        let token = this.storage.getItem<string>(STORAGE_TOKENKEY);
        return !!token;
    }

    isAuthenticated() {
        return !!this.readToken();
    }

    readToken(): string | undefined {
        let token = this.storage.getItem<string>(STORAGE_TOKENKEY);
        if (!token) {
            return undefined;
        }
        if (this.isTokenExpired()) {
            return undefined;
        }
        return token;
    }

    // avoid cyclic deps
    setCredentialsAdapter(credentials: ICredentialsAdapter) {
        this.creds = credentials;
    }

    signOut() {
        if (this.signedIn) {
            this.signedIn = false;
            this.onSignOut.next(1);
        }
    }

    /**
     * Blocks auth request due token obtaining
     */
    startTokenObtaining(obtainFunc: () => Promise<void>) {
        this.tokenObtaining = new Promise((resolve, reject) => {
            obtainFunc().then(() => {
                this.tokenObtaining = undefined;
                resolve(undefined);
            }, () => {
                this.tokenObtaining = undefined;
                reject();
            });
        });
    }

    tokenExpired(): Promise<void> {
        this.storage.clearAll(); // Удаляем испорченный токен
        return this.ensureAuthenticated();
    }

    private closeAuthModal() {
        this.creds.cancelRequest();
    }

    private isTokenExpired(): boolean {
        let expireTicks = this.storage.getItem<number>(STORAGE_EXPIRESKEY);
        if (!expireTicks || !isNumber(expireTicks)) {
            return true;
        } else {
            let expiredDate = new Date(expireTicks);
            if (Date.now() > expiredDate.getTime()) {
                // expired
                return true;
            }
        }
        return false;
    }

    private async requestCredentials(): Promise<any> {
        if (!this.creds) {
            throw "Auth component not defined.";
        }

        if (this.requestingToken) {
            return new Promise((resolve, reject) => {
                this.tokenAwaiters.push(new DeferOperation(resolve, reject));
            });
        }

        this.requestingToken = true;
        return this.creds.requestCredentials();
    }

    private saveToken(token: string, expiresInSeconds: number, persistent: boolean) {
        const storageMode = persistent ? StorageMode.PERSISTENT : StorageMode.SESSION;

        this.storage.save(STORAGE_TOKENKEY, token, storageMode);

        let expiredDate = new Date();
        expiredDate.setSeconds(expiredDate.getSeconds() + expiresInSeconds);
        this.storage.save(STORAGE_EXPIRESKEY, expiredDate.getTime(), storageMode);
    }
}


function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

class DeferOperation {

    constructor(public resolve: Function, public reject: Function) {

    }

}

/**
 * Abstraction from sign-in logic, Sign-in logic may be different for different user domains
 * built-in logic (default) show sign-in form,
 * if current domain requires external aith, then apps make redirect
 */
export interface ICredentialsAdapter {
    cancelRequest();

    requestCredentials(): Promise<any>;
}
