import {Component} from "@angular/core";

@Component({
    selector: "no-content-page",
    template: `
        <page-message [iconId]="'page-not-found'" [messageKey]="'common.pageNotFound'"></page-message>
    `
})
export class NoContentPageComponent {

}
