import {ComponentFactory, ComponentFactoryResolver, Injectable, Injector, Type} from "@angular/core";
import {ContextualRoutesAdapter} from "../../modules/routing";
import {ContextualRouteInfo} from "../../modules/routing/ContextualRouteInfo";
import {ModalInstance, ModalService} from "../../modules/modal";
import {ActivatedRoute} from "@angular/router";
import {isModalWrapperDisabled} from "../../modules/routing/ContextualRouteMap";

const NAVIGATING = "navigating";

@Injectable({providedIn: "root"})
export class AppContextualRoutesAdapter extends ContextualRoutesAdapter {

    private static modalData: any;
    private openedModals: IOpenedModals = {};

    constructor(private modal: ModalService) {
        super();
    }

    activate(cr: ComponentFactory<any>,
             inj: Injector,
             resolver: ComponentFactoryResolver,
             contextualRoute: ContextualRouteInfo) {

        this.closeAll(); // only one opened context modal

        const instance = this.modal.open(<Function>contextualRoute.route.component, {
            resolver: resolver,
            injector: inj,
            bindings: [
                {provide: ActivatedRoute, useValue: contextualRoute.route},
                {provide: ContextualRouteInfo, useValue: contextualRoute}
            ],
            disableWrapper: isModalWrapperDisabled(contextualRoute.route.component as Type<any>)
        }, AppContextualRoutesAdapter.modalData);

        instance.result
            .then(() => {
                this.restoreContext();
            })
            .catch((reason) => {
                if (reason !== NAVIGATING) {
                    this.restoreContext();
                }
            });

        this.openedModals[contextualRoute.id] = instance;

        AppContextualRoutesAdapter.modalData = undefined;
    }

    deactivate(contextualRoute: ContextualRouteInfo) {
        this.closeModal(contextualRoute.id);
    }

    /**
     * Pass additional data to context route
     * @param data      data to pass
     */
    setContextRouteData(data: any) {
        AppContextualRoutesAdapter.modalData = data;
    }

    private closeAll() {
        for (let routeId of Object.keys(this.openedModals)) {
            this.closeModal(routeId);
        }
    }

    private closeModal(routeId: string) {
        if (this.openedModals[routeId]) {
            this.openedModals[routeId].close(NAVIGATING);
            delete this.openedModals[routeId];
        }
    }

}


interface IOpenedModals {
    [id: string]: ModalInstance<any>;
}
