import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {UserRef} from "../../models/UserRef";
import {TeamRef} from "../../models/TeamRef";

@Component({
    selector: "user-link",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<a [routerLink]="link">
        <user-name [item]="_item"></user-name>
    </a>`
})
export class UserLinkComponent {

    link: string[];

    constructor() {
    }

    _item: UserRef | TeamRef;

    @Input() set item(val: UserRef | TeamRef) {
        this._item = val;
        if (val instanceof UserRef) {
            this.link = ["/", "profile", val.username];
        } else {
            this.link = ["/", val.name];
        }
    }

}
