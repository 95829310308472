<div class="side-menu__logo">
    <n-icon [iconId]="'main-logo'"></n-icon>
</div>

<div class="side-menu__items">

    <div class="side-menu__common">
        <!--<side-menu-item type="common" iconId="inbox-common" [text]="'sidemenu.inbox' | translate"-->
        <!--[route]="['/']">-->
        <!--<n-icon iconId="inbox-common"></n-icon>-->
        <!--</side-menu-item>-->
        <!--<side-menu-item type="common" [text]="'sidemenu.starred' | translate"-->
        <!--[route]="['/favorites']">-->
        <!--<n-icon iconId="starred-common"></n-icon>-->
        <!--</side-menu-item>-->
        <side-menu-item [route]="['/']" [text]="'sidemenu.recent' | translate" [type]="'common'">
            <n-icon [fillColor]="'rgba(0,0,0,.4)'" [iconId]="'recent-common'" class="side-menu__sm-icon"></n-icon>
        </side-menu-item>

        <side-menu-item [route]="['/shared']"
                        [text]="'sidemenu.shared' | translate"
                        [type]="'common'">
            <n-icon [fillColor]="'rgba(0,0,0,.4)'" [iconId]="'share'" class="side-menu__sm-icon"></n-icon>
        </side-menu-item>

        <side-menu-item [route]="['/starred']"
                        [text]="'sidemenu.starred' | translate"
                        [type]="'common'">
            <n-icon [fillColor]="'rgba(0,0,0,.4)'" [iconId]="'star'" class="side-menu__sm-icon"></n-icon>
        </side-menu-item>

        <side-menu-item (click)="openSearch($event)"
                        (hotKeyEvent)="openSearch($event)"
                        [hotKeyLabel]="searchHotKey"
                        [hotKey]="'Ctrl+p'"
                        [text]="'sidemenu.search' | translate"
                        [type]="'common'">
            <n-icon [fillColor]="'rgba(0,0,0,.4)'" [iconId]="'search-docs'" class="side-menu__sm-icon"></n-icon>
        </side-menu-item>


    </div>

    <div *ngIf="currentUser$|async" class="side-menu__user">
        <side-menu-item [route]="['profile', (currentUser$|async).username]"
                        [text]="(currentUser$|async).toFullname()"
                        type="avatar">
            <user-avatar [item]="(currentUser$|async)" [size]="'small'"></user-avatar>
        </side-menu-item>

        <div #list (cdkDropListDropped)="drop($event)" cdkDropList>
            <side-menu-item *ngFor="let notebook of notebooks$|async; trackBy: noteBookIdentity"
                            [cdkDragBoundary]="list"
                            [cdkDragLockAxis]="'y'"
                            [cdkDragStartDelay]="dragDelay"
                            [route]="['/profile', (currentUser$|async).username, 'nb', notebook.name]"
                            [text]="notebook.title"
                            [type]="'item'"
                            cdkDrag
                            disableMobileContextMenu>
                <n-icon [iconId]="'notebook-icon'" [style.fill]="notebook.cssColor"></n-icon>
            </side-menu-item>
        </div>
    </div>

    <div #teamList (cdkDropListDropped)="dropTeam($event)" *ngIf="teams$|async" cdkDropList class="side-menu__teams">
        <div *ngFor="let pinnedTeam of teams$|async; trackBy: teamInfoIdentity"
             [cdkDragLockAxis]="'y'"
             [cdkDragStartDelay]="dragDelay"
             cdkDrag
             class="side-menu__team"
             disableMobileContextMenu>
            <side-menu-item [invited]="pinnedTeam.team.currentUserInviting"
                            [route]="[pinnedTeam.team.name]"
                            [text]="pinnedTeam.team.title"
                            [type]="'avatar'">
                <user-avatar [item]="pinnedTeam.team" [size]="'small'"></user-avatar>
            </side-menu-item>

            <div #list (cdkDropListDropped)="dropTeamNotebook(pinnedTeam, $event)" cdkDropList>
                <side-menu-item *ngFor="let notebook of pinnedTeam.pinnedNotebooks; trackBy: noteBookIdentity"
                                [cdkDragBoundary]="list"
                                [cdkDragLockAxis]="'y'"
                                [cdkDragStartDelay]="dragDelay"
                                [route]="[pinnedTeam.team.name, 'nb', notebook.name]"
                                [text]="notebook.title"
                                [type]="'item'"
                                cdkDrag
                                disableMobileContextMenu>
                    <n-icon [iconId]="'notebook-icon'" [style.fill]="notebook.cssColor"></n-icon>
                </side-menu-item>
            </div>
        </div>
    </div>

    <div *ngIf="currentUser$|async" class="side-menu__new-team">
        <button (click)="createTeam()" class="side-menu__new-item-button" type="button">
            <span class="side-menu__new-item-button-icon">
                <n-icon [iconId]="'new-team'"></n-icon>
            </span>
            <span class="side-menu__new-item-button-text">{{'sidemenu.newteam' | translate}}</span>
        </button>
    </div>

</div>

<div class="side-menu__footer">
    <a class="side-menu__footer-link" href="tg://resolve?domain=renoteChangelog" target="_blank">v. {{appVersion}}</a>
    <button #helpButton (click)="openHelp(help)" class="side-menu__footer-link"
            type="button">{{'sidemenu.help' | translate}}</button>
    <button (click)="signout()" class="side-menu__footer-link" type="button">{{'sidemenu.signout' | translate}}</button>
</div>

<ng-template #help>
    <div class="side-menu__help-wrapper">
        <h2>{{'sidemenu.help' | translate}}</h2>

        <p>
            {{'help.hint' | translate }} <a href="mailto:support@renote.team">support@renote.team</a>
        </p>
    </div>
</ng-template>
