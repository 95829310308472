// BOOTSTRAP LISTENER STARTS NECESSARY SERVICES

import {Injector} from "@angular/core";
import {WsConnectionManager} from "./services/websockets/WsConnectionManager";

export function startCoreServices(injector: Injector) {

    let wsManager = injector.get(WsConnectionManager) as WsConnectionManager;
    wsManager.start();

    return () => Promise.resolve();
}
