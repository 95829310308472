import {DocumentMetadata} from "./DocumentMetadata";
import {CurrentUserDocumentPermissions} from "./CurrentUserDocumentPermissions";
import {NotebookInfo} from "../NotebookInfo";
import {DocumentListInfo} from "./DocumentListInfo";
import {BehaviorSubject, map, Observable} from "rxjs";
import {diffInDays} from "../../DateUtility";
import {DocumentAccess, DocumentAccessLevel} from "./DocumentAccess";
import {removeItemsFromArray} from "../../../shared/Utility";

/**
 * Number of days to permanently remove document from recycle
 */
export const DAYS_TO_REMOVE_DOCUMENT = 90;

export class DocumentInfo extends DocumentMetadata {

    permissions: CurrentUserDocumentPermissions;

    daysToPermanentlyRemove$: Observable<number | undefined>;

    // editRestrict: DocumentEditRestrict;
    private _isPublished: BehaviorSubject<boolean>;

    private _publishAccessLevel$: BehaviorSubject<DocumentAccessLevel>;
    // expiresIn: Date;
    private _removedDate$: BehaviorSubject<Date>;

    private _access$: BehaviorSubject<DocumentAccess[]>;

    get access$(): Observable<DocumentAccess[]> {
        return this._access$;
    }

    get starred(): boolean {
        return this._starred$.value;
    }

    get teamName(): string | undefined {
        if (!this.notebook) {
            return undefined;
        }
        return this.notebook.isTeamNotebook ? this.notebook.ownerTeam.name : undefined;
    }

    get notebook(): NotebookInfo | undefined {
        return this._notebook$.value;
    }

    get publishAccessLevel(): Observable<DocumentAccessLevel> {
        return this._publishAccessLevel$;
    }

    get removedDate(): Date | undefined {
        return this._removedDate$.value;
    }

    private _removed$: BehaviorSubject<boolean>;

    get removed$(): Observable<boolean> {
        return this._removed$;
    }

    private _watching$: BehaviorSubject<boolean>;

    get watching$(): Observable<boolean> {
        return this._watching$;
    }

    private _starred$: BehaviorSubject<boolean>;

    get starred$(): Observable<boolean> {
        return this._starred$;
    }

    private _notebook$: BehaviorSubject<NotebookInfo>;

    get notebook$(): Observable<NotebookInfo> {
        return this._notebook$;
    }

    get published(): boolean {
        return this._isPublished.value;
    }

    get published$(): Observable<boolean> {
        return this._isPublished;
    }

    static parse(data: any): DocumentInfo {
        let doc = DocumentMetadata.parse(data, new DocumentInfo()) as DocumentInfo;

        // doc.editors = DocumentAccess.parseArray(data.editors);
        doc._removed$ = new BehaviorSubject<boolean>(!!data.removed);

        doc._removedDate$ = new BehaviorSubject(data.removedDate ? new Date(data.removedDate) : undefined);

        // doc.tags = data.tags ? DocumentTag.parseArray(data.tags) : [];
        doc.permissions = data.permissions ? CurrentUserDocumentPermissions.parse(data.permissions) : undefined;
        // doc.expiresIn = new Date(data.expiresIn);
        // doc.editRestrict = data.editRestrict || "allViewers";
        doc._isPublished = new BehaviorSubject<boolean>(!!data.isPublished);
        doc._notebook$ = new BehaviorSubject(data.notebook ? NotebookInfo.parse(data.notebook) : undefined);
        doc._watching$ = new BehaviorSubject(!!data.watching);
        doc._starred$ = new BehaviorSubject(!!data.starred);
        doc._access$ = new BehaviorSubject(data.editors ? DocumentAccess.parseArray(data.editors) : []);
        doc._publishAccessLevel$ = new BehaviorSubject<DocumentAccessLevel>(data.publishAccessLevel || "readOnly");
        doc.daysToPermanentlyRemove$ = doc._removedDate$
            .pipe(
                map(date => calcDaysToRemove(date))
            );

        return doc;
    }

    addAccess(editors: DocumentAccess[]) {
        let newEditors = this._access$.value.slice(0);
        // remove from array existing access and replace it
        removeItemsFromArray(newEditors, ne => editors.some(e => e.user.username === ne.user.username));
        newEditors = editors.concat(newEditors);
        this._access$.next(newEditors);
    }

    markNotRemoved() {
        this._removed$.next(false);
        this._removedDate$.next(undefined);
    }

    markRemoved() {
        this._removed$.next(true);
        this._removedDate$.next(new Date());
    }

    moveTo(notebook: NotebookInfo) {
        this._notebook$.next(notebook);
    }

    publish(publishAccessLevel: DocumentAccessLevel) {
        this._isPublished.next(true);
        this._publishAccessLevel$.next(publishAccessLevel);
    }

    removeAccess(editors: DocumentAccess[]) {
        const newEditors = this._access$.value.slice(0);
        removeItemsFromArray(newEditors, ne => editors.some(e => e.user.username === ne.user.username));
        this._access$.next(newEditors);
    }

    setStarred(starred: boolean) {
        this._starred$.next(starred);
    }

    setWatched(watch: boolean) {
        this._watching$.next(watch);
    }

    toListInfo(): DocumentListInfo {
        const info = DocumentListInfo.parse({
            id: this.id,
            title: this.title,
            snippet: "",
            previewImageUrl: "",
            tags: [],
            createdDate: this.createdDate,
            notebookName: this.notebook ? this.notebook.name : undefined
        });
        info.createdBy = this.createdBy;
        return info;
    }

    unpublish() {
        this._isPublished.next(false);
        this._publishAccessLevel$.next(DocumentAccessLevel.ReadOnly);
    }
}

const now = new Date();

function calcDaysToRemove(removedDate: Date) {
    if (!removedDate) {
        return 0;
    }
    const diff = diffInDays(now, removedDate);
    const daysToRemove = DAYS_TO_REMOVE_DOCUMENT - diff;
    return daysToRemove > 0 ? daysToRemove : 1;
}
