import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class PopoverConfig {

    public paddings: { left: number, right: number, top: number, bottom: number } = {
        top: 50, left: 20, right: 20, bottom: 20
    };

}
