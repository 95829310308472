import {AfterViewInit, ApplicationRef, ContentChild, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, Renderer2} from "@angular/core";
import {ScrollService} from "../services/scroll.service";
import {fromEvent, Subscription} from "rxjs";

const STICKY_CLASSNAME = "sticky-block";
const STICKIED_CLASSNAME = "_stickied";
export const STICKY_MOBILE_WIDTH = 1024;

@Directive({
    selector: "[stickyBlock]"
})
export class StickyBlockDirective implements OnDestroy {

    @Input() stickyEject: boolean;
    @Output() stickyChanged = new EventEmitter<boolean>();
    private readonly element: HTMLElement;
    private height: number;
    private offset: number;
    private container: HTMLElement;
    private ejected: boolean;
    private zeroOffset: boolean;

    constructor(el: ElementRef,
                private appRef: ApplicationRef,
                private renderer: Renderer2) {
        this.element = el.nativeElement;
    }

    @Input() set stickyZeroOffset(val: boolean) {
        if (val) {
            this.offset = 0;
            this.translate(0);
        }
        this.zeroOffset = val;
    }

    move(diff: number) {
        let offset = this.offset + diff;
        if (offset > 0) {
            offset = 0;
        }
        this.translate(offset);
    }

    ngOnDestroy(): void {
        if (this.ejected && this.element.parentElement) {
            this.element.parentElement.removeChild(this.element);
        }
    }

    stick(translate: boolean) {

        this.stickyChanged.emit(true);
        if (translate) {
            this.height = this.element.getBoundingClientRect().height;
            this.translate(-this.height);
        } else {
            this.translate(0);
        }
        this.renderer.addClass(this.element, STICKIED_CLASSNAME);
        if (this.stickyEject) {
            this.container = this.element.parentElement;
            this.appRef.components[0].location.nativeElement.appendChild(this.element);
            this.ejected = true;
        }
    }

    unstick() {
        this.translate(0);
        if (this.ejected) {
            this.container.appendChild(this.element);
            this.container = undefined;
        }
        this.renderer.removeClass(this.element, STICKIED_CLASSNAME);
        this.stickyChanged.emit(false);
    }

    private translate(offset: number) {
        if (this.zeroOffset) {
            return;
        }
        if (Math.abs(offset) > this.height) {
            offset = -this.height;
        }
        if (this.offset === offset) {
            return;
        }
        this.offset = offset;
        if (offset !== 0) {
            this.renderer.setStyle(this.element, "transform", `translateY(${offset}px)`);
        } else {
            this.renderer.removeStyle(this.element, "transform");
        }
    }

}

@Directive({
    selector: "[stickyContainer]"
})
export class StickyContainerDirective implements AfterViewInit, OnDestroy {

    @Input()
    stickyContainer: "top";

    @Input()
    stickyScrollElement: Element;

    @Input()
    stickOnRevertScroll: boolean;

    @Input()
    stickOnRevertScrollMobile: boolean;

    @ContentChild(StickyBlockDirective, {static: false})
    stickyBlock: StickyBlockDirective;

    private readonly element: HTMLElement;
    private scrollContainer: Element;
    private lastScrollTop: number;
    private isSticky: boolean;
    private scrollSub: Subscription;
    private resizeSub: Subscription;

    constructor(el: ElementRef,
                private renderer: Renderer2,
                private zone: NgZone,
                private scrollSvc: ScrollService) {
        this.element = el.nativeElement;
    }

    ngAfterViewInit() {
        this.scrollContainer = this.stickyScrollElement || this.scrollSvc.getScrollElement();
        this.zone.runOutsideAngular(() => {
            requestAnimationFrame(() => this.onScroll());
            this.scrollSub = fromEvent(this.scrollContainer, "scroll", {passive: true})
                .subscribe(() => {
                    requestAnimationFrame(() => this.onScroll());
                });

            this.resizeSub = fromEvent(window, "resize", {passive: true})
                .subscribe(() => this.onResize());
        });
    }

    ngOnDestroy() {
        this.scrollSub.unsubscribe();
        this.resizeSub.unsubscribe();
    }

    onResize() {
        this.onScroll();
    }

    onScroll() {
        let rect = this.element.getBoundingClientRect();

        if (!rect.width && !rect.height) {
            return;
        }

        let stick: boolean, useTranslate: boolean = false;

        if (this.stickOnRevertScroll) {
            if (this.stickOnRevertScrollMobile) {
                if (window.innerWidth < STICKY_MOBILE_WIDTH) {
                    stick = rect.bottom < 0;
                    useTranslate = true;
                } else {
                    stick = rect.top <= 0;
                }
            } else {
                stick = rect.bottom < 0;
                useTranslate = true;
            }
        } else {
            stick = rect.top <= 0;
        }

        if (stick) {
            if (useTranslate) {
                const scrollTop = this.scrollContainer instanceof Window ?
                    this.scrollContainer.scrollY :
                    this.scrollContainer.scrollTop;

                if (typeof (this.lastScrollTop) === "undefined") {
                    this.lastScrollTop = scrollTop;
                } else {
                    const diff = scrollTop - this.lastScrollTop;
                    this.stickyBlock.move(-(diff / 2));
                    this.lastScrollTop = scrollTop;
                }
            }

            this.stick(rect.height, useTranslate);
        } else {
            this.unStick();
        }
    }

    update() {
        this.onScroll();
    }

    private stick(height: number, useTranslate: boolean) {
        if (this.isSticky) {
            return;
        }
        this.isSticky = true;
        this.stickyBlock.stick(useTranslate);
        this.renderer.addClass(this.element, STICKY_CLASSNAME);
        this.renderer.setStyle(this.element, "height", height + "px");
    }

    private unStick() {
        if (!this.isSticky) {
            return;
        }
        this.isSticky = false;
        this.stickyBlock.unstick();
        this.renderer.removeStyle(this.element, "height");
        this.renderer.removeClass(this.element, STICKY_CLASSNAME);
    }
}
