import {AsyncValidatorFn, FormControl} from "@angular/forms";
import {UserService} from "../services/user.service";

const validEmailRegex = /.+@.+\..+/;

export function validateEmail(userSvc: UserService, shouldExists?: boolean): AsyncValidatorFn {

    let tmrId;

    return (c: FormControl): Promise<any> => {
        if (c.invalid) {
            return Promise.resolve(undefined);
        }

        const email = c.value;
        if (!validEmailRegex.test(email)) {
            return Promise.resolve({
                invalidEmail: true
            });
        }

        if (tmrId) {
            clearTimeout(tmrId);
            tmrId = undefined;
        }

        return new Promise((resolve) => {

            tmrId = setTimeout(() => {
                tmrId = undefined;
                userSvc.emailExists(email)
                    .then((result) => {
                        if ((result && !shouldExists) || (!result && shouldExists)) {
                            resolve({
                                emailExists: true
                            });
                        } else {
                            resolve(undefined);
                        }
                    })
                    .catch(() => {
                        // unable to check
                        resolve(undefined);
                    });
            }, 500);
        });
    };
}
