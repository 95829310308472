import {Attribute, ComponentFactory, ComponentFactoryResolver, ComponentRef, Directive, EventEmitter, Injector, Output, ViewContainerRef} from "@angular/core";
import {ActivatedRoute, PRIMARY_OUTLET} from "@angular/router";
import {ContextualRouteService} from "../ContextualRouteService";
import {IDynamicRouteComponent} from "../IDynamicRouteComponent";

@Directive({selector: "contextual-router-outlet"})
export class ContextualRouterOutlet {

    @Output() activated = new EventEmitter<ComponentRef<any>>();
    private activeCompomnent: ComponentRef<any>;
    private renderedPath: string;
    private name: string;

    /**
     * @internal
     */
    constructor(public location: ViewContainerRef,
                private routeService: ContextualRouteService,
                private resolver: ComponentFactoryResolver,
                @Attribute("name") name: string) {
        this.name = name || PRIMARY_OUTLET;
        routeService.registerOutlet(this.name, this);
    }

    private _activatedRoute: ActivatedRoute;

    get activatedRoute(): ActivatedRoute {
        if (!this.activeCompomnent) return undefined;
        return this._activatedRoute;
    }

    get routeTitle(): string {
        if (!this.activeCompomnent) {
            return undefined;
        }
        let instance = this.activeCompomnent.instance as IDynamicRouteComponent;
        if (instance.getRouteTitle) {
            return instance.getRouteTitle();
        }
        return undefined;
    }

    get isActivated(): boolean {
        return !!this.activeCompomnent;
    }

    get component(): Object {
        if (!this.activeCompomnent) return undefined;
        return this.activeCompomnent.instance;
    }

    activateWith(activatedRoute: ActivatedRoute,
                 resolver: ComponentFactoryResolver | null) {

        const snapshot = activatedRoute["_futureSnapshot"];
        const component: any = <any>snapshot.component;

        let factory: ComponentFactory<any>;
        if (resolver) {
            factory = resolver.resolveComponentFactory(component);
        } else {
            factory = this.resolver.resolveComponentFactory(component);
        }

        // const injector = new OutletInjector(activatedRoute, this.location.injector);
        const inj = Injector.create({
            providers: [
                {provide: ActivatedRoute, useValue: activatedRoute}
            ],
            parent: this.location.injector
        });

        this.routeService.navigate(factory, inj, resolver, activatedRoute);

        // ------------
        // if (this.isActivated) {
        //     throw new Error('Cannot activate an already activated outlet');
        // }
        //
        // this.outletMap = outletMap;
        // this._activatedRoute = activatedRoute;
        //
        // const snapshot = activatedRoute._futureSnapshot;
        // const component = <any>snapshot._routeConfig.component;
        //
        // resolver = resolver || this.resolver;
        // const factory = resolver.resolveComponentFactory(component);
        //
        // const injector = new OutletInjector(activatedRoute, outletMap, this.location.injector);
        //
        // this.activated = this.location.createComponent(factory, this.location.length, injector, []);
        // this.activated.changeDetectorRef.detectChanges();
        //
        // this.activateEvents.emit(this.activated.instance);
    }

    deactivate(): void {
        // do nothing...
    }

    ngOnDestroy(): void {
        this.routeService.removeOutlet(this.name);
    }

    render(factory: ComponentFactory<any>, inj: Injector, activatedRoute: ActivatedRoute) {
        this._activatedRoute = activatedRoute;

        let routeToRender = activatedRoute.snapshot;

        if (typeof (this.renderedPath) !== "undefined" && this.renderedPath === routeToRender.routeConfig.path) {
            // allready rendered
            this.invokeRouteUpdate(this.activeCompomnent.instance, this._activatedRoute);
            this.activated.emit(this.activeCompomnent);
            return;
        }

        this.renderedPath = activatedRoute.snapshot.routeConfig.path;

        if (this.activeCompomnent) {
            this.activeCompomnent.destroy();
        }

        this.activeCompomnent = this.location.createComponent(factory, this.location.length, inj, []);
        this.activeCompomnent.changeDetectorRef.detectChanges();

        this.invokeRouteUpdate(this.activeCompomnent.instance, this._activatedRoute);

        this.activated.emit(this.activeCompomnent);
    }

    private invokeRouteUpdate(cmp: IDynamicRouteComponent, route: ActivatedRoute) {
        if (cmp.routeUpdated) {
            cmp.routeUpdated(route);
        }
    }

}


class OutletInjector implements Injector {
    constructor(private route: ActivatedRoute, private parent: Injector) {
    }

    get(token: any, notFoundValue?: any): any {
        if (token === ActivatedRoute) {
            return this.route;
        }
        return this.parent.get(token, notFoundValue);
    }
}
