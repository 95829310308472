import {ChangeDetectionStrategy, Component} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {validateEmail} from "../../validation/validateEmail";
import {UserService} from "../../services/user.service";
import {ErrorService} from "../../services/error.service";
import {SignupResult} from "../../models/SignupResult";
import {TranslateService} from "../../../modules/translate";
import {validatePasswordsEquals} from "../../validation/validatePasswordEquals";
import {MemorySubject} from "../../../modules/store";
import {AppService} from "../../services/app.service";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: "sign-up-page",
    templateUrl: "./sign-up-page.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./sign-up-page.ng.css"
    ]
})
export class SignUpPageComponent {

    submitting$ = new BehaviorSubject(false);

    defaultEmail$ = new MemorySubject<string>(null);
    showForm$ = new MemorySubject<boolean>(false);
    form: FormGroup;

    private validatedToken: string;

    constructor(private authService: AuthService,
                private appSvc: AppService,
                private router: Router,
                private route: ActivatedRoute,
                private userSvc: UserService,
                private translateSvc: TranslateService,
                private errors: ErrorService) {
    }

    async ngOnInit() {

        this.form = new FormGroup({
            firstname: new FormControl("", [Validators.required, Validators.maxLength(32)]),
            lastname: new FormControl("", [Validators.required, Validators.maxLength(32)]),
            email: new FormControl("", {
                asyncValidators: [validateEmail(this.userSvc)],
                validators: [Validators.required, Validators.maxLength(64)],
                updateOn: "blur"
            }),
            password: new FormControl("", [Validators.required, Validators.maxLength(64), Validators.minLength(8)]),
            confirmPassword: new FormControl("", {
                validators: [Validators.required, Validators.maxLength(64)]
                // updateOn: "blur"
            })
        }, {validators: [validatePasswordsEquals("password", "confirmPassword")]});

        if (this.authService.isAuthenticated()) {
            await this.router.navigateByUrl("/", {replaceUrl: true});
            return;
        }

        const context = await this.appSvc.ensureContext();

        if (context.domain.externalProfiles) {
            // domain does not provide sign-up form...
            this.authService.ensureToken();
            return;
        }

        const token = this.route.snapshot.queryParams["t"];
        if (token) {
            this.userSvc.getEmailByInviteToken(token).then(email => {
                if (email) {
                    this.validatedToken = token;
                    this.defaultEmail$.next(email);
                    this.form.controls["email"].setValue(email);
                }
            });
        }
        this.showForm$.next(true);
    }

    async submit(form: { firstname: string, lastname: string, email: string, password: string }) {
        if (this.form.invalid) {
            return;
        }

        const info = {
            ...form,
            culture: this.translateSvc.getLocale(),
            token: this.validatedToken
        };

        this.submitting$.next(true);
        let result: SignupResult;

        try {
            result = await this.userSvc.signup(info);

            this.authService.authentificateByToken(result.token, result.tokenExpiresIn, true);

            await this.router.navigateByUrl("/");
        } catch (fail) {
            this.errors.showFail(fail);
            return;
        } finally {
            this.submitting$.next(false);
        }


    }

}
