import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, TemplateRef} from '@angular/core';
import {IPopoverOptions} from "../../IPopoverOptions";
import {PopoverService} from "../../PopoverService";
import {PopoverInstance} from "../../PopoverInstance";

@Directive({selector: '[nPopover]'})
export class NPopoverDirective implements OnDestroy {

    @Input()
    nPopover: TemplateRef<any>;

    @Input()
    nPopoverOptions: IPopoverOptions;

    @Output()
    popoverOpened = new EventEmitter();

    @Output()
    popoverClosed = new EventEmitter();

    private instance: PopoverInstance<any>;

    constructor(private popovers: PopoverService, private el: ElementRef) {
    }

    async close() {
        if (this.instance) {
            await this.instance.close();
            this.popoverClosed.emit();
        }
    }

    ngOnDestroy(): void {
        this.close();
    }

    @HostListener("mousedown")
    async open() {
        await this.popovers.closeAll();
        this.instance = this.popovers.open({
            templateRef: this.nPopover,
            popoverData: {},
            position: "bottom",
            targetElementRef: this.el,
            mobileView: true,
            hideArrow: true,
            ...(this.nPopoverOptions || {})
        });

        this.instance.result.then(result => {
            this.popoverClosed.emit(result);
            this.instance = undefined;
        });
    }

}
