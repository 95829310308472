import {CORE_ROUTES} from "./core/core.routes";
import {NoContentPageComponent} from "./core/pages/no-content-page";
import {Route} from "@angular/router";

export const routes: Route[] = [
    ...CORE_ROUTES,

    {
        path: "profile",
        loadChildren: () => import('./+profile/profile.module').then(m => m.ProfileModule)
    },

    {
        path: "",
        loadChildren: () => import('./+teams/teams.module').then(m => m.TeamsModule)
    },
    // temp route for code splitting
    {
        path: "$$doc$$",
        loadChildren: () => import('./+document/document.module').then(m => m.DocumentModule)
    },
    {path: "**", component: NoContentPageComponent}
];
