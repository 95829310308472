import {ExceptionInfo} from "./ExceptionInfo";
import {Injectable} from "@angular/core";

@Injectable()
export class ExceptionLogger {

    logExceptions(exceptions: ExceptionInfo[]): Promise<any> {
        console.error("Errors", exceptions);
        return Promise.resolve();
    }

}
