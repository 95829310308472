import {ChangeDetectionStrategy, Component, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "n-global-styles",
    template: "",
    styleUrls: [
        "./n-global-styles.bem.css"
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class NGlobalStylesComponent {

}
