import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {CredentialService} from "../../services/credential.service";

/**
 * This page reset router outlet content when user signout
 */

@Component({
    selector: "sign-out-page",
    template: `<page-message [messageKey]="'signout.processing'"></page-message>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignOutPageComponent implements OnInit {

    constructor(private authSvc: AuthService,
                private router: Router,
                private credentials: CredentialService) {
    }

    ngOnInit() {

        if (this.authSvc.isAuthenticated() || !this.credentials.signingOut) {
            // prevent for direct navigation
            this.router.navigate(["/"], {replaceUrl: true});
            return;
        }

    }
}
