export class CurrentUserDocumentPermissions {

    canEdit: boolean;
    canEditSettings: boolean;
    canComment: boolean;

    static parse(data: any): CurrentUserDocumentPermissions {
        const roles = new CurrentUserDocumentPermissions();

        roles.canComment = !!data.canComment;
        roles.canEdit = !!data.canEdit;
        roles.canEditSettings = !!data.canEditSettings;

        return roles;
    }
}
