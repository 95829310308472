import {EventEmitter, Injectable} from "@angular/core";
import {ITranslation} from "./ITranslation";
import {map, Observable} from "rxjs";
import {MemorySubject} from "../store";

// noinspection HtmlUnknownAttribute
export const TRANSLATION_NOT_FOUND = "<not found>";
const LOCALSTORAGE_KEY = "_locale";

@Injectable({providedIn: "root"})
export class TranslateService {

    private static cache = {};

    private languages: IRegisteredLanguages = {};

    private langChange$ = new EventEmitter<string>();
    private currentLocaleInternal$ = new MemorySubject<string>();
    private preferedLocale: string;
    private currentValues: any;

    get currentLocale$(): Observable<string> {
        return this.currentLocaleInternal$;
    }

    get currentLocaleSnapshot(): string {
        return this.currentLocaleInternal$.value;
    }

    get onLocaleChange(): EventEmitter<string> {
        return this.langChange$;
    }

    async addLocales(translations: ITranslation[]): Promise<any> {
        for (const translation of translations) {
            this.languages[translation.lang] = translation;
        }

        const defaultLocale = this.preferedLocale || localStorage.getItem(LOCALSTORAGE_KEY) || (navigator.language || navigator["userLanguage"]);
        await this.setLocale(defaultLocale, true);
    }

    elapsedTime(d: Date): string {
        return this.ensureCurrentTranslation().elapsedTime(d);
    }

    formatDate(d: Date, includeYear: boolean, includeTime: boolean): string {
        return this.ensureCurrentTranslation().formatDate(d, includeYear, includeTime);
    }

    getCurrentTranslation(): ITranslation {
        return this.ensureCurrentTranslation();
    }

    getLocale(): string {
        return this.currentLocaleInternal$.value;
    }

    /**
     * Returns translated string of key. Key must be ':' separated,
     * @param key               key of string, for instance "ui:groups:error"
     * @param params            key replacements
     * @returns         translated string
     */
    getString(key: string, params?: { [key: string]: any }): string {
        return this.getValue(key, params).toString();
    }

    getString$(key: string, params?: { [key: string]: any }): Observable<string> {
        return this.currentLocale$
            .pipe(
                map(() => this.getString(key, params))
            );
    }

    getValue(key: string, params?: { [key: string]: any }): any {
        let result: string;
        if (typeof (TranslateService.cache[key]) !== "undefined") {
            result = TranslateService.cache[key];
        } else {
            result = TranslateService.cache[key] = parseValue(key, this.currentValues);
        }
        if (params) {
            for (const pKey of Object.keys(params)) {
                result = result.replace(`$${pKey}`, params[pKey].toString());
            }
        }
        return result;
    }

    async setLocale(locale: string, force?: boolean): Promise<any> {
        if (!this.isSupported(locale) && !force) {
            this.preferedLocale = locale;
            return;
        }
        locale = this.ensureSupported(locale);

        if (this.currentLocaleInternal$.value === locale) {
            return;
        }

        this.currentValues = await (this.ensureTranslation(locale)).getValues();

        TranslateService.cache = {}; // drop cache
        this.langChange$.emit(locale);
        this.currentLocaleInternal$.next(locale);

        localStorage.setItem(LOCALSTORAGE_KEY, locale);
    }

    translateQuantity(num: number, key: string) {
        const val = this.getValue(key);
        return this.ensureCurrentTranslation().translateQuantity(num, val);
    }

    private ensureCurrentTranslation(): ITranslation {
        if (!this.currentLocaleInternal$.hasValue) {
            throw new Error("Current locale not set");
        }
        return this.ensureTranslation(this.currentLocaleInternal$.value);
    }

    private ensureSupported(locale: string): string {
        if (!locale) {
            return "en";
        }
        const culture = locale.replace(/-\w+/g, "");
        const lang = culture.toLowerCase();
        const supportedLocale = this.languages[lang];

        if (!supportedLocale) {
            return "en";
        }

        return lang;
    }

    private ensureTranslation(locale: string): ITranslation {
        const translation = this.languages[locale];
        if (!translation) {
            throw new Error(`Language ${locale} not registered`);
        }
        return translation;
    }

    private isSupported(locale: string) {
        return !!this.languages[locale];
    }
}

function parseValue(key: string, lang: any): any {
    const path = key.split(".");
    let current = lang;
    for (const p of path) {
        current = current[p];
        if (typeof (current) === "undefined") {
            return TRANSLATION_NOT_FOUND;
        }
    }
    return current;
}


interface IRegisteredLanguages {
    [lang: string]: ITranslation;
}
