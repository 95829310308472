import {ComponentFactoryResolver, Injectable, Injector, ViewContainerRef} from "@angular/core";
import {UserPickerItem} from "./UserPickerItem";
import {UserPickerItemComponent} from "./user-picker-item/user-picker-item.component";

@Injectable({providedIn: "root"})
export class UserPickerItemRendererFactory {

    constructor(private _cr: ComponentFactoryResolver) {

    }

    createItemRenderer(viewContainer: ViewContainerRef): (item: UserPickerItem) => Node {
        return (item: UserPickerItem): Node => {

            const injector = Injector.create([
                {provide: UserPickerItem, useValue: item}
            ], viewContainer.injector);

            let cmpFactory = this._cr.resolveComponentFactory(UserPickerItemComponent);
            let cmpRef = viewContainer.createComponent(cmpFactory, viewContainer.length, injector);
            cmpRef.changeDetectorRef.detectChanges();
            return cmpRef.location.nativeElement;
        };
    }

}
