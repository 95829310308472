import {Type} from "@angular/core";

const types = new Map();

export function registerContextualRoute(component: Type<any>, disableModalWrapper?: boolean) {
    types.set(component, disableModalWrapper);
}

export function isContextual(component: Type<any>): boolean {
    return types.has(component);
}

export function isModalWrapperDisabled(component: Type<any>): boolean {
    return types.has(component) && types.get(component);
}
