import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output} from "@angular/core";

const SCROLL_OFFSET = 60;

@Component({
    selector: "search-suggest-item",
    templateUrl: "./search-suggest-item.html",
    styleUrls: [
        "./search-suggest-item.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchSuggestItemComponent {

    @Input()
    suggest: any; //  SearchSuggestBlockInfo | SearchSuggestItem | SearchSuggestNodebookInfo;

    @Input()
    selected: boolean;
    @Input()
    hiddenCount: number;
    @Output()
    select = new EventEmitter();

    constructor(private el: ElementRef) {

    }

    private _focused: boolean;


    get focused(): boolean {
        return this._focused;
    }

    @Input()
    set focused(val: boolean) {
        this._focused = val;
        if (this._focused) {
            Promise.resolve().then(() => {
                // initial focus may prevent scroll
                this.scrollToViewport();
            });
        }
    }

    private scrollToViewport() {
        const scrollContainer = document.getElementsByTagName("search-suggest-modal")[0];
        if (!scrollContainer) {
            return;
        }
        const rect = this.el.nativeElement.getBoundingClientRect();
        if (rect.top < 0) {
            // noinspection JSSuspiciousNameCombination
            const diff = Math.abs(rect.top) + SCROLL_OFFSET;
            scrollContainer.scrollTop = scrollContainer.scrollTop - diff;
        } else if (rect.bottom > window.innerHeight) {
            const diff = rect.bottom - window.innerHeight + SCROLL_OFFSET;
            scrollContainer.scrollTop = scrollContainer.scrollTop + diff;
        }
    }
}
