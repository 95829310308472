<div class="icon">
    <ng-container [ngSwitch]="suggest.type">
        <n-icon *ngSwitchCase="'doc'" [fillColor]="'rgba(0,0,0,.6)'" [iconId]="'doc'"
                class="doc-icon"></n-icon>
        <user-avatar *ngSwitchCase="'user'" [item]="suggest" class="user-icon"></user-avatar>
    </ng-container>
</div>

<div class="text">
    <div class="title">
        {{suggest.title}}
    </div>
    <div class="info"><span class="notebook">{{suggest.notebookTitle}}</span></div>
</div>
