import {ComponentRef} from "@angular/core";

export class PopoverContext<TData> {

    popoverData: TData;

    instance: ComponentRef<any>;

    onDone: (result: any) => void;
    onCancel: () => void;
    onUpdatePosition: () => void;

    constructor(popoverData: TData) {
        this.popoverData = popoverData;
    }

    cancel() {
        this.onCancel();
    }

    done(result: any) {
        this.onDone(result);
    }

    updatePosition() {
        // Promise.resolve().then(() => {
        this.onUpdatePosition();
        // });
    }

}
