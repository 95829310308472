import {Injectable} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import {Location} from "@angular/common";
import {environment} from "../../../environments/environment";

declare const gtag: any;
declare const __GA_TRACKING_ID;

@Injectable({providedIn: "root"})
export class AnalyticsService {

    constructor(private router: Router, private location: Location) {

    }

    sendEvent(category: string, action: string, label: string, value?: any, paramsObj?: any) {
        if (isGAEnabledAndAvailable()) {
            gtag("event", action, {event_category: category, event_label: label, value, ...paramsObj});
        }
    }

    sendHit(url: string, options?: any) {
        if (isGAEnabledAndAvailable()) {
            gtag("config", __GA_TRACKING_ID, {
                page_path: url, ...options
            });
        }
    }

    startCollect() {
        if (!isGAEnabledAndAvailable()) {
            return;
        }
        gtag("set", {appVersion: window["__appVersion"]});

        this.router.events
            .pipe(
                filter(e => e instanceof NavigationEnd)
            )
            .subscribe((e: NavigationEnd) => {
                this.sendHit(this.location.path());
            });
    }

}

export function isGAEnabledAndAvailable(): boolean {
    return environment.enableAnalytics && typeof gtag === "function";
}
