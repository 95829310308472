import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl} from "@angular/forms";
import {concat, map, Observable, of} from "rxjs";
import {EntityColor, getEntityCssColor, getRandomEntityColor} from "../../models/EntityColor";

@Component({
    selector: "new-notebook-action",
    templateUrl: "new-notebook-action.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./new-notebook-action.ng.css"
    ]
})
export class NewNotebookActionComponent implements OnInit {


    @Input() text: string;
    @Output() create = new EventEmitter<EntityColor>();

    colorPicker = new FormControl(getRandomEntityColor());
    color$: Observable<string>;
    isColorPickerOpened: boolean;

    constructor() {
    }

    get selectedColor(): EntityColor {
        return this.colorPicker.value;
    }

    createNotebook() {
        this.create.next(this.colorPicker.value);
    }

    ngOnInit() {
        this.color$ = concat(of(this.colorPicker.value), this.colorPicker.valueChanges)
            .pipe(
                map(c => getEntityCssColor(c))
            );
    }

    toggleColorPicker() {
        this.isColorPickerOpened = !this.isColorPickerOpened;
    }
}
