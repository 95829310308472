import {ChangeDetectionStrategy, Component, ElementRef, Input} from "@angular/core";
import {HtmlProcessorService} from "../../services/html-processor.service";

@Component({
    selector: "message-text",
    template: `
        <div trackHrefNavigation [bindMentions]="html" [innerHTML]="html"></div>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageTextComponent {

    @Input() emails: boolean;
    @Input() links: boolean;
    @Input() mentions: boolean;

    html: string;

    constructor(private el: ElementRef,
                private htmlProcessor: HtmlProcessorService) {
    }

    @Input()
    set set(val: string) {
        this.processText(val);
    }

    private _text: string;

    @Input()
    set text(val: string) {
        this._text = val;
        this.processText(val);
    }

    private processText(text: string) {
        if (!text) {
            return;
        }

        this.html = this.htmlProcessor.processText(text, {
            links: this.links,
            mentions: this.mentions,
            emails: this.emails
        });
    }
}
