import {UserRef} from "./UserRef";
import {TeamMemberRole} from "./TeamInfo";

export class TeamMember {

    readonly user: UserRef;
    readonly inviting: boolean;

    constructor(user: UserRef, role: TeamMemberRole, inviting: boolean) {
        this.user = user;
        this._role = role;
        this.inviting = inviting;
    }

    private _role: TeamMemberRole;

    get role(): TeamMemberRole {
        return this._role;
    }

    static parse(data: any): TeamMember {
        return new TeamMember(UserRef.parse(data.user), data.role, data.inviting);
    }

    static parseArray(data: any[]): TeamMember[] {
        return data.map(d => TeamMember.parse(d));
    }

    makeAdmin() {
        this._role = "administrator";
    }

    makeMember() {
        this._role = "member";
    }
}
