import {PopoverInstance} from "./index";

export class PopoverStack {
    private stack: PopoverInstance<any>[] = [];

    constructor() {

    }

    get openedCount(): number {
        return this.stack.length;
    }

    async closeAll() {
        for (let popover of this.stack) {
            await popover.close();
        }
        this.stack = [];
    }

    async closePopover(instance: PopoverInstance<any>) {
        const indx = this.stack.indexOf(instance);
        if (indx < 0) {
            return;
        }
        const morePopovers = this.stack.splice(indx);
        for (let i = morePopovers.length - 1; i >= 0; i--) {
            await morePopovers[i].close();
        }
    }

    getAll(): PopoverInstance<any>[] {
        return this.stack.slice(0);
    }

    push(instance: PopoverInstance<any>) {
        this.stack.push(instance);
    }
}
