import {PopoverInstance} from "./PopoverInstance";
import {EventEmitter} from "@angular/core";

export type PopoverEvent = { type: "open" | "closed" };

export abstract class PopoverCollection {

    abstract readonly events: EventEmitter<PopoverEvent>;

    abstract add(popover: PopoverInstance<any>);

    abstract closeAll(): Promise<any>;

}
