import {IDocument} from "text-machine";
import {MemorySubject} from "../../../modules/store";
import {Observable} from "rxjs";
import {IOperationTransferObject} from "../../../+document/services/operations/TransferModel";

export class DocumentBody {
    body: IDocument;
    rev: number;
    /**
     * Document Id
     */
    readonly id: string;
    private readonly _lastModified$: MemorySubject<Date>;

    constructor(documentId: string,
                rev: number,
                body: IDocument,
                lastModified: Date) {
        this.rev = rev;
        this.body = body;
        this.id = documentId;
        this._lastModified$ = new MemorySubject<Date>(lastModified);
    }

    get lastModified(): Date {
        return this._lastModified$.value;
    }

    get lastModified$(): Observable<Date> {
        return this._lastModified$;
    }

    static parse(documentId: string, response: any) {
        return new DocumentBody(documentId, response.rev, response.body, response.lastModified ? new Date(response.lastModified) : new Date());
    }

    setLastModified(date: Date) {
        this._lastModified$.next(date);
    }

    updateBody(rev: number, body: IDocument) {
        this.setLastModified(new Date());
        this.rev = rev;
        this.body = body;
    }
}


export interface DocumentBodyWithOperations {
    /**
     * Last document snapshot, base revision
     */
    readonly body: DocumentBody;
    /**
     * Pending local operations
     */
    readonly ops: IOperationTransferObject[];
}
