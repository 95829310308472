<div class="header">
    <div class="avatar">
        <notebook-icon [color]="notebook.cssColor"
                       [shared]="notebook.type === 'open'"
                       class="icon">
        </notebook-icon>
    </div>
    <div class="info">
        <div class="owner">
            <user-link [item]="notebook.owner || notebook.ownerTeam"></user-link>
        </div>
        <div class="title">
            <a [routerLink]="ownerLink">{{notebook.title}}</a>
        </div>
    </div>
</div>

<div class="body">
    <ng-content></ng-content>
</div>
