<div *ngIf="iconId" class="icon">
    <n-icon [iconId]="iconId" [iconSize]="iconSize"></n-icon>
</div>

<div *ngIf="supTitle" class="suptitle">
    {{supTitle}}
</div>
<div class="text">
    {{message$|async}}
</div>

<div class="content">
    <ng-content></ng-content>
</div>
