import {DocumentListInfo} from "./DocumentListInfo";
import {MemorySubject} from "../../../modules/store";
import {Observable} from "rxjs";
import {NotebookInfo} from "../NotebookInfo";

export class DocumentNotebookGroup {

    constructor(notebook: NotebookInfo, documents: DocumentListInfo[]) {
        this._notebook$ = new MemorySubject(notebook);
        this._documents$ = new MemorySubject(documents);
    }

    private _documents$: MemorySubject<DocumentListInfo[]>;

    get documents$(): Observable<DocumentListInfo[]> {
        return this._documents$.asObservable();
    }

    private _notebook$: MemorySubject<NotebookInfo>;

    get notebook$(): Observable<NotebookInfo> {
        return this._notebook$.asObservable();
    }

    get notebookSnapshot(): NotebookInfo {
        return this._notebook$.value;
    }

    get documentsSnapshot(): DocumentListInfo[] {
        return this._documents$.value;
    }

    static parse(data: any): DocumentNotebookGroup {
        return new DocumentNotebookGroup(NotebookInfo.parse(data.notebook), DocumentListInfo.parseArray(data.documents));
    }

    static parseArray(data: any[]): DocumentNotebookGroup[] {
        return data.map(d => DocumentNotebookGroup.parse(d));
    }

    findDocument(documentId: string): DocumentListInfo | undefined {
        return this._documents$.value.find(d => d.id === documentId);
    }

    isEmpty() {
        return this.documentsSnapshot.length === 0;
    }

    moveDocumentUp(documentId: string) {
        const docs = this._documents$.value;
        const indx = docs.findIndex(d => d.id === documentId);
        if (indx > 0) {
            const [removedDoc] = docs.splice(indx, 1);
            docs.unshift(removedDoc);

            this._documents$.next(docs);
        }
    }

    prependDocument(doc: DocumentListInfo) {
        const docs = this._documents$.value.slice(0);
        docs.unshift(doc);
        this._documents$.next(docs);
    }

    removeDocument(doc: DocumentListInfo) {
        const docs = this._documents$.value.slice(0);
        const indx = docs.indexOf(doc);
        if (indx >= 0) {
            docs.splice(indx, 1);
            this._documents$.next(docs);
        }
    }
}
