import {PreloadingStrategy, Route} from "@angular/router";
import {delay, Observable, of, switchMap} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class PreloadModuleStrategy implements PreloadingStrategy {
    preload(route: Route, fn: () => Observable<boolean>): Observable<boolean> {
        return of(true)
            .pipe(
                delay(1000),
                switchMap(_ => fn())
            );
    }
}
