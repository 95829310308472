import {ComponentFactoryResolver, Injector, StaticProvider, TemplateRef, ViewContainerRef} from "@angular/core";
import {ModalContext} from "./ModalContext";


export abstract class ModalCompileContext {
    abstract readonly layerNumber: number;
    abstract modalCtx: ModalContext<any, any>;

    abstract compile(viewContainer: ViewContainerRef);
}

export class TemplateModalCompileContext extends ModalCompileContext {
    constructor(public modalCtx: ModalContext<any, any>,
                private template: TemplateRef<any>,
                public layerNumber: number) {
        super();
    }


    compile(viewContainer: ViewContainerRef) {
        viewContainer.createEmbeddedView(this.template);
    }

}

export class ComponentModalCompileContext extends ModalCompileContext {

    bindings: StaticProvider[];

    constructor(public modalCtx: ModalContext<any, any>,
                private cr: ComponentFactoryResolver,
                public componentType: any,
                public layerNumber: number) {
        super();
    }

    compile(viewContainer: ViewContainerRef) {
        let cmpFactory = this.cr.resolveComponentFactory(this.componentType);
        const vcr = viewContainer, ctxInjector = vcr.injector;

        const childInjector = Injector.create({
            providers: this.bindings,
            parent: ctxInjector
        });

        this.modalCtx.setComponentInstance(vcr.createComponent(cmpFactory, vcr.length, childInjector));
    }

}
