import {Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Optional, Output, Renderer2} from "@angular/core";
import {ITouchEvent, TouchService} from "../services/touch.service";
import {fromEvent, merge, Subscription} from "rxjs";

const SLIDING_CSS_CLASS = "_sliding";
const OPEN_CLASS = "slideout-open";

const DEFAULT_MENU_WIDTH = 320;

@Directive({selector: "[slideMenuContainer]"})
export class SlideMenuContainerDirective {
    constructor(public readonly container: ElementRef) {

    }
}

/**
 * Меню, которое открывается свайпом
 *
 * Использование
 *
 * <div slideMenuContainer>
 *     Main content here
 *
 *     <div data-slideout-ignore>
 *         This block was ignored if touch starts at it
 *     </div>
 *
 *     <div class="menu" slideMenu [direction]="'left'">
 *         Menu items
 *     </div>
 * </div>
 */
@Directive({selector: "[slideMenu]"})
export class SlideMenuDirective implements OnInit, OnDestroy {

    @Input()
    menuOpenClass = OPEN_CLASS;

    /**
     * На какой элемент навешивать класс? По умолчанию - контейнер
     */
    @Input()
    menuOpenClassElement: HTMLElement | ElementRef;

    /**
     * Расстояние слева или справа с которого начинается выдвижение
     */
    @Input()
    openOffset = 70;

    /**
     * Направление выдвигания меню (слева, справа)
     */
    @Input()
    direction: "left" | "right" = "left";

    /**
     * Насколько пикселей надо двинуть меню свайпом, чтобы оно открылось
     */
    @Input()
    tolerance = 70;

    /**
     * Время в ms за которое открывается и закрывается меню
     */
    @Input()
    duration = 300;

    @Input()
    animateMaxWidth: number;

    /**
     * Анимация открытия / закрытия
     */
    @Input()
    easing = "ease";

    @Input()
    backdrop: HTMLElement | ElementRef;

    @Output()
    menuOpen = new EventEmitter();

    @Output()
    menuClose = new EventEmitter();

    @Output()
    menuClosing = new EventEmitter();

    @Input() menuDisabled: boolean;

    @Input()
    scroller: HTMLElement | Window = window;

    private bindings: Array<{ destroy: () => void }> = [];
    private moved: boolean;
    private opening: boolean;
    private opened: boolean;
    private preventMove: boolean;
    private scrolling: boolean;
    private menuWidth: number;
    private currentOffsetX = 0;
    private scrollTimeout: any;
    private animationTimerId: any;

    private scrollSub: Subscription;

    constructor(
        @Optional() private container: SlideMenuContainerDirective,
        private touchSvc: TouchService,
        private menuEl: ElementRef,
        private renderer: Renderer2,
        private zone: NgZone) {
    }

    @Input()
    set menuOpened(val: boolean) {
        if (val) {
            this.open();
        } else {
            this.close();
        }
    }

    private get isAnimating(): boolean {
        if (!this.animateMaxWidth) {
            return true;
        }
        return window.innerWidth <= this.animateMaxWidth;
    }

    private get openClassElm(): Element {
        if (this.menuOpenClassElement) {
            return this.menuOpenClassElement instanceof ElementRef ? this.menuOpenClassElement.nativeElement : this.menuOpenClassElement;
        }
        return this.containerElm;
    }

    private get containerElm(): Element {
        return this.container ? this.container.container.nativeElement : window.document.documentElement;
    }

    private get orientation(): number {
        return this.direction === "right" ? -1 : 1;
    }

    private get backdropElm(): HTMLElement {
        if (this.backdrop && this.backdrop instanceof ElementRef) {
            return this.backdrop.nativeElement;
        } else if (this.backdrop) {
            return this.backdrop as HTMLElement;
        }
        return undefined;
    }

    close() {
        if ((!this.opened && !this.opening)) {
            return;
        }

        this.dropCurrentAnimation();
        this.setTransition();
        this.translateXTo(0);
        this.opened = false;

        const close = () => {
            this.removeTransition();
            this.renderer.setStyle(this.menuEl.nativeElement, "transform", "");
            this.hideBackdrop();
            this.animationTimerId = undefined;

            this.zone.run(() => {
                this.menuClose.emit();
            });
        };

        this.renderer.removeClass(this.openClassElm, this.menuOpenClass);
        if (this.menuClosing.observers.length) {
            this.zone.run(() => this.menuClosing.emit());
        }

        if (this.duration > 0 && this.isAnimating) {
            this.animationTimerId = setTimeout(close, this.duration + 50);
        } else {
            close();
        }

    }

    ngOnDestroy() {
        this.bindings.forEach(i => i.destroy());
        if (this.scrollSub) {
            this.scrollSub.unsubscribe();
        }
        this.renderer.removeClass(this.openClassElm, this.menuOpenClass);
    }

    ngOnInit() {
        const handlers = {
            start: e => this.onStart(e),
            cancel: () => this.onCancel(),
            move: e => this.onMove(e),
            end: () => this.onEnd()
        };

        this.bindings.push(this.touchSvc.bind(this.menuEl.nativeElement, handlers, {useCapture: true}));
        this.bindings.push(this.touchSvc.bind(this.containerElm, handlers, {useCapture: true}));

        if (this.backdrop) {
            this.bindings.push(this.touchSvc.bind(this.backdropElm, handlers, {useCapture: true}));
        }

        if (this.touchSvc.isTouchDevice()) {
            this.zone.runOutsideAngular(() => {
                this.scrollSub = merge(
                    fromEvent(this.scroller, "scroll", {passive: true}),
                    fromEvent(this.menuEl.nativeElement, "scroll", {passive: true})
                ).subscribe(() => {
                    if (this.moved) {
                        return;
                    }
                    clearTimeout(this.scrollTimeout);
                    this.scrolling = true;
                    this.scrollTimeout = setTimeout(() => {
                        this.scrolling = false;
                    }, 300);
                });
            });
        }
    }

    open() {
        this.invalidateMenuWidth();
        this.renderer.addClass(this.openClassElm, this.menuOpenClass);
        this.setTransition();
        this.translateXTo((this.menuWidth || DEFAULT_MENU_WIDTH) * this.orientation);
        this.showBackdrop();
        this.opened = true;
        this.dropCurrentAnimation();

        this.animationTimerId = setTimeout(() => {
            this.removeTransition();
            this.animationTimerId = undefined;
        }, this.duration + 50);

        this.zone.runGuarded(() => {
            this.menuOpen.emit();
        });
    }

    toggle() {
        this.opened ? this.close() : this.open();
    }

    private dropCurrentAnimation() {
        if (this.animationTimerId) {
            clearTimeout(this.animationTimerId);
        }
    }

    private hideBackdrop() {
        if (this.backdrop) {
            this.renderer.setStyle(this.backdropElm, "visibility", "hidden");
        }
    }

    private invalidateMenuWidth() {
        this.menuWidth = this.menuEl.nativeElement.clientWidth;
    }

    private onCancel() {
        this.resetSliding();
    }

    private onEnd() {
        if (!this.moved) {
            return;
        }
        if (this.opened) {
            if (this.opening) {
                this.open();
                return;
            }
            if ((Math.abs(this.currentOffsetX) > this.tolerance)) {
                this.close();
            } else {
                this.open();
            }
        } else {
            if (!this.opening) {
                this.close();
                return;
            }
            (Math.abs(this.currentOffsetX) > this.tolerance) ? this.open() : this.close();
        }
        this.resetSliding();
    }

    private onMove(e: ITouchEvent) {
        if (this.scrolling ||
            this.preventMove ||
            hasIgnoredElements(e.target)
        ) {
            return;
        }

        let translateX = this.currentOffsetX = e.deltaX;

        if (Math.abs(translateX) > this.menuWidth) {
            return;
        }

        const absX = Math.abs(e.deltaX);
        // noinspection JSSuspiciousNameCombination
        const absY = Math.abs(e.deltaY);

        if (absX > 15 && absX > absY) {
            this.opening = true;

            const orientedDifX = e.deltaX * this.orientation;

            if (this.opened && orientedDifX > 0 || !this.opened && orientedDifX < 0) {
                // wrong swipe way
                return;
            }

            if (orientedDifX <= 0) {
                translateX = e.deltaX + this.menuWidth * this.orientation;
                this.opening = false;
            }

            if (!(this.moved && this.openClassElm.classList.contains(this.menuOpenClass))) {
                this.renderer.addClass(this.openClassElm, this.menuOpenClass);
            }

            this.renderer.setStyle(this.menuEl.nativeElement, "transform", "translateX(" + translateX + "px)");
            this.translateXTo(translateX);
            this.moved = true;

            this.renderer.addClass(this.menuEl.nativeElement, SLIDING_CSS_CLASS);
        }

    }

    private onStart(e: ITouchEvent) {
        this.resetSliding();

        if (this.menuDisabled) {
            this.preventMove = true;
            return;
        }

        if (!this.opened) {
            switch (this.direction) {
                case "left":
                    this.preventMove = e.clientX > this.openOffset;
                    break;
                case "right":
                    this.preventMove = (this.containerElm.clientWidth - e.clientX) > this.openOffset;
                    break;
            }
        } else {
            this.preventMove = false;
        }

        if (!this.preventMove) {
            // lazy invalidate menu width
            this.invalidateMenuWidth();
        }

    }

    private removeTransition() {
        this.renderer.setStyle(this.menuEl.nativeElement, "transition", "");
        if (this.backdrop) {
            this.renderer.setStyle(this.backdropElm, "transition", "");
        }
    }

    private resetSliding() {
        this.moved = false;
        this.opening = false;
        this.renderer.removeClass(this.menuEl.nativeElement, SLIDING_CSS_CLASS);
    }

    private setTransition() {
        this.renderer.setStyle(this.menuEl.nativeElement, "transition", "transform " + this.duration + "ms " + this.easing);
        if (this.backdrop) {
            this.renderer.setStyle(this.backdropElm, "transition", "opacity " + this.duration + "ms " + this.easing);
        }
    }

    private showBackdrop() {
        if (this.backdrop) {
            this.renderer.setStyle(this.backdropElm, "visibility", "visible");
        }
    }

    private translateXTo(x: number) {
        this.currentOffsetX = x;
        this.renderer.setStyle(this.menuEl.nativeElement, "transform", "translateX(" + x + "px)");
        if (this.backdrop) {
            const orientedX = x * this.orientation;
            if (orientedX >= 0) {
                this.showBackdrop();
                if (!this.menuWidth) {
                    this.renderer.setStyle(this.backdropElm, "opacity", "1");
                } else {
                    const percent = Math.abs((orientedX / this.menuWidth));
                    this.renderer.setStyle(this.backdropElm, "opacity", percent.toString());
                }
            } else {
                this.hideBackdrop();
            }
        }
    }
}

function hasIgnoredElements(el) {
    while (el.parentNode) {
        if (el.getAttribute("data-slideout-ignore") !== null) {
            return true;
        }
        el = el.parentNode;
    }
    return false;
}
