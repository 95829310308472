import {Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {buildErrorMessage} from "../utils";
import {IControlWithErrors, NControlsValidationErrors, NInputErrorMessages} from "../";
// noinspection TypeScriptPreferShortImport
import {ErrorMessagesFactory} from "../../services/error-messages-factory.service";


@Component({
    selector: "n-textarea",
    styleUrls: [
        "./n-textarea.ng.css"
    ],
    templateUrl: "./n-textarea.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NTextareaComponent),
            multi: true
        }
    ]
})
export class NTextareaComponent implements OnInit, ControlValueAccessor, IControlWithErrors {

    /**
     * Input placeholder with field if empty
     */
    @Input() placeholder = "";

    @Input() clearable: boolean;

    /**
     * No trim value
     */
    @Input() noTrim: boolean;

    /**
     * Marks fields as read only
     */
    @Input() readonly: boolean;

    /**
     * Custom error message for input
     */
    @Input() errorMessages: NInputErrorMessages;
    /**
     * Marks textarea as required (styles only)
     */
    @Input() required: boolean;

    @Output() focus = new EventEmitter<any>();
    @Output() blur = new EventEmitter<any>();
    errorMessage: string;

    showClear: boolean;
    readonly wide: boolean;

    @ViewChild("area", {read: ElementRef, static: true})
    private textArea: ElementRef;
    @ViewChild("container", {read: ElementRef, static: true})
    private container: ElementRef;
    private propagateChange: (_) => void;
    private propagateTouched: () => void;

    constructor(private renderer: Renderer2,
                private messageFactory: ErrorMessagesFactory,
                private cd: ChangeDetectorRef,
                @Attribute("wide") wide: string) {
        this.wide = wide !== null;
    }

    @Input()
    set isDisabled(val: boolean) {
        this.setDisabledState(val);
    }

    @Input()
    set disabled(val: boolean) {
        this.setDisabledState(val);
    }

    clear() {
        this.renderer.setProperty(this.textArea.nativeElement, "value", "");
        this.onChange("");
        this.setFocus();
    }

    /* public api */
    getInputElement(): HTMLInputElement | HTMLTextAreaElement {
        return this.textArea.nativeElement as HTMLTextAreaElement;
    }

    ngOnInit() {
        this.invalidateStyles();
        this.processErrors(undefined);
    }

    onBlur() {
        if (this.propagateTouched) {
            this.propagateTouched();
        }
        this.blur.emit();
    }

    onChange(evt: any) {
        const val = evt.target.value;
        if (this.propagateChange) {
            this.propagateChange(this.noTrim ? val.toString() : val.toString().trim());
        }
        this.invalidateStyles();
        if (this.clearable) {
            this.showClear = !!val;
        }
    }

    onMouseLeave() {
        this.renderer.removeClass(this.container.nativeElement, "n-textarea_hover");
    }

    onMouseOver() {
        this.renderer.addClass(this.container.nativeElement, "n-textarea_hover");
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    setDisabledState(disabled: boolean) {
        this.renderer.setProperty(this.textArea.nativeElement, "disabled", disabled);
        this.cd.markForCheck();
    }

    setErrors(errors: NControlsValidationErrors) {
        this.processErrors(errors);
    }

    /* public api */
    setFocus() {
        this.textArea.nativeElement.focus();
    }

    writeValue(obj: any): void {
        obj = obj || "";
        obj = this.noTrim ? obj.toString() : obj.toString().trim();
        this.renderer.setProperty(this.textArea.nativeElement, "value", obj);
        this.invalidateStyles();
    }

    private invalidateStyles() {
        if (!this.textArea.nativeElement.value) {
            this.renderer.addClass(this.container.nativeElement, "n-textarea_empty");
        } else {
            this.renderer.removeClass(this.container.nativeElement, "n-textarea_empty");
        }
    }

    private processErrors(errors: NControlsValidationErrors) {
        if (!errors) {
            if (this.required) {
                this.errorMessage = buildErrorMessage({required: true}, this.messageFactory, this.errorMessages);
            } else {
                this.errorMessage = "";
            }
            return;
        }

        this.errorMessage = buildErrorMessage(errors, this.messageFactory, this.errorMessages);
    }
}
