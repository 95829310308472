import {EventEmitter, Injectable} from "@angular/core";
import {IPopoverOptions} from "./IPopoverOptions";
import {PopoverInstance} from "./PopoverInstance";
import {PopoverRenderer} from "./PopoverRenderer";
import {PopoverCollection, PopoverEvent} from "./PopoverCollection";

@Injectable({providedIn: "root"})
export class PopoverService {

    private togglers = {};

    constructor(private renderer: PopoverRenderer,
                private popovers: PopoverCollection) {

    }


    get popoverEvents(): EventEmitter<PopoverEvent> {
        return this.popovers.events;
    }

    closeAll(): Promise<any> {
        return this.popovers.closeAll();
    }

    open<TResult>(options: IPopoverOptions): PopoverInstance<TResult> {
        const instance = this.renderer.render<TResult>(options);
        this.popovers.add(instance);
        return instance;
    }

    toggle(key: string, options: IPopoverOptions) {
        if (this.togglers[key]) {
            this.togglers[key].close();
            this.togglers[key] = null;
        } else {
            this.togglers[key] = this.open(options);
            this.togglers[key].result
                .then(() => {
                    this.togglers[key] = null;
                }, () => {
                    this.togglers[key] = null;
                });
        }
    }

}

