import {IPopoverOptions} from "./IPopoverOptions";

/**
 * To inject in PopoverWindowComponent
 */
export class PopoverOptions {

    constructor(public options: IPopoverOptions) {

    }

}
