import {Component} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {ErrorService} from "../../services/error.service";
import {validatePasswordsEquals} from "../../validation/validatePasswordEquals";
import {BehaviorSubject} from "rxjs";


@Component({
    selector: "confirm-password-reset-page",
    templateUrl: "./confirm-password-reset-page.html",
    styleUrls: [
        "./confirm-password-reset-page.ng.css"
    ]
})
export class ConfirmPasswordResetPageComponent {

    confirming$ = new BehaviorSubject<boolean>(false);
    success$ = new BehaviorSubject<boolean>(false);
    errorKey$ = new BehaviorSubject<string>("");
    form: FormGroup;

    private token: string;
    private username: string;

    constructor(private authService: AuthService,
                private router: Router,
                private userSvc: UserService,
                private route: ActivatedRoute,
                private errors: ErrorService) {
    }

    ngOnInit() {

        this.form = new FormGroup({
            password: new FormControl("", [Validators.required, Validators.maxLength(64)]),
            confirmPassword: new FormControl("", {
                validators: [Validators.required, Validators.maxLength(64)],
                updateOn: "blur"
            })
        }, {validators: [validatePasswordsEquals("password", "confirmPassword")]});

        if (this.authService.isAuthenticated()) {
            this.router.navigateByUrl("/", {replaceUrl: true});
            return;
        }

        const query = this.route.snapshot.queryParams;

        this.token = query.token;
        this.username = query.user;

        if (!this.token || !this.username) {
            this.errorKey$.next("common.invalidAddress");
            return;
        }

    }

    async submit(form: { password: string }) {

        if (this.form.invalid) {
            return;
        }

        this.confirming$.next(true);

        try {
            const result = await this.userSvc.confirmPasswordReset(this.username, this.token, form.password);

            this.authService.authentificateByToken(result.token, result.tokenExpiresIn);
        } catch (fail) {
            this.errors.showFail(fail);
            return;
        } finally {
            this.confirming$.next(false);
        }

        this.success$.next(true);

    }

}
