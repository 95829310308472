import {AuthService} from "../auth.service";
import {EventEmitter, Injectable, NgZone} from "@angular/core";
import {diffInSeconds} from "../../../modules/translate/DateUtility";
import {AppService} from "../app.service";
import {Observable, Subject} from "rxjs";

type Awaiter = {
    resolve: (data?: any) => void;
    reject: (reason: { errorCode: number, errorMessage: string }) => void;
    date: Date;
};

const WSREQUESTTIMEOUTINSECONDS = 40;
const WSPINGTIMEOUT = 30000;

const WS_PATH = "/system/ws";

@Injectable({providedIn: "root"})
export class WsConnectionManager {

    readonly onConnectionStateChanged = new EventEmitter<ConnectionState>();

    private connectionAwaiters: Awaiter[] = [];
    private idleRequests: { [reqId: string]: Awaiter } = {};
    private listeners: { [action: string]: Subject<any> } = {};
    private pingTmrId: any;
    private reconnectionTmrId: any;
    private sessionAwaiters: Awaiter[] = [];
    private sessionFetching = false;
    private sessionId: string;
    private ws: WebSocket;

    constructor(private authService: AuthService,
                private appSvc: AppService,
                private zone: NgZone) {
        this._state = ConnectionState.DISCONNECTED;

        this.zone.runOutsideAngular(() => {
            setInterval(() => this.dropRequestByTimeout(), 3000);
        });

        authService.onSignIn.subscribe(() => {
            this.authenticate(authService.readToken());
        });

        authService.onSignOut.subscribe(() => {
            this.disconnect();
        });

        this.connect();
    }

    private _state: ConnectionState;

    get state(): ConnectionState {
        return this._state;
    }

    sendRequest(action: string, data: any): Promise<any> {
        return this.ensureConnected()
            .then(() => this.getSession())
            .then(() => this.send(action, data));
    }

    start() {
        // do nothing...
    }

    subscribe<TMessage = any>(actionName: string): Observable<TMessage> {
        if (!this.listeners[actionName]) {
            this.listeners[actionName] = new Subject<TMessage>();
        }
        return this.listeners[actionName].asObservable();
    }

    private async authenticate(token: string) {
        await this.ensureConnected();
        if (!this.sessionId) {
            await this.getSession();
        } else {
            // auth existing session
            await this.send("auth", {token});
        }
    }

    private cancelPing() {
        if (this.pingTmrId) {
            clearInterval(this.pingTmrId);
        }
    }

    private connect() {
        if (this.state === ConnectionState.CONNECTING) {
            return;
        }

        this.connectionStateChanged(ConnectionState.CONNECTING);

        this.ws = new WebSocket(getWsUrl());
        window["$ws"] = this.ws;

        this.ws.onopen = () => {
            this.connectionStateChanged(ConnectionState.CONNECTED);
        };
        this.ws.onmessage = (evt) => {
            this.handleMessage(evt.data);
        };
        this.ws.onerror = (evt) => {
            console.error("connection error", evt);
            this.connectionStateChanged(ConnectionState.DISCONNECTED);
        };

        this.ws.onclose = () => {
            console.warn("connection closed");
            this.connectionStateChanged(ConnectionState.DISCONNECTED);
        };

    }

    private connectionStateChanged(newState: ConnectionState) {

        switch (newState) {
            case ConnectionState.DISCONNECTED:
                this.cancelPing();

                this._state = ConnectionState.DISCONNECTED;

                this.sessionId = undefined;
                // Пытаемся переподключится при разрыве связи
                if (this.reconnectionTmrId)
                    clearTimeout(this.reconnectionTmrId);

                this.reconnectionTmrId = setTimeout(() => {
                    this.reconnectionTmrId = undefined;
                    this.connect();
                }, 5000);

                Object.keys(this.idleRequests).forEach(requestId => {
                    this.idleRequests[requestId].reject({errorCode: 0, errorMessage: "Disconnected"});
                });
                this.idleRequests = {};
                this.onConnectionStateChanged.next(this.state);
                this.cancelPing();
                break;
            case ConnectionState.CONNECTING:
                this._state = ConnectionState.CONNECTING;

                this.onConnectionStateChanged.next(this.state);
                break;
            case ConnectionState.CONNECTED:
                this.getSession().then(() => {

                    this._state = ConnectionState.CONNECTED;
                    // get connection session
                    for (let awaiter of this.connectionAwaiters) {
                        awaiter.resolve();
                    }
                    this.connectionAwaiters = [];

                    this.onConnectionStateChanged.next(this.state);
                    this.schedulePing();
                }, err => {
                    console.error("Error while getting session", err);
                    if (this.ws) {
                        this.ws.close();
                    }
                });

                break;
            default:
                throw new Error("unknown ws state: " + newState);
        }


    }

    private disconnect() {
        if (this.ws) {
            this.ws.close();
            this.ws = undefined;
        }
        if (this.reconnectionTmrId) {
            clearTimeout(this.reconnectionTmrId);
        }
    }

    private dropRequestByTimeout() {
        let hasTimeoutRequests = false;
        Object.keys(this.idleRequests).forEach(requestId => {
            const idleRequest = this.idleRequests[requestId];
            const lastSeconds = diffInSeconds(idleRequest.date, new Date());

            if (lastSeconds >= WSREQUESTTIMEOUTINSECONDS) {
                hasTimeoutRequests = true;
                idleRequest.reject({errorCode: 0, errorMessage: "Request timeout"});
                delete this.idleRequests[requestId];
            }
        });

        if (hasTimeoutRequests && this.state === ConnectionState.CONNECTED) {
            // reconnect
            console.warn("Reconnect to ws endpoint due timeout requests");
            this.ws.close();
        }
    }

    private ensureConnected(): Promise<any> {
        if (this.state === ConnectionState.CONNECTED) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            this.connectionAwaiters.push({resolve, reject, date: undefined});
        });
    }

    private async getSession(): Promise<string> {
        if (this.sessionFetching) {
            return new Promise((resolve, reject) => {
                this.sessionAwaiters.push({resolve, reject, date: undefined});
            });
        }

        if (this.sessionId) {
            return this.sessionId;
        }

        this.sessionFetching = true;

        try {

            const context = await this.appSvc.ensureContext();
            if (context.domain.externalProfiles && this.authService.hasAnyToken()) {
                // expired session of external profile, avoid anonymous sessions, if possible
                await this.authService.ensureAuthenticated();
            }

            const token = this.authService.readToken();
            const response = await this.send("connect", {token, domain: context.domain.name, agent: navigator.userAgent});

            this.sessionFetching = false;

            this.sessionId = response.sessionId;

            if (this.sessionAwaiters.length) {
                for (let awaiter of this.sessionAwaiters) {
                    awaiter.resolve(this.sessionId);
                }
                this.sessionAwaiters = [];
            }

            return this.sessionId;

        } catch (e) {
            if (e.errorCode === 2) {
                this.authService.tokenExpired().catch(console.error);
            }
            this.sessionFetching = false;

            for (let awaiter of this.sessionAwaiters) {
                awaiter.reject(e);
            }
            this.sessionAwaiters = [];

            throw e;
        }


    }

    private handleMessage(payload: string) {

        this.schedulePing();

        let message;

        try {
            message = JSON.parse(payload);
        } catch (e) {
            console.error(e);
            return;
        }

        if (message.reqId && this.idleRequests[message.reqId]) {
            if (message.errorCode) {
                this.idleRequests[message.reqId].reject(message);
            } else {
                this.idleRequests[message.reqId].resolve(message.data);
            }

            delete this.idleRequests[message.reqId];
        } else {
            // no request found, PUSH message
            if (message.a) {
                if (this.listeners[message.a] && this.listeners[message.a].observers.length) {
                    this.listeners[message.a].next(message.data);
                } else {
                    console.warn("ws message not handled, no listeners", message);
                }
            } else {
                console.warn("ws message not handled", message);
            }

        }

    }

    private schedulePing() {
        this.zone.runOutsideAngular(() => {
            this.cancelPing();
            // пингуем каждые 10 секунд, чтобы сервер знал, что мы живы
            this.pingTmrId = setInterval(() => {
                this.sendRequest("ping", {}).catch((err) => {
                    console.error("Error while ping server", err);
                });
            }, WSPINGTIMEOUT);
        });
    }

    private send(action: string, data: any): Promise<any> {
        let requestId = generateUniqueId();
        let message = {
            a: action,
            session: this.sessionId,
            reqId: requestId,
            data
        };

        return new Promise((resolve, reject) => {
            try {
                this.ws.send(JSON.stringify(message));
            } catch (e) {
                console.error("Websocket send failed:", e);
                reject(e);
            }

            this.idleRequests[requestId] = {resolve, reject, date: new Date()};
        });
    }
}

function getWsUrl(): string {
    let parser = document.createElement("a");
    parser.href = encodeURI(WS_PATH);

    /* у IE может не задан, если ссылка относительная */
    let host = parser.host ? parser.host : location.host;

    let wsProtocol = location.protocol === "https:" ? "wss:" : "ws:";
    return `${wsProtocol}//${host}${WS_PATH}`;
}

export enum ConnectionState {
    CONNECTING = 0,
    CONNECTED = 1,
    DISCONNECTED = 2
}


export function generateUniqueId(): string {
    // always start with a letter (for DOM friendlyness)
    let idstr = String.fromCharCode(Math.floor((Math.random() * 25) + 65));
    do {
        // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
        let ascicode = Math.floor((Math.random() * 42) + 48);
        if (ascicode < 58 || ascicode > 64) {
            // exclude all chars between : (58) and @ (64)
            idstr += String.fromCharCode(ascicode);
        }
    } while (idstr.length < 5);
    return (idstr);
}
