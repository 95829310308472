import {ITranslation} from "../../modules/translate";
import {elapsedRussianTime, formatRussianDate, getRussionMonthNameParent} from "./RussianDate";
import {translateRussianQuantity} from "./RussianMorphology";

export class RussianTranslation implements ITranslation {

    readonly lang = "ru";
    readonly replaceQuotes = true;
    private _values: any;

    constructor() {

    }

    elapsedTime(d: Date): string {
        return elapsedRussianTime(d);
    }

    formatDate(d: Date, includeYear: boolean, includeTime: boolean): string {
        return formatRussianDate(d, includeYear, includeTime);
    }

    getMonthName(d: Date): string {
        return getRussionMonthNameParent(d.getMonth());
    }

    async getValues(): Promise<any> {
        if (this._values) {
            return this._values;
        }

        return await new Promise((resolve, reject) => {
            require.ensure([], () => {
                this._values = require("./ru.json");
                resolve(this._values);
            }, reject, "ru-lang");
        });
    }

    translateQuantity(num: number, values: string[]): string {
        return translateRussianQuantity(num, values);
    }

}
