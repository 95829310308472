<div class="header-bar">
    <div class="menu">
        <n-action-button (click)="openMenu()" [icon]="'burger-menu'"></n-action-button>
    </div>
    <div class="title">
        <span *ngIf="pageTitle$ | async">{{ pageTitle$ | async}}</span>
    </div>
    <div class="content-title">
        {{ pageContentTitle$ | async}}
    </div>
    <div class="actions">
        <div portalOutlet="header-buttons"></div>
    </div>
</div>
<div [portalOutlet]="'header-selected'" class="selected-portal"></div>
