<div [class._bottom]="actionsAlign === 'bottom'"
     [class._error]="type===3"
     [class._info]="type===1"
     [class._success]="type===4"
     [class._warn]="type===2"
     class="toast-text">
    <div class="toast-text__title">{{text}}</div>
    <div *ngIf="actions.length" class="toast-text__actions">
        <button (click)="triggerAction(action)" *ngFor="let action of actions" class="toast-text__action" type="button">
            {{action.title}}
        </button>
    </div>
</div>
