import {NgModule} from "@angular/core";
import {TouchService} from "./services/touch.service";
import {SlideMenuContainerDirective, SlideMenuDirective} from "./directives/slide-menu.directive";
import {LongTapDirective} from "./directives/long-tap.directive";

const DIRECTIVES = [
    SlideMenuDirective,
    SlideMenuContainerDirective,
    LongTapDirective
];

@NgModule({
    declarations: [
        ...DIRECTIVES
    ],
    imports: [],
    exports: [
        ...DIRECTIVES
    ],
    providers: [
        TouchService
    ]
})
export class TouchModule {

}
