import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {UserInfo} from "../../models/UserInfo";
import {PopoverContext} from "../../../modules/n-popover/index";
import {filter, map, Observable} from "rxjs";
import {AppService} from "../../services/app.service";

@Component({
    selector: "mention-user-popover",
    templateUrl: "./mention-user-popover.html",
    styleUrls: [
        "./mention-user-popover.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MentionUserPopoverComponent implements OnInit {

    user: UserInfo;
    domainName$: Observable<string>;

    constructor(private ctx: PopoverContext<UserInfo>, private appSvc: AppService) {
        this.user = ctx.popoverData;
    }

    ngOnInit() {
        this.domainName$ = this.appSvc.context$
            .pipe(
                filter(ctx => !!ctx),
                map(ctx => ctx.domain.title)
            );
    }

}
