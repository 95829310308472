import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {BehaviorSubject, combineLatest, concat, map, Observable, of} from "rxjs";
import {DocumentService} from "../../services/document.service";
import {DocumentListInfo} from "../../models/documents/DocumentListInfo";
import {IApplicationPage, PageTitleContent, TitleContent} from "../../../pages";
import {MemorySubject} from "../../../modules/store";
import {shareLastValue} from "../../../modules/rxjs-ex/shareLastValue";


const PAGE_SIZE = 20;

@Component({
    selector: "shared-page",
    templateUrl: "shared-page.html",
    styleUrls: [
        "./shared-page.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedPageComponent implements IApplicationPage, OnInit {

    documents$: Observable<DocumentListInfo[]>;
    emptyList$: Observable<boolean>;

    readonly documentTitle$ = new MemorySubject<TitleContent>({title: "sidemenu.shared", translate: true});
    readonly pageTitle$ = new MemorySubject<PageTitleContent>({title: "sidemenu.shared", translate: true});

    private limit$ = new BehaviorSubject<number>(PAGE_SIZE);
    private hasMorePages = true;

    constructor(private documents: DocumentService) {

    }

    docIdentity(indx: number, doc: DocumentListInfo) {
        return doc.id;
    }

    getRouteTitle(): string {
        return "$sidemenu.shared";
    }

    ngOnInit() {

        const allDocuments$ =
            concat(of([]), this.documents.getShared()
                .pipe(
                    shareLastValue()
                ));

        this.documents$ = combineLatest(allDocuments$, this.limit$)
            .pipe(
                map(([docs, limit]) => this.filterPage(docs, limit)),
                shareLastValue()
            );

        this.emptyList$ = combineLatest([this.documents.fetchingShared$, allDocuments$])
            .pipe(
                map(([fetching, docs]) => !fetching && docs.length === 0)
            );

    }

    scrollToEnd() {
        if (this.hasMorePages) {
            this.limit$.next(this.limit$.getValue() + PAGE_SIZE);
        }
    }

    private filterPage(docs: DocumentListInfo[], limit: number): DocumentListInfo[] {
        this.hasMorePages = docs.length > limit;
        if (!this.hasMorePages) {
            return docs;
        }
        return docs.slice(0, limit);
    }

}
