import {NControlsValidationErrors, NInputErrorMessages} from "./";
// noinspection TypeScriptPreferShortImport
import {ErrorMessagesFactory} from "../services/error-messages-factory.service";

export function buildModifierText(prefix: string, mod: string): string {
    let result = "";
    if (mod) {
        let mods = mod.split(" ");
        for (let m of mods) {
            result += prefix + m + " ";
        }
    }
    return result;
}

export function buildErrorMessage(errors: NControlsValidationErrors,
                                  factory: ErrorMessagesFactory,
                                  messages: NInputErrorMessages | undefined) {

    const displayingMessages = [];

    for (let err in errors) {
        if (errors.hasOwnProperty(err)) {
            if (messages && messages[err]) {
                displayingMessages.push(messages[err]);
            } else {
                return factory.getMessage(err, errors[err]);
            }
        }

    }

    return displayingMessages.join("\r\n");


}
