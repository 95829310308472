export function diffInSeconds(d1: Date, d2: Date): number {
    let t2 = d2.getTime();
    let t1 = d1.getTime();
    return Math.abs(Math.floor((t2 - t1) / (1000)));
}

export function diffInMinutes(d1: Date, d2: Date): number {
    let t2 = d2.getTime();
    let t1 = d1.getTime();
    return Math.abs(Math.floor((t2 - t1) / (60 * 1000)));
}

export function diffInHours(d1: Date, d2: Date): number {
    let t2 = d2.getTime();
    let t1 = d1.getTime();
    return Math.abs(Math.floor((t2 - t1) / (3600 * 1000)));
}

export function diffInDays(d1: Date, d2: Date): number {
    let t2 = d2.getTime();
    let t1 = d1.getTime();

    return Math.abs(Math.floor((t2 - t1) / (24 * 3600 * 1000)));
}

export function diffInWeeks(d1: Date, d2: Date): number {
    let t2 = d2.getTime();
    let t1 = d1.getTime();

    return Math.abs(Math.floor((t2 - t1) / (24 * 3600 * 1000 * 7)));
}

export function diffInMonths(d1: Date, d2: Date): number {
    let d1Y = d1.getFullYear();
    let d2Y = d2.getFullYear();
    let d1M = d1.getMonth();
    let d2M = d2.getMonth();

    return Math.abs((d2M + 12 * d2Y) - (d1M + 12 * d1Y));
}

export function diffInYears(d1: Date, d2: Date): number {
    return Math.abs(d2.getFullYear() - d1.getFullYear());
}

export function isEqualDateWithoutTime(dateA: Date, dateB: Date): boolean {
    return dateA.getFullYear() === dateB.getFullYear() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getDate() === dateB.getDate();
}

export function getPrevDate(date: Date): Date {
    let tempDate = new Date(date.getTime());
    tempDate.setDate(tempDate.getDate() - 1);
    return tempDate;
}

export function getTimeText(d: Date): string {
    const pad = n => n < 10 ? "0" + n : n;
    return pad(d.getHours()) + ":" + pad(d.getMinutes());
}
