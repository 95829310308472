import {Injectable, NgZone} from "@angular/core";

const KEYCODESMAP = {
    "e": 69,
    "p": 80,
    "s": 83,
    "delete": 46,
    "escape": 27
};

@Injectable({providedIn: "root"})
export class HotKeyService {

    private handlers: RegisteredHandler[] = [];

    constructor(private zone: NgZone) {
        this.zone.runOutsideAngular(() => {
            window.document.addEventListener("keydown", this.handler);
        });
    }

    addHandler(hotKey: string, handler: IHotKeyHandler): () => void {

        const keys = hotKey.split("+");
        let control = false, keyCode: number;
        if (keys.length === 2) {
            control = keys[0] === "Ctrl";
            keyCode = KEYCODESMAP[keys[1]];
        } else {
            keyCode = KEYCODESMAP[keys[0]];
        }

        if (!keyCode) {
            throw new Error(`Unknown key: ${hotKey}`);
        }

        const newHandler = {
            keyCode,
            ctrl: control,
            handler
        };
        this.handlers.push(newHandler);

        return () => {
            const indx = this.handlers.indexOf(newHandler);
            if (indx >= 0) {
                this.handlers.splice(indx, 1);
            }
        };
    }

    private handleKeyDown(e: any) {
        for (let h of this.handlers) {
            const isControl = e.ctrlKey || e.metaKey;
            if (h.keyCode === e.keyCode && (!h.ctrl || isControl)) {
                this.zone.runGuarded(() => {
                    h.handler(e);
                });
                if (e.defaultPrevented) {
                    break;
                }
            }
        }
    }

    private handler = e => this.handleKeyDown(e);
}

interface RegisteredHandler {
    ctrl: boolean;
    handler: IHotKeyHandler;
    keyCode: number;
}

export interface IHotKeyHandler {
    (e): void;
}
