import {EntityColor} from "./EntityColor";
import {Observable} from "rxjs";
import {MemorySubject} from "../../modules/store";
import {IEntityWithAvatar} from "./IEntityWithAvatar";

export class TeamRef implements IEntityWithAvatar {

    readonly avatarColor1: EntityColor;
    readonly avatarColor2: EntityColor;

    constructor(public readonly name: string,
                public readonly title: string,
                avatarPath: string,
                avatarColors: string) {

        const colors = avatarColors.split(";");
        this._avatarPath$ = new MemorySubject(avatarPath);
        this.avatarColor1 = colors[0] as EntityColor;
        this.avatarColor2 = colors[1] as EntityColor;
    }

    get avatarPath(): string {
        return this._avatarPath$.getValue();
    }

    private _avatarPath$: MemorySubject<string>;

    get avatarPath$(): Observable<string> {
        return this._avatarPath$.asObservable();
    }

    static parse(data: any): TeamRef {
        return new TeamRef(data.name, data.title, data.avatarPath, data.avatarColors);
    }

    static parseArray(data: any[]): TeamRef[] {
        return data.map(d => TeamRef.parse(d));
    }

    changeAvatar(avatarPath: string) {
        this._avatarPath$.next(avatarPath);
    }

    getInititals(): string {
        return this.title[0];
    }

    getNavigateUrl(): string[] {
        return ["/", this.name];
    }

    getPopupText(): string {
        return this.title;
    }

    serialize(): any {
        return {
            name: this.name,
            title: this.title,
            avatarPath: this.avatarPath,
            avatarColors: `${this.avatarColor1};${this.avatarColor2}`
        };
    }
}
