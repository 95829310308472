import {ModuleWithProviders, NgModule} from "@angular/core";
import {ContextualRouteService} from "./ContextualRouteService";
import {ContextualRouterOutlet} from "./directives/contextual-router-outlet.directive";
import {ContextualRoutesAdapter} from "./ContextualRoutesAdapter";
import {RouterModule} from "@angular/router";

const ROUTER_DIRECTIVES = [ContextualRouterOutlet];

@NgModule({
    declarations: [
        ...ROUTER_DIRECTIVES
    ], // directives, components, and pipes owned by this NgModule
    imports: [
        RouterModule
    ],
    exports: [
        ...ROUTER_DIRECTIVES
    ],
    providers: []
})
export class ContextRoutingModule {
    static decorateRouter(adapter: any): ModuleWithProviders<ContextRoutingModule> {
        return {
            ngModule: ContextRoutingModule,
            providers: [
                {provide: ContextualRoutesAdapter, useClass: adapter},
                ContextualRouteService
            ]
        };
    }
}
