import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NotebookEditDocumentRestrict, NotebookInfo, NotebookType} from "../../models/NotebookInfo";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EntityColor} from "../../models/EntityColor";
import {TeamType} from "../../models/TeamInfo";

@Component({
    selector: "notebook-settings-form",
    templateUrl: "./notebook-settings-form.html",
    styleUrls: [
        "./notebook-settings-form.ng.css"
    ]
})
export class NotebookSettingsFormComponent implements OnInit {

    @Input()
    notebook: NotebookInfo;

    @Input()
    saving: boolean;

    @Input()
    teamType: TeamType;

    @Output()
    save = new EventEmitter<NotebookFormData>();

    @Output()
    cancel = new EventEmitter<any>();

    form: FormGroup;

    typeCloseKey: string;
    typeOpenKey: string;
    isTeamNotebook: boolean;
    typeDescKey: string;

    constructor() {
    }

    ngOnInit() {

        this.form = new FormGroup({
            title: new FormControl(this.notebook.title, [Validators.required, Validators.maxLength(128)]),
            name: new FormControl(this.notebook.name, [Validators.required, Validators.maxLength(64)]),
            color: new FormControl(this.notebook.color, [Validators.required]),
            type: new FormControl(this.notebook.type, [Validators.required]),
            editRestrict: new FormControl(this.notebook.editRestrict, [Validators.required])
        });

        this.isTeamNotebook = this.notebook.isTeamNotebook;
        this.typeCloseKey = this.isTeamNotebook ? "notebooks.settings.onlyTeamMembers" : "notebooks.settings.justMe";
        this.typeOpenKey = "notebooks.settings.allServiceUsers";
        this.typeDescKey = this.isTeamNotebook ? "notebooks.settings.whoTeamReadDesc" : "notebooks.settings.whoReadDesc";
    }


}


export type NotebookFormData = {
    title: string;
    name: string;
    color: EntityColor;
    type: NotebookType;
    editRestrict: NotebookEditDocumentRestrict;
}
