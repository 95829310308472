import {PopoverRenderer} from "../PopoverRenderer";
import {IPopoverOptions} from "../IPopoverOptions";
import {PopoverInstance} from "../PopoverInstance";
import {ApplicationRef, ComponentFactoryResolver, Injectable, Injector, StaticProvider} from "@angular/core";
import {PopoverContext} from "../PopoverContext";
import {DynamicPopoverCompileContext, InstancePopoverCompileContext, PopoverCompileContext, TemplateCompileContext} from "../PopoverCompileContext";
import {PopoverWindowComponent} from "../directives/public_directives";
import {PopoverOptions} from "../PopoverOptions";


@Injectable({providedIn: "root"})
export class DOMPopoverRenderer extends PopoverRenderer {


    constructor(private injector: Injector, private appRef: ApplicationRef) {
        super();
    }

    render<T>(options: IPopoverOptions): PopoverInstance<T> {

        const ctx = new PopoverContext<any>(options.popoverData);
        let compileContext: PopoverCompileContext;

        const injector = options.injector || this.injector;
        const cr = injector.get(ComponentFactoryResolver);

        let bindings: StaticProvider[] = (options.bindings || []).concat([
            {provide: PopoverRenderer, useValue: this},
            {provide: PopoverContext, useValue: ctx},
            {provide: PopoverOptions, useValue: new PopoverOptions(options)}
        ]);

        if (options.componentInstance) {
            compileContext = new InstancePopoverCompileContext(options.componentInstance, ctx);
        } else if (options.templateRef) {
            compileContext = new TemplateCompileContext(options.templateRef, ctx);
        } else {
            if (!options.componentType) {
                throw new Error("Popover: componentInstance or componentType must be specified");
            }
            compileContext = new DynamicPopoverCompileContext(cr, ctx, options.componentType, bindings);
        }

        bindings = bindings.concat([{
            provide: PopoverCompileContext,
            useValue: compileContext
        }]);

        const childInjector = Injector.create({
            providers: bindings,
            parent: this.injector
        });

        const cmpFactory = cr.resolveComponentFactory(PopoverWindowComponent);
        const cmpRef = cmpFactory.create(childInjector);

        this.appRef.attachView(cmpRef.hostView);

        if (options.parentContainer) {
            options.parentContainer.appendChild(cmpRef.location.nativeElement);
        } else {
            document.body.appendChild(cmpRef.location.nativeElement);
        }

        return new PopoverInstance<T>(cmpRef, ctx, options);

    }

}
