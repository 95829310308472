export class DocumentTag {
    name: string;

    normalizedName: string;

    constructor(name: string) {
        this.name = name;
        this.normalizedName = this.name.toUpperCase();
    }

    static parse(data: any): DocumentTag {
        return new DocumentTag(data.name);
    }

    static parseArray(data: any[]): DocumentTag[] {
        return data.map(d => DocumentTag.parse(d));
    }
}
