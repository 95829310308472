import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {UserRef} from "../../models/UserRef";
import {TeamRef} from "../../models/TeamRef";

@Component({
    selector: "user-name",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `{{title}}`
})

export class UserNameComponent {

    title: string;

    constructor() {
    }

    @Input() set item(val: UserRef | TeamRef) {
        this.title = val instanceof UserRef ? val.toFullname() : val.title;
    }


}
