import {ComponentFactoryResolver, ComponentRef, Injectable, InjectionToken, Injector, StaticProvider} from "@angular/core";

export const RENDER_SERVICE_CONTEXT = new InjectionToken<any>("render service context");

@Injectable({providedIn: "root"})
export class RenderService {


    constructor(private cr: ComponentFactoryResolver,
                private injector: Injector) {

    }

    render<T>(componentType: any,
              contextData: any,
              providers?: StaticProvider[],
              componentResolver?: ComponentFactoryResolver,
              injector?: Injector): ComponentRef<T> {

        let allProviders: StaticProvider[] = [
            {provide: RENDER_SERVICE_CONTEXT, useValue: contextData}
        ];

        if (providers) {
            allProviders = allProviders.concat(providers || []);
        }

        const ctxInjector = injector || this.injector;
        const childInjector = Injector.create({
            parent: ctxInjector,
            providers: allProviders
        });

        const resolver = componentResolver || this.cr;

        const cmpFactory = resolver.resolveComponentFactory<T>(componentType);
        return cmpFactory.create(childInjector);
    }

}
