export class FailServerResponse {

    errors: ValidationResult[];
    statusCode: number;
    message: string;

    body: any;

    constructor(data: any) {

        if (!data) {
            return;
        }
        this.statusCode = data.status;
        this.errors = [];

        if (data.error) {
            const body = data.error;

            this.body = body;
            this.message = body.message;

            if (body.errors) {
                for (let error of body.errors) {
                    this.errors.push(new ValidationResult(error.key, error.message));
                }
            }
        } else {
            this.message = data.message;
        }
    }

    toString() {
        return `FailServerResponse (${this.statusCode}), ${this.message}`;
    }

}

export class ValidationResult {

    constructor(public key: string, public message: string) {

    }

}
