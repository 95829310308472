import {SearchSuggestBlockInfo, SearchSuggestItem, SearchSuggestNodebookInfo} from "../../../models/search/SearchSuggestItem";
import {MemorySubject} from "../../../../modules/store";
import {distinctUntilChanged, map, Observable} from "rxjs";
import {generateUniqueId} from "../../../../shared/Utility";

const CHILD_COUNT_TO_CUT = 3;
const MAX_CHILD_TO_DISPLAY = 5;
export type SearchSuggestListItemValue = SearchSuggestItem | SearchSuggestNodebookInfo | SearchSuggestBlockInfo;

export type SearchSuggestListItem = {
    item: SearchSuggestListItemValue,
    id: string,
    expander?: boolean,
    hiddenItemsCount?: number;
    idToExpand?: string;
};

export class SearchSuggestListState {

    private linearList$ = new MemorySubject<SearchSuggestListItem[]>();
    private focusedId$ = new MemorySubject<string>("");
    private suggests: SearchSuggestItem[];

    private expanded$ = new MemorySubject<string[]>([]);
    private expandedId: string;

    constructor() {
        this.expanded$
            .subscribe(() => {
                this.rebuild();
            });
    }

    get focusedId(): string {
        return this.focusedId$.value;
    }

    get list(): Observable<SearchSuggestListItem[]> {
        return this.linearList$;
    }

    get listSnapshot(): SearchSuggestListItem[] {
        return this.linearList$.value;
    }

    get expanded(): string[] {
        return this.expanded$.value;
    }

    expand(item: SearchSuggestListItem) {
        if (!item.expander) {
            return;
        }
        const expanded = this.expanded$.value;
        if (expanded.indexOf(item.idToExpand) >= 0) {
            return;
        }
        expanded.push(item.idToExpand);
        this.expandedId = item.idToExpand;
        this.expanded$.next(expanded);
    }

    focusTo(item: SearchSuggestListItem) {
        this.focusById(item.id);
    }

    getFocused(): SearchSuggestListItem | undefined {
        return this.listSnapshot.find(l => l.id === this.focusedId$.value);
    }

    isFocused(item: SearchSuggestListItem): Observable<boolean> {
        return this.focusedId$
            .pipe(
                map(id => item.id === id),
                distinctUntilChanged()
            );
    }

    next() {
        let indx = this.listSnapshot.findIndex(s => s.id === this.focusedId$.value);
        if (indx < 0) {
            indx = 0;
        } else {
            indx++;
        }
        if (indx >= this.listSnapshot.length) {
            indx = 0;
        }
        this.focusById(this.listSnapshot[indx].id);
    }

    prev() {
        let indx = this.listSnapshot.findIndex(s => s.id === this.focusedId$.value);
        indx--;
        if (indx < 0) {
            indx = this.listSnapshot.length - 1;
        }
        this.focusById(this.listSnapshot[indx].id);
    }

    setExpanded(expanded: string[]) {
        this.expanded$.next(expanded);
    }

    update(suggests: SearchSuggestItem[], clearState: boolean, focusToId: string) {
        this.suggests = suggests;
        if (clearState) {
            this.expanded$.next([]);
        }
        this.rebuild();
        if (clearState) {
            if (this.listSnapshot.length) {
                this.focusById(focusToId ? focusToId : this.listSnapshot[0].id);
            }
        }
    }

    private focusById(id: string) {
        this.focusedId$.next(id);
    }

    private isIdExpanded(id: string): boolean {
        return this.expanded$.value.indexOf(id) >= 0;
    }

    private rebuild() {
        let newList: SearchSuggestListItem[] = [];
        let expanded: boolean, childsCount: number, shownCount: number;
        if (this.suggests) {
            for (const suggest of this.suggests) {
                newList.push({item: suggest, id: suggest.id});
                expanded = this.isIdExpanded(suggest.id);

                let childItems: SearchSuggestListItem[];

                if (suggest.blocks.length) {
                    childsCount = suggest.blocks.length;
                    const needToCut = !expanded && childsCount > MAX_CHILD_TO_DISPLAY;

                    shownCount = needToCut ? CHILD_COUNT_TO_CUT : 100;
                    childItems = suggest.blocks
                        .slice(0, shownCount)
                        .map((b, i) => {
                            return {
                                item: b,
                                id: suggest.id + "_" + i
                            };
                        });

                }

                if (suggest.notebooks.length) {
                    childsCount = suggest.notebooks.length;
                    const needToCut = !expanded && childsCount > MAX_CHILD_TO_DISPLAY;
                    shownCount = needToCut ? CHILD_COUNT_TO_CUT : 100;

                    childItems = suggest.notebooks
                        .slice(0, shownCount)
                        .map(n => {
                            return {
                                item: n,
                                id: suggest.id + "_" + n.name
                            };
                        });
                }

                if (childItems && childItems.length) {
                    newList = newList.concat(childItems);
                }

                if (this.expandedId === suggest.id) {
                    // to focus to first expanded item
                    this.focusedId$.next(childItems[CHILD_COUNT_TO_CUT].id);
                    this.expandedId = undefined;
                }

                // append expander
                if (!expanded && childsCount > 0) {
                    const hiddenCount = suggest.blocks.length - shownCount;
                    if (hiddenCount > 0) {
                        newList.push({
                            id: generateUniqueId(5),
                            idToExpand: suggest.id,
                            item: undefined,
                            expander: true,
                            hiddenItemsCount: hiddenCount
                        });
                    }
                }
            }
        }
        this.linearList$.next(newList);
    }
}
