<ng-template #withLink>
    <a [nHintDisabled]="!showTitle" [nHint]="title$|async" [routerLink]="navigateUrl$|async">
        <ng-container *ngTemplateOutlet="avatar"></ng-container>
    </a>
</ng-template>

<ng-template #avatar>
    <div [nHintDisabled]="!showTitle" [nHint]="title$ | async" class="wrapper">
        <avatar-img *ngIf="item.avatarPath$|async" [size]="size" [url]="item.avatarPath$ | async"></avatar-img>

        <span *ngIf="!(item.avatarPath$|async)" [style.backgroundImage]="background$|async" class="default">
            <svg class="default-text" height="100%" viewBox="0 0 100 100" width="100%">
                <text fill="#fff" text-anchor="middle" x="50" y="67">{{initials$|async}}</text>
            </svg>
        </span>
    </div>
</ng-template>

<ng-container *ngIf="(navigateUrl$|async); else avatar">
    <ng-container *ngTemplateOutlet="withLink"></ng-container>
</ng-container>


