import {AfterViewInit, Directive, ElementRef, HostListener, Inject, Input, Optional, Self} from "@angular/core";
import {ControlValueAccessor, NgControl, NgModel} from "@angular/forms";

export interface IAutoSizeComponent extends ControlValueAccessor {
    getInputElement(): HTMLInputElement | HTMLTextAreaElement;
}

const typographyStyles = [
    "fontFamily",
    "fontSize",
    "fontWeight",
    "fontStyle",
    "padding",
    "paddingTop",
    "paddingBottom",
    "letterSpacing",
    "textTransform",
    "wordSpacing",
    "textIndent",
    "lineHeight",
    "boxSizing"
];
const mirror: HTMLTextAreaElement = buildMirror();

let mirrored: HTMLTextAreaElement; // то поле, которое уже скопировано
let jsMirror: HTMLTextAreaElement;

@Directive({
    selector: "[autoSize]"
})
export class AutoSizeDirective implements AfterViewInit {

    @Input() maxHeight: number;
    @Input() autoSizeLines = 0;

    private lineHeight: number;
    private paddings: number;

    private lastText: string;
    private jsArea: HTMLTextAreaElement;

    private valueAccessor: IAutoSizeComponent;
    private widthDirty: boolean;

    constructor(private el: ElementRef,
                @Optional() @Inject(NgControl) private control: NgControl,
                @Optional() @Self() private ngModel: NgModel) {
        if (!control) {
            throw new Error("You cannot use autoSize directive without value accessor (formControl, ngModel...)");
        }
        this.valueAccessor = control.valueAccessor as IAutoSizeComponent;
    }

    ngAfterViewInit() {

        const element = this.valueAccessor.getInputElement ? this.valueAccessor.getInputElement() : this.el.nativeElement;
        this.maxHeight = this.maxHeight || 500;

        this.jsArea = <HTMLTextAreaElement>element;


        this.jsArea.addEventListener("input", () => {
            this.adjust(this.jsArea.value);
        });

        // учитываем изменение модели
        this.control.valueChanges.subscribe(val => {
            this.adjust(val);
        });

        if (this.ngModel) {
            this.adjust(this.ngModel.model);
        } else {
            this.adjust(this.control.value);
        }
    }

    @HostListener("window:resize")
    onResize() {
        this.widthDirty = true;
        // if (this.resizeTmrId) {
        //   clearTimeout(this.resizeTmrId);
        // }
        // this.resizeTmrId = setTimeout(() => {
        //   this.resizeTmrId = null;
        //
        //   if (this.isCurrentMirror()) {
        //     this.setWidth();
        //   }
        //   this.adjust(this.jsArea.value);
        //
        // }, 50);
    }

    private adjust(text: string) {

        if (this.lastText === text) {
            return;
        }

        this.initMirror();

        let height, original;

        // Вычисляем высоту одной строки
        if (!this.lineHeight) {
            this.lineHeight = getLineHeight(jsMirror);
        }
        // Вычисляем сумму отступов сверху и снизу
        if (!this.paddings) {
            this.paddings = parseInt(jsMirror.style.paddingTop, 10) + parseInt(jsMirror.style.paddingBottom, 10);
        }

        if (text) {
            this.lastText = text;
            jsMirror.value = text;

            jsMirror.style.overflowY = this.jsArea.style.overflowY;

            original = parseInt(this.jsArea.style.height, 10);

            // Setting scrollTop to zero is needed in IE8 and lower for the next step to be accurately applied
            jsMirror.scrollTop = 0;
            jsMirror.scrollTop = 9e4;

            // Using scrollTop rather than scrollHeight because scrollHeight is non-standard and includes padding.
            height = jsMirror.scrollTop;

            if (Math.abs(height - this.lineHeight) < 3) {
                // 1 строка текста, и лучше принять значение lineHeight, чтобы текстовое поле не дернулось
                height = this.lineHeight;
            }
        } else {
            this.lastText = "";
            height = this.lineHeight;
        }

        if (this.maxHeight && height > this.maxHeight) {
            this.jsArea.style.overflowY = "scroll";
            this.jsArea.classList.add("auto-size_over-max-height");
            height = this.maxHeight;
        } else {
            this.jsArea.style.overflowY = "hidden";
            this.jsArea.classList.remove("auto-size_over-max-height");

        }

        // Вычисляем высоту текстового поля, исходя из того что высота N строки должна помещаться ниже курсора
        height = Math.floor(height + (this.lineHeight * this.autoSizeLines) + this.paddings);
        if (original !== height) {
            if (height === 0) {
                this.jsArea.style.height = "";
            } else {
                this.jsArea.style.height = height + "px";
            }
        }
    }

    private initMirror() {

        // Добавляем в DOM
        if (!jsMirror) {
            jsMirror = mirror as HTMLTextAreaElement;
            document.body.appendChild(jsMirror);
        }

        if (this.jsArea.clientWidth === 0) {
            return;
        }


        if (this.isCurrentMirror()) {
            if (this.widthDirty) {
                // width changed after resize
                this.setWidth();
            }
            return;
        }

        mirrored = this.jsArea;
        // let styles                 = {};

        const areaStyles = window.getComputedStyle(this.jsArea);
        for (const style of typographyStyles) {
            mirror.style[style] = areaStyles[style];
        }

        this.setWidth();
        // Chrome-specific fix:
        // When the textarea y-overflow is hidden, Chrome doesn"t reflow the text to account for the space
        // made available by removing the scrollbar. This workaround triggers the reflow for Chrome.
        if (window["chrome"]) {
            const width = this.jsArea.style.width;
            this.jsArea.style.width = "0px";
            const ignore = this.jsArea.offsetWidth;
            this.jsArea.style.width = width;
        }
    }

    // Зеркало инициировано из текущего поля?
    private isCurrentMirror() {
        return mirrored === this.jsArea;
    }

    private setWidth() {
        let width;
        const style = window.getComputedStyle ? window.getComputedStyle(this.jsArea, null) : null;

        if (style) {
            width = this.jsArea.getBoundingClientRect().width;

            if (width === 0) {
                width = parseInt(style.width, 10);
            }

            if (style["boxSizing"] !== "border-box") {
                ["paddingLeft", "paddingRight", "borderLeftWidth", "borderRightWidth"].forEach((val) => {
                    width -= parseInt(style[val], 10);
                });
            }
        } else {
            width = Math.max(this.jsArea.clientWidth, 0);
        }

        jsMirror.style.width = width + "px";
        this.widthDirty = false;
    }

}

function buildMirror(): HTMLTextAreaElement {
    const mirrorElement = document.createElement("textarea");
    mirrorElement.id = "__txt_mirror";
    mirrorElement.tabIndex = -1;
    mirrorElement.style.left = "0";
    mirrorElement.style.top = "-9999px";
    mirrorElement.style.border = "0";
    mirrorElement.style.padding = "0";
    mirrorElement.style.wordWrap = "break-word";
    mirrorElement.style.height = "0";
    mirrorElement.style.width = "30px";
    mirrorElement.style.minHeight = "0";
    mirrorElement.style.overflow = "hidden";
    mirrorElement.style.position = "absolute";

    return mirrorElement;
}

function getLineHeight(elem: HTMLElement): number {
    const parsedLineHeight = parseFloat(elem.style.lineHeight);
    let result;
    if (elem.style.lineHeight.indexOf("px") >= 0) {
        result = parsedLineHeight;
    } else {
        const fontSize = parseInt(elem.style.fontSize, 10);
        result = fontSize * parsedLineHeight;
    }
    return result;
}
