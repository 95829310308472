import {TeamInfo} from "../models/TeamInfo";
import {NotebookInfo} from "../models/NotebookInfo";
import {BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, switchMap} from "rxjs";
import {TeamService} from "../services/team.service";
import {AppService} from "../services/app.service";
import {UserService} from "../services/user.service";

export function filterNotebookWithEditPermission(team: TeamInfo, notebooks: NotebookInfo[])
    : { noAccessToAll: boolean, notebooks: NotebookInfo[] } {
    let hideNotebooks = false;
    const result = notebooks.filter(n => {
        if (n.editRestrict === "administrators" && team.currentUserRole !== "administrator") {
            hideNotebooks = true;
            return false;
        }
        return true;
    });
    return {noAccessToAll: hideNotebooks, notebooks: result.sort(sortNotebooksAsc)};
}


function sortNotebooksAsc(a: NotebookInfo, b: NotebookInfo): number {
    if (a.title < b.title)
        return -1;
    if (a.title > b.title)
        return 1;
    return 0;
}


export interface IDocumentAsideViewComponent {
    componentHidden?();

    componentShown?();
}

/**
 * Return notebooks list for specified notebook owner with edit permission
 */
export function getNotebookListWithEditPermission(notebook: NotebookInfo,
                                                  teams: TeamService,
                                                  appSvc: AppService,
                                                  userSvc: UserService):
    {
        // with edit permission
        notebooks$: Observable<NotebookInfo[]>,
        // all team or user notebooks
        allNotebooks$: Observable<NotebookInfo[]>
        // edit notebooks has hidden (read access) notebooks
        hasHiddenNotebooks$: Observable<boolean>
    } {
    let notebooks$: Observable<NotebookInfo[]>;
    let hasHiddenNotebooks$ = new BehaviorSubject(false);
    let allNotebooks$: Observable<NotebookInfo[]>;
    if (notebook.isTeamNotebook) {
        const teamName = notebook.ownerTeam.name;
        allNotebooks$ = teams.getTeamNotebooks(teamName, false);
        notebooks$ = combineLatest([allNotebooks$, teams.getTeam(teamName, false)])
            .pipe(
                map(([notebooks, team]) => {
                    const result = filterNotebookWithEditPermission(team, notebooks);
                    hasHiddenNotebooks$.next(result.noAccessToAll);
                    return result.notebooks;
                })
            );
    } else {
        notebooks$ = appSvc.currentUserProfile$
            .pipe(
                map(profile => profile.username),
                distinctUntilChanged(),
                switchMap(username => userSvc.getUserNotebooks(username, false))
            );
        allNotebooks$ = notebooks$;
    }

    return {notebooks$, hasHiddenNotebooks$, allNotebooks$};
}
