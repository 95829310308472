<div [class.shared]="shared" class="icon">
    <svg [style.fill]="color">
        <g fill-rule="evenodd">
            <path class="notebook-cover"
                  d="M5 0h21a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H5a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"></path>
            <path class="notebook_32_is-public" d="M25 17a9 9 0 0 0-9 9h2a7 7 0 0 1 7-7v-2zm0 3a6 6 0 0 0-6 6h2a4 4 0 0 1 4-4v-2zm0 3a3 3 0 0 0-3 3h3v-3z"
                  fill="rgba(0,0,0,0.3)"></path>
            <path d="M10 8h13v2H10V8zm0 4h10v2H10v-2zM5 29h19.757a3 3 0 0 0 2.122-.879L27 28v1.5a1.5 1.5 0 0 1-1.5 1.5h-20a.5.5 0 0 1-.5-.5V29z"
                  fill="#FFF"></path>
            <path d="M6 1.5v26a.5.5 0 1 0 1 0v-26a.5.5 0 0 0-1 0z" fill="rgba(0,0,0,0.2)"></path>
        </g>
    </svg>
</div>
