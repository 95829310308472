import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentService} from "../../services/document.service";
import {NotebookInfo} from "../../models/NotebookInfo";
import {BehaviorSubject, Observable, withLatestFrom} from "rxjs";
import {getNotebookListWithEditPermission} from "../../shared/notebook-helpers";
import {TeamService} from "../../services/team.service";
import {AppService} from "../../services/app.service";
import {UserService} from "../../services/user.service";
import {MemorySubject} from "../../../modules/store";
import {DocumentListInfo} from "../../models/documents/DocumentListInfo";
import {ErrorService} from "../../services/error.service";
import {ToastService} from "../../../modules/n-toasts";
import {TranslateService} from "../../../modules/translate";

@Component({
    selector: "documents-move",
    templateUrl: "./documents-move.html",
    styleUrls: [
        "./documents-move.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsMoveComponent implements OnInit {

    @Input()
    documents: DocumentListInfo[];

    @Input()
    notebook: NotebookInfo;

    @Output()
    done = new EventEmitter();

    notebooks$: Observable<NotebookInfo[]>;

    hasHiddenNotebooks$: Observable<boolean>;

    selectedNotebookName$ = new MemorySubject<string>();
    loading$ = new BehaviorSubject(false);
    moveSignal = new EventEmitter();

    constructor(private documentSvc: DocumentService,
                private teams: TeamService,
                private appSvc: AppService,
                private userSvc: UserService,
                private errSvc: ErrorService,
                private toasts: ToastService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        const notebooksResult = getNotebookListWithEditPermission(this.notebook, this.teams, this.appSvc, this.userSvc);

        this.notebooks$ = notebooksResult.notebooks$;
        this.hasHiddenNotebooks$ = notebooksResult.hasHiddenNotebooks$;
        this.selectedNotebookName$.next(this.notebook.name);

        this.moveSignal
            .pipe(
                withLatestFrom(this.notebooks$)
            )
            .subscribe(async ([, notebooks]) => {
                const selectedNotebook = notebooks.find(n => n.name === this.selectedNotebookName$.value);
                if (selectedNotebook) {
                    await this.move(selectedNotebook);
                }
            });
    }

    notebookIdentity = (index, notebook: NotebookInfo) => notebook.name;

    select(notebook: NotebookInfo) {
        this.selectedNotebookName$.next(notebook.name);
    }

    private async move(notebook: NotebookInfo) {
        if (this.loading$.value) {
            return;
        }
        this.loading$.next(true);
        try {
            await this.documentSvc.moveDocuments(this.documents, notebook);
            this.done.emit();
            this.toasts.showSuccess(this.translate.getString("notebooks.move.success"));
        } catch (e) {
            this.errSvc.showFail(e);
        } finally {
            this.loading$.next(false);
        }
    }
}
