import {Injectable} from "@angular/core";
import {ToastService} from "../../modules/n-toasts/toast.service";
import {FailServerResponse} from "../models/FailServerResponse";
import {TranslateService} from "../../modules/translate/translate.service";

@Injectable({providedIn: "root"})
export class ErrorService {

    constructor(private toasts: ToastService, private translate: TranslateService) {

    }

    showError(messageKey: string) {
        let message = this.translate.getString(messageKey);
        this.showToast(message);
    }

    showFail(fail: FailServerResponse) {
        let message = fail.message || "";
        if (!message) {
            message = this.getHttpStatusMessage(fail.statusCode);
        }
        if (fail.errors && fail.errors.length) {
            message += ": \n";
            for (let error of fail.errors) {
                if (typeof (error) === "string") {
                    message += error + "\n ";
                } else {
                    if (error.message) {
                        message += error.message + " ";
                    }
                }
            }
        }
        this.showToast(message);
    }

    private getHttpStatusMessage(status: number) {
        switch (status) {
            case 400:
                return this.translate.getString("http-message.400");
            case 0:
                return this.translate.getString("http-message.0");
            case 404:
                return this.translate.getString("http-message.404");
            case 403:
                return this.translate.getString("http-message.403");
            case 409:
                return this.translate.getString("http-message.409");
            case 500:
                return this.translate.getString("http-message.500");
            default:
                return this.translate.getString("http-message.unknown") + ": " + status;
        }
    }

    private showToast(message: string) {
        this.toasts.showError(message);
    }
}
