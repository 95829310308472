<div (click)="createDocument()" class="new-document-action">
    <div class="button">
        <n-icon [iconId]="'plus'" class="button-icon"></n-icon>
        <span class="button-text">{{"documents.create" | translate }}</span>
    </div>

    <div class="body">
        <div class="icon">
            <div class="cyrcle"></div>
            <n-icon [iconId]="'plus'" class="plus-icon"></n-icon>
        </div>
        <div class="info">
            <div class="text">{{"documents.create" | translate }}</div>
            <div class="document-name">{{text}}</div>
        </div>
        <div class="hint">Shift + Enter</div>
    </div>
</div>
