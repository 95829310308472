<google-signin-button *ngIf="!hideGoogle"></google-signin-button>

<div class="form-window-extra__sign-up">
    <span *ngIf="showSignUp">
        {{"signIn.signUpLabel" | translate}}<br>
        <a [routerLink]="['/', 'sign-up']">
            {{"signIn.signUpAction" | translate}}
        </a>
    </span>

    <span *ngIf="showSignIn">
        {{"signUp.signInLabel" | translate}}<br>
        <a [routerLink]="['/']">
            {{"signUp.signInAction" | translate}}
        </a>
    </span>

    <span *ngIf="showBoth">
        <a [routerLink]="['/']">
            {{"signUp.signInAction" | translate}}
        </a>
        {{"common.or" | translate}}
        <a [routerLink]="['/', 'sign-up']">
            {{"signIn.signUpAction" | translate}}
        </a>
    </span>
</div>
