import {PopoverCollection, PopoverEvent} from "../PopoverCollection";
import {PopoverInstance} from "../PopoverInstance";
import {ElementRef, EventEmitter} from "@angular/core";
import {isParentElement} from "../helpers";
import {PopoverStack} from "../PopoverStack";

const POPOVER_OPEN_CLASS = "popover-open";
const POPOVER_MOBILEVIEW_CLASS = "popover-open__mobile";

export class DOMPopoverCollection extends PopoverCollection {
    events = new EventEmitter<PopoverEvent>();

    private stack = new PopoverStack();
    // private activePopover: PopoverInstance<any>;

    constructor() {
        super();
        document.addEventListener("mousedown", this.mouseDownHandler, {capture: true, passive: true});
    }

    add(popover: PopoverInstance<any>) {

        Promise.resolve().then(() => {
            // avoid close on click before open
            this.stack.push(popover);

            this.events.emit({type: "open"});
            popover.onClose.subscribe(async () => {
                await this.stack.closePopover(popover);
                this.events.emit({type: "closed"});
                this.invalidateBodyClass(popover);
            });
            this.invalidateBodyClass(popover);
        });
    }

    async closeAll() {
        await this.stack.closeAll();
        this.invalidateBodyClass();
    }

    private invalidateBodyClass(targetPopover?: PopoverInstance) {
        if (!targetPopover) {
            document.body.classList.remove(POPOVER_OPEN_CLASS);
            document.body.classList.remove(POPOVER_MOBILEVIEW_CLASS);
            return;
        }
        if (this.stack.openedCount > 0) {
            document.body.classList.add(POPOVER_OPEN_CLASS);
            if (targetPopover.options.mobileView) {
                document.body.classList.add(POPOVER_MOBILEVIEW_CLASS);
            }
        } else {
            document.body.classList.remove(POPOVER_OPEN_CLASS);
            if (targetPopover.options.mobileView) {
                document.body.classList.remove(POPOVER_MOBILEVIEW_CLASS);
            }
        }
    }

    private mouseDownHandler = (e) => {
        const target = e.target;

        const openedPopovers = this.stack.getAll().reverse();
        // traverse from top to bottom
        for (let openedPopover of openedPopovers) {
            if (target && openedPopover.opener && isParentElement(target, openedPopover.opener.nativeElement)) {
                // click on popover opener element
                return;
            }

            const outOfPopover = checkClickOutPopover(e, openedPopover.popoverElement);
            if (!outOfPopover) {
                return;
            }

            if (openedPopover.autoclose) {
                this.stack.closePopover(openedPopover);
                this.invalidateBodyClass(openedPopover);
            }
        }
    };
}

function checkClickOutPopover(event, el: ElementRef) {
    let parent = event.target;
    while (parent) {
        if (parent !== document && (parent === el.nativeElement.parent || parent === el.nativeElement)) {
            return false;
        }

        if (!parent.parentNode && parent !== document) {
            return false;
        } else {
            parent = parent.parentNode;
        }
    }
    return true;
}

