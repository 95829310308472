import {ActivatedRoute} from "@angular/router";

export class ContextualRouteInfo {

    constructor(public id: string,
                public route: ActivatedRoute,
                public currentRouteTitle: string) {

    }

}
