<div class="avatar">
    <user-avatar [item]="user" [link]="true"></user-avatar>
</div>
<div class="info">
    <div class="username">@{{user.username}}</div>
    <h3 class="name">
        <user-link [item]="user"></user-link>
    </h3>
    <div *ngIf="user.externalPageUrl" class="external">
        <a [href]="user.externalPageUrl" target="_blank">{{ "profile.externalLink" | translate }}
            {{ domainName$|async }}</a>
    </div>
</div>
