import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "./translate.service";

@Pipe({
    name: "translateQuantity"
})
export class TranslateQuantityPipe implements PipeTransform {

    constructor(private translate: TranslateService) {

    }

    transform(num: number, key: string): any {
        const dismissNumber = key.indexOf("-") === 0;
        if (dismissNumber) {
            return this.translate.translateQuantity(num, key.slice(1));
        }
        return num.toString() + " " + this.translate.translateQuantity(num, key);
    }

}
