import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ReactiveFormsModule} from "@angular/forms";
import {N_DIRECTIVES} from "./directives";
import {N_PUBLIC_COMPONENTS} from "./components";
import {NDropdownMenuPopoverComponent} from "./components/dropdown/n-dropdown-menu-popover/n-dropdown-menu-popover.component";
import {NHintComponent} from "./components/n-hint/n-hint.component";

const PUBLIC_DIRECTIVES = [
    ...N_PUBLIC_COMPONENTS,
    ...N_DIRECTIVES,
];

@NgModule({
    declarations: [
        ...PUBLIC_DIRECTIVES,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule
    ],
    exports: [
        ...PUBLIC_DIRECTIVES
    ],
    providers: []
})
export class NFrameworkModule {
}
