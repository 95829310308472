import {EventEmitter, Injectable} from "@angular/core";
import {ModalInstance, ModalService} from "../../../modules/modal";
import {ActivatedRoute, NavigationEnd, Params, Router} from "@angular/router";
import {filter, pluck} from "rxjs";
import {SearchSuggestModalComponent} from "../../components/search/search-suggest-modal/search-suggest-modal.component";
import {Location} from "@angular/common";
import {DocumentService} from "../document.service";

export interface ISearchSuggestModalData {
    navigateEvents: EventEmitter<{ url: any[], pathToHighlight?: number[], documentId?: string }>;
}

@Injectable({providedIn: "root"})
/**
 * Manage search suggest modal
 */
export class SearchSuggestsService {
    private suggestModalInst: ModalInstance<any>;
    private navigateToResultEvents = new EventEmitter<{ url: any[], pathToHighlight?: number[], documentId?: string }>();
    private nextNavigation: any[];
    private navigating: boolean;

    constructor(private router: Router,
                private modals: ModalService,
                private location: Location,
                docSvc: DocumentService,
                route: ActivatedRoute) {

        this.navigateToResultEvents.subscribe(async ({url, pathToHighlight, documentId}) => {
            if (this.navigating) {
                return;
            }
            this.nextNavigation = url;
            this.navigating = true;
            try {
                docSvc.highlightBlock(documentId, pathToHighlight);
                await this.navigateToClose();
            } finally {
                this.navigating = false;
            }

        });

        route.queryParams
            .pipe(
                pluck("search")
            )
            .subscribe(val => {
                if (val && !this.isOpened()) {

                    this.suggestModalInst = this.modals.open(SearchSuggestModalComponent, {
                        escape: true,
                        backdrop: true,
                        windowClass: "lightbox"
                    }, {
                        navigateEvents: this.navigateToResultEvents
                    } as ISearchSuggestModalData);

                    this.suggestModalInst.result
                        .then((url: any[]) => {
                            this.nextNavigation = url;
                            if (!this.nextNavigation) {
                                this.closeModal(true);
                            }
                        })
                        .catch(() => this.closeModal(true));
                } else if (!val && this.isOpened()) {
                    if (!this.nextNavigation) {
                        this.closeModal(false);
                    }
                }

            });

        router.events
            .pipe(
                filter(e => e instanceof NavigationEnd)
            )
            .subscribe(async () => {
                if (this.nextNavigation) {
                    await this.router.navigate(this.nextNavigation);
                    this.nextNavigation = undefined;
                    await this.closeModal(false);
                }
            });
    }

    async openSuggests() {
        if (this.isOpened()) {
            return; // already opened
        }
        await this.updateQuery({search: "1"});
    }

    private async closeModal(navigate: boolean) {
        if (this.isOpened()) {
            const modal = this.suggestModalInst;
            // first set modal undefined, to avoid close loop
            this.suggestModalInst = undefined;

            await modal.close();
            if (navigate) {
                await this.navigateToClose();
            }
        }
    }

    private isOpened(): boolean {
        return !!this.suggestModalInst;
    }

    private async navigateToClose() {
        if (window.history.length > 2) {
            this.location.back();
        } else {
            await this.updateQuery({search: undefined});
        }
    }

    private updateQuery(queryParams: Params) {
        return this.router.navigate(
            [],
            {
                queryParams: queryParams,
                queryParamsHandling: "merge" // remove to replace all query params by provided
            });
    }
}
