import {ModalService} from "./ModalService";

let escEnabled = true;

export function enableModalEscKey(enable: boolean) {
    escEnabled = enable;
}

export function closeModalOnEsc(modalService: ModalService) {
    window.addEventListener("keydown", (evt: KeyboardEvent) => {

        if (!modalService.isOpen() || !escEnabled) {
            return;
        }
        let last = modalService.getLast();
        if (!last.options.escape) {
            return;
        }
        switch (evt.which) {
            case 27: {
                evt.preventDefault();
                modalService.closeLast();
                break;
            }
        }
    });

    return () => Promise.resolve();
}