import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Injector, Renderer2, ViewChild} from "@angular/core";
import {applyArrowStyles, calculatePosition, IBox} from "../../../n-popover/position";


export type NHintPlacement = "top" | "left" | "right" | "bottom";

@Component({
    selector: "n-hint",
    styleUrls: [
        "./n-hint.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<span class="title">{{title}}</span><span *ngIf="subtitle" class="subtitle">{{subtitle}}</span><span #arrow
                                                                                                                  class="arrow"></span>`
})
export class NHintComponent implements AfterViewInit {

    title: string;
    subtitle: string;

    @ViewChild("arrow", {static: true})
    arrow: ElementRef;

    private targetElement: ElementRef;
    private placement: NHintPlacement;

    constructor(private el: ElementRef,
                private injector: Injector,
                private renderer: Renderer2) {

        const options = this.injector.get(NHintOptions);

        this.title = options.title;
        this.subtitle = options.subtitle;
        this.placement = options.placement;
        this.targetElement = options.target;

        renderer.setAttribute(el.nativeElement, "role", "tooltip");
    }

    ngAfterViewInit() {
        const targetRect = this.targetElement.nativeElement.getBoundingClientRect();

        const windowContainer: IBox = {
            width: (document.body.offsetWidth || document.body.clientWidth),
            height: window.innerHeight
        };

        let arrowSize: IBox;
        switch (this.placement) {
            case "left":
            case "right":
                arrowSize = {width: 5, height: 10};
                break;
            case "top":
            case "bottom":
                arrowSize = {width: 10, height: 5};
                break;
        }

        const position = calculatePosition(this.placement,
            targetRect,
            {height: this.el.nativeElement.clientHeight, width: this.el.nativeElement.clientWidth},
            windowContainer, undefined, arrowSize
        );

        const popoverY = position.top + (window.pageYOffset || document.documentElement.scrollTop);

        this.renderer.setStyle(this.el.nativeElement, "left", position.left + "px");
        this.renderer.setStyle(this.el.nativeElement, "top", popoverY + "px");

        // position arrow

        this.renderer.addClass(this.arrow.nativeElement, `_${position.arrowPos.arrowPlacement}`);
        applyArrowStyles(this.renderer, this.arrow, position.arrowPos);
    }

}

export class NHintOptions {

    readonly title: string;
    readonly subtitle: string;
    readonly placement: NHintPlacement;
    readonly target: ElementRef;

    constructor(title: string,
                subtitle: string,
                placement: NHintPlacement,
                target: ElementRef) {
        this.title = title;
        this.subtitle = subtitle;
        this.placement = placement;
        this.target = target;
    }

}
