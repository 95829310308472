import {ModalInstance} from "./ModalInstance";
import {IModalOptions} from "./IModalOptions";
import {ModalRenderer} from "./ModalRenderer";
import {ComponentRef, TemplateRef} from "@angular/core";
import {ModalBackdropComponent} from "./components/modal-backdrop/modal-backdrop.component";


const BODY_CLASS = "modal-open";

export class ModalStack {

    private stack: ModalInstance<any>[] = [];
    private backdrop: ComponentRef<ModalBackdropComponent>;

    constructor() {

    }

    get openedModalCount(): number {
        return this.stack.length;
    }

    get openedVisibleModalCount(): number {
        return this.stack.filter(i => !i.stashed).length;
    }

    get openedBackdropModalCount(): number {
        return this.stack.filter(i => i.options.backdrop).length;
    }

    closeLast() {
        if (this.openedVisibleModalCount === 0) {
            return;
        }
        const instance = this.getLast();

        return instance.close().then(() => {
            this.removeInstance(instance);

        });
    }

    getLast(): ModalInstance<any> {
        return this.stack[this.stack.length - 1];
    }

    open<TResult>(modalRenderer: ModalRenderer,
                  modalData?: any,
                  componentType?: Function,
                  templateRef?: TemplateRef<any>,
                  options?: IModalOptions): ModalInstance<TResult> {

        if (!componentType && !templateRef) {
            throw new Error("Specify template or component for modal");
        }

        const layer = this.openedModalCount;
        const instance = componentType ? modalRenderer.render<TResult>(componentType, modalData, options, layer) : modalRenderer.renderTemplate<TResult>(templateRef, modalData, options, layer);

        const modalsToClose = this.getModalsAfter(instance);

        instance.dismiss.subscribe(async () => {
            await closeModals(modalsToClose);
            this.removeInstance(instance);
        });

        this.stack.push(instance);
        // this.lastOpenedLayer = layer;

        const onModalOpen = () => {
            if (options && options.backdrop && !this.backdrop) {
                this.backdrop = modalRenderer.renderBackdrop(options && options.injector);
                this.backdrop.instance.setLayer(layer);
            }

            if (options && options.openBodyClass) {
                addBodyClass(options.openBodyClass);
            }
            this.invalidateBodyClass();

            if (this.stack.length > 1) {
                this.stack[this.stack.length - 2].markAsLast(false);
            }
            const last = this.getLast();

            last.markAsLast(true);
            last.focus();
        };

        if (!options || !options.stashed) {
            onModalOpen();
        }
        instance.onUnstash.subscribe(onModalOpen);

        return instance;
    }

    private getModalsAfter(instance: ModalInstance<any>): ModalInstance<any>[] {
        const indx = this.stack.indexOf(instance);
        if (indx < 0 || indx === this.stack.length - 1) {
            return [];
        }
        return this.stack.slice(indx);
    }

    private invalidateBodyClass() {
        if (this.openedVisibleModalCount === 0) {
            removeBodyClass(BODY_CLASS);
        } else {
            addBodyClass(BODY_CLASS);
        }
    }

    private removeInstance(instance: ModalInstance<any>) {
        const indx = this.stack.indexOf(instance);
        if (indx >= 0) {
            this.stack.splice(indx, 1);
        }

        if (instance.options.openBodyClass) {
            removeBodyClass(instance.options.openBodyClass);
        }

        this.invalidateBodyClass();

        if (this.openedBackdropModalCount === 0) {
            if (this.backdrop) {
                this.backdrop.destroy();
                this.backdrop = undefined;
            }
        } else {
            // focus last opened modal window
            const last = this.getLast();

            last.focus();
            last.markAsLast(true);
        }
    }
}

function addBodyClass(className: string) {
    document.body.classList.add(className);
}

function removeBodyClass(className: string) {
    document.body.classList.remove(className);
}

async function closeModals(instances: ModalInstance<any>[]) {
    for (let i = instances.length - 1; i >= 0; i--) {
        await instances[i].close();
    }
}
