<div [class._empty]="empty$|async"
     [ngClass]="{'_disabled': isDisabled, '_required': required}"
     class="n-select">

    <button #selectButton (blur)="onBlur()"
            (dropdownToggleClosed)="onMenuClosed()"
            (dropdownToggleOpened)="onMenuOpened()"
            (keydown)="onKeyDown($event)"
            [class._active]="isActive$|async"
            [disabled]="isDisabled"
            [horizontalAlign]="'left'"
            [nDropdownOffset]="3"
            [nDropdown]="menu"
            [setupMinWidth]="true" class="n-select__link"
            type="button">
    <span *ngIf="(selectedOption$|async)?.iconId|async" class="n-select__icon">
        <n-icon [fillColor]="(selectedOption$|async)?.iconColor|async"
                [iconId]="(selectedOption$|async).iconId|async"></n-icon>
    </span>
        <span *ngIf="selectedOption$|async" class="n-select__text">{{(selectedOption$|async).heading|async}}</span>
        <span *ngIf="!(selectedOption$|async)" class="n-select__placeholder">{{placeholder}}</span>
        <span [delay]="200" [nHint]="errorMessage" class="n-select__system-icon"></span>
    </button>

    <div *ngIf="switcher" class="n-select__pills">
        <button (click)="selectOption(option)"
                *ngFor="let option of items$|async; trackBy: optionIdentity"
                [attr.tid]="option.tid"
                [class._active]="(selectedOption$|async) === option"
                [disabled]="isDisabled"
                class="n-select__pill"
                type="button">
        <span *ngIf="option.iconId|async" class="pill-icon">
          <n-icon [fillColor]="option.iconColor|async" [iconId]="option.iconId|async"></n-icon>
        </span>
            <span *ngIf="option.heading|async" class="pill-text">
          {{option.heading|async}}
        </span>
        </button>
    </div>

    <n-dropdown-menu #menu [wide]="true">
    </n-dropdown-menu>
</div>
