export const CURRENT_DOMAIN = location.protocol + "//" + location.host;


export function generateUniqueId(length: number): string {
    // always start with a letter (for DOM friendlyness)
    let idstr = String.fromCharCode(Math.floor((Math.random() * 25) + 65));
    do {
        // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
        let ascicode = Math.floor((Math.random() * 42) + 48);
        if (ascicode < 58 || ascicode > 64) {
            // exclude all chars between : (58) and @ (64)
            idstr += String.fromCharCode(ascicode);
        }
    } while (idstr.length < length);
    return idstr.toLowerCase();
}

export function removeFromArray<T>(arr: T[], itemToRemove: T) {
    const indx = arr.indexOf(itemToRemove);
    if (indx >= 0) {
        arr.splice(indx, 1);
    }
}

export function removeItemsFromArray<T>(array: T[], predicate: (item: T, i: number) => boolean): number {
    let i = array.length;
    let counts = 0;
    while (i--) {
        if (predicate(array[i], i)) {
            array.splice(i, 1);
            counts++;
        }
    }
    return counts;
}

export function copyTextToClipboard(text: string): boolean {
    // Create fake element tot copy from
    const fakeElem = document.createElement("textarea");

    // Prevent zooming on iOS
    fakeElem.style.fontSize = "12pt";
    // Reset box model
    fakeElem.style.border = "0";
    fakeElem.style.padding = "0";
    fakeElem.style.margin = "0";
    // Move element out of screen horizontally
    fakeElem.style.position = "absolute";
    fakeElem.style["left"] = "-9999px";
    // Move element to the same position vertically
    let yPosition = window.pageYOffset || document.documentElement.scrollTop;
    fakeElem.style.top = `${yPosition}px`;

    fakeElem.setAttribute("readonly", "");
    fakeElem.value = text;

    document.body.appendChild(fakeElem);

    fakeElem.select();
    fakeElem.setSelectionRange(0, fakeElem.value.length);
    fakeElem.removeAttribute("readonly");

    try {
        return document.execCommand("copy");
    } catch (err) {
        return false;
    } finally {
        document.body.removeChild(fakeElem);
    }

}


let _isMobile: boolean;

const userAgent = navigator.userAgent;

export function isMobileDevice(): boolean {
    if (typeof (_isMobile) !== "undefined") {
        return _isMobile;
    }

    _isMobile = !!(userAgent.match(/Android/i)
        || userAgent.match(/webOS/i)
        || userAgent.match(/iPhone/i)
        || userAgent.match(/iPad/i)
        || userAgent.match(/iPod/i)
        || userAgent.match(/BlackBerry/i)
        || userAgent.match(/Windows Phone/i));

    return _isMobile;

}

const isMacOs = !!(navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i));

export function getOsCommandIdentifier(macText, otherText) {
    if (isMacOs) {
        return macText;
    }
    return otherText;
}
