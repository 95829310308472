<label [ngClass]="modifierText"
       class="radio"
       tid="n-radio">

    <input [formControl]="inputControl"
           [name]="name"
           [value]="value"
           class="input"
           type="radio">

    <span class="text">

        <span>
            {{text}}
        </span>

        <span class="desc">
            {{description}}
        </span>

    </span>

    <ng-content></ng-content>
</label>
