import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {IDropdownMenuItem} from "../n-dropdown-menu.component";
import {combineLatest, distinctUntilChanged, map, Observable, Subject, switchMap, takeUntil} from "rxjs";
import {PopoverContext} from "../../../../n-popover";

// noinspection TypeScriptPreferShortImport

@Component({
    selector: "n-dropdown-menu-popover",
    templateUrl: "./n-dropdown-menu-popover.html",
    styleUrls: [
        "./n-dropdown-menu-popover.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NDropdownMenuPopoverComponent implements OnInit, OnDestroy, AfterViewInit {

    menuItems$: Observable<IDropdownMenuItem[]>;
    hasIcon$: Observable<boolean>;
    focusedIndex$: Observable<number>;

    @ViewChild("menuElm", {static: true})
    menuElm: ElementRef;
    wide: boolean;
    private componentExist$ = new Subject<any>();
    private focusedIndexSnapshot: number;

    constructor(private ctx: PopoverContext<{ items: Observable<IDropdownMenuItem[]>, focusedIndex: Observable<number>, wide?: boolean }>) {
        this.menuItems$ = ctx.popoverData.items;
        this.focusedIndex$ = ctx.popoverData.focusedIndex;
        this.wide = ctx.popoverData.wide;
        this.hasIcon$ = this.menuItems$.pipe(
            switchMap(items => combineLatest(items.map(i => i.iconId))),
            map(icons => icons.some(i => !!i)),
            distinctUntilChanged()
        );
    }

    ngAfterViewInit() {
        this.invalidateActiveResultVisible(this.focusedIndexSnapshot);
    }

    ngOnDestroy() {
        this.componentExist$.next(1);
        this.componentExist$.complete();
    }

    ngOnInit() {

        this.focusedIndex$
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.componentExist$)
            )
            .subscribe(index => {
                this.focusedIndexSnapshot = index;
                if (index >= 0) {
                    // scroll to focused item
                    this.invalidateActiveResultVisible(index);
                }
            });
    }

    select(item: IDropdownMenuItem) {
        if (item.disabledSnapshot()) {
            return;
        }
        this.ctx.done(item);
    }

    private invalidateActiveResultVisible(index: number) {

        const wrapper = this.menuElm.nativeElement;

        const items = wrapper.querySelectorAll(".menu-item");
        const focusedItem = items[index];

        if (focusedItem) {
            const wrapperRect = wrapper.getBoundingClientRect();
            const height = wrapperRect.height;

            if (height <= 0) {
                return;
            }
            let scrollTop = wrapper.scrollTop;
            let visibleBottom = height + scrollTop;

            let elTop = focusedItem.offsetTop;
            let elBottom = elTop + focusedItem.offsetHeight;
            if (elBottom >= visibleBottom) {
                wrapper.scrollTop = scrollTop + (elBottom - visibleBottom);
            } else if (focusedItem.offsetTop < scrollTop) {
                wrapper.scrollTop = focusedItem.offsetTop;
            }
        }

    }
}
