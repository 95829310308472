<div #container [class._clearable]="clearable"
     [class._required]="required"
     [class.n-input_wide]="wide"
     class="n-input">

  <span *ngIf="icon" class="n-input__icon">
      <n-icon [fillColor]="'rgba(0,0,0,0.6)'" [iconId]="icon" [iconSet]="iconSet" [iconSize]="'14'"
              [optionalSize]="true"></n-icon>
  </span>

    <input #input
           (blur)="onBlur()"
           (compositionend)="compositionEnd($event)"
           (compositionstart)="compositionStart()"
           (focus)="focus.emit($event)"
           (input)="onChange($event)"
           [attr.pattern]="pattern"
           [attr.readonly]="readonly ? '' : null"
           [placeholder]="placeholder"
           [type]="type"
           autocomplete="{{autocomplete ? 'on' : 'off'}}"
           class="n-input__input"/>

    <span (mouseleave)="onMouseLeave()"
          (mouseover)="onMouseOver()"
          class="n-input__system-icon"></span>

    <span class="n-select__error">{{errorMessage}}</span>

    <span (click)="clear()"
          (mouseleave)="onMouseLeave()"
          (mouseover)="onMouseOver()"
          *ngIf="clearable && !disabled"
          [class._show]="showClear"
          class="n-input__clear-button"></span>
</div>
