import {EntityColor, getEntityCssColor} from "../EntityColor";
import {IEntityWithAvatar} from "../IEntityWithAvatar";
import {Observable, of} from "rxjs";

export type SearchSuggestType = "team" | "user" | "doc";

export class SearchSuggestItem implements IEntityWithAvatar {

    readonly $type = "suggest";

    readonly type: SearchSuggestType | string /*for AOT*/;

    readonly title: string;
    readonly description: string;
    readonly avatarPath: string;
    readonly name: string;
    readonly scope: string;

    readonly notebooks: SearchSuggestNodebookInfo[];
    readonly blocks: SearchSuggestBlockInfo[];

    readonly avatarColor1: EntityColor;
    readonly avatarColor2: EntityColor;
    readonly avatarPath$: Observable<string>;

    readonly author: string;
    readonly created: Date | undefined;

    readonly notebookTitle: string;

    constructor(type: SearchSuggestType,
                title: string,
                description: string,
                avatarPath: string,
                avatarColors: string,
                name: string,
                scope: string,
                notebooks: SearchSuggestNodebookInfo[],
                blocks: SearchSuggestBlockInfo[],
                author: string,
                notebookTitle: string,
                created?: Date) {
        this.type = type;
        this.name = name;
        this.title = title;
        this.description = description;
        this.avatarPath = avatarPath;
        this.name = name;
        this.scope = scope;
        this.notebooks = notebooks;
        this.blocks = blocks;

        this.author = author;
        this.created = created;

        if (avatarColors) {
            const colors = avatarColors.split(";");
            this.avatarColor1 = colors[0] as EntityColor;
            this.avatarColor2 = colors[1] as EntityColor;
        }

        this.avatarPath$ = of(this.avatarPath);
        this.notebookTitle = notebookTitle;
    }

    get id(): string {
        return `${this.type}_${this.name}`;
    }

    static parse(data: any): SearchSuggestItem {
        return new SearchSuggestItem(
            data.type,
            data.title,
            data.description,
            data.avatarPath,
            data.avatarColors,
            data.name,
            data.scope,
            data.notebooks ? data.notebooks.map(n => new SearchSuggestNodebookInfo(data.type, data.name, n.name, n.title, n.color)) : [],
            data.blocks ? data.blocks.map(n => new SearchSuggestBlockInfo(data.name, n.path, n.text, n.type)) : [],
            data.author,
            data.notebookTitle,
            data.created ? new Date(data.created) : undefined
        );
    }

    getFirstName() {
        if (this.type === "user") {
            const parts = this.title.split(" ");
            return parts.length > 1 ? parts[0] : this.title;
        }
        return undefined;
    }

    getInititals(): string {
        if (this.type === "user") {
            const fi = this.title.split(" ");
            if (fi.length === 2) {
                return fi[0][0] + fi[1][0];
            }
        }
        return this.title[0];
    }

    getNavigateUrl(): string[] {
        return [];
    }

    getPopupText(): string {
        return "";
    }
}

export class SearchSuggestNodebookInfo {

    readonly $type = "notebook";

    readonly title: string;
    readonly name: string;
    readonly color: EntityColor;
    readonly cssColor: string;
    readonly ownerName: string;
    readonly ownerType: "user" | "team";

    constructor(ownerType: "user" | "team", ownerName: string, name: string, title: string, color: EntityColor) {
        this.ownerType = ownerType;
        this.ownerName = ownerName;
        this.name = name;
        this.title = title;
        this.color = color;

        this.cssColor = getEntityCssColor(this.color);
    }
}

export class SearchSuggestBlockInfo {

    readonly $type = "block";

    readonly path: number[];
    readonly text: string;
    readonly type: string;
    readonly documentId: string;

    constructor(documentId: string, path: number[], text: string, type: string) {
        this.documentId = documentId;
        this.path = path;
        this.text = text;
        this.type = type;
    }
}
