import {APP_BOOTSTRAP_LISTENER, NgModule} from "@angular/core";
import {DOMModalRenderer} from "./dom/DOMModalRenderer";
import {ModalRenderer} from "./ModalRenderer";
import {ModalService} from "./ModalService";
import {ModalWindowComponent} from "./components/modal-window/modal-window.component";
import {ModalBackdropComponent} from "./components/modal-backdrop/modal-backdrop.component";
import {closeModalOnEsc} from "./closeModalOnEsc";

@NgModule({
    declarations: [
        ModalWindowComponent,
        ModalBackdropComponent,
    ],
    imports: [],
    exports: [],
    providers: [
        { provide: ModalRenderer, useClass: DOMModalRenderer },
        {
            provide: APP_BOOTSTRAP_LISTENER,
            multi: true,
            useFactory: closeModalOnEsc,
            deps: [ModalService]
        }
    ]
})
export class ModalsModule {

}
