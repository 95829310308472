import {ElementRef, Injectable, Injector, NgZone, Type} from "@angular/core";
import {IPopoverOptions, PopoverInstance, PopoverService} from "../../modules/n-popover";
import {isMobileDevice} from "../../shared/Utility";

/**
 * Open popover on mouse hover
 */
@Injectable({providedIn: "root"})
export class HoverPopoverService {

    constructor(private popovers: PopoverService,
                private zone: NgZone) {

    }

    bind(element: ElementRef, adapter: IHoverPopoverAdapter): HoverPopoverInstance {
        if (isMobileDevice()) {
            return; // do not provide this func on mobile devices
        }
        return new HoverPopoverInstance(adapter, this.popovers, element.nativeElement, this.zone);
    }

}

let nextDelaysPrevented = false; // Показана попаха с режимом мгновенного отображения
let nextDelaysResetTimer: any;

let activeInstance: HoverPopoverInstance;

export class HoverPopoverInstance {

    private popover: PopoverInstance<any>;
    private isShowPopover: boolean;
    private isDestroyed: boolean;
    private mouseOver: boolean;
    private timerId: any;
    private mouseX: number;
    private mouseY: number;

    constructor(private adapter: IHoverPopoverAdapter,
                private popoverService: PopoverService,
                private element: Element,
                private zone: NgZone) {

        zone.runOutsideAngular(() => {
            element.addEventListener("mouseover", this.mouseOverHandler);
            element.addEventListener("mouseout", this.mouseOutHandler);
            element.addEventListener("click", this.clickHandler);
        });
    }

    destroy() {
        this.element.removeEventListener("mouseover", this.mouseOverHandler);
        this.element.removeEventListener("mouseout", this.mouseOutHandler);
        this.element.removeEventListener("click", this.clickHandler);

        this.isDestroyed = true;
        this.hide();
    }

    handlerMouseOut = () => {

        if (!this.mouseOver) {
            return;
        }

        this.mouseOver = false;

        this.cancelHide();

        this.timerId = setTimeout(() => {
            if (this.mouseOver) {
                return;
            }
            // устанавливаем таймер до сброса задержки появления
            if (nextDelaysPrevented) {
                const timeout = typeof this.adapter.resetFirstShowDelay !== "undefined" ? this.adapter.resetFirstShowDelay : 1500;
                if (nextDelaysResetTimer) {
                    clearTimeout(nextDelaysResetTimer);
                }
                nextDelaysResetTimer = setTimeout(() => {
                    if (!this.isShowPopover) {
                        nextDelaysPrevented = false;
                    }
                }, timeout);
            }

            this.hide();
        }, typeof this.adapter.hideDelay !== "undefined" ? this.adapter.hideDelay : 500);
    };

    handlerMouseOver = (event) => {

        if (this.mouseOver) {
            return;
        }
        this.cancelHide();

        this.mouseOver = true;

        if (this.isShowPopover) {
            return;
        }

        if (event) {
            this.mouseX = event.clientX;
            this.mouseY = event.clientY;
        }

        // Delay появления

        this.cancelHide();

        // показываем попап сразу
        if (this.adapter.preventNextDelays && nextDelaysPrevented) {
            if (nextDelaysResetTimer) {
                clearTimeout(nextDelaysResetTimer);
                nextDelaysResetTimer = undefined;
            }
            this.displayPopup();
            return;
        }

        // или с задержкой
        this.timerId = setTimeout(() => {
            if (this.mouseOver && !this.isShowPopover) {
                this.displayPopup();
            }
        }, typeof this.adapter.displayDelay !== "undefined" ? this.adapter.displayDelay : 750);
    };

    private cancelHide() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = undefined;
        }
    }

    private clickHandler = () => {
        this.hide();
        this.mouseOver = false;
    };

    private createPopover(popoverData: any) {
        this.isShowPopover = true;
        const el: ElementRef = this.adapter.bindToElement && this.adapter.bindingElement ? this.adapter.bindingElement : new ElementRef(this.element);

        const options = {
            popoverClass: this.adapter.popoverClass || "popover__mention",
            position: this.adapter.position || "right",
            hideArrow: true,
            posX: this.adapter.bindToElement ? undefined : this.mouseX + 10,
            posY: this.adapter.bindToElement ? undefined : this.mouseY + 10,
            targetElementRef: this.adapter.bindToElement ? el : undefined,
            animate: true,
            autoClose: true,
            componentType: this.adapter.popoverComponentType,
            injector: this.adapter.injector,
            popoverData
        } as IPopoverOptions;


        this.popover = this.popoverService.open(options);
        this.popover.mouseover.subscribe(this.handlerMouseOver);
        this.popover.mouseleave.subscribe(this.handlerMouseOut);

        const onClose = () => {
            this.isShowPopover = false;
            this.popover = undefined;
            this.mouseOver = false;
            this.mouseX = 0;
            this.mouseY = 0;
        };

        this.popover.result.then(onClose, onClose);
    }

    private displayPopup() {
        this.zone.run(() => {

            this.adapter.getPopoverData().then(data => {
                if (!this.mouseOver || this.isDestroyed) {
                    return;
                }

                if (activeInstance) {
                    activeInstance.hide();
                }
                activeInstance = this;

                if (this.adapter.preventNextDelays) {
                    nextDelaysPrevented = true;
                }
                this.createPopover(data);
            }, fail => {

            });
        });
    }

    private hide() {
        this.cancelHide();
        if (this.popover) {
            this.popover.close();
            this.popover = undefined;
        }
    }

    private mouseOutHandler = () => {
        this.handlerMouseOut();
    };

    private mouseOverHandler = (e) => {
        this.handlerMouseOver(e);
    };
}

export interface IHoverPopoverAdapter {
    /**
     * Тип компонента поповера
     */
    popoverComponentType: Type<any>;
    /**
     *  Для создания popoverComponentType
     */
    injector?: Injector;
    position?: "right" | "left" | "top" | "bottom";
    bindToElement: boolean;
    /**
     *  Задержка до появления попапа
     */
    displayDelay?: number;
    /**
     *  Задержка до исчезновения попапа
     */
    hideDelay?: number;
    /**
     *  Убрать задержку после первого появления
     */
    preventNextDelays?: boolean;
    /**
     *  Задержка до сброса "задержки первого появления" (только если установлен флаг preventNextDelays)
     */
    resetFirstShowDelay?: number;
    bindingElement?: ElementRef;
    popoverClass?: string;

    /**
     * Данные для поповера
     */
    getPopoverData(): Promise<any>;
}
