import {FormControl} from "@angular/forms";

const VALID_ENTITY_NAME_REGEXP = /^[A-z0-9-]+$/;

export function validateEntityName(c: FormControl): {
    [key: string]: any;
} {
    let name = c.value as string, valid = false;

    if (!name) {
        return null;
    }

    if (VALID_ENTITY_NAME_REGEXP.test(name)) {
        if (!name.startsWith("-") && !name.endsWith("-") && name.indexOf("_") < 0) {
            valid = true;
        }
    }

    return valid ? null : {
        invalidName: true
    };

}
