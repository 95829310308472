<div class="app-layout" slideMenuContainer>
    <div (menuClose)="menuClosed()"
         (menuOpen)="menuOpened()"
         [backdrop]="backdrop"
         [menuOpenClassElement]="bodyElement"
         [menuOpened]="menuOpened$|async"
         class="app-layout__menu slim-scroll"
         slideMenu>
        <side-menu></side-menu>
    </div>

    <div class="app-layout__body" scroll-fix-body>
        <div class="app-layout__header">
            <header-bar></header-bar>
        </div>
        <div class="app-layout__content">
            <contextual-router-outlet (activated)="onActivate($event)"></contextual-router-outlet>
        </div>
    </div>

    <div #backdrop (click)="closeMenu()" class="app-layout__backdrop"></div>
</div>
