import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

import {TranslateService} from "../../../modules/translate";
import {BehaviorSubject, combineLatest, map, Observable} from "rxjs";
import {MemorySubject} from "../../../modules/store";
import {KeyCodes} from "../../../shared/KeyCodes";
import {ScrollService} from "../../services/scroll.service";
import {SupportService} from "../../services/support.service";
import {StickyContainerDirective} from "../../directives/stickyBlock.directive";

@Component({
    selector: "filter-input",
    templateUrl: "./filter-input.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./filter-input.bem.css"
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FilterInputComponent),
            multi: true
        }
    ]
})

export class FilterInputComponent implements ControlValueAccessor, OnInit {

    @Output()
    focus = new EventEmitter<any>();
    @Output()
    submit = new EventEmitter<any>();

    @Input()
    loading: boolean;

    @ViewChild("input", {read: ElementRef, static: true})
    input: ElementRef;

    placeholderText$: Observable<string>;
    isEmpty$ = new BehaviorSubject<boolean>(true);

    @ViewChild(StickyContainerDirective, {static: true})
    sticky: StickyContainerDirective;

    private propagateChange: (_: any) => void;
    private _placeholder$ = new MemorySubject<string>();
    private prevScrollPosition: number;
    private composing: boolean;
    private compositionMode: boolean;

    constructor(private translateSvc: TranslateService,
                private scrollSvc: ScrollService,
                private supportSvc: SupportService,
                private renderer: Renderer2,
                private zone: NgZone,
                private el: ElementRef) {
        this.compositionMode = !_isAndroid();
    }

    @Input() set placeholder(val: string) {
        this._placeholder$.next(val);
    }

    clearFilter(e?: any) {
        if (this.input.nativeElement.value) {
            this.writeValue("");
            this.valueChanged("");
            this.setFocus(true);
            if (e) e.stopPropagation();
        }
    }

    compositionEnd(evt: any) {
        const value = evt.target.value;
        this.composing = false;
        if (this.compositionMode) {
            this.valueChanged(value);
        }
    }

    compositionStart() {
        this.composing = true;
    }

    handleKeyDown(e) {
        if (e.shiftKey && e.keyCode === KeyCodes.ENTER) {
            this.submit.emit();
        }
    }

    ngOnInit() {

        this.placeholderText$ =
            combineLatest([this._placeholder$, this.translateSvc.currentLocale$])
                .pipe(
                    map(([key]) => key ? this.translateSvc.getString(key) : "")
                );

        this.zone.runOutsideAngular(() => {

            this.input.nativeElement.addEventListener("focus", () => this.el.nativeElement.classList.add("_focused"));
            this.input.nativeElement.addEventListener("blur", () => this.el.nativeElement.classList.remove("_focused"));

        });
    }

    onChange(evt: any) {
        const val = evt.target.value;
        this.valueChanged(val);
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {

    }

    restoreScroll() {
        if (typeof (this.prevScrollPosition) !== "undefined") {
            const scrollTo = this.prevScrollPosition;
            this.scrollSvc.scrollTo(scrollTo);
            this.sticky.update();

            this.prevScrollPosition = undefined;
        }
    }

    setFocus(force?: boolean) {
        if (force || !this.supportSvc.isTouch()) {
            this.input.nativeElement.focus();
        }
    }

    writeValue(obj: any): void {
        obj = obj || "";
        const normalizedValue = obj.toString().trim();
        this.renderer.setProperty(this.input.nativeElement, "value", normalizedValue);
        this.invalidateStyles();
    }

    private invalidateStyles() {
        if (!this.input.nativeElement.value) {
            this.isEmpty$.next(true);
        } else {
            this.isEmpty$.next(false);
        }
    }

    private saveCurrentScroll() {
        if (typeof (this.prevScrollPosition) === "undefined") {
            this.prevScrollPosition = this.scrollSvc.getScrollTop();
            this.scrollSvc.moveTop();
        }
        // this.scrollSvc.scrollTo(this.el.nativeElement.offsetTop);
    }

    private valueChanged(val: string) {
        const normalized = val.toString().trim();
        if (this.propagateChange) {
            this.propagateChange(normalized);
        }
        if (val) {
            this.saveCurrentScroll();
        }
        this.invalidateStyles();
    }
}

function _isAndroid(): boolean {
    return /android (\d+)/.test(navigator.userAgent.toLowerCase());
}
