import {NgModule} from "@angular/core";
import {TranslatePipe} from "./translate.pipe";
import {TranslateQuantityPipe} from "./translate-quantity.pipe";

const TRANSLATE_DIRECTIVES = [
    TranslatePipe,
    TranslateQuantityPipe
];

@NgModule({
    declarations: [
        ...TRANSLATE_DIRECTIVES
    ], // directives, components, and pipes owned by this NgModule
    imports: [],
    exports: [
        ...TRANSLATE_DIRECTIVES
    ],
    providers: []
})
export class TranslateModule {

}
