import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output} from "@angular/core";

@Component({
    selector: "upload-button",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./upload-button.ng.css"
    ],
    templateUrl: "./upload-button.html"
})
export class UploadButtonComponent implements OnInit {

    @Input()
    accept: string;
    @Input()
    multiple: boolean;
    @Input()
    textKey: string;
    @Input()
    loading: boolean;
    @Output()
    fileSelect = new EventEmitter<File>();

    constructor(private el: ElementRef) {

    }

    ngOnInit() {

        let fileInput = this.el.nativeElement.getElementsByTagName("input")[0];

        if (this.multiple) {
            fileInput.setAttribute("multiple", "true");
        }

        fileInput.addEventListener("change", () => {
            const files = processFilesForAccept(fileInput.files, this.accept);
            if (!files.length) return;
            this.fileSelect.emit(this.multiple ? files : files[0]);
            fileInput.value = null;
        });

    }

}


function isFileTypeValidForAccept(fileType: string, accept: string): boolean {
    if (!accept || !accept.length) {
        return true;
    }

    const parts: string[] = accept.split(",");
    for (let i = 0; i < parts.length; i++) {
        const part = parts[i].replace(/^\s+|\s+$/g, ""); // trim
        const r = new RegExp(part);
        if (r.test(fileType))
            return true;
    }
    return false;
}

function processFilesForAccept(files: FileList, accept: string): any[] {
    const result = [];
    for (let i = 0; i < files.length; i++) {
        if (isFileTypeValidForAccept(files[i].type, accept)) {
            result.push(files[i]);
        }
    }
    return result;
}
