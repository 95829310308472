import {ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, OnInit, Renderer2, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {buildModifierText} from "../utils";

@Component({
    selector: "n-checkbox",
    templateUrl: "./n-checkbox.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./n-checkbox.ng.css"
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NCheckboxComponent),
            multi: true
        }
    ]
})
export class NCheckboxComponent implements ControlValueAccessor, OnInit {

    @Input() description: string;
    @Input() name: string;
    @Input() mod: string;
    @Input() text: string;

    // fields
    modifierText: string;
    checked: boolean;

    @ViewChild("input", {read: ElementRef, static: true})
    input: ElementRef;

    private propagateChange: (_) => void;

    constructor(private renderer: Renderer2) {
    }


    @Input()
    set isDisabled(val: boolean) {
        this.setDisabledState(val);
    }

    ngOnInit(): void {
        this.modifierText = buildModifierText("n-checkbox_", this.mod);
    }

    onChange(event) {
        if (this.propagateChange) {
            this.propagateChange(event.target.checked);
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.input.nativeElement, "disabled", isDisabled);
    }

    writeValue(obj: boolean): void {
        this.renderer.setProperty(this.input.nativeElement, "checked", obj);
    }

}

