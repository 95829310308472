import {Component, forwardRef, ViewContainerRef} from "@angular/core";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {UserSearcher} from "./UserSearcher";
import {UserPickerItem} from "./UserPickerItem";
import {UserPickerItemRendererFactory} from "./UserPickerItemRendererFactory";
import {UserPickerItemNoResultFactory} from "./UserPickerItemNoResultFactory";

@Component({
    selector: "user-picker",
    templateUrl: "./user-picker.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserPickerComponent),
            multi: true
        }
    ]
})
export class UserPickerComponent implements ControlValueAccessor {

    itemRenderer: (item: UserPickerItem) => Node;
    noResultsRenderer: (text: string) => Text;

    pickerControl = new FormControl([]);
    private propagateChange: any;

    constructor(public searcher: UserSearcher,
                vc: ViewContainerRef,
                userItemRendererFactory: UserPickerItemRendererFactory,
                noResultsFactory: UserPickerItemNoResultFactory) {
        this.itemRenderer = userItemRendererFactory.createItemRenderer(vc);
        this.noResultsRenderer = noResultsFactory.createRenderer();
    }

    ngOnInit() {
        this.pickerControl.valueChanges.subscribe(v => this.propagateChange && this.propagateChange(v));
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: any): void {
        this.pickerControl.setValue(obj);
    }

}
