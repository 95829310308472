const globalComponents = new Map<string, Function>();

export interface ISplitComponentDefinition {
    key: string;
    component: Function;
}

export function resolveGlobalComponent(key: string): any {
    return globalComponents.get(key);
}

export function registerSplitComponents(globals: ISplitComponentDefinition[]) {
    globals.forEach(def => globalComponents.set(def.key, def.component));
}
