import {MemorySubject} from "./MemorySubject";
import {Observable} from "rxjs";
import {diffInSeconds} from "../../core/DateUtility";

export class ExpireDataStream<T> {

    private dataDate: Date;

    constructor(private secondsToExpire: number, data?: T) {
        this.dataDate = new Date();
        this._data = new MemorySubject<T>(data);
    }

    private _data: MemorySubject<T>;

    get data(): Observable<T> {
        return this._data.asObservable();
    }

    isExpired(): boolean {
        const secs = diffInSeconds(new Date(), this.dataDate);
        return secs > this.secondsToExpire;
    }

    update(data: T) {
        this.dataDate = new Date();
        this._data.next(data);
    }

}
