import {Injectable} from "@angular/core";

export const DOCUMENTS_SNAPSHOT_STORE_NAME = "snapshots";
export const DOCUMENTS_PENDING_ACTIONS_NAME = "pending_actions";
export const DOCUMENTS_PENDING_OPERATIONS_NAME = "pending_operations";
export const DOCUMENTS_DIFF_OPERATIONS_NAME = "diff_ops";

interface IStoreDefinition {
    readonly name: string;
    readonly indexes?: IIndexesDefinitions[];
}

interface IDatabaseScheme {
    readonly databaseName: string;
    readonly version: number;
    readonly stores: IStoreDefinition[];
}

export const ACTION_DOCUMENT_ID_INDEX = {name: "documentId", fields: "documentId", unique: true, multiEntry: false};
export const OP_DOCUMENT_ID_INDEX = {name: "documentId", fields: "documentId", unique: false, multiEntry: false};

export const DATABASE_SCHEME: IDatabaseScheme = {
    databaseName: "Documents",
    version: 11,
    stores: [
        // stores document state snapshots after each sync document state with the server
        {
            name: DOCUMENTS_SNAPSHOT_STORE_NAME
        },
        // store list of operations which waiting sending to the server
        {
            name: DOCUMENTS_PENDING_OPERATIONS_NAME,
            indexes: [
                OP_DOCUMENT_ID_INDEX
            ]
        },
        // stores pending actions (immutable bundle of operations) ebfore sending to server
        // action have unique id and do not apply on the server twice
        {
            name: DOCUMENTS_PENDING_ACTIONS_NAME,
            indexes: [
                ACTION_DOCUMENT_ID_INDEX
            ]
        },
        // stores operations which are applied to the document after last snapshot was made
        // this operations allow to restore last document state when user edit document in offline mode
        // this operations drop after each revision increment
        {
            name: DOCUMENTS_DIFF_OPERATIONS_NAME,
            indexes: [
                OP_DOCUMENT_ID_INDEX
            ]
        }
    ]
};

@Injectable({providedIn: "root"})
export class DatabaseService {

    async clearAllStorage() {
        const result = indexedDB.deleteDatabase(DATABASE_SCHEME.databaseName);
        return new Promise((resolve) => {
            result.onsuccess = resolve;
        });
    }

    async getStore<T extends IPrimaryKey>(storeName: string,
                                          mode: IDBTransactionMode = "readonly"): Promise<DatabaseStore<T>> {
        if (!isSupport()) {
            throw new Error("IndexedDB not supported");
        }
        return new DatabaseStore<T>(await this.ensureStore(storeName, mode));
    }

    private async ensureStore(storeName: string,
                              mode: IDBTransactionMode = "readonly"): Promise<IDBObjectStore> {
        const request = indexedDB.open(DATABASE_SCHEME.databaseName, DATABASE_SCHEME.version);
        return new Promise<IDBObjectStore>((resolve, reject) => {
            request.onerror = (err) => {
                reject(err);
            };
            request.onsuccess = (event) => {
                const db = event.target["result"];
                const transaction = db.transaction([storeName], mode);
                resolve(transaction.objectStore(storeName));
            };
            request.onupgradeneeded = (e) => {
                console.log("upgrading database", e);
                const db = e.currentTarget["result"];

                if (e.oldVersion >= 1) {
                    const upgradeTransaction = e["target"]["transaction"];
                    for (let storeDef of DATABASE_SCHEME.stores) {
                        let store: IDBObjectStore;
                        if (db.objectStoreNames.contains(storeDef.name)) {
                            store = upgradeTransaction.objectStore(storeDef.name);
                        } else {
                            store = db.createObjectStore(storeDef.name);
                        }
                        if (storeDef.indexes) {
                            for (const index of storeDef.indexes) {
                                if (store.indexNames.contains(index.name)) {
                                    store.deleteIndex(index.name);
                                }
                                store.createIndex(index.name, index.fields, {unique: !!index.unique, multiEntry: !!index.multiEntry});
                            }
                        }
                    }
                } else {

                    for (let storeDef of DATABASE_SCHEME.stores) {
                        const store = db.createObjectStore(storeDef.name);
                        if (storeDef.indexes) {
                            for (const index of storeDef.indexes) {
                                store.createIndex(index.name, index.fields, {unique: !!index.unique, multiEntry: !!index.multiEntry});
                            }
                        }
                    }
                    this.ensureStore(storeName, mode).then(resolve, reject);
                }

            };
        });
    }
}

function isSupport(): boolean {
    return !!window.indexedDB;
}

export interface IPrimaryKey {
    id: string;
}

export interface IIndexesDefinitions {
    readonly name: string;
    readonly fields: string[] | string;
    readonly unique?: boolean;
    readonly multiEntry?: boolean;
}

export class DatabaseStore<T extends IPrimaryKey = any> {

    constructor(private store: IDBObjectStore) {

    }


    find(indexName: string, range: IDBKeyRange): Promise<T[]> {
        const index = this.store.index(indexName);
        return new Promise<T[]>((resolve, reject) => {
            const result: T[] = [];
            const request = index.openCursor(range);
            request.onsuccess = function (event) {
                const cursor = event.target["result"];
                if (cursor) {
                    result.push(cursor.value);
                    cursor.continue();
                } else {
                    resolve(result);
                }
            };
            request.onerror = function (err) {
                reject(err);
            };
        });
    }

    /**
     * Read entity by primary key
     */
    async read(id: string): Promise<T | undefined> {
        if (!isSupport()) {
            return undefined;
        }
        return new Promise<T>((resolve, reject) => {
            const request = this.store.get(id);
            request.onerror = function (event) {
                reject(event);
            };
            request.onsuccess = function () {
                resolve(request.result as T);
            };
        });
    }

    /**
     * Remove entity by primary key
     */
    async remove(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const request = this.store.delete(id);
            request.onsuccess = function () {
                resolve(undefined);
            };

            request.onerror = function (e) {
                reject(e);
            };
        });
    }

    /**
     * Remove entity by primary key
     */
    async removeRange(indexName: string, range: IDBKeyRange): Promise<any> {
        return new Promise((resolve, reject) => {
            const index = this.store.index(indexName);
            const request = index.openCursor(range);

            request.onsuccess = function () {
                const cursor = request.result;

                if (cursor) {
                    cursor.delete();
                    cursor.continue();
                } else {
                    resolve(undefined);
                }
            };
            request.onerror = function (e) {
                reject(e);
            };
        });
    }

    /**
     * Add or update entity by primary key
     */
    async save(entity: T) {
        return new Promise((resolve, reject) => {
            const request = this.store.put(entity, entity.id);
            request.onsuccess = function () {
                resolve(undefined);
            };
            request.onerror = function (e) {
                reject(e);
            };
        });
    }


}
