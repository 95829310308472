import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NotebookInfo} from "../../models/NotebookInfo";

@Component({
    selector: "notebook-select-item",
    styleUrls: [
        "./notebook-select-item.ng.css"
    ],
    templateUrl: "./notebook-select-item.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotebookSelectItemComponent implements OnInit {

    @Input()
    notebook: NotebookInfo;

    @Input()
    selected: boolean;

    constructor() {
    }

    ngOnInit() {
    }
}
