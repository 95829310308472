import {EventEmitter, Injectable, TemplateRef} from "@angular/core";
import {ModalRenderer} from "./ModalRenderer";
import {ModalInstance} from "./ModalInstance";
import {IModalOptions} from "./IModalOptions";
import {ModalStack} from "./ModalStack";

let stack: ModalStack;

@Injectable({providedIn: "root"})
export class ModalService {

    /**
     * Global hook for opening modals
     */
    openModalEvents = new EventEmitter<ModalInstance<any>>();

    constructor(private modalRenderer: ModalRenderer) {
        stack = new ModalStack();
    }

    closeLast() {
        return stack.closeLast();
    }

    focusTopModal() {
        if (stack.openedVisibleModalCount > 0) {
            stack.getLast().focus();
        }
    }

    getLast(): ModalInstance<any> {
        return stack.getLast();
    }

    getModalContainer() {
        return document.getElementsByTagName("modal-window")[0];
    }

    isOpen() {
        return stack.openedVisibleModalCount > 0;
    }

    open<TResult>(componentType: Function, options?: IModalOptions, modalData?: any): ModalInstance<TResult> {
        if (!stack) {
            throw new Error("not initialized");
        }
        const instance = stack.open<TResult>(this.modalRenderer, modalData, componentType, undefined, options);
        this.openModalEvents.emit(instance);
        return instance;
    }

    openTemplate<TResult>(template: TemplateRef<any>, options?: IModalOptions): ModalInstance<TResult> {
        if (!stack) {
            throw new Error("not initialized");
        }
        const instance = stack.open<TResult>(this.modalRenderer, {}, undefined, template, options);
        this.openModalEvents.emit(instance);
        return instance;
    }

}
