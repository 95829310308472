/**
 * Invitation was sent by email
 */
export class TeamEmailInvite {

    readonly email: string;

    constructor(email: string) {
        this.email = email;
    }

    static parse(data: any): TeamEmailInvite {
        return new TeamEmailInvite(data.email);
    }

    static parseArray(data: any[]): TeamEmailInvite[] {
        return data.map(d => TeamEmailInvite.parse(d));
    }

}
