import {Component, ElementRef, forwardRef, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TranslateService} from "../../../modules/translate";
import {SearchPickerNoResultsRenderer} from "./SearchPickerNoResultsRenderer";
import {DefaultSearchPickerOptions, IPickerItem, ISearcher, SearchPicker} from "searchpicker";

@Component({
    selector: "search-picker",
    template: ``,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchPickerComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./search-picker.bem.css",
        "../../../../../node_modules/searchpicker/src/css/searchPicker.css"
    ]
})

export class SearchPickerComponent implements ControlValueAccessor, OnInit {

    @Input()
    placeholderKey: string;
    @Input()
    minlen: number = 0;
    @Input()
    maxresults: number;
    @Input()
    searcher: ISearcher;
    @Input()
    resultRenderer: (item: IPickerItem, query: string) => Node;
    @Input()
    noResultsRenderer: (query: string) => Text;


    // fields:
    private searchPicker: SearchPicker;
    private propagateChange: (_: IPickerItem[]) => void;
    private applying: boolean;

    constructor(private el: ElementRef, private translate: TranslateService, private noResultsRendererFactory: SearchPickerNoResultsRenderer) {
        this.el.nativeElement.classList.add("search-picker");
    }

    ngOnInit() {
        let options = new DefaultSearchPickerOptions();
        options.source = [];
        options.placeholder = this.placeholderKey ? this.translate.getString(this.placeholderKey) : "";
        options.minLengthToSearch = this.minlen;
        options.maxSelectedChoices = this.maxresults;
        options.searcher = this.searcher;
        options.resultRenderer = this.resultRenderer;
        options.noResultsRenderer = this.noResultsRenderer || this.noResultsRendererFactory.createRenderer();

        this.searchPicker = new SearchPicker(this.el.nativeElement, options);
        this.searchPicker.on("choiceAdded", () => {
            if (this.applying) {
                return;
            }
            this.propagateChange(this.searchPicker.getChoices().slice(0));
        });
        this.searchPicker.on("choiceRemoved", () => {
            if (this.applying) {
                return;
            }
            this.propagateChange(this.searchPicker.getChoices().slice(0));
        });

    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(newArray: IPickerItem[]): void {
        newArray = newArray || [];
        this.applying = true;
        this.searchPicker.clearChoices();
        for (let i = 0; i < newArray.length; i++) {
            this.searchPicker.addChoice(newArray[i]);
        }
        this.applying = false;
    }

}
