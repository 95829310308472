import {Injectable} from "@angular/core";
import {ImageInfo} from "../models/ImageInfo";
import {filter, map, Subject} from "rxjs";
import {FileService} from "./file.service";
import {AppService} from "./app.service";


@Injectable({providedIn: "root"})
export class ImageService {

    private imageHost: string;

    constructor(private fileSvc: FileService, appSvc: AppService) {

        appSvc.context$
            .pipe(
                filter(ctx => !!ctx),
                map(c => c.hosts.images)
            )
            .subscribe(host => {
                this.imageHost = host;
            });

    }

    private static isExternalUrl(url: string): boolean {
        let fullUrlPrefix = ["http://", "https://", "ftp://", "ftps://", "data:"];
        for (let prefix of fullUrlPrefix) {
            if (url.indexOf(prefix) === 0) {
                return true;
            }
        }
        return false;
    }

    constructFullUrl(urlOrPath: string, size: ImageSize = "medium"): string {
        if (ImageService.isExternalUrl(urlOrPath)) {
            return urlOrPath;
        }
        return this.getImageUrl(urlOrPath, size);
    }

    getImageUrl(path: string, size: ImageSize = "medium"): string {
        return `${this.imageHost}/${size}/${path}`;
    }

    upload(files: File[]): IUploadImageProgress {
        const uploadResult = this.fileSvc.postFiles(files, `api/images`);
        return {
            result: uploadResult.result.then(data => ImageInfo.parseArray(data.images)),
            progress: uploadResult.progress
        };
    }
}

export interface IUploadImageProgress {
    result: Promise<ImageInfo[]>;
    progress: Subject<number>;
}

export type ImageSize = "large" | "medium" | "small" | "original";
