import {ChangeDetectionStrategy, Component} from "@angular/core";
import {ToastContext} from "../../ToastContext";
import {IMessageToastAction, IMessageToastParams} from "../../IMessageToastParams";
import {MessageToastType} from "../../MessageToastType";

@Component({
    selector: "n-toast-text",
    styleUrls: ["./n-toast-text.ng.css"],
    templateUrl: "./n-toast-text.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NToastTextComponent {

    text: string;
    type: MessageToastType;

    actions: IMessageToastAction[];
    onAction: (action: IMessageToastAction) => void;
    actionsAlign: "bottom" | "right";

    constructor(private ctx: ToastContext<IMessageToastParams>) {
        this.text = ctx.data.text;
        this.type = ctx.data.type;
        this.actions = ctx.data.actions;
        this.onAction = ctx.data.onAction;
        this.actionsAlign = ctx.data.actionsAlign;
    }

    triggerAction(action: IMessageToastAction) {
        this.onAction(action);
    }

}
