export function truncateTime(d: Date): Date {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

export function dateEquals(d1: Date, d2: Date): boolean {
    if (isNullOrUndefined(d1) || isNullOrUndefined(d2)) {
        return false;
    }
    return truncateTime(d1).getTime() === truncateTime(d2).getTime();
}

export function parseStringDate(text: string, isNative = false): Date {
    if (!isNative) {
        const dateFormatRegexp = new RegExp(/^(\d{2}).(\d{2}).(\d{4})$/);
        if (text) {
            const dateParts = text.trim().split(".");
            if (dateFormatRegexp.test(text) && dateParts.length === 3 && dateParts[0] && dateParts[1] && dateParts[2]) {
                const date = new Date(dateParts[2] as any, (dateParts[1] as any - 1), dateParts[0] as any);
                return (date as any !== "Invalid Date") && !isNaN(date as any) ? date : null;
            } else {
                return null;
            }
        } else {
            return null;
        }
    } else {
        const nativeDateFormatRegexp = new RegExp(/^(\d{4})-(\d{2})-(\d{2})$/);
        if (text) {
            const dateParts = text.trim().split("-");
            if (nativeDateFormatRegexp.test(text) && dateParts.length === 3 && dateParts[0] && dateParts[1] && dateParts[2]) {
                const date = new Date(dateParts[0] as any, (dateParts[1] as any - 1), dateParts[2] as any);
                return (date as any !== "Invalid Date") && !isNaN(date as any) ? date : null;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

function isNullOrUndefined(obj: any): boolean {
    return typeof (obj) === "undefined" || obj === null;
}

export function dateToString(date: Date): string {
    return ("0" + date.getDate()).slice(-2) + "."
        + ("0" + (date.getMonth() + 1)).slice(-2) + "."
        + date.getFullYear();
}

export function isParentElement(sourceEl: any, parentEl: any): boolean {
    if (sourceEl === parentEl) {
        return true;
    }

    let parent = sourceEl.parentNode;
    while (parent) {
        if (parent === parentEl) {
            return true;
        }
        if (parent.parentNode) {
            parent = parent.parentNode;
        } else {
            return false;
        }
    }
    return false;
}

