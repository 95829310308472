export class FileInfo {

    size: number;
    filename: string;
    path: string;

    static parse(data: any): FileInfo {
        const info = new FileInfo();
        info.size = data.size;
        info.filename = data.filename;
        info.path = data.path;
        return info;
    }

    static parseArray(data: any[]): FileInfo[] {
        return data.map(d => FileInfo.parse(d));
    }
}
