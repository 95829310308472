import {AfterViewInit, ChangeDetectorRef, Directive, Optional, Self} from "@angular/core";
import {NgControl} from "@angular/forms";
import {IControlWithErrors} from "../components";


@Directive({
    selector: "n-input,n-textarea"
})
export class NErrorsDirective implements AfterViewInit {

    constructor(@Optional() @Self() private control: NgControl, private cd: ChangeDetectorRef) {

    }

    ngAfterViewInit(): void {
        if (this.control) {
            const va = (this.control.valueAccessor as any) as IControlWithErrors;
            if (va.setErrors) {
                va.setErrors(this.control.errors);
                this.control.statusChanges.subscribe((a) => {
                    va.setErrors(this.control.errors);
                    this.cd.markForCheck();
                });
            }
        }
    }

}
