import {ChangeDetectionStrategy, Component, ElementRef, Renderer2} from "@angular/core";
import {ErrorService} from "../../services/error.service";

/* This is entry point of each document, keep it core chunk*/

@Component({
    selector: "document-entry",
    styles: [
        `:host {
            display: block;
            width: 100%;
            height: 100%;
            background: white;
            position: fixed;
            z-index: 1050;
            top: 0;
            left: 0;
        }

        :host(._loaded ) {
            position: static;
            z-index: auto;
        }

        @media print {
            :host {
                height: auto !important;
            }
        }
        `
    ],
    template: `
        <dynamic-component [module]="'document'" [type]="'DocumentPageComponent'" (instance)="loaded()" (error)="onError()"></dynamic-component>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DocumentEntryComponent {
    constructor(private errorSvc: ErrorService, private renderer: Renderer2, private el: ElementRef) {
    }

    loaded() {
        this.renderer.addClass(this.el.nativeElement, "_loaded");
    }

    onError() {
        this.errorSvc.showError(`document.loadError`);
    }
}
