import {ApplicationContext} from "../models/ApplicationContext";
import {UserRef} from "../models/UserRef";
import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {BehaviorSubject, filter, map, Observable} from "rxjs";
import {CultureCode} from "../models/CultureCode";
import {TranslateService} from "../../modules/translate";
import {MemorySubject} from "../../modules/store";
import {CacheableDataContextService} from "./cacheable-data-context.service";
import {TeamInfo} from "../models/TeamInfo";
import {NotebookInfo} from "../models/NotebookInfo";
import {UserInfo} from "../models/UserInfo";
import {PinnedTeamInfo} from "../models/PinnedTeamInfo";
import {moveItemInArray} from "@angular/cdk/drag-drop";
import {VERSION_INFO} from "../../../environments/version";

export function getAppVersion() {
    const parts = VERSION_INFO.version.split(".");
    if (parts.length < 3) {
        return VERSION_INFO.version;
    }
    return `${parts[0]}.${parts[1] || 0}`;
}

@Injectable({providedIn: "root"})
export class AppService {

    private onlineNavigator = new BehaviorSubject<boolean>(window.navigator.onLine);
    private ensureAwaiters: Function[] = [];

    private appContext$ = new MemorySubject<ApplicationContext>();
    private actualizing: boolean;

    constructor(private dataCtx: CacheableDataContextService,
                private authSvc: AuthService,
                private translateSvc: TranslateService) {

        this.authSvc.onSignOut.subscribe(() => {
            this.appContext$.next(undefined);
        });

        this.authSvc.onSignIn.subscribe(() => {
            this.actualizeContext(false);
        });

        window.addEventListener("offline", () => this.onlineNavigator.next(false));
        window.addEventListener("online", () => this.onlineNavigator.next(true));

        this.appContext$.subscribe(ctx => {
            if (ctx) {
                if (!ctx.anonymous) {
                    this.translateSvc.setLocale(ctx.user.culture || "en");
                }
                this.ensureAwaiters.forEach(a => a(ctx));
                this.ensureAwaiters = [];
            }
            this.actualizing = false;
        });

        if (authSvc.isAuthenticated()) {
            this.actualizeContext(false);
        }
    }

    get currentCulture$(): Observable<CultureCode> {
        return this.translateSvc.currentLocale$
            .pipe(
                map(c => c as CultureCode)
            );
    }

    get contextSnapshot(): ApplicationContext {
        return this.appContext$.value;
    }

    get currentUserSnapshot(): UserRef | undefined {
        if (!this.contextSnapshot) {
            return undefined;
        }
        return this.contextSnapshot.user;
    }

    get context$(): Observable<ApplicationContext> {
        return this.appContext$.asObservable();
    }

    get currentUserProfile$(): Observable<UserInfo> {
        return this.context$
            .pipe(
                map(c => c ? c.user : undefined),
                filter(u => !!u)
            );
    }

    async actualizeContext(requireAuth: boolean, bypassCache?: boolean): Promise<any> {
        if (requireAuth && !this.authSvc.isAuthenticated()) {
            await this.authSvc.ensureAuthenticated();
        }

        return new Promise((resolve, reject) => {
            if (this.actualizing) {
                // prevent double fetching
                this.ensureAwaiters.push(resolve);
            } else {
                this.actualizing = true;
                this.dataCtx.get(`api/context`, {allowAnonymous: true}, bypassCache).subscribe(d => {
                    const ctx = new ApplicationContext(d);
                    this.appContext$.next(ctx);
                    resolve(ctx);
                }, err => reject(err));

            }
        });
    }

    addTeam(team: TeamInfo) {
        const existTeamIndx = this.contextSnapshot.pinnedTeams.findIndex(t => t.team.name === team.name);
        const newTeam = new PinnedTeamInfo(team, [], false);
        if (existTeamIndx >= 0) {
            this.contextSnapshot.pinnedTeams.splice(existTeamIndx, 1, newTeam);
        } else {
            this.contextSnapshot.pinnedTeams.push(newTeam);
        }
        this.appContext$.update();
    }

    async changeCulture(culture: CultureCode): Promise<any> {
        if (this.translateSvc.currentLocaleSnapshot === culture) {
            return;
        }

        this.translateSvc.setLocale(culture);

        if (this.contextSnapshot) {
            await this.dataCtx.put(`/api/userculture`, {culture});

            this.contextSnapshot.user.culture = culture;
            this.appContext$.update();
        }

    }

    /**
     * Returns only authenticated context (with user). If current context anonymous then request auth
     */
    ensureAuthorizedContext(): Promise<ApplicationContext> {
        if (this.contextSnapshot && !this.contextSnapshot.anonymous) {
            return Promise.resolve(this.contextSnapshot);
        }
        return this.actualizeContext(true);
    }

    /**
     * Returns current context. Context may be anonymous
     */
    ensureContext(): Promise<ApplicationContext> {
        if (this.contextSnapshot) {
            return Promise.resolve(this.contextSnapshot);
        }
        return this.actualizeContext(false);
    }

    isAuthenticated(): boolean {
        return this.authSvc.isAuthenticated();
    }

    movePinnedNotebook(notebook: NotebookInfo, newOrder: number) {
        if (!this.contextSnapshot) {
            return;
        }
        const indx = this.contextSnapshot.pinnedNotebooks.indexOf(notebook);
        if (indx < 0) {
            return;
        }
        moveItemInArray(this.contextSnapshot.pinnedNotebooks, indx, newOrder);
        this.appContext$.update();
    }

    movePinnedTeam(teamName: string, newOrder: number) {
        if (!this.contextSnapshot) {
            return;
        }
        const indx = this.contextSnapshot.pinnedTeams.findIndex(t => t.team.name === teamName);
        if (indx < 0) {
            return;
        }
        moveItemInArray(this.contextSnapshot.pinnedTeams, indx, newOrder);
        this.appContext$.update();
    }

    movePinnedTeamNotebook(teamName: string, notebook: NotebookInfo, newOrder: number) {
        if (!this.contextSnapshot) {
            return;
        }
        const team = this.contextSnapshot.pinnedTeams.find(t => t.team.name === teamName);
        if (!team) {
            return;
        }
        const indx = team.pinnedNotebooks.indexOf(notebook);
        if (indx < 0) {
            return;
        }
        moveItemInArray(team.pinnedNotebooks, indx, newOrder);
        this.appContext$.update();
    }

    notebookChanged(notebookName: string, notebook: NotebookInfo, teamName?: string) {
        let pinnedNotebooks = this.determineNotebooks(teamName);
        if (!pinnedNotebooks) {
            return;
        }
        const index = pinnedNotebooks.findIndex(t => t.name.toUpperCase() === notebookName.toUpperCase());
        if (index >= 0) {
            pinnedNotebooks.splice(index, 1, notebook);
            this.appContext$.update();
        }
    }

    notebookPinned(notebook: NotebookInfo, teamName?: string) {

        let pinnedNotebooks = this.determineNotebooks(teamName);
        if (!pinnedNotebooks) {
            return;
        }

        const index = pinnedNotebooks.findIndex(t => t.name.toUpperCase() === notebook.name.toUpperCase());
        if (index >= 0) {
            pinnedNotebooks.splice(index, 1, notebook);
        } else {
            pinnedNotebooks.push(notebook);
        }
        this.appContext$.update();
    }

    notebookUnpinned(notebook: NotebookInfo, teamName?: string) {
        let pinnedNotebooks = this.determineNotebooks(teamName);
        if (!pinnedNotebooks) {
            return;
        }

        const index = pinnedNotebooks.findIndex(t => t.name.toUpperCase() === notebook.name.toUpperCase());
        if (index >= 0) {
            pinnedNotebooks.splice(index, 1);
        }
        this.appContext$.update();
    }

    online(): Observable<boolean> {
        return this.onlineNavigator;
    }

    removeTeam(teamName: string) {
        const index = this.contextSnapshot.pinnedTeams.findIndex(t => t.team.name === teamName);
        if (index >= 0) {
            this.contextSnapshot.pinnedTeams.splice(index, 1);
            this.appContext$.update();
        }
    }

    teamUpdated(teamName: string, team: TeamInfo) {
        const oldTeam = this.contextSnapshot.pinnedTeams.find(t => t.team.name === teamName);
        if (oldTeam) {
            const indx = this.contextSnapshot.pinnedTeams.indexOf(oldTeam);
            this.contextSnapshot.pinnedTeams.splice(indx, 1, new PinnedTeamInfo(team, oldTeam.pinnedNotebooks, false));
            this.appContext$.update();
        }
    }

    private determineNotebooks(teamName?: string): NotebookInfo[] {
        let pinnedNotebooks: NotebookInfo[];
        if (teamName) {
            const team = this.contextSnapshot.pinnedTeams.find(t => t.team.name === teamName);
            if (!team) {
                return undefined;
            }
            pinnedNotebooks = team.pinnedNotebooks;
        } else {
            pinnedNotebooks = this.contextSnapshot.pinnedNotebooks;
        }
        return pinnedNotebooks;
    }
}
