import {ChangeDetectionStrategy, Component, Input, OnDestroy} from "@angular/core";
import {TranslateService} from "../../../modules/translate";
import {MemorySubject} from "../../../modules/store";
import {combineLatest, map, Subscription} from "rxjs";


@Component({
    selector: "page-message",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./page-message.html",
    styleUrls: [
        "./page-message.ng.css"
    ]
})
export class PageMessageComponent implements OnDestroy {
    @Input()
    iconId: string;

    @Input()
    iconSize: string;

    @Input()
    supTitle: string;
    // fields
    message$ = new MemorySubject("");
    private sub: Subscription;
    private _messageKey$ = new MemorySubject<string>();

    constructor(private translateSvc: TranslateService) {

    }

    @Input()
    set messageKey(val: string) {
        this._messageKey$.next(val);
    }

    @Input()
    set message(val: string) {
        this.message$.next(val);
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = undefined;
        }
    }

    ngOnInit() {
        if (this._messageKey$.hasValue()) {
            this.sub =
                combineLatest([this.translateSvc.currentLocale$, this._messageKey$])
                    .pipe(
                        map(([_, key]) => this.translateSvc.getString(key))
                    )
                    .subscribe(val => this.message$.next(val));
        }
    }

}
