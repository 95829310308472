<div *ngIf="showForm$|async" class="page">
    <div class="body">
        <form-header>
            <dr-logo></dr-logo>
        </form-header>

        <form-window>

            <form-window-header>{{"signUp.header" | translate}}</form-window-header>

            <form (ngSubmit)="submit(form.value)" [formGroup]="form" class="form" focusInvalidFieldOnSubmit>

                <div class="name-wrapper">

                    <dr-field class="column" label="signUp.firstname">
                        <n-input #nameInput [focusOnDefault]="nameInput" [formControlName]="'firstname'" [required]="true"
                                 wide></n-input>
                    </dr-field>

                    <dr-field class="column" label="signUp.lastname">
                        <n-input [formControlName]="'lastname'" [required]="true" wide></n-input>
                    </dr-field>

                </div>

                <dr-field label="signUp.email">
                    <n-input *ngIf="!(defaultEmail$|async)" [formControlName]="'email'" [required]="true" type="email"
                             wide></n-input>
                    <div *ngIf="defaultEmail$|async" style="margin-top: 10px">{{defaultEmail$|async}}</div>
                </dr-field>
                <dr-field label="signUp.pwd">
                    <n-input [formControlName]="'password'" [required]="true" [type]="'password'" wide></n-input>
                </dr-field>

                <password-checker [password]="form.controls.password.value"></password-checker>

                <dr-field label="signUp.confirmPwd">
                    <n-input [formControlName]="'confirmPassword'" [required]="true" [type]="'password'" wide></n-input>
                </dr-field>

                <div class="submit">
                    <n-button [disabled]="submitting$ | async"
                              [loading]="submitting$ | async"
                              [text]="'signUp.submit'|translate"
                              [theme]="'prime'"
                              [type]="'submit'"></n-button>
                </div>

            </form>

        </form-window>

        <form-window-extra [showSignIn]="true"></form-window-extra>
    </div>
    <div class="footer">
        <culture-switcher type="tabs"></culture-switcher>
    </div>
</div>



