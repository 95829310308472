import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {AppModule} from "./app/app.module";
import {environment} from "./environments/environment";
import {Workbox} from "workbox-window";

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {ngZoneEventCoalescing: true})
    .then(() => {
        if ("serviceWorker" in navigator) {
            const wb = new Workbox('/sw.js');

            wb.addEventListener('installed', event => {
                if (event.isUpdate) {
                    // TODO update prompt?
                    // if (confirm(`New content is available!. Click OK to refresh`)) {
                    //     window.location.reload();
                    // }
                }
            });

            wb.register();
        }
    })
    .catch(err => console.error(err));

window.history.scrollRestoration = "manual";

// show loader after 1sec

setTimeout(() => {
    const loader = document.getElementById("loader");
    if (loader) {
        loader.style.display = "block";
    }
}, 1000);

