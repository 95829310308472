import {Directive, ElementRef, HostListener, Input} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

const allowedProtocols = [
    "http://", "https://", "ftp://", "ftps://"
];

let bypassClick: boolean;

function getOrigin(): string {
    let origin = window.location["origin"];
    if (!origin) {
        origin = window.location.protocol + "//" + window.location.host;
    }
    return origin;
}

@Directive({
    selector: "[trackHrefNavigation]"
})
export class TrackHrefNavigationDirective {

    @Input() enableOneClick: boolean;

    constructor(private router: Router, private el: ElementRef, private route: ActivatedRoute) {

    }

    @HostListener("click", ["$event"])
    onClick(e: MouseEvent): boolean {
        if (e.button !== 0 || bypassClick) {
            bypassClick = false;
            return true;
        }
        const anchor = this.getAnchorFromTarget(e);
        if (!anchor) {
            return true;
        }

        if (!this.isInContentEditable(anchor)) {
            // click in anchor in popup, need to handle through router
            return this.processClick(anchor);
        }
        if (this.enableOneClick && (e.ctrlKey || e.metaKey)) {
            return true;
        }
        if (!this.enableOneClick && !e.ctrlKey && !e.metaKey) {
            return true;
        }

        return this.processClick(anchor);
    }

    @HostListener("dblclick", ["$event"])
    onDblClick(e: MouseEvent): boolean {
        const anchor = this.getAnchorFromTarget(e);
        if (!anchor) {
            return true;
        }
        if (!this.enableOneClick && !this.processClick(anchor)) {
            clearSelection();
            return false;
        }
        return true;
    }

    private getAnchorFromTarget(e): HTMLAnchorElement | undefined {
        let elm: Element = e.target;
        while (elm) {
            if (elm === this.el.nativeElement) {
                return undefined;
            }
            if (elm.nodeType === 1 && elm.tagName.toUpperCase() === "A") {
                return elm as HTMLAnchorElement;
            }
            elm = elm.parentElement;
        }
    }

    private isInContentEditable(anchor: HTMLAnchorElement): boolean {
        let elm: Element = anchor.parentElement;
        while (elm) {
            if (elm === this.el.nativeElement) {
                return false;
            }
            const attr = elm.getAttribute("contenteditable");
            if (attr && attr === "true") {
                return true;
            }
            elm = elm.parentElement;
        }
        return false;
    }

    private processClick(anchor: HTMLAnchorElement): boolean {
        let href = anchor.getAttribute("href");
        if (href) {
            const origin = getOrigin();
            if (href.startsWith(origin)) {
                href = href.substring(origin.length);
            }

            for (let i = 0; i < allowedProtocols.length; i++) {
                let protocol = allowedProtocols[i];
                if (href.startsWith(protocol)) {
                    performNavigation(anchor);
                    return false;
                }
            }
            if (href.startsWith("/api/")) {
                performNavigation(anchor);
                return;
            }
            this.router.navigateByUrl(href);
            return false;
        }
        return true;
    }

}

/*

const navigate = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.router.navigateByUrl(this.buildUrl(info));
};

e.addEventListener("dblclick", (e) => {
    clearSelection();
    navigate(e);
});

e.addEventListener("click", (e) => {
    if (e.ctrlKey || e.metaKey) {
        navigate(e);
    }
});

*/

function clearSelection() {
    if (document["selection"] && document["selection"].empty) {
        document["selection"].empty();
    } else if (window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
    }
}

function performNavigation(elm: HTMLAnchorElement) {
    bypassClick = true;
    elm.setAttribute("contenteditable", "false");
    elm.click();
    elm.removeAttribute("contenteditable");
}
