import {Attribute, ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Optional, SimpleChanges, ViewChild} from "@angular/core";
import {NSelectComponent} from "../n-select.component";
// noinspection TypeScriptPreferShortImport
import {NDropdownMenuItemComponent} from "../../../components/dropdown/n-dropdown-menu-item.component";
import {NDropdownMenuDividerComponent} from "../../dropdown/n-dropdown-menu-divider.component";

@Component({
    selector: "n-option",
    templateUrl: "./n-option.html",
    styleUrls: [
        "./n-option.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NOptionComponent implements OnInit, OnChanges {
    // inject services here
    @Input() value: any;
    @Input() heading: string;
    @Input() description: string;
    @Input() iconId: string;
    @Input() iconColor: string;
    @Input() divider: string | boolean;

    @ViewChild("menuItem", {static: false})
    menuItem: NDropdownMenuItemComponent | NDropdownMenuDividerComponent;
    _divider: boolean;

    constructor(@Optional() private selectCmp: NSelectComponent,
                @Attribute("tid") public tid: string) {
    }

    ngOnChanges(c: SimpleChanges) {
        const {divider} = c;
        if (divider) {
            this._divider = (divider.currentValue === "" || !!divider.currentValue);
        }
    }

    ngOnInit() {
        if (this.value === undefined) {
            this.value = this;
        }
    }

    select() {
        this.selectCmp.selectOption(this.menuItem.getDropdownMenuItem(), false, true);
    }
}
