import {Injectable} from "@angular/core";
import {filter, map, Subject} from "rxjs";
import {AuthService} from "./auth.service";
import {FileInfo} from "../models/FileInfo";
import {AppService} from "./app.service";
import {FailServerResponse} from "../models/FailServerResponse";


@Injectable({providedIn: "root"})
export class FileService {

    private fileHost: string;

    constructor(private auth: AuthService, appSvc: AppService) {
        appSvc.context$
            .pipe(
                filter(ctx => !!ctx),
                map(c => c.hosts.files)
            )
            .subscribe(host => {
                this.fileHost = host;
            });
    }

    contructFullUrl(url: string): string {
        return `${this.fileHost}/${url}`;
    }

    postFiles<T>(files: File[], url: string): IPostFilesProgress {

        let progress = new Subject<number>();
        let result = new Promise<T>((resolve, reject) => {
            this.auth.ensureToken()
                .then(token => {

                    const formData: any = new FormData();
                    const xhr = new XMLHttpRequest();
                    for (let i = 0; i < files.length; i++) {
                        formData.append("uploads[]", files[i], files[i].name);
                    }

                    const updateProgress = (event) => {
                        if (event.total === 0) {
                            return; // chrome may return 0 in total
                        }
                        let percent = Math.round(event.loaded / event.total * 100);
                        progress.next(percent);
                    };

                    xhr.upload.addEventListener("progress", updateProgress, false);
                    xhr.upload.onloadstart = function (event) {
                        progress.next(0);
                    };

                    xhr.onreadystatechange = function () {
                        if (xhr.readyState === 4) {
                            if (xhr.status === 200) {
                                resolve(JSON.parse(xhr.response));
                            } else {
                                reject(new FailServerResponse(xhr.response ? JSON.parse(xhr.response) : undefined));
                            }
                            progress.complete();
                        }
                    };

                    xhr.open("POST", url, true);
                    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
                    xhr.send(formData);
                });
        });

        return {
            result: result,
            progress: progress
        };
    }

    upload(files: File[]): IUploadFilesProgress<FileInfo[]> {

        const uploadResult = this.postFiles(files, `api/files`);

        return {
            result: uploadResult.result.then(data => FileInfo.parseArray(data.files)),
            progress: uploadResult.progress
        };
    }
}

export interface IPostFilesProgress {
    result: Promise<any>;
    progress: Subject<number>;
}

export interface IUploadFilesProgress<T> {
    result: Promise<T>;
    progress: Subject<number>;
}
