import {ErrorHandler, ModuleWithProviders, NgModule} from "@angular/core";
import {DefaultErrorHandler} from "./DefaultErrorHandler";
import {ExceptionLogger} from "./ExceptionLogger";

@NgModule({
    providers: []
})
export class ErrorLoggingModule {
    static forRoot(): ModuleWithProviders<ErrorLoggingModule> {
        return {
            ngModule: ErrorLoggingModule,
            providers: [
                {provide: ErrorHandler, useClass: DefaultErrorHandler},
                ExceptionLogger
            ]
        };
    }
}
