import {ChangeDetectionStrategy, Component, forwardRef, OnInit} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {EntityColor, getEntityColors, getEntityCssColor} from "../../models/EntityColor";

@Component({
    selector: "notebook-color-picker",
    templateUrl: "notebook-color-picker.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NotebookColorPickerComponent),
            multi: true
        }
    ],
    styleUrls: [
        "./notebook-color-picker.ng.css"
    ]
})

export class NotebookColorPickerComponent implements ControlValueAccessor, OnInit {

    colors: EntityColor[] = getEntityColors();
    selectedColor: EntityColor;

    constructor() {
    }

    cssColor(color: EntityColor): string {
        return getEntityCssColor(color);
    }

    ngOnInit() {
    }

    propagateChange = (_: any) => {
    };

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    select(val: EntityColor) {
        this.selectedColor = val;
        if (this.propagateChange) {
            this.propagateChange(val);
        }
    }

    writeValue(obj: EntityColor): void {
        this.selectedColor = obj;
    }
}
