<div [stickOnRevertScroll]="true"
     [stickyContainer]="'top'"
     class="filter-input__container">
    <div [class._empty]="isEmpty$|async" [loading-line]="loading" class="filter-input" stickyBlock>
        <!--suppress HtmlFormInputWithoutLabel -->
        <input #input
               (compositionend)="compositionEnd($event)"
               (compositionstart)="compositionStart()"
               (focus)="focus.emit($event)"
               (input)="onChange($event)"
               (keydown)="handleKeyDown($event)"
               (keydown.escape)="clearFilter($event)"
               [placeholder]="placeholderText$|async"
               class="filter-input__input"/>
        <span (click)="clearFilter()" *ngIf="!(isEmpty$|async)" class="filter-input__clear-icon">
            <n-icon [iconId]="'filter-input-clear'"></n-icon>
        </span>
    </div>
</div>
