export class ToastInstance {

    private _isClosed: boolean;

    constructor(private onClose: () => void, private _data: any) {
    }

    get data(): any {
        return this._data;
    }

    get closed() {
        return this._isClosed;
    }

    close() {
        if (!this._isClosed) {
            this._isClosed = true;
            this.onClose();
        }
    }

}
