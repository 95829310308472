import {DocumentMetadata} from "./DocumentMetadata";
import {DocumentTag} from "./DocumentTag";
import {BehaviorSubject, Observable} from "rxjs";
import {MemorySubject} from "../../../modules/store";

export class DocumentListInfo extends DocumentMetadata {

    private _innerTags = new BehaviorSubject<DocumentTag[]>([]);
    private _innerTitle = new BehaviorSubject("");
    private _innerSnippet$ = new MemorySubject<string>();

    private _sRev: number;

    get sRev(): number {
        return this._sRev;
    }

    private _previewImageUrl$ = new MemorySubject<string>();

    get previewImageUrl$(): Observable<string> {
        return this._previewImageUrl$.asObservable();
    }

    private _notebookName: string;

    get notebookName(): string {
        return this._notebookName;
    }

    get previewImageUrl(): string {
        return this._previewImageUrl$.value;
    }

    get snippet(): string {
        return this._innerSnippet$.value;
    }

    get snippet$(): Observable<string> {
        return this._innerSnippet$.asObservable();
    }

    get tags$(): Observable<DocumentTag[]> {
        return this._innerTags;
    }

    get title$(): Observable<string> {
        return this._innerTitle;
    }

    static parse(data: any): DocumentListInfo {
        let info = DocumentMetadata.parse(data, new DocumentListInfo()) as DocumentListInfo;
        if (data.tags) {
            info.replaceTags(DocumentTag.parseArray(data.tags));
        }

        info._sRev = data.sRev;
        info._notebookName = data.notebookName;
        info._innerTitle.next(data.title);
        info._innerSnippet$.next(data.snippet || "");
        info._previewImageUrl$.next(data.previewImageUrl);

        return info;
    }

    static parseArray(data: any[]): DocumentListInfo[] {
        return data.map(d => DocumentListInfo.parse(d));
    }

    addTags(tags: DocumentTag[]) {
        const list = this._innerTags.getValue();
        for (let tag of tags) {
            if (!list.some(t => t.normalizedName === tag.normalizedName)) {
                list.push(tag);
            }
        }
        this._innerTags.next(list);
    }

    getTagsSnapshot(): DocumentTag[] {
        return this._innerTags.getValue();
    }

    removeTags(tags: DocumentTag[]) {
        const list = this._innerTags.getValue();
        for (let tag of tags) {
            let existTag = list.find(t => t.normalizedName === tag.normalizedName);
            if (existTag) {
                list.splice(list.indexOf(existTag), 1);
            }
        }
        this._innerTags.next(list);
    }

    replaceTags(tags: DocumentTag[]) {
        this._innerTags.next(tags);
    }

    updateInfo(snippet: string, previewImageUrl: string) {
        this._innerSnippet$.next(snippet);
        this._previewImageUrl$.next(previewImageUrl);
    }

    updateTitle(title: string) {
        if (this.title !== title) {
            this._innerTitle.next(title);
            this.title = title;
        }
    }
}

export function filterDocumentList(docs: DocumentListInfo[], filter: string): DocumentListInfo[] {

    let result = docs.sort((a, b) => {
        if (a.createdDate > b.createdDate) return -1;
        if (a.createdDate < b.createdDate) return 1;
        return 0;
    });

    if (!filter) {
        return result;
    }

    result = result
        .filter(d => {
            const nomalizedTitle = (d.title || "").toUpperCase();
            const normalizedQuery = filter.toUpperCase();

            if (nomalizedTitle.indexOf(normalizedQuery) >= 0) {
                return true;
            }
            const normalizedAuthor = d.createdBy.toFullname().toUpperCase();
            if (normalizedAuthor.indexOf(normalizedQuery) >= 0) {
                return true;
            }

            const normalizedSnippet = d.snippet.toUpperCase();

            return normalizedSnippet.indexOf(normalizedQuery) >= 0;


        });

    return result;

}


export function documentListComparer(list1: DocumentListInfo[], list2: DocumentListInfo[]): boolean {
    if (list1.length !== list2.length) {
        return false;
    }

    for (let i = 0; i < list1.length; i++) {
        const list1Item = list1[i];
        const list2Item = list2[i];
        if (list1Item.title !== list2Item.title ||
            list1Item.snippet !== list2Item.snippet ||
            list1Item.id !== list2Item.id ||
            list1Item.notebookName !== list2Item.notebookName) {
            return false;
        }
    }

    return true;
}


