import {ComponentRef, ElementRef, EventEmitter, NgZone} from "@angular/core";
import {PopoverContext} from "./PopoverContext";
import {IPopoverOptions} from "./IPopoverOptions";
import {PopoverWindowComponent} from "./directives/public_directives";
import {IRect} from "./position";

export const POPOVER_COMPONENT_SELECTOR = "n-popover-window";
export const POPOVER_OPEN_CLASS = "popover__open";

/**
 * Result of popover
 */
export interface PopoverResult<T> {
    data: T;
    closed: boolean;
}

/**
 * Represent instance of shown popover
 */
export class PopoverInstance<T = any> {

    result: Promise<PopoverResult<T>>;

    onClose = new EventEmitter<any>();
    mouseover: EventEmitter<any> = new EventEmitter<any>();
    mouseleave: EventEmitter<any> = new EventEmitter<any>();
    opener: ElementRef;
    private resolve: (result: PopoverResult<T>) => void;
    private zone: NgZone;
    private closed: boolean;

    constructor(private cmpRef: ComponentRef<PopoverWindowComponent>,
                private popoverCtx: PopoverContext<any>,
                public readonly options: IPopoverOptions) {

        this.zone = cmpRef.injector.get<NgZone>(NgZone);

        this.result = new Promise((resolve) => {
            this.resolve = resolve;
        });

        popoverCtx.onDone = result => this.done(result);
        popoverCtx.onCancel = () => this.close();
        popoverCtx.onUpdatePosition = () => {
            this.cmpRef.instance.positionPopover();
        };

        // this.cmpRef.instance.popoverEl ? this.cmpRef.instance.popoverEl.nativeElement : cmpRef.location.nativeElement;

        const elem = cmpRef.location.nativeElement;
        if (elem) {
            this.zone.runOutsideAngular(() => {
                const node = elem;
                node.addEventListener("mouseover", (e) => {
                    this.mouseover.emit(e);
                });
                node.addEventListener("mouseleave", (e) => {
                    this.mouseleave.emit(e);
                });
            });
        }

        if (options.targetElementRef) {
            this.opener = options.targetElementRef;
            this.options.targetElementRef.nativeElement.classList.add(POPOVER_OPEN_CLASS);
        }
    }

    get popoverElement(): ElementRef {
        return this.cmpRef.instance.popoverEl;
    }

    get autoclose() {
        return typeof (this.options.autoclose) === "undefined" || this.options.autoclose;
    }

    get instance(): any {
        return this.popoverCtx.instance.instance;
    }

    close() {
        if (this.closed) {
            return;
        }
        return this.closePopover().then(() => {
            this.resolve({
                data: undefined,
                closed: true
            });
        });
    }

    done(result: T) {
        if (this.closed) {
            return;
        }
        return this.closePopover().then(() => {
            this.resolve({
                data: result,
                closed: false
            });
        });
    }

    // TODO need beter way to update position
    updatePosition(rect: IRect) {
        this.options.targetRect = rect;
        this.cmpRef.instance.positionPopover();
    }

    private closePopover(): Promise<any> {

        this.closed = true;
        const instance = this.popoverCtx.instance;

        let idle: Promise<any> = Promise.resolve();
        if (instance && instance["canDestroyPopover"]) {
            idle = instance["canDestroyPopover"]();
        }

        if (this.options.targetElementRef) {
            this.options.targetElementRef.nativeElement.classList.remove(POPOVER_OPEN_CLASS);
        }

        this.onClose.emit();

        if (this.options.animate) {
            idle = idle.then(() => new Promise(resolve => {
                this.cmpRef.instance.hide();
                setTimeout(resolve, 200);
            }));
        }

        return idle.then(() => this.cmpRef.destroy());
    }

}
