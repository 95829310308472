import {ChangeDetectionStrategy, Component, ComponentRef, ViewEncapsulation} from "@angular/core";
import {MenuService} from "../../services/menu.service";
import {IApplicationPage} from "../../../pages";
import {TitleService} from "../../services/title.service";
import {Observable, Subscription} from "rxjs";

import "searchpicker/src/css/searchPicker.css";

@Component({
    selector: "app-layout",
    templateUrl: "./app-layout.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "./app-layout.bem.css",
        "./../../directives/loading-line/loading-line.bem.css",
        "./../../shared/highlighter.bem.css",
        "../../services/ripple/ripple-effect.css"
    ]
})
export class AppLayoutComponent {
    readonly menuOpened$: Observable<boolean>;

    readonly bodyElement = document.body;

    private titleSub: Subscription;
    private pageTitleSub: Subscription;

    constructor(private menuSvc: MenuService,
                private titleSvc: TitleService) {
        this.menuOpened$ = this.menuSvc.menuOpened$;
    }

    closeMenu() {
        this.menuSvc.closeMenu();
    }

    menuClosed() {
        this.menuSvc.closeMenu();
    }

    menuOpened() {
        this.menuSvc.openMenu();
    }

    onActivate(cmpRef: ComponentRef<any>) {
        const page = cmpRef.instance as IApplicationPage;
        if (this.titleSub) {
            this.titleSub.unsubscribe();
            this.titleSub = undefined;
        }
        if (this.pageTitleSub) {
            this.pageTitleSub.unsubscribe();
            this.pageTitleSub = undefined;
        }

        if (page.documentTitle$) {
            this.titleSub = page.documentTitle$.subscribe(content => {
                this.titleSvc.setDocumentTitle(content.title, content.translate);
            });
        }

        if (page.pageTitle$) {
            this.pageTitleSub = page.pageTitle$.subscribe(content => {
                this.titleSvc.setPageTitle(content.title, content.contentTitle, content.translate);
            });
        }
    }

}
