import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2} from "@angular/core";
import {IDropdownMenuItem, NDropdownMenuComponent} from "./n-dropdown-menu.component";
import {NDropdownMenuPopoverComponent} from "./n-dropdown-menu-popover/n-dropdown-menu-popover.component";


import {PopoverInstance, PopoverService} from "../../../n-popover";
import {HorizontalAlign} from "../../../n-popover/position";
import {KeyCodes} from "../KeyCodes";

// noinspection TypeScriptPreferShortImport


@Directive({
    selector: "[nDropdown]"
})

export class NDropdownDirective implements OnDestroy {

    @Input() nDropdown: NDropdownMenuComponent;

    @Input() position: "top" | "bottom" = "bottom";

    @Input() horizontalAlign: HorizontalAlign = "right";

    @Input() setupMinWidth: boolean;

    @Input() nDropdownOffset: number;

    @Input() nDropdownContainer: ElementRef;

    @Output()
    dropdownToggleOpened = new EventEmitter<any>();

    @Output()
    dropdownToggleClosed = new EventEmitter<any>();

    private popover: PopoverInstance<IDropdownMenuItem>;

    constructor(private el: ElementRef,
                private renderer: Renderer2,
                private popoverSvc: PopoverService) {
    }

    get opened(): boolean {
        return !!this.popover;
    }

    close() {
        if (this.opened) {
            this.popover.close();
            this.popover = null;
        }
    }

    @HostListener("keydown", ["$event"])
    keypress(event: KeyboardEvent): void {

        const keyCode = event.which || event.keyCode;
        switch (keyCode) {
            case KeyCodes.SPACE: {
                this.toggleAndSelect();
                event.preventDefault();
                break;
            }
            case KeyCodes.ENTER: {
                this.toggleAndSelect();
                event.stopPropagation();
                break;
            }
            case KeyCodes.ESCAPE: {
                if (this.opened) {
                    this.close();
                    event.stopPropagation();
                }
                break;
            }
            case KeyCodes.ARROW_UP: {
                if (this.opened) this.nDropdown.focusPrev();
                event.preventDefault();
                break;
            }
            case KeyCodes.HOME: {
                if (this.opened) this.nDropdown.focusFirst();
                event.preventDefault();
                break;
            }
            case KeyCodes.ARROW_DOWN: {
                if (this.opened) this.nDropdown.focusNext();
                event.preventDefault();
                break;
            }
            case KeyCodes.END: {
                if (this.opened) this.nDropdown.focusLast();
                event.preventDefault();
                break;
            }
        }
    }

    ngOnDestroy() {
        this.close();
    }

    open() {
        if (this.opened || !this.nDropdown) {
            return;
        }

        if (this.popover) {
            this.popover.close();
            return;
        }

        if (this.nDropdown.getItemsSnapshot().length === 0) {
            return;
        }

        const menuItems$ = this.nDropdown.getItems();

        this.popover = this.popoverSvc.open({
            componentType: NDropdownMenuPopoverComponent,
            targetElementOffset: this.nDropdownOffset,
            parentContainer: this.nDropdownContainer ? this.nDropdownContainer.nativeElement : undefined,
            popoverData: {
                items: menuItems$,
                focusedIndex: this.nDropdown.focusedIndex$,
                wide: this.nDropdown.wide,
            },
            mobileView: this.nDropdown.mobileView === undefined ? true : this.nDropdown.mobileView,
            popoverClass: "n-dropdown-menu-popover__wrapper",
            position: this.position,
            horizontalAlign: this.horizontalAlign,
            hideArrow: true,
            targetElementRef: this.el,
            setupMinWidth: this.setupMinWidth
        });

        this.popover.result.then(result => {
            if (!result.closed) {
                this.nDropdown.select(result.data);
            }
            this.dropdownToggleClosed.emit();
            this.nDropdown.setFocusedItemIndex(-1);
            this.popover = null;
        });

        this.dropdownToggleOpened.emit();
    }

    @HostListener("mousedown")
    toggle(): void {
        if (this.opened) {
            this.close();
        } else {
            this.open();
        }
    }

    private toggleAndSelect() {
        if (this.opened) {
            if (this.nDropdown.focusedIndexSnapshot !== -1) {
                this.nDropdown.selectFocused();
            }
            this.close();
        } else {
            this.open();
        }
    }
}
