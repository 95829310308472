import {AsyncValidatorFn, FormControl} from "@angular/forms";
import {validateEntityName} from "./EntityNameValidation";
import {UserService} from "../services/user.service";

export function validateUsername(userSvc: UserService, initialValue: string, options?: { shouldExist?: boolean, serverCheck?: boolean }): AsyncValidatorFn {

    const shouldExist = options && options.shouldExist;
    const serverCheck = options && options.serverCheck;

    let tmrId;

    return (c: FormControl): any => {

        if (c.invalid) {
            return Promise.resolve(null);
        }

        if (!serverCheck) {
            let result = validateEntityName(c);
            if (result) {
                return Promise.resolve(result);
            }

            if (!c.dirty || c.value === initialValue) {
                return Promise.resolve(null);
            }
        }

        if (tmrId) {
            clearTimeout(tmrId);
            tmrId = null;
        }

        return new Promise((resolve) => {

            tmrId = setTimeout(() => {
                tmrId = null;
                if (!c.value) {
                    resolve(null);
                    return;
                }
                userSvc.userNameExists(c.value)
                    .then((result) => {
                        if ((result && !shouldExist) || (!result && shouldExist)) {
                            resolve({
                                userExists: true
                            });
                        } else {
                            resolve(null);
                        }
                    })
                    .catch(() => {
                        // unable to check
                        resolve(null);
                    });
            }, 500);

        });
    };
}
