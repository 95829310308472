import {DataContextService, RequestOptions} from "./data-context.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {CacheStorageProvider} from "./CacheStorageProvider";
import {MemorySubject} from "../../modules/store";
import {HttpHeaders} from "@angular/common/http";

const autoCache = true;
const CACHE_DATA_MARKER = "*fromCache";

function buildHeaders(headers: HttpHeaders): Record<string, string> {
    const result = {};
    for (let h of headers.keys()) {
        result[h] = headers.get(h);
    }
    return result;
}

/**
 * Сервис позволяем получать данные от API используя локальный кеш
 */
@Injectable({providedIn: "root"})
export class CacheableDataContextService {

    constructor(private data: DataContextService,
                private cacheStorage: CacheStorageProvider) {

    }

    clearCache(url: string): Promise<any> {
        return this.cacheStorage.remove(url);
    }

    "delete"(url: string, options?: RequestOptions): Promise<any> {
        return this.data.delete(url, options);
    }

    get(url: string,
        options?: RequestOptions,
        bypassCache?: boolean): Observable<any> {

        const response$ = new MemorySubject();

        let networkDataReceived = false;

        // fetch fresh data
        this.data.getResponse(url, options)
            .then((res) => {

                response$.next(res.body);
                response$.complete();

                networkDataReceived = true;

                if (autoCache) {
                    if (!window["Response"]) {
                        return; // IE 11
                    }
                    const resNew = new Response(JSON.stringify(res.body), {
                        "status": res.status,
                        "statusText": res.statusText,
                        headers: buildHeaders(res.headers)
                    });
                    this.cacheStorage.set(res.url, resNew);
                }
            }, fail => {
                response$.error(fail);
                response$.complete();
            });

        // fetch cached data
        if (!bypassCache) {
            this.cacheStorage.get(buildFullUrl(url, options))
                .then((response) => {
                    if (!response) {
                        return undefined;
                    }
                    return response.json();
                })
                .then((data) => {
                    // don't overwrite newer network data
                    if (data && !networkDataReceived) {
                        data[CACHE_DATA_MARKER] = true;
                        response$.next(data);
                    }
                })
                .catch(() => {
                    // do nothing
                });
        }

        return response$;

    }

    patch(url: string, body: any, options?: RequestOptions): Promise<any> {
        return this.data.patch(url, body, options);
    }

    post(url: string, body?: any, options?: RequestOptions): Promise<any> {
        // do not cache
        return this.data.post(url, body, options);
    }

    put(url: string, body?: any, options?: RequestOptions): Promise<any> {
        return this.data.put(url, body, options);
    }

}

export function buildFullUrl(url: string, options?: any) {
    if (!options || !options.params) {
        return url;
    }

    const p = new URLSearchParams();

    Object.keys(options.params).forEach((key) => {
        p.append(key, options.params[key]);
    });
    const query = p.toString();

    return query ? `${url}?${query}` : url;
}

export function isCachedData(data: any): boolean {
    return data.hasOwnProperty(CACHE_DATA_MARKER);
}
