<div [ngClass]="mod" class="dr-field">

    <div *ngIf="label || labelExtra" class="dr-field__label">
        <span *ngIf="label" class="dr-field__label-main">{{label | translate}}</span>
        <span *ngIf="labelExtra" class="dr-field__label-extra">{{labelExtra | translate}}</span>
    </div>

    <div class="dr-field__wrapper">
        <div class="dr-field__body">
            <ng-content></ng-content>
        </div>
        <div *ngIf="description" class="dr-field__description">{{description | translate}}</div>
    </div>

</div>
