import {Injectable, NgZone} from "@angular/core";

export interface ITouchHandlers {
    start?: (e: ITouchEvent) => void;
    move?: (e: ITouchEvent) => void;
    cancel?: () => void;
    end?: () => void;
}

export interface ITouchEvent {
    pageX: number;
    pageY: number;

    clientX: number;
    clientY: number;

    target: Element;

    deltaX: number;
    deltaY: number;
}

@Injectable({providedIn: "root"})
export class TouchService {

    constructor(private zone: NgZone) {

    }

    bind(element: Element, handlers: ITouchHandlers, options?: TouchBindOptions): {
        destroy: () => void
    } {
        return this.zone.runOutsideAngular(() => {
            let startX = 0;
            let startY = 0;

            const mouseEvents = options && options.mouseEvents;
            const listenerOptions = {passive: true, capture: options && options.useCapture};

            function onStart(e) {
                const evt = getEvent(e);
                if (!evt) {
                    return;
                }
                startX = evt.pageX;
                startY = evt.pageY;
                if (handlers.start) {
                    handlers.start(evt);
                }
            }

            function getEvent(e): ITouchEvent | undefined {
                if (typeof (e.pageX) === "undefined" && typeof (e.touches) === "undefined") {
                    return undefined;
                }
                const pageX = e.pageX || e.touches[0].pageX;
                const pageY = e.pageY || e.touches[0].pageY;
                return {
                    pageX,
                    pageY,
                    clientX: e.clientX || e.touches[0].clientX,
                    clientY: e.clientY || e.touches[0].clientY,
                    target: e.target,
                    deltaX: pageX - startX,
                    deltaY: pageY - startY
                };
            }

            function onMove(e) {
                const evt = getEvent(e);
                if (handlers.move && evt) {
                    handlers.move(evt);
                }
            }

            function onEnd() {
                if (handlers.end) {
                    handlers.end();
                }
            }

            function onCancel() {
                if (handlers.cancel) {
                    handlers.cancel();
                }
            }

            element.addEventListener("touchstart", onStart, listenerOptions);
            element.addEventListener("touchmove", onMove, listenerOptions);
            element.addEventListener("touchend", onEnd, listenerOptions);
            element.addEventListener("touchcancel", onCancel, listenerOptions);

            if (mouseEvents) {
                element.addEventListener("mousedown", onStart, listenerOptions);
                element.addEventListener("mousemove", onMove, listenerOptions);
                element.addEventListener("mouseup", onEnd, listenerOptions);
            }

            return {
                destroy: () => {
                    element.removeEventListener("touchstart", onStart, listenerOptions as any);
                    element.removeEventListener("touchmove", onMove, listenerOptions as any);
                    element.removeEventListener("touchend", onEnd, listenerOptions as any);
                    element.removeEventListener("touchcancel", onCancel, listenerOptions as any);

                    if (mouseEvents) {
                        element.removeEventListener("mousedown", onStart, listenerOptions as any);
                        element.removeEventListener("mousemove", onMove, listenerOptions as any);
                        element.removeEventListener("mouseup", onEnd, listenerOptions as any);
                    }
                }
            };

        });
    }

    isTouchDevice(): boolean {
        return "ontouchstart" in window        // works on most browsers
            || !!navigator.maxTouchPoints;
    }
}

export interface TouchBindOptions {
    /**
     * Обрабатывать действия мыши?
     */
    mouseEvents?: boolean;

    useCapture?: boolean;
}
