import {UserInfo} from "./UserInfo";
import {NotebookInfo} from "./NotebookInfo";
import {PinnedTeamInfo} from "./PinnedTeamInfo";
import {DomainInfo} from "./DomainInfo";

export type HostsConfig = {
    files: string;
    images: string;
    export: string;
};

export class ApplicationContext {

    readonly user: UserInfo | undefined;
    readonly hosts: HostsConfig;

    readonly pinnedNotebooks: NotebookInfo[];
    readonly pinnedTeams: PinnedTeamInfo[];
    readonly domain: DomainInfo;

    constructor(serverData: any) {
        if (serverData.user) {
            this.user = UserInfo.parse(serverData.user);
        }
        this.hosts = serverData.hosts;
        this.domain = new DomainInfo(serverData.domain);
        this.pinnedNotebooks = serverData.pinnedNotebooks ? NotebookInfo.parseArray(serverData.pinnedNotebooks) : [];
        this.pinnedTeams = serverData.pinnedTeams ? PinnedTeamInfo.parseArray(serverData.pinnedTeams) : [];
    }

    get anonymous(): boolean {
        return !this.user;
    }

}
