<div [class._open-colors-picker]="isColorPickerOpened" class="new-notebook-action">

    <div class="cover">
        <div [style.backgroundColor]="color$|async" class="cover-front"></div>
        <div [style.borderColor]="color$|async" class="cover-back"></div>

        <div class="button">
            <n-icon [iconId]="'plus'" class="button-icon"></n-icon>
            <span class="button-text">{{"notebooks.create" | translate }}</span>
        </div>
    </div>

    <div class="body">
        <div (click)="createNotebook()" [style.fill]="color$|async" class="icon">
            <div [style.backgroundColor]="color$|async" class="cyrcle"></div>
            <n-icon [iconId]="'plus'" [style.fill]="color$|async" class="plus-icon"></n-icon>
        </div>
        <div (click)="createNotebook()" class="info">
            <div class="text">{{"notebooks.create" | translate }}</div>
            <div class="notebook-name">{{text}}</div>
        </div>
        <div (click)="toggleColorPicker()" class="action-button">
            <n-icon [iconId]="'select'" class="action-button-icon"></n-icon>
        </div>
    </div>
    <div class="colors">
        <notebook-color-picker [formControl]="colorPicker"></notebook-color-picker>
    </div>
    <div class="hint">Shift + Enter</div>
</div>
