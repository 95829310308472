import {Directive, ElementRef, Input, Renderer2} from "@angular/core";

const CSS_CLASS = "_loading";

@Directive({selector: "[loading-line]"})
export class LoadingLineDirective {

    constructor(private el: ElementRef, private renderer: Renderer2) {
        renderer.addClass(el.nativeElement, "_loading-line");
    }

    @Input("loading-line")
    set line(val: boolean) {
        if (val) {
            this.renderer.addClass(this.el.nativeElement, CSS_CLASS);
        } else {
            this.renderer.removeClass(this.el.nativeElement, CSS_CLASS);
        }
    }
}
