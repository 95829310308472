import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NotebookInfo} from "../../models/NotebookInfo";
import {Router} from "@angular/router";
import {map, Observable, switchMap} from "rxjs";
import {MemorySubject} from "../../../modules/store";

@Component({
    selector: "notebook-header",
    templateUrl: "./notebook-header.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        "./notebook-header.ng.css"
    ]
})
export class NotebookHeaderComponent implements OnInit {

    @Input()
    canPin: boolean;

    @Input()
    ownerTitle: string;

    @Input()
    canSelect: boolean;

    @Input()
    ownerLink: any[];

    @Input()
    settingsUrl: any[];

    @Input()
    recycleLink: any[];

    @Input()
    canManage: boolean;

    @Output()
    pin = new EventEmitter();

    @Output()
    select = new EventEmitter();

    @Output()
    remove = new EventEmitter();

    pinKey$: Observable<string>;

    readonly notebook$ = new MemorySubject<NotebookInfo>();

    constructor(private router: Router) {

    }

    @Input()
    set notebook(val: NotebookInfo) {
        this.notebook$.next(val);
    }

    ngOnInit() {
        this.pinKey$ = this.notebook$
            .pipe(
                switchMap(n => n.pinned$),
                map(pinned => pinned ? "notebooks.menu.unpin" : "notebooks.menu.pin")
            );
    }

    openSettings() {
        this.router.navigate(this.settingsUrl);
    }

    toBin() {
        this.router.navigate(this.recycleLink);
    }

}
