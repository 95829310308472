import {ErrorHandler, Injectable} from "@angular/core";
import {ExceptionLogger} from "./ExceptionLogger";
import {ExceptionInfo} from "./ExceptionInfo";
import {Subject, throttleTime} from "rxjs";

@Injectable()
export class DefaultErrorHandler extends ErrorHandler {

    //Время, через которое отправляем ошибки, если не появилось новых

    private buffer: ExceptionInfo[] = [];
    private errorEvents$: Subject<any> = new Subject<any>();

    constructor(private logger: ExceptionLogger) {
        super();
        this.errorEvents$
            .pipe(
                throttleTime(1500)
            )
            .subscribe(() => this.flushErrors());
    }

    handleError(error: any): void {
        this.updateErrorStorage(error);
        this.errorEvents$.next(1);

        super.handleError(error);
    }

    private flushErrors() {
        this.logger.logExceptions(this.buffer).catch(
            (fail: any) => {
                console.error("unable to submit errors to server", fail);
            });
    }

    /*
     * добавляет ошибку в хранилище и возвращает количество сохраненных ошибок
     * */
    private updateErrorStorage(exception: any) {
        let alreadyLogged = false;
        for (const err of this.buffer) {
            if (err.message == exception.message) {
                alreadyLogged = true;
                err.count += 1;
                break;
            }
        }

        if (!alreadyLogged) {
            this.buffer.push({
                address: location.pathname + location.search,
                message: exception.message,
                stacktrace: exception.stack,
                count: 1,
                rawException: exception
            });
        }

    }
}
