<div #menuElm [class._hasIcon]="hasIcon$|async" [class._wide]="wide" class="menu">
    <div (click)="select(item)"
         *ngFor="let item of menuItems$|async; let currentIndex = index"
         [attr.tid]="item.tid"
         [attr.title]="item.heading|async"
         [ngClass]="{_disabled: (item.disabled|async),
        _divider: item.divider,
        _focused: currentIndex===(focusedIndex$|async)}"
         class="menu-item">

        <ng-container *ngIf="!item.divider">
            <div *ngIf="item.iconId|async" class="menu-item__icon">
                <n-icon [fillColor]="item.iconColor|async" [iconId]="item.iconId|async"></n-icon>
            </div>
            <div class="menu-item__text">
                {{item.heading|async}}
            </div>
            <div *ngIf="item.description|async" class="menu-item__description">
                {{item.description|async}}
            </div>
        </ng-container>
    </div>
</div>
