import {UserRef} from "../UserRef";


export class DocumentMetadata {

    id: string;
    createdBy: UserRef;
    createdDate: Date;
    title: string;

    static parse(data: any, dest?: DocumentMetadata): DocumentMetadata {
        const doc = dest || new DocumentMetadata();
        doc.createdDate = new Date(data.createdDate);
        doc.createdBy = data.createdBy ? UserRef.parse(data.createdBy) : undefined; // may be undefined (see DocumentInfo.toListInfo method
        doc.title = data.title || "";
        doc.id = data.id;
        return doc;
    }

    static parseArray(data: any[]): DocumentMetadata[] {
        return data.map(d => DocumentMetadata.parse(d));
    }

}
