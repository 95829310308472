<page-message *ngIf="emptyList$|async" [iconId]="'recent-not-found'" [messageKey]="'recent.emptyList'"></page-message>

<div (infiniteScroll)="scrollToEnd()" *ngIf="(recentGroups$ | async).length" class="page">

    <div class="title">{{"recent.title"|translate}}</div>

    <notebook-section *ngFor="let group of recentGroups$ | async;trackBy:groupIdentity"
                      [notebook]="group.notebook$|async">
        <document-list-item *ngFor="let doc of group.documents$ | async;trackBy:docIdentity"
                            [canSelect]="false"
                            [document]="doc"
                            [selectMode]="false"
                            [selected]="false"
                            [showGroup]="true"></document-list-item>
    </notebook-section>
</div>
