import {Injectable, Injector} from "@angular/core";
import {ExceptionInfo, ExceptionLogger} from "./modules/error-logging";
import {environment} from "../environments/environment";
import {FailServerResponse} from "./core/models/FailServerResponse";
import {DataContextService} from "./core/services/data-context.service";
import {getAppVersion} from "./core/services/app.service";


@Injectable({providedIn: "root"})
export class AppExceptionLogger extends ExceptionLogger {

    constructor(private injector: Injector) {
        super();
    }

    logExceptions(exceptions: ExceptionInfo[]): Promise<any> {
        if (!environment.enableClientErrorLogging) {
            return Promise.resolve();
        }
        const exceptionsToLog = exceptions.filter(needToLog).map(ex => {
            return {
                message: ex.message,
                count: ex.count,
                address: ex.address,
                stacktrace: ex.stacktrace
            };
        });

        if (exceptionsToLog.length) {
            const dataContext = this.injector.get(DataContextService) as DataContextService;
            const appVersion = getAppVersion();
            exceptionsToLog.forEach(e => e["appVersion"] = appVersion);
            return dataContext.post("/api/logs", exceptionsToLog);
        }

        return Promise.resolve();
    }

}

function needToLog(exception: ExceptionInfo): boolean {
    const {rawException} = exception;
    if (rawException && rawException.rejection instanceof FailServerResponse) {
        return (rawException.rejection as FailServerResponse).statusCode !== 0; //network problem?
    }
    return true;
}
