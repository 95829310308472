import {Injectable} from "@angular/core";

export interface INIconMetadata {
    size: string;
    fill: string;
    border?: string;
}

export interface INIconSet {
    [iconId: string]: INIconMetadata[];
}

export abstract class NFrameworkIconsFactory {
    /**
     * Create icon for specified iconId
     * @param iconSet                   icons set (namespace)
     * @returns                         promise of created icon.
     */
    abstract getIconSet(iconSet?: string): Promise<INIconSet | undefined>;
}

@Injectable({
    providedIn: "root"
})
export class EmptyIconsFactory extends NFrameworkIconsFactory {

    getIconSet(iconId: string, iconSet?: string): Promise<INIconSet | undefined> {
        return Promise.resolve(undefined);
    }

}
