import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "./translate.service";
import {Subscription} from "rxjs";

@Pipe({
    name: "translate",
    pure: false
})
export class TranslatePipe implements PipeTransform, OnDestroy {

    private subscription: Subscription;

    constructor(private translate: TranslateService, private _ref: ChangeDetectorRef) {
        this.subscription = this.translate.onLocaleChange.subscribe(() => {
            this._ref.markForCheck();
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
    }

    transform(key: string, p?: { [key: string]: any }): string {
        if (!key) {
            return "";
        }
        return this.translate.getString(key, p);
    }

}
