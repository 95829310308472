import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ModalService} from "../../../../modules/modal";

@Component({
    selector: "lightbox-header",
    templateUrl: "./lightbox-header.html",
    styleUrls: [
        "./lightbox-header.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LightboxHeaderComponent {

    @Input()
    title: string;

    constructor(private modals: ModalService) {
    }

    close() {
        this.modals.closeLast();
    }
}
