<ng-template #innerTmpl>
        <span class="icon">
            <ng-content></ng-content>
        </span>
    <span class="text">{{text}}</span>
    <span *ngIf="hotKeyLabel" class="hotkey">{{hotKeyLabel}}</span>
</ng-template>

<ng-template #blockTmpl>
    <button [ngClass]="typeClass" class="item">
        <ng-container *ngTemplateOutlet="innerTmpl"></ng-container>
    </button>
</ng-template>

<a *ngIf="route; else blockTmpl"
   [class._invited]="invited"
   [ngClass]="typeClass"
   [routerLinkActiveOptions]="{exact: true}"
   [routerLinkActive]="'_selected'"
   [routerLink]="route"
   class="item"
   draggable="false">
    <ng-container *ngTemplateOutlet="innerTmpl"></ng-container>
</a>
