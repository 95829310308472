import {AfterViewChecked, ChangeDetectionStrategy, Component, EventEmitter, OnDestroy} from "@angular/core";
import {Observable, Subject, takeUntil} from "rxjs";
import {PopoverContext} from "../../../modules/n-popover";
import {SearchSuggestItem} from "../../models/search/SearchSuggestItem";

@Component({
    selector: "mention-suggests",
    templateUrl: "./mention-suggests.html",
    styleUrls: [
        "./mention-suggests.ng.css"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MentionSuggestsComponent implements AfterViewChecked, OnDestroy {

    mentions$: Observable<SearchSuggestItem[]>;
    activeId$: Observable<string>;
    onMouseDown?: () => void;
    private readonly onSelect: EventEmitter<SearchSuggestItem>;
    private rendered$: EventEmitter<any>;
    private componentExist$ = new Subject();
    private updatePos: boolean;

    constructor(private ctx: PopoverContext<{
        mentions$: Observable<SearchSuggestItem[]>,
        activeId$: Observable<string>,
        onSelect?: EventEmitter<SearchSuggestItem>,
        onMouseDown?: () => void,
        rendered$: EventEmitter<any>
    }>) {
        this.mentions$ = ctx.popoverData.mentions$
            .pipe(
                takeUntil(this.componentExist$)
            );
        this.onSelect = ctx.popoverData.onSelect;
        this.activeId$ = ctx.popoverData.activeId$;
        this.rendered$ = ctx.popoverData.rendered$;
        this.onMouseDown = ctx.popoverData.onMouseDown;

        this.mentions$.subscribe(() => {
            this.updatePos = true;
        });
    }

    ngAfterViewChecked(): void {
        // this.rendered$.emit();
        if (this.updatePos) {
            this.updatePos = false;
            this.ctx.updatePosition();
        }
    }

    ngOnDestroy(): void {
        this.componentExist$.next(1);
        this.componentExist$.complete();
    }

    select(suggest: SearchSuggestItem) {
        if (this.onSelect) {
            this.onSelect.emit(suggest);
        }
        this.ctx.done(suggest);
    }

    suggestIdentity = (indx, suggest: SearchSuggestItem) => suggest.id;
}
