export class ImageInfo {

    path: string;
    width: number;
    height: number;

    static parse(data: any) {
        let info = new ImageInfo();
        info.width = data.width || 0;
        info.height = data.height || 0;
        info.path = data.path;
        return info;
    }

    static parseArray(data: any[]): ImageInfo[] {
        return data.map(d => ImageInfo.parse(d));
    }

}
