import {Injectable} from "@angular/core";
import {distinctUntilChanged, Observable} from "rxjs";
import {MemorySubject} from "../../modules/store";
import {TranslateService} from "../../modules/translate";

@Injectable({providedIn: "root"})
export class TitleService {

    private _docTitle$ = new MemorySubject<string>("Renote");

    constructor(private translateSvc: TranslateService) {

    }

    private _pageTitle$ = new MemorySubject<string>();

    get pageTitle$(): Observable<string> {
        return this._pageTitle$.asObservable()
            .pipe(
                distinctUntilChanged()
            );
    }

    private _pageContentTitle$ = new MemorySubject<{ content: string, scrollOffset: number }>(undefined);

    get pageContentTitle$(): Observable<{ content: string, scrollOffset: number }> {
        return this._pageContentTitle$.asObservable()
            .pipe(
                distinctUntilChanged()
            );
    }

    get documentTitle$(): Observable<string> {
        return this._docTitle$.asObservable()
            .pipe(
                distinctUntilChanged()
            );
    }

    setDocumentTitle(title: string, translate?: boolean) {
        if (!title) {
            title = "Renote";
        } else {
            if (translate) title = this.translateSvc.getString(title);
            title = `${title} - Renote`;
        }

        this._docTitle$.next(title);
        window.document.title = title;
    }

    setPageTitle(title: string, contentTitle?: { content: string, scrollOffset: number }, translateTitle?: boolean) {
        this._pageTitle$.next(translateTitle ? this.translateSvc.getString(title) : title);
        this._pageContentTitle$.next(contentTitle);
    }

}
