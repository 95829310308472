import {ComponentFactoryResolver, ComponentRef, Injector, StaticProvider, TemplateRef, ViewContainerRef} from "@angular/core";
import {PopoverContext} from "./PopoverContext";

export abstract class PopoverCompileContext {
    abstract compile(viewContainer: ViewContainerRef): Promise<any>;
}

/**
 * Create popover content dynamically from component type
 */
export class DynamicPopoverCompileContext extends PopoverCompileContext {


    constructor(private cr: ComponentFactoryResolver,
                private popoverCtx: PopoverContext<any>,
                public componentType: any,
                private bindings: StaticProvider[]) {
        super();
    }

    compile(viewContainer: ViewContainerRef): Promise<any> {
        const cmpFactory = this.cr.resolveComponentFactory(this.componentType);

        const vcr = viewContainer, ctxInjector = vcr.parentInjector;
        const childInjector = Injector.create({
            providers: this.bindings,
            parent: ctxInjector
        });
        this.popoverCtx.instance = vcr.createComponent(cmpFactory, vcr.length, childInjector);

        return Promise.resolve();
    }

}

/**
 * Create popover content from specified component instance
 */
export class InstancePopoverCompileContext extends PopoverCompileContext {

    constructor(private instance: ComponentRef<any>,
                private popoverCtx: PopoverContext<any>) {
        super();
    }

    compile(viewContainer: ViewContainerRef): Promise<any> {
        this.popoverCtx.instance = this.instance;
        return Promise.resolve();
    }

}

export class TemplateCompileContext extends PopoverCompileContext {

    constructor(private templateRef: TemplateRef<any>, private popoverCtx: PopoverContext<any>) {
        super();
    }

    compile(viewContainer: ViewContainerRef): Promise<any> {
        const view = viewContainer.createEmbeddedView(this.templateRef, {popover: this.popoverCtx});
        return Promise.resolve();
    }

}
